import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Form, message, Modal, Popconfirm, Table, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { ColumnsType } from "antd/es/table";
import { I18n } from "react-redux-i18n";
import { UserType } from "../../../types/userType";
import { renderNullableDate, renderNullableText } from "../../../utils/renderNullableText";
import { roleToLabel, UserRolesEnum } from "../../../types/userRoles";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import store, { RootState } from "../../../store";
import { deleteSubUser } from "../../../store/slices/users/usersService";
import { useActions } from "../../../hooks/useActions";
import { PasswordResetFormItems } from "./helpers";
import FooterActions from "../../../components/FooterActions";

const columns = (handlePasswordChange: (userId?: string) => void): ColumnsType<UserType> => [
  {
    title: I18n.t("email"),
    dataIndex: "email",
    key: "email",
    render: renderNullableText,
  },
  {
    title: I18n.t("roles"),
    dataIndex: "roles",
    key: "roles",
    render: (roles: string[]) => (
      <div style={{ display: "grid" }}>
        {!roles
          ? "N/A"
          : roles
              .filter((role) => role === UserRolesEnum.SUB_USER_READ || role === UserRolesEnum.SUB_USER_EDIT)
              .map((role) => <span style={{ lineHeight: 1.2 }}>{roleToLabel(role as UserRolesEnum)}</span>)}
      </div>
    ),
  },
  {
    key: "createdAt",
    dataIndex: "createdAt",
    title: I18n.t("createDate"),
    render: (_, record) => renderNullableDate(record?.createdAt),
  },
  {
    title: I18n.t("actions"),
    key: "action",
    render: (text, record) => {
      return (
        <div style={{ display: "flex" }}>
          <Tooltip title={I18n.t("passwordChange")}>
            <Button type={"text"} onClick={() => handlePasswordChange(record?.id)}>
              <ReloadOutlined />
            </Button>
          </Tooltip>
          <Popconfirm title={I18n.t("userDeleteConfirmationText")} onConfirm={() => subUserDeleteHandler(record?.id)}>
            <Button type={"text"}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      );
    },
  },
];

function subUserDeleteHandler(id?: string) {
  if (!id) return;
  store.dispatch(deleteSubUser(id));
}

const SubUsersTableComponent: FC = () => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<{ newPassword: string; subUserId: string }>();
  const { getSubUsers, resetSubUsersPassword } = useActions();

  const data = useSelector((state: RootState) => state.users.subUsersList);

  const handlePasswordChange = useCallback(
    (subUserId?: string) => {
      setOpen(true);
      form.setFieldsValue({ subUserId });
    },
    [form],
  );

  const handleCancel = useCallback(() => {
    setOpen(false);
    form.resetFields();
  }, [setOpen, form]);

  const handleSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        resetSubUsersPassword(values);
        handleCancel();
      })
      .catch(() => message.error(I18n.t("fillRequiredFields")));
  }, [form, handleCancel]);

  useEffect(() => {
    getSubUsers();
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <Table
        pagination={{
          size: "small",
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        dataSource={data}
        columns={columns(handlePasswordChange)}
        rowKey={(record: UserType) => record?.id + ""}
      />
      <Modal
        open={open}
        onCancel={handleCancel}
        title={I18n.t("passwordChange")}
        footer={<FooterActions onSubmit={handleSubmit} onCancel={handleCancel} submitLabelTranslationKey={"save"} />}
      >
        <Form form={form} layout={"vertical"} style={{ marginTop: 30 }}>
          <PasswordResetFormItems />
        </Form>
      </Modal>
    </div>
  );
};

export default SubUsersTableComponent;
