import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { DELETE, GET, POST, PUT } from "../../../types/httpMethod";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import { addToList, removeFromList, setCurrent, setList, updateInList } from "./qrCodesSlice";
import { QrCodeCreateType, QrCodeType } from "../../../types/qrCode";

export const getQrCodesBySurveyId = createAsyncThunk<void, string>(
  "qrCodes/getQrCodesBySurveyId",
  async (surveyId, { dispatch }) => {
    try {
      const res = await requestApi(GET, `/qrcode/survey/${surveyId}`, {});
      dispatch(setQrCodesList(res));
    } catch (e) {
      throw e;
    }
  },
);

export const getQrCodeById = createAsyncThunk<void, string>("qrCodes/getQrCodeById", async (qrCodeId, { dispatch }) => {
  try {
    const res = await requestApi(GET, `/qrcode/${qrCodeId}`, {});
    dispatch(setCurrentQrCode(res));
  } catch (e) {
    throw e;
  }
});

export const createQrCode = createAsyncThunk<void, QrCodeCreateType>(
  "qrCodes/createQrCode",
  async (qrCode, { dispatch }) => {
    try {
      const res = await requestApi(POST, "/qrcode", qrCode);
      dispatch(addQrCodeToList(res));
      message.success(I18n.t("qrCodeCreated"));
    } catch (e) {
      throw e;
    }
  },
);

export const updateQrCode = createAsyncThunk<
  void,
  {
    readonly qrCodeId: string;
    readonly qrCode: QrCodeType;
  }
>("qrCodes/updateQrCode", async ({ qrCodeId, qrCode }, { dispatch }) => {
  try {
    const res = await requestApi(PUT, `/qrcode/${qrCodeId}`, qrCode);
    dispatch(updateQrCodeInList(res));
    message.success(I18n.t("qrCodeUpdated"));
  } catch (e) {
    throw e;
  }
});

export const deleteQrCodeById = createAsyncThunk<void, string>(
  "qrCodes/deleteQrCodeById",
  async (qrCodeId, { dispatch }) => {
    try {
      await requestApi(DELETE, `/qrcode/${qrCodeId}`, {});
      dispatch(removeQrCodeFromList(qrCodeId));
      message.success(I18n.t("qrCodeDeleted"));
    } catch (e) {
      throw e;
    }
  },
);

export const setQrCodesList = createAsyncThunk<void, Array<QrCodeType>>(
  "qrCodes/setQrCodesList",
  (qrCodes, { dispatch }) => {
    dispatch(setList(qrCodes));
  },
);

export const setCurrentQrCode = createAsyncThunk<void, QrCodeType | undefined>(
  "qrCodes/setCurrentQrCode",
  (qrCode, { dispatch }) => {
    dispatch(setCurrent(qrCode));
  },
);

export const removeQrCodeFromList = createAsyncThunk<void, string>(
  "qrCodes/removeQrCodeFromList",
  (qrCodeId, { dispatch }) => {
    dispatch(removeFromList(qrCodeId));
  },
);

export const addQrCodeToList = createAsyncThunk<void, QrCodeType>("qrCodes/addQrCodeToList", (qrCode, { dispatch }) => {
  dispatch(addToList(qrCode));
});

export const updateQrCodeInList = createAsyncThunk<void, QrCodeType>(
  "qrCodes/updateQrCodeInList",
  (qrCode, { dispatch }) => {
    dispatch(updateInList(qrCode));
  },
);
