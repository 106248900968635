import React, { FC, useCallback, useState } from "react";
import InputFormItemComponent from "../../../../components/form/items/input";
import { RootState } from "../../../../store";
import { useSelector } from "react-redux";
import TagsFormItemComponent from "../../../../components/form/items/tagsInput";
import { I18n, Translate } from "react-redux-i18n";
import SelectFormItemComponent from "../../../../components/form/items/select";
import useSurveyDisabled from "../../../../hooks/useSurveyDisabled";
import { Button, message, notification, Select } from "antd";
import { BusinessCategories, SurveyGoals, useAiAssistant } from "../../../../hooks/useAiAssistant";
import { SURVEYS_PAGE } from "../../../../utils/paths";
import { useNavigate } from "react-router-dom";
import { DEFAULT_ERROR_LABEL, DEFAULT_QUESTION } from "../../../../utils";
import { useRequests } from "../../../../hooks/useRequests";
import { QuestionType } from "../../../../types/questionType";
import { getSurveyLanguages, langTitle, Language } from "../../../../types/language";
import { END_QUESTION, INITIAL_SURVEY_AI_DATA, QUESTIONS_DATA } from "../../../../utils/surveyTemplate";
import { useActions } from "../../../../hooks/useActions";
import { StepsEnum } from "../../../../types/surveyEditTabsEnum";
import { AiLoader } from "../../../../components/aiLoader";
import { CreateSurveyTourItemsEnum, TourItemsEnum } from "../../../../types/tour";
import { useTourCtx } from "../../../../hooks/providers/tours/TourProvider";
import HiddenValueFormItemComponent from "../../../../components/form/items/hiddenValue";
import { ChannelEnum } from "../../../../types/channelEnum";

type Props = {
  readonly createByAI?: Boolean;
};

const WELCOME_SMS_INDEX = 0;

const ConfigurationsStepComponent: FC<Props> = ({ createByAI }) => {
  const {
    data: {
      [TourItemsEnum.CREATE_SURVEY]: { refs },
    },
  } = useTourCtx();

  const survey = useSelector((state: RootState) => state.surveys.current);
  const languages = useSelector((state: RootState) => state.languages.list);
  const { createSurvey, createQuestion } = useRequests();
  const { setSurveyEditActiveStep } = useActions();
  const navigate = useNavigate();
  const disabled = useSurveyDisabled();
  const { request } = useAiAssistant();
  const [loading, setLoading] = useState(false);
  const [goal, setGoal] = useState<string | undefined>();
  const [business, setBusiness] = useState<string | undefined>();
  const [errors, setErrors] = useState<{ goal: boolean; business: boolean }>({ business: false, goal: false });

  const handleCreateSurveyByAI = useCallback(async () => {
    if (!goal || !business) {
      setErrors({ goal: !goal, business: !business });
      return message.error(I18n.t("fillRequiredFields"));
    }
    if (errors.goal || errors.business) {
      setErrors({ goal: !goal, business: !business });
    }
    setLoading(true);
    request(goal, business)
      .then(async (res) => {
        const newSurvey = await createSurvey(INITIAL_SURVEY_AI_DATA(goal.concat(", ", business)) as any);
        await createQuestion(newSurvey.surveyId, QUESTIONS_DATA[WELCOME_SMS_INDEX] as any);

        const newQuestions = res.questions.map((question, orderIdx) => {
          const obj: QuestionType = {
            ...DEFAULT_QUESTION,
            order: orderIdx + 2,
            name: question.question_title,
            textTranslations: Object.keys(question.question_text).map((lang) => ({
              language: { langCode: lang, langName: langTitle[lang as Language] },
              text: question.question_text[lang as Language],
            })),
            answerTemplate: {
              ...DEFAULT_QUESTION.answerTemplate,
              type: question.type as any,
              answerChoices: Object.keys(question.answer_choices?.RU).map((_, choiceIndex) => {
                return {
                  order: choiceIndex,
                  choiceEn: "",
                  choiceKk: "",
                  choiceRu: "",
                  textTranslations: Object.keys(question.answer_choices).map((lang) => ({
                    language: { langCode: lang, langName: langTitle[lang as Language] },
                    text: question.answer_choices[lang as Language][choiceIndex],
                  })),
                };
              }),
            },
          };
          return obj;
        });

        const promises = newQuestions.map((question) => createQuestion(newSurvey.surveyId, question));
        await Promise.all([
          ...promises,
          createQuestion(
            newSurvey.surveyId,
            END_QUESTION(promises.length + 2, getSurveyLanguages([Language.RU, Language.EN])),
          ),
        ]);

        navigate(SURVEYS_PAGE.concat("/", newSurvey.surveyId, "/edit"));
        setSurveyEditActiveStep(StepsEnum.QUESTIONS);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        notification.error({ message: I18n.t(DEFAULT_ERROR_LABEL) });
      });
  }, [request, setLoading, goal, business]);

  if (createByAI) {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          <h1 style={{ fontWeight: 600 }}>AI-ассистент</h1>
          <h3 style={{ fontWeight: 600, marginBottom: 24 }}>
            Наш AI-ассистент поможет вам создать опрос, пожалуйста ответьте на следующие вопросы
          </h3>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "inherit",
              marginBottom: 24,
            }}
          >
            <h4>
              Укажите цель опроса? <span style={{ color: "red", fontWeight: 600, fontSize: 17 }}>*</span>
            </h4>
            <Select
              disabled={loading}
              value={goal}
              onSelect={(val) => {
                if (errors.goal) {
                  setErrors((prev) => ({ ...prev, goal: false }));
                }
                setGoal(val);
              }}
              placeholder={"Выберите цель опроса"}
              style={{ width: 370, border: errors.goal ? "1px solid red" : undefined, borderRadius: 7 }}
              options={SurveyGoals.map((it) => ({ label: it, value: it }))}
            />
            {errors.goal && <Translate value={"requiredField"} style={{ color: "red" }} />}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "inherit",
              marginBottom: 24,
            }}
          >
            <h4>
              Укажите к какой категории относится ваш бизнес:{" "}
              <span style={{ color: "red", fontWeight: 600, fontSize: 17 }}>*</span>
            </h4>
            <Select
              disabled={loading}
              value={business}
              placeholder={"Выберите категорию бизнеса"}
              onSelect={(val) => {
                if (errors.business) {
                  setErrors((prev) => ({ ...prev, business: false }));
                }
                setBusiness(val);
              }}
              style={{ width: 370, border: errors.business ? "1px solid red" : undefined, borderRadius: 7 }}
              options={BusinessCategories.map((it) => ({ label: it, value: it }))}
            />
            {errors.business && <Translate value={"requiredField"} style={{ color: "red" }} />}
          </div>
          {!loading && (
            <div style={{ display: "flex", gap: 10, justifyContent: "flex-end", marginTop: 90 }}>
              <Button onClick={() => navigate(SURVEYS_PAGE)}>
                <Translate value={"cancel"} />
              </Button>
              <Button loading={loading} onClick={handleCreateSurveyByAI} type={"primary"}>
                <Translate value={"next"} />
              </Button>
            </div>
          )}
        </div>
        {loading && (
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <AiLoader />
            <h2 style={{ whiteSpace: "nowrap" }}>Наш AI разогревается, пожалуйста, подождите немного…</h2>

            <div style={{ display: "flex", gap: 10, justifyContent: "flex-end", marginTop: 90 }}>
              <Button onClick={() => navigate(SURVEYS_PAGE)}>
                <Translate value={"cancel"} />
              </Button>
              <Button loading={loading} type={"primary"}>
                <Translate value={"next"} />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div style={{ maxWidth: 630 }} ref={refs[CreateSurveyTourItemsEnum.SURVEY_NAME]}>
        <InputFormItemComponent name={"surveyName"} label={I18n.t("surveyName")} />
      </div>
      <HiddenValueFormItemComponent name={["communicationChannel"]} value={ChannelEnum.WEB} />
      {/*<div style={{ maxWidth: 630 }} ref={refs[CreateSurveyTourItemsEnum.SURVEY_TYPE]}>*/}
      {/*  <RadioFormItemComponent*/}
      {/*    values={communicationChannelTypes}*/}
      {/*    label={I18n.t("surveyType")}*/}
      {/*    name={"communicationChannel"}*/}
      {/*    disabled={!!survey?.status}*/}
      {/*    required={false}*/}
      {/*  />*/}
      {/*</div>*/}
      <div style={{ maxWidth: 630 }} ref={refs[CreateSurveyTourItemsEnum.TAGS]}>
        <TagsFormItemComponent label={I18n.t("tags")} disabled={disabled} type={"surveys"} name={"tags"} searchable />
      </div>
      <div style={{ maxWidth: 630 }} ref={refs[CreateSurveyTourItemsEnum.LANGUAGES]}>
        <SelectFormItemComponent
          required
          mode={"multiple"}
          name={"languages"}
          disabled={!!survey?.surveyId}
          label={I18n.t("languages")}
          values={languages.map((it) => ({
            label: it.langName,
            value: it.langCode,
          }))}
        />
      </div>
    </div>
  );
};

export default ConfigurationsStepComponent;
