import React, { ReactNode } from "react";
import ConfigurationsStepComponent from "../pages/surveys/edit/steps/configurations";
import PersonalizationComponent from "../pages/surveys/edit/steps/personalization";
import IntegrationsComponent from "../pages/surveys/edit/steps/integrations";
import ResultsPage from "../pages/surveys/edit/steps/results/index";
import QuestionsComponent from "../pages/surveys/edit/steps/questions/index";
import { I18n } from "react-redux-i18n";
import SharePage from "../pages/surveys/edit/steps/share";

export enum StepsEnum {
  CONFIGURATIONS = "CONFIGURATIONS",
  SHARE = "SHARE",
  QUESTIONS = "QUESTIONS",
  PERSONALIZATION = "PERSONALIZATION",
  INTEGRATIONS = "INTEGRATIONS",
  RESULTS = "RESULTS",
}

export const surveyEditPageTabItems = () => [
  {
    key: StepsEnum.CONFIGURATIONS,
    label: I18n.t("general"),
  },
  {
    key: StepsEnum.QUESTIONS,
    label: I18n.t("questionnaireQuestions"),
  },
  {
    key: StepsEnum.PERSONALIZATION,
    label: I18n.t("personalization"),
  },
  {
    key: StepsEnum.SHARE,
    label: I18n.t("share"),
  },
  {
    key: StepsEnum.INTEGRATIONS,
    label: I18n.t("integrations"),
  },
  {
    key: StepsEnum.RESULTS,
    label: I18n.t("results"),
  },
];

export const surveyEditPageTabContent = (createByAI?: boolean): Record<StepsEnum, ReactNode> => ({
  [StepsEnum.CONFIGURATIONS]: <ConfigurationsStepComponent createByAI={createByAI} />,
  [StepsEnum.SHARE]: <SharePage />,
  [StepsEnum.QUESTIONS]: <QuestionsComponent />,
  [StepsEnum.PERSONALIZATION]: <PersonalizationComponent />,
  [StepsEnum.INTEGRATIONS]: <IntegrationsComponent />,
  [StepsEnum.RESULTS]: <ResultsPage />,
});
