import "./index.scss";
import { FC, useEffect } from "react";
import { Button, Popconfirm, Table } from "antd";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../store";
import { ColumnsType } from "antd/es/table";
import { OffDayType } from "../../../../types/offDayType";
import { useActions } from "../../../../hooks/useActions";
import { deleteOffDay } from "../../../../store/slices/offDays/offDaysService";
import { renderNullableText } from "../../../../utils/renderNullableText";
import { I18n, Translate } from "react-redux-i18n";

const columns = (): ColumnsType<OffDayType> => [
  {
    title: I18n.t("date"),
    dataIndex: "day",
    key: "day",
    render: renderNullableText,
  },
  {
    title: I18n.t("description"),
    dataIndex: "description",
    key: "description",
    render: renderNullableText,
  },
  {
    title: I18n.t("action"),
    key: "action",
    render: (text, record) => (
      <Popconfirm
        title={I18n.t("deleteDateConfirmation")}
        onConfirm={() => offDayDeleteHandler(record?.id)}
      >
        <Button danger type={"text"}>
          <Translate value={"delete"} />
        </Button>
      </Popconfirm>
    ),
  },
];

function offDayDeleteHandler(id?: number) {
  if (!id) return;
  store.dispatch(deleteOffDay(id));
}

const OffDaysTableComponent: FC = () => {
  const data = useSelector((state: RootState) => state.offDays.list);
  const { getOffDays } = useActions();

  useEffect(() => {
    getOffDays();
  }, []);

  return (
    <Table
      pagination={{
        size: "small",
        showSizeChanger: true,
        defaultPageSize: 10,
      }}
      dataSource={data}
      columns={columns()}
      rowKey={(record: OffDayType) => record?.id + ""}
    />
  );
};

export default OffDaysTableComponent;
