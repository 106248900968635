import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { GET, POST } from "../../../types/httpMethod";
import { setConditionsList } from "./conditionsSlice";
import { ConditionType, SubscriberCondition } from "../../../types/subscriberConditionType";
import { message } from "antd";
import { setQuantity } from "../surveys/surveysSlice";
import { I18n } from "react-redux-i18n";

export const getConditions = createAsyncThunk<void, undefined>("conditions/getConditions", async (_, { dispatch }) => {
  try {
    const res: Array<ConditionType> = await requestApi(GET, "/conditions/subscriber", {});
    dispatch(setConditionsList(res));
  } catch (e) {
    throw e;
  }
});

export const calculateConditions = createAsyncThunk<void, Array<SubscriberCondition>>(
  "conditions/calculateConditions",
  async (conditions, { dispatch }) => {
    try {
      const res: number = await requestApi(POST, "/conditions/calculate", conditions);
      dispatch(setQuantity(res));
      message.info(`${I18n.t("respondentsCount")}: ${res}`);
    } catch (e) {
      console.log(e);
    }
  },
);

export const setConditionsQuantity = createAsyncThunk<void, number>(
  "conditions/setConditionsQuantity",
  (quantity, { dispatch }) => {
    dispatch(setQuantity(quantity));
  },
);
