import { FC } from "react";
import { DatePicker, Form } from "antd";
import { FormItemType } from "../../../types/formItemType";
import { DEFAULT_VALIDATION_MESSAGE } from "../../../utils";
import dayjs from "dayjs";
import { I18n } from "react-redux-i18n";

const DatepickerFormItemComponent: FC<
  FormItemType & {
    readonly disablePast?: boolean;
    readonly format?: string;
  }
> = (props) => {
  const {
    name,
    label,
    required = true,
    disabled,
    disablePast = true,
    hasFeedback = true,
    noStyle,
    onChange,
    style,
    format,
  } = props;

  return (
    <Form.Item
      name={name}
      label={label}
      noStyle={noStyle}
      hasFeedback={hasFeedback}
      rules={[{ required: required, message: I18n.t(DEFAULT_VALIDATION_MESSAGE) }]}
    >
      <DatePicker
        format={format}
        showNow={false}
        allowClear={false}
        onChange={onChange}
        disabled={disabled}
        inputReadOnly={true}
        showToday={!disablePast}
        style={{ width: "100%", ...style }}
        disabledDate={!disablePast ? undefined : (date) => dayjs(date).isBefore(new Date())}
      />
    </Form.Item>
  );
};

export default DatepickerFormItemComponent;
