import "./index.scss";
import React, { FC, useCallback, useState, useMemo } from "react";
import { Button, Form, Popconfirm, Table, Typography } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import {
  ContactsAttributeDatatypeEnum,
  ContactsAttributeType,
  TranslateDataTypeKey,
} from "../../../types/ContactsAttributeType";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useActions } from "../../../hooks/useActions";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import InputFormItemComponent from "../../../components/form/items/input";
import { renderNullableDate } from "../../../utils/renderNullableText";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "text";
  record: ContactsAttributeType;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps} className={"children-form-item-no-margin"}>
      {editing ? (
        <InputFormItemComponent name={dataIndex} required hasFeedback={false} style={{ marginBottom: 0 }} />
      ) : (
        children
      )}
    </td>
  );
};

const ContactsAttributesTable: FC = () => {
  const [form] = Form.useForm<ContactsAttributeType>();
  const data = useSelector((state: RootState) => state.contacts.attributesList);
  const { updateAttribute, deleteAttribute } = useActions();
  const [editingKey, setEditingKey] = useState<string | undefined>();

  const isEditing = useCallback((record: ContactsAttributeType) => record.propertyId === editingKey, [editingKey]);

  const edit = useCallback(
    (record: Partial<ContactsAttributeType>) => {
      form.setFieldsValue({ ...record });
      setEditingKey(record?.propertyId);
    },
    [form],
  );

  const cancel = useCallback(() => {
    setEditingKey("");
  }, []);

  const save = useCallback(
    (propertyId?: string) => {
      form
        .validateFields()
        .then((values) => {
          const found = data.find((it) => it?.propertyId === propertyId);
          if (found && propertyId) {
            updateAttribute({
              attributeId: propertyId,
              values: { ...found, ...values },
            });
          }
          setEditingKey(undefined);
        })
        .catch(console.log);
    },
    [form, data, updateAttribute],
  );

  const translateKeys = useCallback(
    (translations: Record<ContactsAttributeDatatypeEnum, string>): Record<string, () => JSX.Element> => {
      return Object.keys(translations).reduce((acc, key) => {
        acc[key] = () => <Translate value={translations[key as ContactsAttributeDatatypeEnum]} />;
        return acc;
      }, {} as Record<string, () => JSX.Element>);
    },
    [],
  );

  const translated = useMemo(() => translateKeys(TranslateDataTypeKey), []);

  const columns = [
    {
      title: I18n.t("attributeName"),
      dataIndex: "description",
      width: "25%",
      editable: true,
    },
    {
      title: I18n.t("propertyKey"),
      dataIndex: "propertyKey",
      width: "15%",
    },
    {
      title: I18n.t("datatype"),
      dataIndex: "dataType",
      width: "10%",
      render: (text: string) => {
        const translator = translated[text as keyof typeof translated];
        return translator ? translator() : text;
      },
    },
    {
      title: I18n.t("whoAdded"),
      dataIndex: "createdBy",
      width: "20%",
    },
    {
      title: I18n.t("whenAdded"),
      dataIndex: "createdAt",
      width: "20%",
      render: renderNullableDate,
    },
    {
      title: I18n.t("actions"),
      dataIndex: "operation",
      render: (_: any, record: ContactsAttributeType) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Typography.Link onClick={() => save(record.propertyId)} style={{ marginRight: 8 }}>
              <Translate value={"save"} />
            </Typography.Link>
            <Popconfirm title={I18n.t("cancelAllChangesConfirmation")} onConfirm={cancel}>
              <a href="/" onClick={(e) => e.preventDefault()}>
                <Translate value={"cancel"} />
              </a>
            </Popconfirm>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <Button type={"text"} onClick={() => edit(record)}>
              <EditOutlined />
            </Button>
            <Popconfirm
              title={I18n.t("attributeDeleteConfirmation")}
              onConfirm={() => {
                if (record?.propertyId) {
                  deleteAttribute(record.propertyId);
                }
              }}
            >
              <Button type={"text"}>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ContactsAttributeType) => ({
        record,
        title: col.title,
        inputType: "text",
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div style={{ marginTop: 24 }}>
      <Form form={form} component={false}>
        <Table
          dataSource={data}
          columns={mergedColumns}
          pagination={{ onChange: cancel }}
          components={{ body: { cell: EditableCell } }}
          rowKey={(record: ContactsAttributeType) => record?.propertyId + ""}
        />
      </Form>
    </div>
  );
};

export default ContactsAttributesTable;
