import { DistributionType } from "../types/distributionType";
import dayjs from "dayjs";
import { DistributionChannel } from "../types/DistributionChannel";

export const sortDistributions = (list: Array<DistributionType>) => {
  return list
    .map((it) => it)
    .sort((a, b) => {
      const valA = dayjs(a.createdAt);
      const valB = dayjs(b.createdAt);
      return valA.isAfter(valB) ? -1 : 1;
    });
};

export const sortDistributionChannels = (list: Array<DistributionChannel>) => {
  return list
    .map((it) => it)
    .sort((a, b) => {
      const valA = dayjs(a.createdAt);
      const valB = dayjs(b.createdAt);
      return valA.isAfter(valB) ? -1 : 1;
    });
};
