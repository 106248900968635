import { FC, useCallback, useEffect, useMemo, useState } from "react";
import StatisticsLayout from "../../../../../../components/statisticsLayout";
import { I18n } from "react-redux-i18n";
import { ChartViewTypeEnum, mapValuesFromStringToNumber } from "../helpers";
import { LabelValue } from "../../../../../../types/labelValue";
import { ColumnChart } from "../../../../../../components/charts";
import { useParams } from "react-router-dom";
import { requestApi } from "../../../../../../utils/requestApi";
import { POST } from "../../../../../../types/httpMethod";

const AverageTime: FC = () => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
      <Chart type={ChartViewTypeEnum.DEVICE} title={"avgTime"} subTitle={"byDeviceType"} />
      <Chart type={ChartViewTypeEnum.LANGUAGE} subTitle={"byLanguage"} />
      <Chart type={ChartViewTypeEnum.MAILING} subTitle={"byDistributionType"} />
    </div>
  );
};

export default AverageTime;

type ChartProps = { type: ChartViewTypeEnum; title?: string; subTitle: string };

const Chart: FC<ChartProps> = ({ type, title, subTitle }) => {
  const { data, loading } = useLoadDataByCategory(type);
  return (
    <StatisticsLayout
      padding={"40px"}
      subTitle={I18n.t(subTitle)}
      title={title ? I18n.t(title) : undefined}
      children={<ColumnChart data={data} height={350} loading={loading} />}
    />
  );
};

const useLoadDataByCategory = (type: ChartViewTypeEnum) => {
  const { id: surveyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Array<LabelValue>>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    requestApi(POST, "/chart/average-time", {}, false, false, {}, { surveyId, type })
      .then((res) => setData(mapValuesFromStringToNumber(res)))
      .finally(() => setLoading(false));
  }, [type, surveyId]);

  useEffect(() => loadData(), []);

  return useMemo(() => {
    return { data, loading, reRetch: loadData };
  }, [data, loading]);
};
