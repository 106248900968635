import React, { FC, ReactNode } from "react";
import { I18n } from "react-redux-i18n";
import { Button, Popconfirm } from "antd";

type Props = {
  readonly loading?: boolean;
  readonly disabled?: boolean;
  readonly width?: number;
  readonly gap?: number;
  readonly submitLabel?: string;
  readonly cancelLabel?: string;
  readonly confirmTitle?: string;
  readonly cancelLabelTranslationKey?: string;
  readonly submitLabelTranslationKey?: string;
  readonly onCancel: () => void;
  readonly onSubmit: () => void;
  readonly submitIcon?: ReactNode;
};

const defaultProps = {
  width: "100%",
  loading: false,
  disabled: false,
  cancelLabelTranslationKey: "cancel",
  submitLabelTranslationKey: "save",
};

const FooterActions: FC<Props> = (props) => {
  const {
    gap,
    width,
    loading,
    onSubmit,
    onCancel,
    disabled,
    submitIcon,
    cancelLabel,
    submitLabel,
    confirmTitle,
    cancelLabelTranslationKey,
    submitLabelTranslationKey,
  } = {
    ...defaultProps,
    ...props,
  };
  return (
    <div
      style={{
        width: width,
        marginTop: 30,
        gap: gap ?? 10,
        display: "flex",
        marginBottom: 14,
        justifyContent: "center",
      }}
    >
      <Button onClick={onCancel} style={{ flex: 1 }}>
        {cancelLabel ?? I18n.t(cancelLabelTranslationKey)}
      </Button>
      {confirmTitle ? (
        <Popconfirm
          disabled={disabled}
          title={confirmTitle}
          onConfirm={onSubmit}
          overlayInnerStyle={{ whiteSpace: "nowrap" }}
        >
          <Button icon={submitIcon} type={"primary"} style={{ flex: 1 }} disabled={disabled} loading={loading}>
            {submitLabel ?? I18n.t(submitLabelTranslationKey)}
          </Button>
        </Popconfirm>
      ) : (
        <Button
          type={"primary"}
          loading={loading}
          icon={submitIcon}
          onClick={onSubmit}
          disabled={disabled}
          style={{ flex: 1 }}
        >
          {submitLabel ?? I18n.t(submitLabelTranslationKey)}
        </Button>
      )}
    </div>
  );
};

export default FooterActions;
