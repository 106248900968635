import { FC } from "react";

const Title: FC<{ value: string }> = ({ value }) => {
  return (
    <span
      style={{
        fontSize: 21,
        fontWeight: 400,
        marginBottom: 24,
      }}
    >
      {value}
    </span>
  );
};

export default Title;
