import { WebhookType } from "../../../types/WebhookType";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type StateType = {
  list: Array<WebhookType>;
  current?: WebhookType;
};

const initialState: StateType = {
  list: [],
  current: undefined,
};

const webhooksSlice = createSlice({
  name: "webhooks",
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<Array<WebhookType>>) {
      state.list = action.payload;
    },
    removeFromList(state: StateType, action: PayloadAction<string>) {
      state.list = state.list.filter((it) => it.webhookId !== action.payload);
    },
    setCurrent(
      state: StateType,
      action: PayloadAction<WebhookType | undefined>,
    ) {
      state.current = action.payload;
    },
  },
});

export const { setList, removeFromList, setCurrent } = webhooksSlice.actions;
export default webhooksSlice.reducer;
