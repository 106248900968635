import { SurveyDecor, SurveyMetaData } from "../../../../../types/surveyType";
import { APP_PRIMARY_COLOR } from "../../../../../utils";

export const LOGO_TYPES = ".png, .gif, .jpeg, .jpg, .svg, .svg+xml";
export const FAVICON_TYPES = ".png, .gif, .jpeg, .jpg, .svg, .svg+xml, .ico, .x-icon";
export const DEFAULT_COLOR = APP_PRIMARY_COLOR;
export const MAX_FILE_WEIGHT = 2000000;
export const MAX_LOGO_HEIGHT = 3000;
export const MAX_LOGO_WIDTH = 3840;
export const MIN_LOGO_HEIGHT = 20;
export const MIN_LOGO_WIDTH = 20;
export const MAX_FAVICON_SIZE = 512;
export const SURVEY_DECOR_FONT_MIN_SIZE = 8;
export const SURVEY_DECOR_FONT_MAX_SIZE = 30;

export type DecorFormType = {
  readonly surveyDecor: SurveyDecor;
  readonly surveyMetadata: SurveyMetaData;
  readonly themeColor: string;
};
