import { I18n } from "react-redux-i18n";
import { SurveyStatus } from "./surveyType";
import { APP_PRIMARY_COLOR } from "../utils";

export enum StatusEnum {
  NEW = "NEW",
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  PAUSED = "PAUSED",
  FINISHED = "FINISHED",
  DELETED = "DELETED",
}

type StatusInfoValueType = { backgroundColor: string; textColor: string; label: string };

export const statusInfoRu = (): Record<StatusEnum, StatusInfoValueType> => ({
  [StatusEnum.NEW]: { label: I18n.t("draft"), backgroundColor: "#B6ADE2", textColor: "white" },
  [StatusEnum.ACTIVE]: { label: I18n.t("active"), backgroundColor: "#D6EFE4", textColor: APP_PRIMARY_COLOR },
  [StatusEnum.PAUSED]: { label: I18n.t("paused"), backgroundColor: "#60BD9A", textColor: "#181A29" },
  [StatusEnum.FINISHED]: { label: I18n.t("finished"), backgroundColor: "#1B434D", textColor: "#C7E751" },
  [StatusEnum.DELETED]: { label: I18n.t("deleted"), backgroundColor: "", textColor: "" },
  [StatusEnum.DRAFT]: { label: I18n.t("draft"), backgroundColor: "", textColor: "" },
});

export const surveyStatusInfoRu = (): Record<SurveyStatus, StatusInfoValueType> => ({
  [SurveyStatus.ACTIVE]: { label: I18n.t("active"), backgroundColor: "#D6EFE4", textColor: APP_PRIMARY_COLOR },
  [SurveyStatus.DEACTIVATED]: { label: I18n.t("deactivated"), backgroundColor: "#F7DADC", textColor: "#EE0017" },
  [SurveyStatus.DELETED]: { label: I18n.t("deleted"), backgroundColor: "#F7DADC", textColor: "#EE0017" },
});
