import "./index.scss";
import "antd/dist/reset.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { BrowserRouter } from "react-router-dom";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import "dayjs/locale/kk";
import { PersistGate } from "redux-persist/integration/react";
import dayjs from "dayjs";
import Application from "./application";

dayjs.locale("ru");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor} loading={null}>
        <Application />
      </PersistGate>
    </BrowserRouter>
  </Provider>,
);
