import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FormItemType } from "../../../types/formItemType";
import { Button, Form, message, Upload, UploadFile } from "antd";
import { RcFile } from "antd/es/upload";
import { useActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useParams } from "react-router-dom";
import { I18n, Translate } from "react-redux-i18n";
import { DistributionType } from "../../../types/distributionType";
import { PlusOutlined } from "@ant-design/icons";

type UploadFormItemComponentProps = {
  readonly distributionId?: string;
  readonly hasError?: boolean;
  readonly createdDistributionId?: string;
  readonly subsFile?: Pick<DistributionType, "subsFileName" | "subsFileCount">;
} & FormItemType;

const UploadFormItemComponent: FC<UploadFormItemComponentProps> = (props) => {
  const {
    name,
    label,
    required = true,
    disabled,
    distributionId,
    createdDistributionId,
    subsFile,
    tooltip,
    hasError,
    style,
    wrapperStyles,
    placeHolder,
  } = props;
  const { id: surveyId } = useParams();
  const { uploadSubscribersCsv, deleteSubscribersCsv } = useActions();
  const [subscriberList, setSubscriberList] = useState<UploadFile | undefined>();
  const survey = useSelector((state: RootState) => state.surveys.current);

  const handleUpload = useCallback(
    (file: RcFile) => {
      if (file.type !== "text/csv") {
        message.error(`${I18n.t("fileAcceptType")}: .csv`);
        return false;
      }
      const id = survey?.surveyId ?? surveyId;
      setSubscriberList(file);
      if (!id || !distributionId) {
        return false;
      }
      uploadSubscribersCsv({
        surveyId: id,
        subscriberList: file,
        distributionId,
      });
      return false;
    },
    [surveyId, distributionId, survey?.surveyId],
  );

  const handleDelete = useCallback(() => {
    setSubscriberList(undefined);
    const id = survey?.surveyId || surveyId;
    if (!!id && !!distributionId) {
      deleteSubscribersCsv({ surveyId: id, distributionId });
    }
  }, [surveyId, survey?.surveyId, distributionId]);

  useEffect(() => {
    const id = survey?.surveyId || surveyId;
    if (!!id && createdDistributionId && !!subscriberList) {
      uploadSubscribersCsv({
        surveyId: id,
        distributionId: createdDistributionId,
        subscriberList: subscriberList,
      });
    }
  }, [createdDistributionId]);

  const fileList = useMemo(() => {
    if (subscriberList?.name || subsFile?.subsFileName) {
      return [{ name: subscriberList?.name ?? subsFile?.subsFileName } as UploadFile];
    }
    return [];
  }, [subscriberList, subsFile]);

  return (
    <Form.Item
      label={label}
      tooltip={tooltip}
      required={required}
      style={wrapperStyles}
      className={"upload-parent"}
      {...(hasError && { help: I18n.t("requiredMessage"), validateStatus: "error" })}
    >
      <Upload
        accept={".csv"}
        disabled={disabled}
        fileList={fileList}
        onRemove={handleDelete}
        beforeUpload={handleUpload}
        name={typeof name === "string" ? name : undefined}
      >
        <Button disabled={disabled} style={style} icon={<PlusOutlined />}>
          {placeHolder ?? <Translate value={"selectFile"} />}
        </Button>
      </Upload>
    </Form.Item>
  );
};

export default UploadFormItemComponent;
