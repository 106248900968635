import "../index.scss";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Form, Input, message, Modal, Tag } from "antd";
import {
  DistributionStatus,
  DistributionType,
  ReceiverTypeEnum,
  SourceEnum,
} from "../../../../../../types/distributionType";
import { I18n } from "react-redux-i18n";
import SelectFormItemComponent from "../../../../../../components/form/items/select";
import DatepickerFormItemComponent from "../../../../../../components/form/items/datepicker";
import TimePickerComponent from "../../../../../../components/form/items/timepicker";
import { dayKeysList, DaysEnum, daysLabelRu, daysShortLabelRu } from "../../../../../../types/daysEnum";
import InputNumberFormItemComponent from "../../../../../../components/form/items/inputNumber";
import SubscriberProfileFiltersComponent from "../../../../../../components/conditionsTag";
import dayjs, { Dayjs } from "dayjs";
import { getMomentTime } from "../../../../../../utils/getMomentFromTimeStr";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_ERROR_LABEL,
  DEFAULT_TIME_FORMAT,
  DistributionInitialData,
} from "../../../../../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { useActions } from "../../../../../../hooks/useActions";
import HiddenValueFormItemComponent from "../../../../../../components/form/items/hiddenValue";
import setMaxTimeToDate from "../../../../../../utils/setMaxTimeToDate";
import UploadFormItemComponent from "../../../../../../components/form/items/upload";
import { Outlet } from "react-router-dom";
import FooterActions from "../../../../../../components/FooterActions";
import Divider from "../../../../../../components/divider";
import { SmsProviderType } from "../../../../../../types/smsProvider";
import { EmailProviderType } from "../../../../../../types/EmailProviderType";
import InputFormItemComponent from "../../../../../../components/form/items/input";
import { DistributionChannelTypeEnum } from "../../../../../../types/DistributionChannel";
import { useDistributionsApi } from "../../../../../../hooks/useDistributionsApi";
import { useDistributionChannelsApi } from "../../../../../../hooks/useDistributionChannelsApi";

type DistributionEditModalProps = {
  readonly open: boolean;
  readonly onCancel: () => void;
  readonly data?: DistributionType;
  readonly surveyId?: string;
  readonly descriptionInitial?: string;
  readonly receiverType: ReceiverTypeEnum;
  readonly distributionChannelId?: number;
};

const toDistributionDto = (data: DistributionType): DistributionType => {
  return {
    ...data,
    endDate: setMaxTimeToDate(data?.endDate as Dayjs, DEFAULT_DATE_FORMAT),
    schedule: {
      ...data.schedule,
      fromTime: dayjs(data?.schedule?.fromTime).format(DEFAULT_TIME_FORMAT),
      toTime: dayjs(data?.schedule?.toTime).format(DEFAULT_TIME_FORMAT),
    },
    emailProviderId: data?.emailProviderId ? parseInt(data.emailProviderId + "") : null,
    smsProviderId: data?.smsProviderId ? parseInt(data.smsProviderId + "") : null,
  };
};

const DistributionEditModal: FC<DistributionEditModalProps> = (props) => {
  const { distributionChannelId, descriptionInitial, receiverType, open, onCancel, data, surveyId } = props;
  const [createdDistributionId, setCreatedDistributionId] = useState<string | undefined>();
  const { setCurrentDistributionChannel, setCurrentDistribution } = useActions();
  const [loading, setLoading] = useState(false);

  const { createDistribution, updateDistribution } = useDistributionsApi(surveyId ?? "");
  const { createDistributionChannel, updateDistributionChannel } = useDistributionChannelsApi();
  const [form] = Form.useForm<DistributionType & { description: string }>();

  const attributes = useSelector((state: RootState) => state.contacts.attributesList);
  const subscriberConditions = useSelector((state: RootState) => state.distributions.current?.subscriberConditions);
  const { smsProviders, emailProviders } = useSelector((state: RootState) => {
    return {
      emailProviders: state.emailProviders.list,
      smsProviders: state.smsProviders.list,
    };
  });

  const isReadonly = useMemo(() => {
    return data?.status === DistributionStatus.ACTIVE || data?.status === DistributionStatus.FINISHED;
  }, [data]);

  const isEditing = useMemo(() => !!data?.distributionId, [data]);

  const handleClear = useCallback(() => {
    onCancel();
    form.resetFields();
    setCurrentDistributionChannel(undefined);
    setCurrentDistribution(undefined);
    setLoading(false);
  }, [onCancel, setCurrentDistribution, setCurrentDistributionChannel, setLoading, form]);

  const getSubsFile = useMemo(() => {
    return data?.subsFileName
      ? {
          subsFileCount: data?.subsFileCount,
          subsFileName: data.subsFileName,
        }
      : undefined;
  }, [data]);

  const handleSubmit = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        if (!surveyId) {
          message.error(I18n.t(DEFAULT_ERROR_LABEL));
          return;
        }
        setLoading(true);

        const dto = {
          ...toDistributionDto(values),
          subscriberConditions: subscriberConditions ?? [],
          receiverType: receiverType,
        };

        try {
          if (values?.distributionId) {
            await updateDistribution(dto);

            if (distributionChannelId) {
              await updateDistributionChannel({
                channelId: distributionChannelId,
                description: values.description,
              });
            }
          } else {
            const res = await createDistribution(dto);
            setCreatedDistributionId(res.distributionId);

            if (res.distributionId) {
              await createDistributionChannel({
                distributionId: res.distributionId,
                surveyId: surveyId,
                description: values.description,
                channelType: receiverType as unknown as DistributionChannelTypeEnum,
              });
            }
            handleClear();
          }
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      })
      .catch(() => {
        message.error(I18n.t("fillRequiredFields"));
      });
  }, [
    distributionChannelId,
    receiverType,
    form,
    surveyId,
    handleClear,
    subscriberConditions,
    setCreatedDistributionId,
    createDistribution,
    createDistributionChannel,
    updateDistributionChannel,
    updateDistribution,
    setLoading,
  ]);

  useEffect(() => {
    form.setFieldsValue(
      getValues(
        isEditing
          ? ({
              ...data,
              emailProviderId: String(data?.emailProviderId ?? "") || undefined,
              smsProviderId: String(data?.smsProviderId ?? "") || undefined,
            } as any)
          : {
              ...DistributionInitialData,
              description: descriptionInitial,
            },
      ),
    );
  }, []);

  return (
    <Modal
      open={open}
      width={700}
      onCancel={handleClear}
      title={I18n.t(receiverType === ReceiverTypeEnum.MSISDN ? "smsDistribution" : "emailDistribution")}
      footer={
        <FooterActions
          loading={loading}
          disabled={isReadonly}
          onCancel={handleClear}
          onSubmit={handleSubmit}
          submitLabelTranslationKey={"save"}
        />
      }
    >
      <Form
        form={form}
        layout={"horizontal"}
        disabled={isReadonly}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 15 }}
        style={{ marginTop: 30 }}
      >
        <HiddenValueFormItemComponent name={"distributionId"} />
        <HiddenValueFormItemComponent name={"surveyId"} value={surveyId} />
        <InputFormItemComponent name={"description"} label={I18n.t("description")} />
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const sourceId: SourceEnum = getFieldValue("sourceId");
            if (sourceId === SourceEnum.CONTACTS) {
              return <Outlet />;
            }
            return (
              <UploadFormItemComponent
                createdDistributionId={createdDistributionId}
                distributionId={data?.distributionId}
                style={{ width: "100%" }}
                label={I18n.t("file")}
                subsFile={getSubsFile}
                name={"selectFile"}
                required={true}
              />
            );
          }}
        </Form.Item>
        {/*<Form.Item shouldUpdate noStyle>*/}
        {/*  {({ setFieldValue }) => {*/}
        {/*    const onChange = () => {*/}
        {/*      setFieldValue("smsProviderId", null);*/}
        {/*      setFieldValue("emailProviderId", null);*/}
        {/*    };*/}
        {/*    return (*/}
        {/*      <RadioFormItemComponent*/}
        {/*        onChange={onChange}*/}
        {/*        name={"receiverType"}*/}
        {/*        values={receiverTypes}*/}
        {/*        label={I18n.t("distributionChannel")}*/}
        {/*      />*/}
        {/*    );*/}
        {/*  }}*/}
        {/*</Form.Item>*/}
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const channel = getFieldValue("receiverType");
            let providers: SmsProviderType[] | EmailProviderType[] = [];
            let name = "";

            if (channel === ReceiverTypeEnum.MSISDN) {
              providers = smsProviders;
              name = "smsProviderId";
            } else if (channel === ReceiverTypeEnum.EMAIL) {
              providers = emailProviders;
              name = "emailProviderId";
            }

            return (
              <SelectFormItemComponent
                allowClear
                name={name}
                required={false}
                hasFeedback={false}
                label={I18n.t("provider")}
                values={providers.map((it) => {
                  const id = String(it?.providerId ?? "");
                  return {
                    label: it?.description ?? id,
                    value: id,
                  };
                })}
              />
            );
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const sourceId$: SourceEnum = getFieldValue("sourceId");
            if (sourceId$ === SourceEnum.FILE) {
              return <Outlet />;
            }

            return (
              <SelectFormItemComponent
                required
                name={"propertyKey"}
                label={I18n.t("attributeContainingSendingAddress")}
                tooltip={I18n.t("attributeContainingSendingAddressHelpText")}
                values={attributes.map((it) => ({ value: it.propertyKey, label: it.propertyKey }))}
              />
            );
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            const sourceId$: SourceEnum = getFieldValue("sourceId");
            if (sourceId$ === SourceEnum.FILE) {
              return <Outlet />;
            }

            return (
              <SubscriberProfileFiltersComponent type={"distributions"} title={I18n.t("subscriberSelectionLogic")} />
            );
          }}
        </Form.Item>
        <Divider />
        <DatepickerFormItemComponent required disablePast name={"endDate"} label={I18n.t("endDate")} />
        <Form.Item label={I18n.t("time")} required>
          <Input.Group compact>
            <TimePickerComponent
              noStyle
              required
              hasFeedback={false}
              name={["schedule", "fromTime"]}
              style={{ width: "45%", borderRadius: 6 }}
            />
            <span
              style={{
                padding: 0,
                width: "10%",
                margin: "3px 0",
                display: "inline-flex",
                justifyContent: "center",
              }}
            >
              :
            </span>
            <TimePickerComponent
              noStyle
              required
              hasFeedback={false}
              name={["schedule", "toTime"]}
              style={{ width: "45%", borderRadius: 6 }}
            />
          </Input.Group>
        </Form.Item>
        <SelectFormItemComponent
          required
          mode={"multiple"}
          tagRender={(values) => <Tag style={{ fontWeight: 700 }}>{daysShortLabelRu()[values?.value as DaysEnum]}</Tag>}
          label={I18n.t("days")}
          name={["schedule", "days"]}
          values={dayKeysList.map((key) => ({
            value: key,
            label: daysLabelRu()[key as DaysEnum],
          }))}
        />
        <InputNumberFormItemComponent name={"dailyLimit"} label={I18n.t("dailyDistributionSize")} />
      </Form>
    </Modal>
  );
};

export default DistributionEditModal;

const getValues = (data?: DistributionType) => ({
  ...data,
  endDate: data?.endDate ? dayjs(data.endDate) : undefined,
  schedule: {
    ...data?.schedule,
    fromTime: getMomentTime(data?.schedule?.fromTime),
    toTime: getMomentTime(data?.schedule?.toTime),
    days: data?.schedule?.days?.map(String),
  },
});
