import { FC, useState } from "react";
import { Form, Mentions } from "antd";
import { I18n } from "react-redux-i18n";
import { FormItemType, FormName } from "../../../types/formItemType";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { REQUIRED_MESSAGE } from "../../../utils/getRequiredMessage";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { SmileOutlined } from "@ant-design/icons";
import { TextTranslationType } from "../../../types/textTranslationType";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

type MentionFormItemProps = {
  readonly showEmoji?: boolean;
} & FormItemType;
const TRIGGER_PREFIX = "$";

const MentionFormItemComponent: FC<MentionFormItemProps> = (props) => {
  const userParams = useSelector((state: RootState) => state.users.params);

  const inputProps = {
    style: props.style,
    disabled: props.disabled,
    readOnly: props.disabled,
    options: userParams,
    prefix: TRIGGER_PREFIX,
    rows: props.rows,
    autoSize: props.autoSize,
    placeholder: capitalizeFirstLetter(props.placeHolder || `${I18n.t("enter")} ${props.label || ""}`),
  };

  const formProps = {
    ...props,
    rules: [{ required: props.required, message: REQUIRED_MESSAGE() }],
  };

  return (
    <div style={props.showEmoji ? { display: "flex", alignItems: "center" } : {}}>
      <Form.Item {...formProps}>
        <Mentions {...inputProps} />
      </Form.Item>
      {props.showEmoji && <EmojiPickHandler name={props.name} disabled={props.disabled} />}
    </div>
  );
};

export default MentionFormItemComponent;

const EmojiPickHandler: FC<{
  readonly name: FormName;
  readonly disabled?: boolean;
}> = ({ name, disabled }) => {
  return (
    <Form.Item shouldUpdate noStyle>
      {({ setFieldValue, getFieldValue }) => {
        const translations: Array<TextTranslationType> = getFieldValue("textTranslations");
        function onEmojiSelect(value: string) {
          if (disabled) return;
          setFieldValue(
            "textTranslations",
            translations.map((it, index) =>
              index === (name as Array<any>).at(1)
                ? {
                    ...it,
                    text: (it?.text || "") + value,
                  }
                : it,
            ),
          );
        }

        return <Emoji onEmojiSelect={onEmojiSelect} />;
      }}
    </Form.Item>
  );
};

type EmojiProps = {
  readonly onEmojiSelect: (value: string) => void;
};

const Emoji: FC<EmojiProps> = ({ onEmojiSelect }) => {
  const [show, setShow] = useState(false);
  const changeVisible = () => setShow(!show);
  return (
    <>
      <SmileOutlined onClick={changeVisible} style={{ marginLeft: 5, fontSize: 16 }} />
      <EmojiContent show={show} onClose={changeVisible} onEmojiSelect={onEmojiSelect} />
    </>
  );
};

type EmojiContentProps = {
  readonly show: boolean;
  readonly onClose: () => void;
} & EmojiProps;

const EmojiContent: FC<EmojiContentProps> = ({ show, onEmojiSelect, onClose }) => {
  const [, setMisClickCount] = useState(0);

  if (!show) {
    return <></>;
  }

  const handleSelect = (value: { native: string }) => {
    onEmojiSelect(value.native);
  };

  const handleBlur = () => {
    setMisClickCount((prevCount) => {
      if (prevCount > 0) {
        onClose();
        return 0;
      }
      return prevCount + 1;
    });
  };

  return (
    <div style={{ position: "absolute", zIndex: 999, right: -360 }}>
      <Picker
        data={data}
        searchPosition={"none"}
        previewPosition={"none"}
        skinTonePosition={"none"}
        onClickOutside={handleBlur}
        onEmojiSelect={handleSelect}
      />
    </div>
  );
};
