import React, { FC, useCallback, useEffect, useState } from "react";
import { useActions } from "../../../../../../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { ChannelEnum } from "../../../../../../types/channelEnum";
import { QuestionType } from "../../../../../../types/questionType";
import { getNextOrderValue } from "../../../../utils";
import getNonIndexedQuestion from "../../../../../../utils/getNonIndexedQuestion";
import { message, Table } from "antd";
import { questionsColumns } from "./columns";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MenuOutlined } from "@ant-design/icons";
import { I18n } from "react-redux-i18n";
import isWelcome from "../../../../../../utils/welcomeSmsChecker";
import { cutWelcomeMessage } from "../helpers";
import { CreateSurveyQuestionsTourItemsEnum, TourItemsEnum } from "../../../../../../types/tour";
import { useTourCtx } from "../../../../../../hooks/providers/tours/TourProvider";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

const Row = ({ children, ...props }: RowProps) => {
  const { tourInProgress } = useTourCtx();

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props["data-row-key"],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      "translate3d(0,",
    ),
    transition,
    ...(isDragging ? { position: "relative", zIndex: tourInProgress ? 0 : 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === "sort") {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: "none", cursor: "move" }} {...listeners} />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const QuestionsTable: FC<{
  readonly changeEditPageVisible: () => void;
  readonly actionsDisabled: boolean;
}> = ({ changeEditPageVisible, actionsDisabled }) => {
  const {
    data: {
      [TourItemsEnum.CREATE_SURVEY_QUESTIONS]: { refs },
    },
  } = useTourCtx();

  const { deleteQuestion, setCurrentQuestion, createQuestion, reorderQuestions } = useActions();

  const { surveyId, questions, isWebChannel } = useSelector((state: RootState) => ({
    surveyId: state.surveys.current?.surveyId,
    questions: state.questions.list,
    isWebChannel: state.surveys.channel === ChannelEnum.WEB,
  }));

  const [dataSource, setDataSource] = useState<Array<QuestionType>>([]);
  const [rowChanged, setRowChanged] = useState(false);

  const onEdit = useCallback(
    (q?: QuestionType) => {
      setCurrentQuestion(q);
      changeEditPageVisible();
    },
    [setCurrentQuestion, changeEditPageVisible],
  );

  const onDelete = useCallback(
    (questionId?: string) => {
      if (surveyId && questionId && !actionsDisabled) {
        deleteQuestion({ surveyId, questionId });
      }
    },
    [surveyId, actionsDisabled, deleteQuestion],
  );

  const onDuplicate = useCallback(
    (q: QuestionType) => {
      const order = getNextOrderValue(questions);
      if (surveyId) {
        createQuestion({
          surveyId: surveyId,
          question: getNonIndexedQuestion(order, q, isWebChannel),
        });
      }
    },
    [surveyId, questions, isWebChannel, createQuestion],
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (actionsDisabled) {
      return;
    }
    const welcomeSmsId = questions.find(isWelcome)?.questionId;

    const isUsingAsCondition = questions.find((q) => {
      const id = q?.previousAnswerCondition?.answeredQuestionId;
      return id === active.id || id === over?.id || (q?.questionId === active.id && !!q?.previousAnswerCondition);
    });

    if (isUsingAsCondition) {
      message.warning(I18n.t("questionsDragDropWarningText"));
    }

    if (active.id !== over?.id && welcomeSmsId !== active.id && welcomeSmsId !== over?.id) {
      setRowChanged(true);
      setDataSource((prev) => {
        const activeIndex = prev.findIndex((i) => i?.questionId === active.id);
        const overIndex = prev.findIndex((i) => i?.questionId === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    if (rowChanged && surveyId) {
      setRowChanged(false);
      reorderQuestions({ surveyId, questions: dataSource });
    }
  }, [rowChanged, dataSource]);

  useEffect(() => setDataSource(questions), [questions]);

  return (
    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={cutWelcomeMessage(dataSource).map((i) => i?.questionId + "")}
        strategy={verticalListSortingStrategy}
      >
        <div ref={refs[CreateSurveyQuestionsTourItemsEnum.QUESTIONS_TABLE]}>
          <Table
            pagination={{
              pageSizeOptions: [5, 10, 20, 30, 50],
              showSizeChanger: true,
              defaultPageSize: 30,
              size: "small",
            }}
            components={{ body: { row: Row } }}
            dataSource={cutWelcomeMessage(dataSource)}
            rowKey={(record: QuestionType) => record?.questionId + ""}
            columns={questionsColumns(onEdit, onDelete, onDuplicate, actionsDisabled, questions)}
          />
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default QuestionsTable;
