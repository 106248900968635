import { requestApi } from "../utils/requestApi";
import { DELETE, GET, POST, PUT } from "../types/httpMethod";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import { setIsAnythingEdited } from "../store/slices/surveys/surveysSlice";
import { useAppDispatch } from "../store";
import { SurveyEditType, SurveyType } from "../types/surveyType";
import { QuestionType } from "../types/questionType";
import {
  addQuestion,
  removeQuestionsMediaProperties,
  setQuestions,
  updateQuestionsMediaProperties,
} from "../store/slices/questions/questionsService";
import { WebhookType } from "../types/WebhookType";
import { setCurrentWebhook } from "../store/slices/webhooks/webhooksService";
import { QrCodeCreateType, QrCodeType, QrCodeUpdateType } from "../types/qrCode";
import { addQrCodeToList, setCurrentQrCode, updateQrCodeInList } from "../store/slices/qrCodes/qrCodesService";
import { useCallback } from "react";
import { RcFile } from "antd/es/upload";
import { DEFAULT_FIRST_SMS } from "../utils";
import { mapTextToSurveyUrl } from "../pages/surveys/edit/steps/questions/helpers";
import { getSurveys, setCommunicationChannel, setCurrentSurvey } from "../store/slices/surveys/surveysService";
import { updateList } from "../store/slices/questions/questionsSlice";
import { MeResponseType } from "../types/userType";
import { getUsersBrowserTimezone } from "../utils/timezones";
import { setSubscriptionInfo, setUserExtendedData$, updateUserTimezone } from "../store/slices/users/usersService";
import { AddMediaToQuestionResponseType } from "../types/AddMediaToQuestionResponseType";
import { prepareQuestion } from "./helpers";
import { BuySubscriptionPlanResponseType } from "../types/BuySubscriptionPlanResponseType";
import { Pageable } from "../types/pageable";
import { PaymentHistoryResponse } from "../types/paymentHistoryType";
import { updateQrCodeInDistributionsList } from "../store/slices/distributionChannels/distributionChannelsService";

interface IUseRequestsReturnType {
  readonly createSurvey: (survey: SurveyEditType) => Promise<SurveyType>;
  readonly deleteSurvey: (surveyId: string) => Promise<void>;
  readonly createQuestion: (surveyId: string, question: QuestionType) => Promise<QuestionType>;
  readonly updateQuestion: (surveyId: string, question: QuestionType) => Promise<QuestionType>;
  readonly createWebhook: (surveyId: string, webhook: WebhookType) => Promise<WebhookType>;
  readonly createQrCode: (qrCode: QrCodeCreateType) => Promise<QrCodeType>;
  readonly updateQrCode: (qrCode: QrCodeUpdateType, channelId?: number) => Promise<QrCodeType>;
  readonly uploadLogo: (qrCodeId: string, file: RcFile) => Promise<string>;
  readonly createWelcomeSms: (newSurvey: SurveyType) => Promise<void>;
  readonly getUserInformation: () => Promise<MeResponseType>;
  readonly completeTour: (tourId: number) => Promise<void>;
  readonly addMediaToQuestion: (
    surveyId: string,
    questionId: string,
    file: RcFile,
  ) => Promise<AddMediaToQuestionResponseType>;
  readonly deleteMediaFromQuestion: (surveyId: string, questionId: string) => Promise<void>;
  readonly buySubscriptionPlan: (
    planId: string,
    userId: string,
    subscriptionId: string,
  ) => Promise<BuySubscriptionPlanResponseType>;
  readonly cancelCurrentSubscription: () => Promise<void>;
  readonly getPaymentsHistory: (page: Pageable) => Promise<PaymentHistoryResponse>;
}

export const useRequests = (): IUseRequestsReturnType => {
  const dispatch = useAppDispatch();

  const createSurvey = useCallback(
    async (survey: SurveyEditType): Promise<SurveyType> => {
      try {
        const res = await requestApi(POST, "/surveys", survey);
        await dispatch(setCurrentSurvey(res));
        await dispatch(setCommunicationChannel(res?.communicationChannel));
        await getUserInformation();
        message.success(I18n.t("surveyCreated"));
        return res;
      } catch (e) {
        throw e;
      } finally {
        dispatch(setIsAnythingEdited(false));
      }
    },
    [dispatch],
  );

  const deleteSurvey = useCallback(
    async (surveyId: string): Promise<void> => {
      try {
        await requestApi(DELETE, `/surveys/${surveyId}`, {});
        await dispatch(getSurveys());
        await getUserInformation();
        message.success(I18n.t("surveyDeleted"));
        return Promise.resolve();
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const createQuestion = useCallback(
    async (surveyId: string, question: QuestionType): Promise<QuestionType> => {
      try {
        const dto = prepareQuestion(question);
        const res: QuestionType = await requestApi(POST, `/surveys/${surveyId}/questions`, dto);
        await dispatch(addQuestion(res));
        return res;
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const updateQuestion = useCallback(
    async (surveyId: string, question: QuestionType): Promise<QuestionType> => {
      try {
        const dto = prepareQuestion(question);
        const res: QuestionType = await requestApi(PUT, `/surveys/${surveyId}/questions/${question?.questionId}`, dto);
        dispatch(updateList(res));
        message.success(I18n.t("changesSaved"));
        return res;
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const createWebhook = useCallback(
    async (surveyId: string, webhook: WebhookType): Promise<WebhookType> => {
      try {
        const res: WebhookType = await requestApi(POST, `/surveys/${surveyId}/webhooks`, webhook);
        message.success(I18n.t("webhookCreated"));
        await dispatch(setCurrentWebhook(res));
        return res;
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const createQrCode = useCallback(
    async (qrCode: QrCodeCreateType): Promise<QrCodeType> => {
      try {
        const res: QrCodeType = await requestApi(POST, "/qrcode", qrCode);
        message.success(I18n.t("qrCodeCreated"));
        await dispatch(addQrCodeToList(res));
        await dispatch(setCurrentQrCode(res));
        return res;
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const updateQrCode = useCallback(
    async (qrCode: QrCodeUpdateType, channelId?: number): Promise<QrCodeType> => {
      try {
        const res: QrCodeType = await requestApi(PUT, `/qrcode/${qrCode.qrCodeId}`, qrCode);
        message.success(I18n.t("changesSaved"));
        if (channelId) {
          await dispatch(updateQrCodeInDistributionsList({ channelId, qrCode: res }));
        }
        await dispatch(updateQrCodeInList(res));
        return res;
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const uploadLogo = useCallback(async (qrCodeId: string, logo: RcFile) => {
    try {
      return await requestApi(POST, `/qrcode/${qrCodeId}/logo/upload`, { logo }, true);
    } catch (e) {
      message.error(I18n.t("errorWhileSaving"));
      throw e;
    }
  }, []);

  const createWelcomeSms = useCallback(
    async (newSurvey: SurveyType) => {
      try {
        const question = {
          ...DEFAULT_FIRST_SMS,
          textTranslations: newSurvey.languages.map(mapTextToSurveyUrl),
        };

        const res: QuestionType = await requestApi(POST, `/surveys/${newSurvey.surveyId}/questions`, question);
        await dispatch(setQuestions([res]));
        return Promise.resolve();
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const getUserInformation = useCallback(async (): Promise<MeResponseType> => {
    try {
      const res: MeResponseType = await requestApi(
        GET,
        "/users/me",
        {},
        false,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        false,
      );
      dispatch(setUserExtendedData$(res.userData));
      dispatch(setSubscriptionInfo(res.subscriptionData));
      const tz = getUsersBrowserTimezone().label;

      if (!res?.userData.zoneId && tz) {
        dispatch(updateUserTimezone(tz));
      }

      return res;
    } catch (e) {
      throw e;
    }
  }, [dispatch]);

  const completeTour = useCallback(
    async (tourId: number): Promise<void> => {
      try {
        await requestApi(POST, `/tours/${tourId}/complete`, {}, false, false);
        await getUserInformation();
        return Promise.resolve();
      } catch (e) {
        throw e;
      }
    },
    [getUserInformation],
  );

  const addMediaToQuestion = useCallback(
    async (surveyId: string, questionId: string, file: RcFile): Promise<AddMediaToQuestionResponseType> => {
      try {
        const res = await requestApi(POST, `/surveys/${surveyId}/questions/${questionId}/media`, { file }, true, false);
        message.success(I18n.t("questionMediaBanner.uploadedSuccessfullyText"));
        dispatch(updateQuestionsMediaProperties({ questionId, ...res }));
        return Promise.resolve(res);
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const deleteMediaFromQuestion = useCallback(async (surveyId: string, questionId: string): Promise<void> => {
    try {
      await requestApi(DELETE, `/surveys/${surveyId}/questions/${questionId}/media`, {}, false, false);
      dispatch(removeQuestionsMediaProperties(questionId));
      return Promise.resolve();
    } catch (e) {
      throw e;
    }
  }, []);

  const buySubscriptionPlan = useCallback(
    async (planId: string, userId: string, subscriptionId: string): Promise<BuySubscriptionPlanResponseType> => {
      try {
        const res = await requestApi(POST, `/subscription/buy/${planId}`, {
          userId,
          subscriptionId,
          planId,
        });
        return Promise.resolve(res);
      } catch (e) {
        throw e;
      }
    },
    [],
  );

  const cancelCurrentSubscription = useCallback(async (): Promise<void> => {
    try {
      await requestApi(DELETE, `/subscription`, {});
      return Promise.resolve();
    } catch (e) {
      throw e;
    }
  }, []);

  const getPaymentsHistory = useCallback(async (page: Pageable): Promise<PaymentHistoryResponse> => {
    try {
      const res: PaymentHistoryResponse = await requestApi(GET, "/payments/history", {}, false, true, undefined, page);
      return Promise.resolve(res);
    } catch (e) {
      throw e;
    }
  }, []);

  return {
    createSurvey: createSurvey,
    deleteSurvey: deleteSurvey,
    createQuestion: createQuestion,
    updateQuestion: updateQuestion,
    createWebhook: createWebhook,
    createQrCode: createQrCode,
    updateQrCode: updateQrCode,
    uploadLogo: uploadLogo,
    createWelcomeSms: createWelcomeSms,
    getUserInformation: getUserInformation,
    completeTour: completeTour,
    addMediaToQuestion: addMediaToQuestion,
    deleteMediaFromQuestion: deleteMediaFromQuestion,
    buySubscriptionPlan: buySubscriptionPlan,
    cancelCurrentSubscription: cancelCurrentSubscription,
    getPaymentsHistory: getPaymentsHistory,
  };
};
