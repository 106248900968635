import React, { FC, useEffect, useState } from "react";
import InputFormItemComponent from "../../../../../../components/form/items/input";
import { I18n } from "react-redux-i18n";

const EndBlock: FC<{ redirectToAnotherSite: boolean }> = ({ redirectToAnotherSite }) => {
  const [redirectPath, setRedirectPath] = useState<string | undefined>();

  useEffect(() => {
    if (redirectToAnotherSite) {
      setRedirectPath(undefined);
    }
  }, [redirectToAnotherSite]);

  return (
    <InputFormItemComponent
      name={"redirectPath"}
      tooltip={"https://example.com, http://example.com"}
      required={redirectToAnotherSite}
      disabled={!redirectToAnotherSite}
      label={I18n.t("redirectUrl")}
      errorText={I18n.t("invalidUrl")}
      pattern={/^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/i}
      onChange={(value) => setRedirectPath(value)}
      initialValue={redirectPath}
    />
  );
};

export default EndBlock;
