import { Language, SurveyLanguage } from "../types/language";
import { QuestionType } from "../types/questionType";
import { ChannelEnum } from "../types/channelEnum";
import { APP_PRIMARY_COLOR, WELCOME_SMS_LABEL } from "./index";
import { TemplateEnum } from "../types/templateEnum";

export const INITIAL_SURVEY_AI_DATA = (name: string) => ({
  surveyName: name,
  communicationChannel: ChannelEnum.WEB,
  tags: ["ai", "ии"],
  questions: [],
  sourceConditions: [],
  themeColor: APP_PRIMARY_COLOR,
  surveyMetadata: {
    metaTitle: "Оцените работу нашего сервиса",
    metaDescription: "Ваше мнение очень важно для нас",
  },
  languages: [
    { langCode: "RU", langName: "Русский" },
    { langCode: "EN", langName: "English" },
  ],
});

export const SURVEY_DATA = {
  surveyName: "Шаблон опроса по оценке работы оператора",
  sourceId: "API",
  type: "EVENT",
  communicationChannel: "WEB",
  status: "ACTIVE",
  dailyLimit: 100,
  tags: [],
  questions: [],
  subscriberConditions: [],
  sourceConditions: [],
  schedule: { fromTime: "00:00", toTime: "23:59", days: [1, 2, 3, 4, 5, 6, 7] },
  surveyMetadata: {
    metaTitle: "Оцените работу нашего сервиса",
    metaDescription: "Ваше мнение очень важно для нас",
  },
  languages: [
    { langCode: "EN", langName: "English" },
    { langCode: "KK", langName: "Қазақ" },
    { langCode: "RU", langName: "Русский" },
  ],
};

export const QUESTIONS_DATA = [
  {
    name: WELCOME_SMS_LABEL,
    order: 1,
    textTranslations: [
      {
        language: { langCode: "EN", langName: "English" },
        text: "${SURVEY_URL}",
      },
      {
        language: { langCode: "KK", langName: "Қазақ" },
        text: "${SURVEY_URL}",
      },
      {
        language: { langCode: "RU", langName: "Русский" },
        text: "${SURVEY_URL}",
      },
    ],
    kkText: "",
    ruText: "",
    enText: "",
    tags: [],
    conditions: [],
    answerTemplate: null,
    previousAnswerCondition: null,
    startBlock: false,
    endBlock: false,
  },
  {
    name: "Оценка",
    order: 2,
    textTranslations: [
      {
        language: { langCode: "EN", langName: "English" },
        text: "Please rate our work:",
      },
      {
        language: { langCode: "KK", langName: "Қазақ" },
        text: "Please rate our work:",
      },
      {
        language: { langCode: "RU", langName: "Русский" },
        text: "Please rate our work:",
      },
    ],
    kkText: "",
    ruText: "",
    enText: "",
    tags: [],
    conditions: [],
    answerTemplate: {
      type: "NPS",
      answerChoices: [],
      addCustomAnswer: false,
      textTranslations: [],
      customAnswerTextRu: null,
      customAnswerTextKk: null,
      customAnswerTextEn: null,
      customAnswerIsRequired: null,
      customAnswerType: null,
      criteria: [],
      csiScale: null,
      csiParams: [],
      npsInfoParams: {
        type: "RATING",
        icon: "star",
        maxGrade: 5,
        npsParams: [],
      },
      filesUpload: [],
    },
    previousAnswerCondition: null,
    startBlock: false,
    endBlock: false,
  },
  {
    name: "Thanks",
    order: 3,
    textTranslations: [
      {
        language: { langCode: "EN", langName: "English" },
        text: "Thank you for your time! Have a good day!",
      },
      {
        language: { langCode: "KK", langName: "Қазақ" },
        text: "Уақыт бөлгеніңізге рахмет! Күніңіз жақсы өтсін!",
      },
      {
        language: { langCode: "RU", langName: "Русский" },
        text: "Спасибо за ваше время! Хорошего дня!",
      },
    ],
    kkText: "",
    ruText: "",
    enText: "",
    tags: [],
    conditions: [],
    answerTemplate: {
      type: "NO_ANSWER",
      answerChoices: [],
      addCustomAnswer: false,
      textTranslations: [],
      customAnswerTextRu: null,
      customAnswerTextKk: null,
      customAnswerTextEn: null,
      customAnswerIsRequired: null,
      customAnswerType: null,
      criteria: [],
      csiScale: null,
      csiParams: [],
      npsInfoParams: null,
      filesUpload: [],
    },
    previousAnswerCondition: null,
    startBlock: false,
    endBlock: true,
  },
];

const ThanksMessageTranslations: Record<string, string> = {
  [Language.EN]: "Thank you for your time! Have a good day!",
  [Language.KK]: "Уақыт бөлгеніңізге рахмет! Күніңіз жақсы өтсін!",
  [Language.RU]: "Спасибо за ваше время! Хорошего дня!",
};

export const END_QUESTION = (order: number, languages?: Array<SurveyLanguage>): QuestionType =>
  ({
    name: "Thanks",
    order: order,
    textTranslations:
      languages?.map((language) => {
        const msgTranslation = ThanksMessageTranslations[language.langCode];
        return {
          language,
          text: msgTranslation ?? ThanksMessageTranslations[Language.RU],
        };
      }) || [],
    kkText: "",
    ruText: "",
    enText: "",
    tags: [],
    conditions: [],
    answerTemplate: {
      type: TemplateEnum.END_BLOCK,
      answerChoices: [],
      addCustomAnswer: false,
      textTranslations: [],
      customAnswerTextRu: null,
      customAnswerTextKk: null,
      customAnswerTextEn: null,
      customAnswerIsRequired: null,
      customAnswerType: null,
      criteria: [],
      csiScale: null,
      csiParams: [],
      npsInfoParams: null,
      filesUpload: [],
    },
    previousAnswerCondition: null,
    startBlock: false,
    endBlock: true,
  } as unknown as QuestionType);
