import store from "../store";
import { LimitsEnum } from "../types/subscriptionInfo";

const isAvailable = (key: LimitsEnum) => {
  const subscriptionInfo = store.getState().users.subscriptionInfo;
  const availableQuantity = subscriptionInfo?.usage?.[key] ?? 0;

  return availableQuantity > 0;
};

export const isSurveyCreatingAvailable = () => isAvailable(LimitsEnum.SURVEYS);
export const isContactsCreatingAvailable = () => isAvailable(LimitsEnum.CONTACTS);
