import { Instance } from "./resultsPageTypes";
import { Dayjs } from "dayjs";
import { Pageable } from "./pageable";

export type SurveyInstanceStateType = {
  filters: SurveyInstanceFiltersType;
  pageable: Pageable;
  data?: SurveyInstanceResponseType;
};

export type SurveyInstanceResponseColumnPropType = {
  readonly order: number;
  readonly question: string;
};

type QuestionId = string;
type InstancesType = Array<Instance>;

export type SurveyInstanceResponseColumnType = Record<QuestionId, SurveyInstanceResponseColumnPropType>;

export type SurveyInstanceResponseType = {
  readonly columns: SurveyInstanceResponseColumnType;
  readonly instances: InstancesType;
  readonly totalElements: number;
};

type AnswersFilter = Record<string, string[] | undefined>;

export type SurveyInstanceFiltersType = {
  answersFilter: AnswersFilter;
  finished: boolean;
  notFinished: boolean;
  searchContact?: string;
  from?: Dayjs | string;
  to?: Dayjs | string;
};

export const SurveyInstanceFiltersStateInitial: SurveyInstanceFiltersType = {
  answersFilter: {},
  finished: true,
  notFinished: true,
  searchContact: undefined,
  from: undefined,
  to: undefined,
};

export const PageableInitial: Pageable = {
  page: 0,
  size: 10,
};

export const SurveyInstanceStateInitial: SurveyInstanceStateType = {
  filters: SurveyInstanceFiltersStateInitial,
  pageable: PageableInitial,
  data: undefined,
};
