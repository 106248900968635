import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Pageable } from "../../../types/pageable";
import { PageableInitial } from "../../../types/surveyInstance";
import { DistributionChannel } from "../../../types/DistributionChannel";

type StateType = {
  list: Array<DistributionChannel>;
  current?: DistributionChannel;
  pageable: Pageable;
};

const initialState: StateType = {
  list: [],
  current: undefined,
  pageable: PageableInitial,
};

const distributionChannelsSlice = createSlice({
  name: "distributionChannels",
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<Array<DistributionChannel>>) {
      state.list = action.payload;
    },
    addToList(state: StateType, action: PayloadAction<DistributionChannel>) {
      state.list = [action.payload, ...state.list];
    },
    updateInList(state: StateType, action: PayloadAction<DistributionChannel>) {
      state.list = state.list.map((it) =>
        it.channelId === action.payload.channelId ? { ...it, ...action.payload } : it,
      );
    },
    updateDescriptionInList(
      state: StateType,
      action: PayloadAction<Pick<DistributionChannel, "channelId" | "description">>,
    ) {
      state.list = state.list.map((it) =>
        it.channelId === action.payload.channelId ? { ...it, description: action.payload.description } : it,
      );
    },
    updateQrCodeInDistributionsList(
      state: StateType,
      action: PayloadAction<Pick<DistributionChannel, "channelId" | "qrCode">>,
    ) {
      state.list = state.list.map((it) =>
        it.channelId === action.payload.channelId ? { ...it, qrCode: action.payload.qrCode } : it,
      );
    },
    setCurrent(state: StateType, action: PayloadAction<DistributionChannel | undefined>) {
      state.current = action.payload;
    },
    updateCurrent(state: StateType, action: PayloadAction<Partial<DistributionChannel>>) {
      if (action?.payload) {
        state.current = {
          ...state.current,
          ...action.payload,
        } as DistributionChannel;
      }
    },
    setPageable(state: StateType, action: PayloadAction<Pageable>) {
      state.pageable = action.payload;
    },
  },
});

export const {
  setPageable,
  setList,
  addToList,
  setCurrent,
  updateCurrent,
  updateInList,
  updateDescriptionInList,
  updateQrCodeInDistributionsList,
} = distributionChannelsSlice.actions;
export default distributionChannelsSlice.reducer;
