import { I18n } from "react-redux-i18n";

export enum DaysEnum {
  MONDAY = "1",
  TUESDAY = "2",
  WEDNESDAY = "3",
  THURSDAY = "4",
  FRIDAY = "5",
  SATURDAY = "6",
  SUNDAY = "7",
}

export const dayKeysList = Object.values(DaysEnum);

export const daysLabelRu = (): Record<DaysEnum, string> => ({
  [DaysEnum.MONDAY]: I18n.t("monday"),
  [DaysEnum.TUESDAY]: I18n.t("tuesday"),
  [DaysEnum.WEDNESDAY]: I18n.t("wednesday"),
  [DaysEnum.THURSDAY]: I18n.t("thursday"),
  [DaysEnum.FRIDAY]: I18n.t("friday"),
  [DaysEnum.SATURDAY]: I18n.t("saturday"),
  [DaysEnum.SUNDAY]: I18n.t("sunday"),
});

export const daysShortLabelRu = (): Record<DaysEnum, string> => ({
  [DaysEnum.MONDAY]: I18n.t("mondayShortLabel"),
  [DaysEnum.TUESDAY]: I18n.t("tuesdayShortLabel"),
  [DaysEnum.WEDNESDAY]: I18n.t("wednesdayShortLabel"),
  [DaysEnum.THURSDAY]: I18n.t("thursdayShortLabel"),
  [DaysEnum.FRIDAY]: I18n.t("fridayShortLabel"),
  [DaysEnum.SATURDAY]: I18n.t("saturdayShortLabel"),
  [DaysEnum.SUNDAY]: I18n.t("sundayShortLabel"),
});
