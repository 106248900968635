import store from "../store";
import { Language, toKcLanguage } from "../types/language";
import { APP_PREFIX } from "./index";

export const getKeycloakAuthUrl = () => {
  const currentUrl = window._env_.KEYCLOAK_BASE_URL,
    realm = `realms/${window._env_.KEYCLOAK_REALM}`,
    clientId = `client_id=${window._env_.KEYCLOAK_CLIENT_ID}`,
    redirectUri = `redirect_uri=${getCurrentOriginUrlWithAppPrefix()}`,
    lang = `ui_locales=${toKcLanguage[store.getState().persisted.appLanguage || Language.RU]}`,
    responseType = "response_type=code";

  return `${currentUrl}/${realm}/protocol/openid-connect/auth?${responseType}&${clientId}&${redirectUri}&${lang}`;
};

export const getCurrentOriginUrlWithAppPrefix = () => {
  const currentUrl = window.location.origin;
  return currentUrl + APP_PREFIX;
};

export const getTokens = (withRedirectUri = false) => {
  const tokens = {
    jwtToken: store.getState().session.jwtToken,
    refreshToken: store.getState().session.refreshToken,
  };

  if (withRedirectUri) {
    return {
      ...tokens,
      redirectUri: getCurrentOriginUrlWithAppPrefix(),
    };
  }
  return tokens;
};
