import { message } from "antd";
import { I18n } from "react-redux-i18n";
import { DEFAULT_ERROR_LABEL } from "./index";

export const copyToClipboard = (value: string, isImage = false) => {
  if (isImage) {
    fetch(value)
      .then(async (img) => {
        const imgBlob = await img.blob();
        await navigator.clipboard.write([
          new ClipboardItem({
            "image/png": imgBlob,
          }),
        ]);
        message.success(I18n.t("successCopiedToClipBoard"));
      })
      .catch(() => message.error(I18n.t(DEFAULT_ERROR_LABEL)));
    return;
  }

  navigator.clipboard
    .writeText(value)
    .then(() => message.success(I18n.t("successCopiedToClipBoard")))
    .catch(() => message.error(I18n.t(DEFAULT_ERROR_LABEL)));
};
