export enum UserRolesEnum {
  SUB_USER_EDIT = "subuser_edit",
  SUB_USER_READ = "subuser_read",
}

export const UserRoleLabel: Record<UserRolesEnum, string> = {
  [UserRolesEnum.SUB_USER_EDIT]: "Editor",
  [UserRolesEnum.SUB_USER_READ]: "Viewer",
};

export const roleToLabel = (role: UserRolesEnum) => UserRoleLabel[role] ?? role;
