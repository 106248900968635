import { I18n } from "react-redux-i18n";

function formatTime(seconds: number): string {
  if (seconds >= 86400) {
    const days = Math.floor(seconds / 86400);
    return `${days} ${getNounEnding(
      days,
      I18n.t("formatTime.day"),
      I18n.t("formatTime.day2form"),
      I18n.t("formatTime.days"),
    )}`;
  } else if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} ${getNounEnding(
      hours,
      I18n.t("formatTime.hour"),
      I18n.t("formatTime.hour2form"),
      I18n.t("formatTime.hours"),
    )}`;
  } else if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} ${getNounEnding(
      minutes,
      I18n.t("formatTime.minute"),
      I18n.t("formatTime.minute2form"),
      I18n.t("formatTime.minutes"),
    )}`;
  } else {
    return `${seconds} ${getNounEnding(
      seconds,
      I18n.t("formatTime.second"),
      I18n.t("formatTime.second2form"),
      I18n.t("formatTime.seconds"),
    )}`;
  }
}

function getNounEnding(number: number, form1: string, form2: string, form3: string): string {
  const absNumber = Math.abs(number);
  const lastDigit = absNumber % 10;
  const lastTwoDigits = absNumber % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return form3;
  }

  if (lastDigit === 1) {
    return form1;
  }

  if (lastDigit >= 2 && lastDigit <= 4) {
    return form2;
  }

  return form3;
}

export { getNounEnding, formatTime };
