import * as AuthActions from "./session/sessionService";
import * as SurveyActions from "./surveys/surveysService";
import * as OffDaysActions from "./offDays/offDaysService";
import * as PersistedActions from "./persisted/persistedActions";
import * as ConditionsActions from "./conditions/conditionsService";
import * as QuestionsActions from "./questions/questionsService";
import * as ApiKeysActions from "./apiKeys/apiKeysService";
import * as ResultsActions from "./results/resultsService";
import * as WebhookActions from "./webhooks/webhooksService";
import * as UsersActions from "./users/usersService";
import * as LanguageActions from "./languages/languagesService";
import * as UtilsActions from "./app/appService";
import * as DistributionsActions from "./distributions/distributionsService";
import * as QrCodesActions from "./qrCodes/qrCodesService";
import * as ContactsActions from "./contacts/contactsService";
import * as EmailProvidersActions from "./emailProviders/emailProvidersService";
import * as SmsProvidersActions from "./smsProviders/smsProvidersService";
import * as DistributionChannelsActions from "./distributionChannels/distributionChannelsService";

export const allActionCreators = {
  ...AuthActions,
  ...SurveyActions,
  ...OffDaysActions,
  ...PersistedActions,
  ...ConditionsActions,
  ...QuestionsActions,
  ...ApiKeysActions,
  ...ResultsActions,
  ...WebhookActions,
  ...UsersActions,
  ...LanguageActions,
  ...EmailProvidersActions,
  ...SmsProvidersActions,
  ...DistributionsActions,
  ...QrCodesActions,
  ...ContactsActions,
  ...UtilsActions,
  ...DistributionChannelsActions,
};
