import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../store";

export type TimezonesState = {
  utc?: string;
  area?: string;
  label?: string;
};

export const getUsersBrowserTimezone = (): TimezonesState => {
  const userTimeZone = moment.tz.guess();
  const formattedTimeZone = moment.tz(userTimeZone).format("UTCZ");

  return {
    utc: formattedTimeZone,
    area: userTimeZone,
    label: `(${formattedTimeZone}) ${userTimeZone}`,
  };
};

export const getTimezonesList = () => {
  const timeZones = moment.tz.names();

  return timeZones
    .sort(compareOffsets)
    .map((timeZone) => {
      const offset = moment.tz(timeZone).format("Z");
      const offsetFormatted = `(UTC${offset})`;
      return `${offsetFormatted} ${timeZone}`;
    })
    .map((formattedTimezone) => ({ label: formattedTimezone, value: formattedTimezone }));
};

function compareOffsets(timeZone1: string, timeZone2: string) {
  const offset1 = moment.tz(timeZone1).utcOffset();
  const offset2 = moment.tz(timeZone2).utcOffset();
  return offset1 - offset2;
}

export const TimezonesList = getTimezonesList();

export const useTimezone = () => {
  const userInfo = useSelector((state: RootState) => state.users.userExtendedData);
  const [value, setValue] = useState<TimezonesState | undefined>();

  const onTimezoneChange = useCallback(
    (tz: string) => {
      setValue(mapTimezoneStringToObject(tz));
    },
    [value],
  );

  useEffect(() => {
    if (userInfo?.zoneId && !value) {
      onTimezoneChange(userInfo.zoneId);
    }
  }, [userInfo?.zoneId, value]);

  return {
    ...value,
    onTimezoneChange: onTimezoneChange,
  };
};

export const mapTimezoneStringToObject = (tz: string) => {
  const utc = tz.split(")").at(0)?.slice(1);
  const area = tz.split(")").at(1);
  return { utc, area, label: tz };
};

export const convertDateToCurrentTimezone = (date?: string) => {
  const tz = store.getState().users.userExtendedData?.zoneId ?? getUsersBrowserTimezone().label;

  if (!date || !tz) {
    return date;
  }

  const timezone = mapTimezoneStringToObject(tz);
  const dateUTC = new Date(date);
  const timezoneOffsetMatch = timezone.label.match(/([-+]\d{2}):(\d{2})/);

  if (!timezoneOffsetMatch) {
    return date;
  }

  const userTimezoneOffset = parseInt(timezoneOffsetMatch[1]) * 60 + parseInt(timezoneOffsetMatch[2]);
  const dateUserTimezone = new Date(dateUTC.getTime() + userTimezoneOffset * 60000);
  return dateUserTimezone.toISOString();
};
