import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { DELETE, GET, POST, PUT } from "../../../types/httpMethod";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import { addToList, removeFromList, setCurrent, setList, updateInList } from "./smsProvidersSlice";
import { SmsProviderType } from "../../../types/smsProvider";

export const getSmsProviders = createAsyncThunk<void, undefined>(
  "smsProviders/getSmsProviders",
  async (_, { dispatch }) => {
    try {
      const res = await requestApi(GET, "/sms-provider", {});
      dispatch(setSmsProvidersData(res));
    } catch (e) {
      throw e;
    }
  },
);

export const deleteSmsProvider = createAsyncThunk<void, number>(
  "smsProviders/deleteSmsProvider",
  async (smsProviderId, { dispatch }) => {
    try {
      await requestApi(DELETE, `/sms-provider/${smsProviderId}`, {});
      dispatch(removeFromList(smsProviderId));
      message.success(I18n.t("smsProviderDeleted"));
    } catch (e) {
      throw e;
    }
  },
);

export const createSmsProvider = createAsyncThunk<void, SmsProviderType>(
  "smsProviders/createSmsProvider",
  async (smsProvider, { dispatch }) => {
    try {
      const res = await requestApi(POST, "/sms-provider", smsProvider);
      dispatch(addSmsProviderToList(res));
      message.success(I18n.t("smsProviderCreated"));
    } catch (e) {
      throw e;
    }
  },
);

export const updateSmsProvider = createAsyncThunk<
  void,
  {
    readonly smsProvidersId: number;
    readonly values: SmsProviderType;
  }
>("smsProviders/updateSmsProvider", async ({ smsProvidersId, values }, { dispatch }) => {
  try {
    const res = await requestApi(PUT, `/sms-provider/${smsProvidersId}`, values);
    dispatch(updateSmsProviderInList(res));
    message.success(I18n.t("smsProviderChanged"));
  } catch (e) {
    throw e;
  }
});

export const setSmsProvidersData = createAsyncThunk<void, Array<SmsProviderType>>(
  "smsProviders/setSmsProvidersData",
  (data, { dispatch }) => {
    dispatch(setList(data));
  },
);

export const updateSmsProviderInList = createAsyncThunk<void, SmsProviderType>(
  "smsProviders/updateSmsProviderInList",
  (data, { dispatch }) => {
    dispatch(updateInList(data));
  },
);

export const setCurrentSmsProvider = createAsyncThunk<void, SmsProviderType | undefined>(
  "smsProviders/setCurrentSmsProvider",
  (data, { dispatch }) => {
    dispatch(setCurrent(data));
  },
);

export const addSmsProviderToList = createAsyncThunk<void, SmsProviderType>(
  "smsProviders/addSmsProviderToList",
  (data, { dispatch }) => {
    dispatch(addToList(data));
  },
);
