import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveyInfo } from "../../../types/surveyInfo";
import { ChannelEnum } from "../../../types/channelEnum";
import {
  PageableInitial,
  SurveyInstanceFiltersType,
  SurveyInstanceResponseType,
  SurveyInstanceStateInitial,
  SurveyInstanceStateType,
} from "../../../types/surveyInstance";
import { SurveyType } from "../../../types/surveyType";
import { Pageable } from "../../../types/pageable";

type StateType = {
  list: Array<SurveyType>;
  current?: SurveyType;
  currentInfo?: SurveyInfo;
  conditionsQuantity?: number;
  isAnythingEdited: boolean;
  channel?: ChannelEnum;
  surveyInstance: SurveyInstanceStateType;
};

const initialState: StateType = {
  list: [],
  current: undefined,
  currentInfo: undefined,
  conditionsQuantity: undefined,
  isAnythingEdited: false,
  channel: undefined,
  surveyInstance: SurveyInstanceStateInitial,
};

const surveysSlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<Array<SurveyType>>) {
      state.list = action.payload;
    },
    setCurrent(state: StateType, action: PayloadAction<SurveyType | undefined>) {
      if (!action.payload) {
        state.channel = undefined;
        state.isAnythingEdited = false;
      }
      state.current = action.payload;
    },
    setCurrentInfo(state: StateType, action: PayloadAction<SurveyInfo | undefined>) {
      state.currentInfo = action.payload;
    },
    setQuantity(state: StateType, action: PayloadAction<number | undefined>) {
      state.conditionsQuantity = action.payload;
    },
    updateCurrent(state: StateType, action: PayloadAction<any>) {
      let values: any = {};
      Object.keys(action.payload).map((key) => (values[key] = action.payload[key]));
      state.current = { ...state.current, ...values } as SurveyType;
      state.isAnythingEdited = true;
    },
    updateInList(state: StateType, action: PayloadAction<SurveyType>) {
      state.list = state.list.map((it) => (it.surveyId !== action.payload?.surveyId ? it : action.payload));
    },
    setIsAnythingEdited(state: StateType, action: PayloadAction<boolean>) {
      state.isAnythingEdited = action.payload;
    },
    setChannel(state: StateType, action: PayloadAction<ChannelEnum | undefined>) {
      state.channel = action.payload;
    },
    setSurveyInstance(state: StateType, action: PayloadAction<SurveyInstanceStateType | undefined>) {
      state.surveyInstance = action.payload || SurveyInstanceStateInitial;
    },
    setSurveyInstanceFilters(state: StateType, action: PayloadAction<SurveyInstanceFiltersType>) {
      state.surveyInstance.filters = action.payload;
    },
    resetSurveyInstanceFilters(state: StateType) {
      state.surveyInstance.filters = SurveyInstanceStateInitial.filters;
    },
    setSurveyInstancePageable(state: StateType, action: PayloadAction<Pageable>) {
      state.surveyInstance.pageable = action.payload;
    },
    resetSurveyInstancePageable(state: StateType) {
      state.surveyInstance.pageable = { ...PageableInitial, size: state.surveyInstance.pageable.size };
    },
    setSurveyInstanceData(state: StateType, action: PayloadAction<SurveyInstanceResponseType>) {
      state.surveyInstance.data = action.payload;
    },
  },
});

export const {
  setIsAnythingEdited,
  setCurrentInfo,
  updateCurrent,
  updateInList,
  setQuantity,
  setChannel,
  setCurrent,
  setList,
  setSurveyInstance,
  setSurveyInstanceData,
  setSurveyInstanceFilters,
  setSurveyInstancePageable,
  resetSurveyInstanceFilters,
  resetSurveyInstancePageable,
} = surveysSlice.actions;

export default surveysSlice.reducer;
