import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StepsEnum } from "../../../types/surveyEditTabsEnum";
import { SurveyFilter } from "../../../types/surveyType";
import { Pageable } from "../../../types/pageable";
import { Language } from "../../../types/language";
import { AuthStep } from "../../../types/authStep";

type StateType = {
  authStep: AuthStep;
  appLanguage: Language;
  surveysPagePageable: Pageable;
  surveysPageFilters: SurveyFilter;
  surveysEditPageActiveStep: StepsEnum | undefined;
};

const initialState: StateType = {
  authStep: AuthStep.LOGIN,
  appLanguage: Language.RU,
  surveysPagePageable: {
    page: 0,
    size: 10,
    sort: "created_at,desc",
    query: "",
  },
  surveysPageFilters: {
    createdBy: undefined,
    status: undefined,
    tags: [],
  },
  surveysEditPageActiveStep: undefined,
};

const persistedSlice = createSlice({
  name: "persisted",
  initialState,
  reducers: {
    setAuthStep(state: StateType, action: PayloadAction<AuthStep>) {
      state.authStep = action.payload;
    },
    setSurveysPagePageable(state: StateType, action: PayloadAction<Pageable>) {
      state.surveysPagePageable = action.payload;
    },
    updateSurveysPageFilters(state: StateType, action: PayloadAction<Record<string, any>>) {
      state.surveysPageFilters = {
        ...state.surveysPageFilters,
        ...action.payload,
      };
    },
    setSurveysEditPageActiveStep(state: StateType, action: PayloadAction<StepsEnum | undefined>) {
      state.surveysEditPageActiveStep = action.payload;
    },
    setAppLanguage(state: StateType, action: PayloadAction<Language>) {
      state.appLanguage = action.payload;
    },
  },
});

export const {
  setAuthStep,
  setAppLanguage,
  setSurveysPagePageable,
  updateSurveysPageFilters,
  setSurveysEditPageActiveStep,
} = persistedSlice.actions;
export default persistedSlice.reducer;
