import "./index.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import type { InputRef } from "antd";
import { Button, Form, Input, Tag } from "antd";
import { FormItemType } from "../../../types/formItemType";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useActions } from "../../../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Translate } from "react-redux-i18n";
import { APP_PREFIX } from "../../../utils";

const TagsFormItemComponent: FC<
  FormItemType & {
    readonly type: "surveys" | "questions";
    readonly searchable?: boolean;
  }
> = ({ label, disabled, type, searchable, tooltip }) => {
  const { updateCurrentQuestion, updateCurrentSurvey } = useActions();
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);
  const [selectedTags, setSelectedTags] = useState<Array<string>>([]);

  const tags: Array<string> = useSelector((state: RootState) => (state as any)[type]?.current?.tags || []);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const showInput = () => {
    setInputVisible(!disabled);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      onSetTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleClose = (removedTag: string) => {
    setSelectedTags((values) => values.filter((tag) => tag !== removedTag));
    onSetTags(tags.filter((tag) => tag !== removedTag));
  };

  function onSetTags(_tags: Array<string>) {
    if (type === "questions") {
      return updateCurrentQuestion({ tags: _tags });
    }
    updateCurrentSurvey({ tags: _tags });
  }

  const handleSelect = (tag: string) => {
    const checked = !selectedTags.find((t) => t === tag);
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  return (
    <Form.Item label={label} tooltip={tooltip}>
      {!!selectedTags.length && searchable && (
        <div className={"tags-action-btns"}>
          <a target={"_blank"} rel={"noreferrer"} href={`${APP_PREFIX}/surveys?tags=${selectedTags}`}>
            <SearchOutlined />
          </a>
        </div>
      )}
      {tags.map((tag) => (
        <Tag
          key={tag}
          closable={!disabled}
          onClose={() => handleClose(tag)}
          onClick={searchable ? () => handleSelect(tag) : undefined}
          className={selectedTags.includes(tag) ? "tag-checked" : ""}
          style={{ marginBottom: 10, cursor: "pointer" }}
        >
          <span>{tag}</span>
        </Tag>
      ))}
      {inputVisible && !disabled && (
        <Input
          type="text"
          size="small"
          ref={inputRef}
          value={inputValue}
          style={{ width: 100 }}
          onBlur={handleInputConfirm}
          onChange={handleInputChange}
          onPressEnter={handleInputConfirm}
        />
      )}
      {disabled ? (
        <Button type={"dashed"} size={"small"} disabled>
          <PlusOutlined /> <Translate value={"addTag"} />
        </Button>
      ) : (
        !inputVisible && (
          <Tag onClick={showInput} className={"tag-add-btn"}>
            <PlusOutlined /> <Translate value={"addTag"} />
          </Tag>
        )
      )}
    </Form.Item>
  );
};

export default TagsFormItemComponent;
