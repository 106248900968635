import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiKeyType } from "../../../types/apiKeyType";

type StateType = {
  list: Array<ApiKeyType>;
};
const initialState: StateType = {
  list: [],
};

const apiKeysSlice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {
    setApiKeysList(state: StateType, action: PayloadAction<Array<ApiKeyType>>) {
      state.list = action.payload;
    },
    removeApiKeyFromList(state: StateType, action: PayloadAction<number>) {
      state.list = state.list.filter(
        (offDay) => offDay.apiKeyId !== action.payload,
      );
    },
    addApiKeyToList(state: StateType, action: PayloadAction<ApiKeyType>) {
      state.list.push(action.payload);
    },
  },
});

export const { addApiKeyToList, setApiKeysList, removeApiKeyFromList } =
  apiKeysSlice.actions;
export default apiKeysSlice.reducer;
