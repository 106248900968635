import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { DELETE, GET, PATCH, POST, PUT } from "../../../types/httpMethod";
import { UserExtendedData, UserType } from "../../../types/userType";
import {
  addSubUserToList,
  addUserToList,
  removeSubUserFromList,
  removeUserFromList,
  setContactParams,
  setCurrent,
  setList,
  setSubscriptionInfo as setSubscriptionInfo$,
  setSubUsersList,
  setUserExtendedData,
  setUserTimezone,
  updateUserFullName,
} from "./usersSlice";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import { PasswordChangeProps } from "../../../components/header/passwordChange";
import { setUsername } from "../session/sessionSlice";
import { SubscriptionInfo } from "../../../types/subscriptionInfo";

export const getUsers = createAsyncThunk<void, undefined>("users/getUsers", async (_, { dispatch }) => {
  try {
    const res = await requestApi(GET, "/users", {});
    dispatch(setList(res));
  } catch (e) {
    throw e;
  }
});

export const getSubUsers = createAsyncThunk<void, undefined>("users/getSubUsers", async (_, { dispatch }) => {
  try {
    const res = await requestApi(GET, "/users/subusers", {});
    dispatch(setSubUsersList$(res));
  } catch (e) {
    throw e;
  }
});

export const deleteSubUser = createAsyncThunk<void, string>("users/deleteSubUser", async (subUserId, { dispatch }) => {
  try {
    await requestApi(DELETE, "/users/subusers", {}, false, true, undefined, { subUserId });
    dispatch(removeSubUserFromList$(subUserId));
  } catch (e) {
    throw e;
  }
});

export const createSubUser = createAsyncThunk<void, UserType>("users/createSubUser", async (subUser, { dispatch }) => {
  try {
    const res = await requestApi(POST, "/users/subusers", subUser);
    dispatch(addSubUserToList$(res));
    message.success(I18n.t("successful"));
  } catch (e) {
    throw e;
  }
});

export const resetSubUsersPassword = createAsyncThunk<
  void,
  {
    newPassword: string;
    subUserId: string;
  }
>("users/resetSubUsersPassword", async ({ newPassword, subUserId }) => {
  try {
    await requestApi(POST, "/users/subusers/password/reset", {}, false, true, undefined, {
      newPassword,
      subUserId,
    });
    message.success(I18n.t("successful"));
  } catch (e) {
    throw e;
  }
});

export const registerUser = createAsyncThunk<void, UserType>("users/registerUser", async (user, { dispatch }) => {
  try {
    const res: UserType = await requestApi(POST, "/users", user);
    dispatch(setCurrent(res));
    dispatch(setUsername(res.username));
    message.success(I18n.t("registrationSuccess"));
  } catch (e) {
    message.error(I18n.t("registrationError"));
    throw e;
  }
});

export const resendVerificationCode = createAsyncThunk<void, string>("users/resendMail", async (email) => {
  try {
    await requestApi(POST, "/users/code/resend", {}, false, true, {}, { email });
  } catch (e) {
    throw e;
  }
});

export const changeUserPassword = createAsyncThunk<void, PasswordChangeProps>(
  "users/changeUserPassword",
  async (data) => {
    try {
      await requestApi(
        PUT,
        "/users/password",
        {},
        false,
        true,
        {},
        {
          newPassword: data.newPassword,
          oldPassword: data.oldPassword,
        },
      );

      message.success(I18n.t("passwordChanged"));
    } catch (e) {
      throw e;
    }
  },
);

export const getUserContactParams = createAsyncThunk<void, undefined>("users/getUserContactParams", async () => {
  // try {
  //   const res = await requestApi(GET, "/users/params", {});
  //   dispatch(setContactParamsData(res));
  // } catch (e) {
  //   throw e;
  // }
});

export const updateUserTimezone = createAsyncThunk<void, string>(
  "users/updateUserTimezone",
  async (timezone, { dispatch }) => {
    try {
      await requestApi(POST, "/users/upsert/timezone", {}, false, true, undefined, { zoneId: timezone });
      dispatch(setUserTimezone$(timezone));
    } catch (e) {
      throw e;
    }
  },
);

export const updateUserInfo = createAsyncThunk<
  void,
  {
    readonly firstName: string;
    readonly lastName: string;
  }
>("users/updateUserInfo", async (userInfo, { dispatch }) => {
  try {
    await requestApi(PATCH, "/users/info", { ...userInfo });
    dispatch(updateUserFullName$(userInfo));
  } catch (e) {
    throw e;
  }
});

export const updateUserFullName$ = createAsyncThunk<void, Pick<UserExtendedData, "firstName" | "lastName">>(
  "users/setSubUsersList$",
  (payload, { dispatch }) => {
    dispatch(updateUserFullName(payload));
  },
);

export const setContactParamsData = createAsyncThunk<void, Array<string>>(
  "users/setContactParamsData",
  (data, { dispatch }) => {
    dispatch(setContactParams(data));
  },
);

export const setUserExtendedData$ = createAsyncThunk<void, UserExtendedData | undefined>(
  "users/setUserExtendedData$",
  (data, { dispatch }) => {
    dispatch(setUserExtendedData(data));
  },
);

export const setUserTimezone$ = createAsyncThunk<void, string>("users/setUserTimezone$", (data, { dispatch }) => {
  dispatch(setUserTimezone(data));
});

export const removeUserFromList$ = createAsyncThunk<void, string>(
  "users/removeUserFromList$",
  (userId, { dispatch }) => {
    dispatch(removeUserFromList(userId));
  },
);

export const addUserToList$ = createAsyncThunk<void, UserType>("users/addUserToList$", (user, { dispatch }) => {
  dispatch(addUserToList(user));
});

export const removeSubUserFromList$ = createAsyncThunk<void, string>(
  "users/removeSubUserFromList$",
  (userId, { dispatch }) => {
    dispatch(removeSubUserFromList(userId));
  },
);

export const addSubUserToList$ = createAsyncThunk<void, UserType>("users/addSubUserToList$", (user, { dispatch }) => {
  dispatch(addSubUserToList(user));
});

export const setSubUsersList$ = createAsyncThunk<void, Array<UserType>>(
  "users/setSubUsersList$",
  (list, { dispatch }) => {
    dispatch(setSubUsersList(list));
  },
);

export const setUsersList$ = createAsyncThunk<void, Array<UserType>>("users/setUsersList$", (list, { dispatch }) => {
  dispatch(setList(list));
});

export const setSubscriptionInfo = createAsyncThunk<void, SubscriptionInfo | undefined>(
  "users/setSubscriptionInfo",
  (data, { dispatch }) => {
    dispatch(setSubscriptionInfo$(data));
  },
);
