import "./index.scss";
import React, { FC, useCallback, useState } from "react";
import { Button, Form, Tag } from "antd";
import { useActions } from "../../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { conditionsAbbr, factsAbbr, SubscriberCondition } from "../../types/subscriberConditionType";
import { PlusOutlined } from "@ant-design/icons";
import ConditionModal from "../condition/conditionModal";
import { I18n, Translate } from "react-redux-i18n";
import useSurveyDisabled from "../../hooks/useSurveyDisabled";
import ConditionInline from "../condition/conditionInline";
import Divider from "../divider";

type Props = {
  readonly type: "questions" | "distributions";
  readonly title?: string;
  readonly noStyle?: boolean;
  readonly variant?: "modal" | "inline";
};

const SubscriberProfileFiltersComponent: FC<Props> = (props) => {
  const { type, title = I18n.t("bySubscriberProfile"), noStyle, variant = "modal" } = props;
  const { updateCurrentQuestion, updateCurrentDistribution } = useActions();
  const [showAddModal, setShowAddModal] = useState(false);
  const subscriberConditions: Array<SubscriberCondition> = useSelector(
    (state: RootState) =>
      (state as any)[type]?.current?.[type === "questions" ? "conditions" : "subscriberConditions"] || [],
  );
  const disabled = useSurveyDisabled();

  const onSetConditions = useCallback(
    (_conditions: Array<SubscriberCondition>) => {
      if (type === "questions") {
        return updateCurrentQuestion({ conditions: _conditions });
      }
      updateCurrentDistribution({ subscriberConditions: _conditions });
    },
    [type, updateCurrentQuestion, updateCurrentDistribution],
  );

  const deleteCondition = useCallback(
    (index: number) => {
      onSetConditions(subscriberConditions.filter((_, i) => i !== index));
    },
    [subscriberConditions],
  );

  const onShowAddForm = useCallback(() => {
    setShowAddModal((prev) => !prev);
  }, [setShowAddModal]);

  const onConditionSave = useCallback(
    (condition: SubscriberCondition) => {
      onSetConditions([...subscriberConditions, condition]);
    },
    [subscriberConditions],
  );

  return (
    <div className={"conditions-tag"}>
      {variant === "modal" ? (
        <ConditionModal show={showAddModal} onHide={onShowAddForm} onFinish={onConditionSave} />
      ) : (
        <ConditionInline onAdd={onConditionSave} />
      )}
      <Divider />
      <Form.Item noStyle={noStyle} label={title}>
        {subscriberConditions.map((condition, index) => (
          <Tag key={condition.value + index} closable={!disabled} onClose={() => deleteCondition(index)}>
            {factsAbbr()[condition.fact] ?? condition.fact}
            {": "}
            <b>
              {conditionsAbbr()[condition.operator]} {condition.value}
            </b>
          </Tag>
        ))}
        {variant === "modal" && <Action disabled={disabled} onShowAddForm={onShowAddForm} />}
      </Form.Item>
    </div>
  );
};

export default SubscriberProfileFiltersComponent;

type ActionProps = {
  readonly disabled: boolean;
  readonly onShowAddForm: () => void;
};
const Action: FC<ActionProps> = ({ disabled, onShowAddForm }) => {
  if (disabled) {
    return (
      <Button type={"dashed"} size={"small"} disabled>
        <PlusOutlined /> <Translate value={"addFilter"} />
      </Button>
    );
  }

  return (
    <Tag onClick={onShowAddForm} className={"tag-add-btn"}>
      <PlusOutlined /> <Translate value={"addFilter"} />
    </Tag>
  );
};
