import { useMemo, useRef } from "react";
import { TourProps } from "antd";
import { I18n } from "react-redux-i18n";
import { TourItemsEnum, WelcomeTourItemsEnum } from "../../../types/tour";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IGetTourDataReturnProps } from "./types";

export const useWelcomeTourData = (): IGetTourDataReturnProps => {
  const { tourObj, tourCompleted } = useSelector((state: RootState) => {
    const obj = state.users.userExtendedData?.tours.find((t) => t.tourName === TourItemsEnum.WELCOME);
    return {
      tourObj: obj,
      tourCompleted: Boolean(obj?.completed),
    };
  });
  const location = useLocation();

  const available = useMemo(() => {
    return location.pathname.endsWith("/surveys") && !tourCompleted;
  }, [location.pathname, tourCompleted]);

  const myProfile = useRef(null);
  const surveys = useRef(null);
  const settings = useRef(null);
  const contacts = useRef(null);
  const createSurvey = useRef(null);

  const modalProps = {
    title: I18n.t(`tours.${TourItemsEnum.WELCOME}.title`),
    description: I18n.t(`tours.${TourItemsEnum.WELCOME}.description`),
    submitLabel: I18n.t("tours.startTour"),
    cancelLabel: I18n.t("tours.rejectTour"),
  };

  const steps: TourProps["steps"] = [
    {
      title: I18n.t(`tours.${TourItemsEnum.WELCOME}.myProfile`),
      description: I18n.t(`tours.${TourItemsEnum.WELCOME}.myProfileDescription`),
      target: () => myProfile.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.WELCOME}.surveys`),
      description: I18n.t(`tours.${TourItemsEnum.WELCOME}.surveysDescription`),
      target: () => surveys.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.WELCOME}.settings`),
      description: I18n.t(`tours.${TourItemsEnum.WELCOME}.settingsDescription`),
      target: () => settings.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.WELCOME}.contacts`),
      description: I18n.t(`tours.${TourItemsEnum.WELCOME}.contactsDescription`),
      target: () => contacts.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.WELCOME}.createSurvey`),
      description: I18n.t(`tours.${TourItemsEnum.WELCOME}.createSurveyDescription`),
      target: () => createSurvey.current,
    },
  ];

  return {
    available: available,
    completed: tourCompleted,
    steps: steps,
    refs: {
      [WelcomeTourItemsEnum.MY_PROFILE]: myProfile,
      [WelcomeTourItemsEnum.SURVEYS]: surveys,
      [WelcomeTourItemsEnum.SETTINGS]: settings,
      [WelcomeTourItemsEnum.CONTACTS]: contacts,
      [WelcomeTourItemsEnum.CREATE_SURVEY]: createSurvey,
    },
    modal: modalProps,
    tourObj: tourObj,
  };
};
