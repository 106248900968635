import dayjs, { Dayjs } from "dayjs";

export const getMomentTime = (time?: Dayjs | string) => {
  const split = typeof time === "string" ? time.split(":") : [];
  if (split.length) {
    const k = new Date();
    k.setHours(Number(split[0]));
    k.setMinutes(Number(split[1]));
    return dayjs(k);
  }
  return undefined;
};
