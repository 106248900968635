import { CSSProperties, ReactNode } from "react";

type Props = {
  children: ReactNode;
  position?: "left" | "right" | "center" | "space-around";
  width?: number | string;
  mt?: number;
  gap?: number;
  alignItems?: CSSProperties["alignItems"];
};

const FlexSpace = (props: Props) => {
  return (
    <div
      style={{
        gap: props.gap ?? 30,
        display: "flex",
        flexWrap: "wrap",
        alignItems: props?.alignItems ?? "center",
        marginTop: props?.mt ?? 0,
        width: props?.width ?? "100%",
        justifyContent: props?.position ?? "left",
      }}
    >
      {props.children}
    </div>
  );
};

export default FlexSpace;
