import axios, { ResponseType } from "axios";
import store from "../store";
import { setLoader } from "../store/slices/app/appService";
import { notification } from "antd";
import { I18n } from "react-redux-i18n";
import { doLogout } from "./doLogout";
import { DEFAULT_ERROR_LABEL } from "./index";

type Config = {
  method: string;
  url: string;
  params: any;
  headers: any;
  data: any;
  responseType?: ResponseType;
};

const baseUrl = window._env_.API_BASE_URL + window._env_.API_PATH;

export const requestApi = async (
  method: string,
  url: string,
  data: any,
  multipart = false,
  withLoader = true,
  headers = {},
  params = {},
  baseURL = baseUrl,
  responseType?: ResponseType,
  showError = true,
) => {
  const axiosInstance = axios.create({ baseURL });

  const config: Config = { method, url, params, headers, data, responseType };

  const token = store.getState().session.jwtToken;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  if (multipart) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    config.headers = {
      ...config.headers,
      "Content-Type": undefined,
      enctype: "multipart/form-data",
    };
    config.data = formData;
  }

  try {
    store.dispatch(setLoader(withLoader));
    const response = await axiosInstance.request(config);
    return response.data;
  } catch (e: any) {
    if (e.response.status === 401) {
      doLogout(false);
    }
    if (showError) {
      notification.error({ message: e.response.data?.error ?? e.message ?? I18n.t(DEFAULT_ERROR_LABEL) });
    }
    console.error(e);
    throw e;
  } finally {
    store.dispatch(setLoader(false));
  }
};
