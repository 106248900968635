import { DistributionStatus } from "../types/distributionType";

export enum DistributionStatusAction {
  START = "start",
  PAUSE = "pause",
  FINISH = "finish",
}

export const getDistributionStatusByAction = (action: DistributionStatusAction) => {
  switch (action) {
    case DistributionStatusAction.FINISH:
      return DistributionStatus.FINISHED;
    case DistributionStatusAction.START:
      return DistributionStatus.ACTIVE;
    case DistributionStatusAction.PAUSE:
      return DistributionStatus.PAUSED;
  }
};

export const getMessageKeyByAction = (action: DistributionStatusAction) => {
  switch (action) {
    case DistributionStatusAction.FINISH:
      return "distributionFinished";
    case DistributionStatusAction.START:
      return "distributionStarted";
    case DistributionStatusAction.PAUSE:
      return "distributionPaused";
  }
};
