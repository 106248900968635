import React, { FC, useCallback } from "react";
import { Form, Input, Modal } from "antd";
import { I18n } from "react-redux-i18n";
import { useActions } from "../../hooks/useActions";
import FooterActions from "../FooterActions";
import { getPasswordTooltip } from "./helpers";

export type PasswordChangeProps = {
  readonly oldPassword: string;
  readonly newPassword: string;
};

const PasswordChange: FC<{
  readonly show: boolean;
  readonly setShow: (show: boolean) => void;
  readonly className?: string;
}> = ({ show, setShow, className }) => {
  const { changeUserPassword } = useActions();
  const [form] = Form.useForm<PasswordChangeProps>();

  const onSubmit = useCallback(() => {
    form
      .validateFields()
      .then(async (values) => {
        await changeUserPassword(values);
        setShow(false);
        form.resetFields();
      })
      .catch(console.log);
  }, [form, changeUserPassword, setShow]);

  const onCancel = useCallback(() => {
    form.resetFields();
    setShow(false);
  }, [form]);

  return (
    <Modal
      open={show}
      width={400}
      footer={
        <FooterActions
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitLabelTranslationKey={"confirm"}
          confirmTitle={I18n.t("passwordChangeConfirm")}
        />
      }
      onCancel={onCancel}
      className={className ?? "password-change-modal"}
      title={I18n.t("passwordChange")}
    >
      <Form form={form} layout={"vertical"} onFinish={onSubmit} style={{ marginTop: 30 }}>
        <Form.Item
          name={"oldPassword"}
          label={I18n.t("oldPassword")}
          rules={[
            {
              required: true,
              message: I18n.t("enterOldPassword"),
            },
          ]}
        >
          <Input.Password placeholder={I18n.t("enterOldPassword")} />
        </Form.Item>

        <Form.Item
          name={"newPassword"}
          tooltip={getPasswordTooltip()}
          label={I18n.t("newPassword")}
          rules={[
            {
              required: true,
              message: I18n.t("enterNewPassword"),
              pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[_!@#$%^&+=-])(?!.*['"]).{8,32}$/,
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder={I18n.t("enterNewPassword")} />
        </Form.Item>

        <Form.Item
          label={I18n.t("confirmPassword")}
          dependencies={["newPassword"]}
          name={"confirm"}
          hasFeedback
          rules={[
            {
              required: true,
              message: I18n.t("confirmPassword"),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(I18n.t("passwordsNotEqual")));
              },
            }),
          ]}
        >
          <Input.Password placeholder={I18n.t("enterNewPassword")} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PasswordChange;
