import React, { FC } from "react";
import { FileImageOutlined } from "@ant-design/icons";

export const IconImage: FC<{ readonly path?: string }> = ({ path }) => {
  if (path) {
    return (
      <img src={path} alt="" style={{ top: -2, maxWidth: 20, maxHeight: 20, marginRight: 5, position: "relative" }} />
    );
  }

  return <FileImageOutlined />;
};
