import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SessionType } from "../../../types/sessionType";

const initialState: SessionType = {
  jwtToken: undefined,
  refreshToken: undefined,
  username: undefined,
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    login(state: SessionType, action: PayloadAction<SessionType>) {
      state.jwtToken = action.payload.jwtToken;
      state.refreshToken = action.payload.refreshToken;
      state.username = action.payload?.username;
    },
    logout(state: SessionType) {
      state.jwtToken = undefined;
      state.refreshToken = undefined;
      state.username = undefined;
    },
    setUsername(state: SessionType, action: PayloadAction<string | undefined>) {
      state.username = action.payload;
    },
  },
});

export const { login, logout, setUsername } = sessionSlice.actions;
export default sessionSlice.reducer;
