import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Modal, Tour } from "antd";
import FooterActions from "../../../components/FooterActions";
import { TourItemsEnum } from "../../../types/tour";
import { useRequests } from "../../useRequests";
import { useLocation } from "react-router-dom";
import { IGetTourDataReturnProps } from "./types";
import { useWelcomeTourData } from "./useWelcomeTourData";
import { useCreateSurveyTourData } from "./useCreateSurveyTourData";
import { useCreateSurveyQuestionsTourData } from "./useCreateSurveyQuestionsTourData";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { I18n } from "react-redux-i18n";

type TourContextProps = {
  readonly currentTour: IGetTourDataReturnProps | undefined;
  readonly data: Record<TourItemsEnum, IGetTourDataReturnProps>;
  readonly tourInProgress: boolean;
};

const TourContext = createContext<TourContextProps | null>(null);

const useTourCtx = () => {
  const context = useContext(TourContext);

  if (!context) {
    throw new Error("useTourCtx must be used within an TourProvider");
  }

  return context;
};

const TourProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { completeTour } = useRequests();
  const welcomeTourProps = useWelcomeTourData();
  const createSurveyTourProps = useCreateSurveyTourData();
  const createSurveyQuestionsTourProps = useCreateSurveyQuestionsTourData();
  const location = useLocation();
  const tours = useSelector((state: RootState) => state.users.userExtendedData?.tours ?? []);

  const [tourOpened, setTourOpened] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [currentTour, setCurrentTour] = useState<IGetTourDataReturnProps | undefined>();

  const data: Record<TourItemsEnum, IGetTourDataReturnProps> = {
    [TourItemsEnum.WELCOME]: welcomeTourProps,
    [TourItemsEnum.CREATE_SURVEY]: createSurveyTourProps,
    [TourItemsEnum.CREATE_SURVEY_QUESTIONS]: createSurveyQuestionsTourProps,
    [TourItemsEnum.CREATE_SURVEY_DESIGN]: welcomeTourProps,
    [TourItemsEnum.CREATE_SURVEY_SHARE]: welcomeTourProps,
    [TourItemsEnum.CREATE_SURVEY_INTEGRATIONS]: welcomeTourProps,
    [TourItemsEnum.CREATE_SURVEY_RESULTS]: welcomeTourProps,
    [TourItemsEnum.CONTACTS]: welcomeTourProps,
    [TourItemsEnum.SETTINGS]: welcomeTourProps,
    [TourItemsEnum.MY_PROFILE]: welcomeTourProps,
  };

  const onCancel = useCallback(() => {
    setTourOpened(false);
    setModalOpened(false);
    setCurrentTour(undefined);
  }, [setTourOpened, setModalOpened, setCurrentTour]);

  const onCompleteTour = useCallback(async () => {
    try {
      const tourId = currentTour?.tourObj?.tourId;
      if (tourId) await completeTour(tourId);
      onCancel();
    } catch (e) {
      console.log(e);
    }
  }, [currentTour, completeTour, onCancel]);

  const onSubmitModal = useCallback(() => {
    setModalOpened(false);
    setTourOpened(true);
  }, [setTourOpened, setModalOpened]);

  useEffect(() => {
    const itemsKeys = Object.keys({ ...data }) as TourItemsEnum[];
    for (const item of itemsKeys) {
      const dataItem = { ...data[item] };
      if (dataItem.available && !dataItem.completed) {
        setCurrentTour({ ...dataItem });
        if (!dataItem.modal) {
          setTourOpened(true);
        } else {
          setModalOpened(true);
        }
      }
    }
  }, [location.pathname]);

  return (
    <TourContext.Provider
      value={{
        data: data,
        currentTour: currentTour,
        tourInProgress: Boolean(currentTour) && tourOpened,
      }}
    >
      {children}
      {Boolean(tours.length) && (
        <>
          {Boolean(currentTour?.modal) && (
            <Modal
              open={modalOpened}
              onCancel={onCancel}
              title={currentTour?.modal?.title}
              children={currentTour?.modal?.description}
              footer={
                <FooterActions
                  onSubmit={onSubmitModal}
                  onCancel={onCompleteTour}
                  submitLabel={currentTour?.modal?.submitLabel}
                  cancelLabel={currentTour?.modal?.cancelLabel}
                />
              }
            />
          )}
          {currentTour && (
            <Tour
              steps={currentTour.steps?.map((step) => ({
                ...step,
                nextButtonProps: { ...step.nextButtonProps, children: I18n.t("tours.nextButtonTitle") },
                prevButtonProps: { ...step.prevButtonProps, children: I18n.t("tours.prevButtonTitle") },
              }))}
              open={tourOpened}
              onClose={onCancel}
              onFinish={onCompleteTour}
            />
          )}
        </>
      )}
    </TourContext.Provider>
  );
};

export { useTourCtx, TourProvider };
