import React, { FC, useEffect, useState } from "react";
import { Alert, Button } from "antd";
import { Translate } from "react-redux-i18n";
import CreateAttribute from "./createAttribute";
import ContactsAttributesTable from "./table";
import { useActions } from "../../../hooks/useActions";

const ContactsSettings: FC = () => {
  const [createAttributeVisible, setCreateAttributeVisible] = useState(false);
  const { getAttributes } = useActions();

  useEffect(() => {
    getAttributes();
  }, []);

  return (
    <div>
      <Alert type={"info"} style={{ marginBottom: 20 }} message={<Translate value={"contactsSettingsHelpTxt"} />} />
      <div>
        <Button type={"primary"} onClick={() => setCreateAttributeVisible(true)}>
          <Translate value={"addAttribute"} />
        </Button>
      </div>
      <ContactsAttributesTable />
      <CreateAttribute open={createAttributeVisible} onClose={() => setCreateAttributeVisible(false)} />
    </div>
  );
};

export default ContactsSettings;
