import { FC, useCallback, useState } from "react";
import IconButton from "../iconButton";
import DistributionEditModal from "../../../distributionAndNotification/distributions/distributionEditModal";
import { useParams } from "react-router-dom";
import { ReceiverTypeEnum } from "../../../../../../../types/distributionType";
import { I18n } from "react-redux-i18n";
import { SMS } from "../../../../../../../components/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { useActions } from "../../../../../../../hooks/useActions";

type Props = {
  readonly showButton?: boolean;
  readonly openModal?: boolean;
};

const SMSButton: FC<Props> = ({ showButton = true, openModal }) => {
  const { id: surveyId } = useParams();
  const [modalOpen, setModalOpen] = useState(openModal ?? false);
  const { currentChannel, currentDistribution } = useSelector((state: RootState) => ({
    currentChannel: state.distributionChannels.current,
    currentDistribution: state.distributions.current,
  }));
  const { setCurrentDistributionChannel } = useActions();

  const handleClick = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
    setCurrentDistributionChannel(undefined);
  }, [setModalOpen, setCurrentDistributionChannel]);

  return (
    <>
      {showButton && <IconButton icon={<SMS />} label={"SMS"} onClick={handleClick} />}
      {modalOpen && (
        <DistributionEditModal
          descriptionInitial={currentChannel?.description ?? I18n.t("smsDistribution")}
          distributionChannelId={currentChannel?.channelId}
          receiverType={ReceiverTypeEnum.MSISDN}
          onCancel={handleCloseModal}
          data={currentDistribution}
          surveyId={surveyId}
          open={modalOpen}
        />
      )}
    </>
  );
};

export default SMSButton;
