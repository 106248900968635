import React, { FC, useCallback, useMemo, useState } from "react";
import QuestionEditPage from "./edit";
import { useActions } from "../../../../../hooks/useActions";
import { Button, List, message } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { useModalCtx } from "../../../../../hooks/useConfirmModal";
import { QuestionType } from "../../../../../types/questionType";
import { getNextOrderValue } from "../../../utils";
import { cutWelcomeMessage, NEW_QUESTION, useQuestionEditPageSelectorsState } from "./helpers";
import { templateIcons } from "../../../../../types/templateEnum";
import useSurveyDisabled from "../../../../../hooks/useSurveyDisabled";
import FooterActions from "../../../../../components/FooterActions";
import { CreateSurveyQuestionsTourItemsEnum, TourItemsEnum } from "../../../../../types/tour";
import { useTourCtx } from "../../../../../hooks/providers/tours/TourProvider";

export const useFormDirtyState = () => {
  const [isFormDirty, setFormDirty] = useState(false);
  return { isFormDirty, setFormDirty };
};

type Props = {
  readonly changeEditPageVisible: (val?: boolean) => void;
};

const QuestionEditPageLayout: FC<Props> = ({ changeEditPageVisible }) => {
  const {
    tourInProgress,
    data: {
      [TourItemsEnum.CREATE_SURVEY_QUESTIONS]: { refs },
    },
  } = useTourCtx();
  const disabled = useSurveyDisabled();
  const { setCurrentQuestion } = useActions();
  const { openModal, closeModal } = useModalCtx();
  const { isFormDirty, setFormDirty } = useFormDirtyState();
  const { question: currentQuestion, questions } = useQuestionEditPageSelectorsState();

  const confirm = useCallback(
    (q?: QuestionType) => {
      if (!isFormDirty) {
        return setCurrentQuestion(q);
      }
      openModal({
        title: I18n.t("areYouSureYouWantToLeaveEditPage"),
        body: I18n.t("youWillLostYourChanges"),
        onCancel: closeModal,
        footer: (
          <FooterActions
            submitLabelTranslationKey={"confirm"}
            onCancel={closeModal}
            onSubmit={() => {
              setFormDirty(false);
              setCurrentQuestion(q);
              closeModal();
            }}
          />
        ),
      });
    },
    [isFormDirty],
  );

  const handleAddNewQuestion = useCallback(() => {
    if (!currentQuestion?.questionId) {
      return message.warning(I18n.t("youHaveUncompletedNewQuestionWarning"));
    }
    setCurrentQuestion(NEW_QUESTION(getNextOrderValue(questions)));
  }, [currentQuestion, questions]);

  const dataSource = useMemo(() => {
    if (!currentQuestion?.questionId) {
      return cutWelcomeMessage(currentQuestion ? [...questions, currentQuestion] : questions);
    }
    return cutWelcomeMessage(questions);
  }, [currentQuestion, questions]);

  const onGetBack = useCallback(() => {
    changeEditPageVisible(false);
  }, [changeEditPageVisible]);

  return (
    <div className={"question-edit-page-layout"}>
      <div className={"q-list"} ref={refs[CreateSurveyQuestionsTourItemsEnum.QUESTIONS_LIST]}>
        <div style={{ marginBottom: 20, width: "92%" }}>
          <Button
            disabled={false}
            style={{ width: "100%" }}
            icon={<ArrowLeftOutlined />}
            onClick={tourInProgress ? undefined : onGetBack}
            ref={refs[CreateSurveyQuestionsTourItemsEnum.BACK_BUTTON]}
          >
            <Translate value={"backToQuestionsTable"} />
          </Button>
        </div>
        <List
          style={{ paddingRight: 10 }}
          itemLayout="horizontal"
          dataSource={dataSource}
          renderItem={(item, index) => (
            <List.Item
              key={index}
              onClick={() => (item?.order !== currentQuestion?.order ? confirm(item) : {})}
              className={`--list-item ${
                currentQuestion?.order === item?.order
                  ? !currentQuestion?.questionId
                    ? "new-active"
                    : "active"
                  : "inactive"
              }`}
            >
              <List.Item.Meta
                title={
                  <div style={{ display: "flex" }}>
                    <span style={{ overflow: "hidden", marginRight: 5 }}>
                      #{item?.order - 1}: {item?.name}
                    </span>
                    <span style={{ marginLeft: "auto" }}>
                      {item?.answerTemplate?.type ? templateIcons[item?.answerTemplate?.type] : ""}
                    </span>
                  </div>
                }
              />
            </List.Item>
          )}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginRight: 25,
          }}
        >
          <Button
            type={"dashed"}
            disabled={disabled}
            icon={<PlusOutlined />}
            style={{ marginTop: 30, width: 300 }}
            onClick={tourInProgress ? undefined : handleAddNewQuestion}
          >
            <Translate value={"addQuestion"} />
          </Button>
        </div>
      </div>
      <div className={"qe-content"}>
        <QuestionEditPage
          formDirtyState={{ isFormDirty, setFormDirty }}
          changeEditPageVisible={changeEditPageVisible}
        />
      </div>
    </div>
  );
};

export default QuestionEditPageLayout;
