import { useMemo, useRef } from "react";
import { TourProps } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import { CreateSurveyQuestionsTourItemsEnum, TourItemsEnum } from "../../../types/tour";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IGetTourDataReturnProps } from "./types";
import { StepsEnum } from "../../../types/surveyEditTabsEnum";
import { useActions } from "../../useActions";
import { NEW_QUESTION } from "../../../pages/surveys/edit/steps/questions/helpers";
import { getNextOrderValue } from "../../../pages/surveys/utils";

export const useCreateSurveyQuestionsTourData = (): IGetTourDataReturnProps => {
  const { tourObj, tourCompleted, isCurrentStepQuestions, isListQuestionsEmpty, questions } = useSelector(
    (state: RootState) => {
      const obj = state.users.userExtendedData?.tours.find((t) => t.tourName === TourItemsEnum.CREATE_SURVEY_QUESTIONS);
      const qList = state.questions.list;

      return {
        tourObj: obj,
        tourCompleted: Boolean(obj?.completed),
        isCurrentStepQuestions: state.persisted.surveysEditPageActiveStep === StepsEnum.QUESTIONS,
        isListQuestionsEmpty: qList.length <= 1 || false,
        questions: qList,
      };
    },
  );
  const { setQuestionCreateFormVisible, setCurrentQuestion } = useActions();
  const location = useLocation();

  const available = useMemo(() => {
    return isCurrentStepQuestions && location.pathname.endsWith("/edit") && !tourCompleted && isListQuestionsEmpty;
  }, [location.pathname, tourCompleted, isCurrentStepQuestions, isListQuestionsEmpty]);

  const questionsTable = useRef(null);
  const createQuestionButton = useRef(null);

  const questionName = useRef(null);
  const template = useRef(null);
  const languageSelecting = useRef(null);
  const questionSettings = useRef(null);
  const questionPreview = useRef(null);
  const questionShowLogic = useRef(null);
  const tags = useRef(null);
  const questionsList = useRef(null);
  const backButton = useRef(null);
  const surveyPreviewButton = useRef(null);

  const modalProps = {
    title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.title`),
    description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.description`),
    submitLabel: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.submitBtn`),
    cancelLabel: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.cancelBtn`),
  };

  const steps: TourProps["steps"] = [
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionsTableTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionsTableDescription`),
      target: () => questionsTable.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.createQuestionButtonTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.createQuestionButtonDescription`),
      target: () => createQuestionButton.current,
      nextButtonProps: {
        onClick: () => {
          setCurrentQuestion(NEW_QUESTION(getNextOrderValue(questions)));
          setQuestionCreateFormVisible(true);
        },
      },
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.templateTitle`),
      description: (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.templateDescription1`} />
          <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.templateDescription2`} />
          <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.templateDescription3`} />
          <a
            href="https://www.notion.so/surveysuite/4e190b1508fe4cc6b932028083fa740c?pvs=4"
            rel={"noreferrer"}
            target={"_blank"}
          >
            <Translate value={"tours.link"} />
          </a>
        </div>
      ),
      target: () => template.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionNameTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionNameDescription`),
      target: () => questionName.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.languageSelectingTitle`),
      description: (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.languageSelectingDescription1`} />
          <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.languageSelectingDescription2`} />
          <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.languageSelectingDescription3`} />
        </div>
      ),
      target: () => languageSelecting.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionSettingsTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionSettingsDescription`),
      target: () => questionSettings.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionPreviewTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionPreviewDescription`),
      target: () => questionPreview.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionShowLogicTitle`),
      description: (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionShowLogicDescription1`} />
          <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionShowLogicDescription2`} />
        </div>
      ),
      target: () => questionShowLogic.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.tagsTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.tagsDescription`),
      target: () => tags.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionsListTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.questionsListDescription`),
      target: () => questionsList.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.backButtonTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.backButtonDescription`),
      target: () => backButton.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.surveyPreviewButtonTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY_QUESTIONS}.surveyPreviewButtonDescription`),
      target: () => surveyPreviewButton.current,
    },
  ];

  return {
    available: available,
    completed: tourCompleted,
    steps: steps,
    refs: {
      [CreateSurveyQuestionsTourItemsEnum.QUESTIONS_TABLE]: questionsTable,
      [CreateSurveyQuestionsTourItemsEnum.CREATE_QUESTION_BUTTON]: createQuestionButton,

      [CreateSurveyQuestionsTourItemsEnum.QUESTION_NAME]: questionName,
      [CreateSurveyQuestionsTourItemsEnum.TEMPLATE]: template,
      [CreateSurveyQuestionsTourItemsEnum.LANGUAGE_SELECTION]: languageSelecting,
      [CreateSurveyQuestionsTourItemsEnum.QUESTION_SETTINGS]: questionSettings,
      [CreateSurveyQuestionsTourItemsEnum.QUESTION_PREVIEW]: questionPreview,
      [CreateSurveyQuestionsTourItemsEnum.QUESTION_SHOW_LOGIC]: questionShowLogic,
      [CreateSurveyQuestionsTourItemsEnum.TAGS]: tags,
      [CreateSurveyQuestionsTourItemsEnum.QUESTIONS_LIST]: questionsList,
      [CreateSurveyQuestionsTourItemsEnum.BACK_BUTTON]: backButton,
      [CreateSurveyQuestionsTourItemsEnum.SURVEY_PREVIEW_BUTTON]: surveyPreviewButton,
    },
    modal: modalProps,
    tourObj: tourObj,
  };
};
