import { LabelValue } from "../../../../../types/labelValue";
import dayjs, { Dayjs } from "dayjs";

export enum TabKeysEnum {
  REVIEW = "REVIEW",
  REPORTS = "REPORTS",
  ANSWERS = "ANSWERS",
}

export const createMonthList = (value?: Dayjs | null): Array<LabelValue> => {
  const currentDate = value ? new Date(value.toDate()) : new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const totalDays = new Date(currentYear, currentMonth + 1, 0).getDate();
  const monthList: { label: string; value: number }[] = [];

  for (let day = 1; day <= totalDays; day++) {
    const date = new Date(currentYear, currentMonth, day);
    const formattedDate = dayjs(date).format("DD");
    const randomValue = Math.floor(Math.random() * 4001);

    monthList.push({
      label: formattedDate,
      value: randomValue,
    });
  }

  return monthList;
};

export const mapMonthList = (data: Array<LabelValue>): Array<LabelValue> => {
  return data.map((it) => ({
    label: it.label ? dayjs(it.label as string).format("DD") : it.label || "N/A",
    value: it.value ? Number(it.value) : 0,
  }));
};

export const mapValuesFromStringToNumber = (data: Array<LabelValue>): Array<LabelValue> => {
  return data.map((it) => ({
    label: it.label,
    value: it.value ? Number(it.value) : 0,
  }));
};

export const convertListStatisticsToObj = (values: Array<LabelValue>) => {
  return values.reduce((result: any, item) => {
    const { label, value } = item;
    result[label as any] = Number(value);
    return result;
  }, {});
};

export const getRandomKey = () => {
  return Math.random().toString(36).slice(2, 9);
};

export enum ChartViewTypeEnum {
  DEVICE = "DEVICE",
  LANGUAGE = "LANGUAGE",
  MAILING = "MAILING",
}
