export const AiLoader = () => {
  return (
    <div className={"ai-loader"}>
      <div className="wrap">
        <div className="angle" />
        <div className="angle" />
        <div className="angle" />
        <div className="angle" />
      </div>
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation={12} />
            <feColorMatrix in="blur" mode="matrix" result="goo" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" />
            <feComposite in2="goo" in="SourceGraphic" operator="atop" />
          </filter>
        </defs>
      </svg>
    </div>
  );
};
