export enum SecurityEnum {
  AUTO = "Auto",
  NONE = "None",
  SSL = "SSL",
  TSL = "TSL",
}

export enum EmailProviderStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum EmailProviderTypesEnum {
  POSTMARK = "postmark",
  SMTP = "smtp",
}

export type EmailProviderType = {
  readonly providerType?: EmailProviderTypesEnum;
  readonly providerId?: number;
  readonly smtpServer: string;
  readonly port: string;
  readonly username?: string;
  readonly password?: string;
  readonly status: EmailProviderStatus;
  readonly xPostmarkServerToken: string;
  readonly description: string;
  readonly fromEmail: string;
  readonly securityType: SecurityEnum;
  readonly userId: string;
};
