import { Button, Checkbox, Form, Input } from "antd";
import React, { FC, useState } from "react";
import { CustomAnswerLinesEnum, lineOptionsList } from "../../../../../../types/customAnswerTypesEnum";
import { I18n, Translate } from "react-redux-i18n";
import RadioFormItemComponent from "../../../../../../components/form/items/radio";
import { SearchOutlined } from "@ant-design/icons";
import InputFormItemComponent from "../../../../../../components/form/items/input";

type FormatType = "none" | "regex";

type Props = {
  readonly showLineOptions?: boolean;
  readonly showRegexOptions?: boolean;
};

const CustomTemplate: FC<Props> = (props) => {
  const { showLineOptions, showRegexOptions } = props;
  const [format, setFormat] = useState<FormatType>("none");

  return (
    <div style={{ width: "100%", maxWidth: 435 }}>
      <Form.Item name={["answerTemplate", "customAnswerIsRequired"]} valuePropName={"checked"} noStyle>
        <Checkbox children={I18n.t("answerRequired")} style={{ marginBottom: 14 }} />
      </Form.Item>
      {showLineOptions && (
        <RadioFormItemComponent
          name={["answerTemplate", "customAnswerLineOption"]}
          initialValue={CustomAnswerLinesEnum.ONE_LINE}
          label={I18n.t("inputField")}
          values={lineOptionsList()}
          required={false}
        />
      )}
      {showRegexOptions && (
        <RadioFormItemComponent
          onChange={(newFormat: string) => setFormat(newFormat as FormatType)}
          values={[
            { value: "none", label: "Нет" },
            { value: "regex", label: "Regex" },
          ]}
          label={"Формат поля ввода"}
          initialValue={"none"}
          required={false}
          name={"format"}
        />
      )}

      {format === "regex" && (
        <div style={{ maxWidth: 600 }}>
          <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
            <Input
              value={`${I18n.t("defaultLabel")}: /[A-Za-z0-9]/`}
              style={{ cursor: "default", marginRight: 10 }}
              disabled
            />
            <Button
              type={"primary"}
              icon={<SearchOutlined />}
              onClick={() => window.open("https://learn.javascript.ru/regular-expressions", "_blank")}
            >
              <Translate value={"documentation"} />
            </Button>
          </div>
          <InputFormItemComponent name={["answerTemplate", "customRegexp"]} placeHolder={I18n.t("enterRegexp")} />
        </div>
      )}
    </div>
  );
};

export default CustomTemplate;
