import InputFormItemComponent from "../../../../components/form/items/input";
import { I18n } from "react-redux-i18n";
import SelectFormItemComponent from "../../../../components/form/items/select";
import React, { FC } from "react";
import { SmsProviderTypesEnum } from "../../../../types/smsProvider";
import HiddenValueFormItemComponent from "../../../../components/form/items/hiddenValue";

const providers = Object.values(SmsProviderTypesEnum).map((it) => ({ value: it, label: it }));

export const FormItems: FC = () => {
  return (
    <>
      <SelectFormItemComponent required name={"providerType"} values={providers} label={I18n.t("provider")} />
      <InputFormItemComponent name={"description"} label={I18n.t("connectionName")} />
      <InputFormItemComponent name={"sender"} label={I18n.t("sender")} />
      <InputFormItemComponent
        name={"url"}
        label={"URL"}
        errorText={I18n.t("invalidUrl")}
        tooltip={"http://example.com:4000"}
        pattern={/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i}
      />
      <InputFormItemComponent name={"username"} label={I18n.t("userName")} required={false} />
      <InputFormItemComponent type={"password"} label={I18n.t("password")} name={"password"} required={false} />
      <HiddenValueFormItemComponent name={"providerId"} />
    </>
  );
};
