import { QuestionType } from "../../../../../../types/questionType";
import { ColumnsType } from "antd/es/table";
import { MAX_QUESTIONS_QUANTITY, TEXT_LIGHT_COLOR } from "../../../../../../utils";
import { I18n, Translate } from "react-redux-i18n";
import { renderNullableText } from "../../../../../../utils/renderNullableText";
import isWelcome from "../../../../../../utils/welcomeSmsChecker";
import { Button, Popover } from "antd";
import ActionButton from "../../../../../../components/actionButton";
import { MoreOutlined } from "@ant-design/icons";
import React from "react";
import { getTemplateLabels, templateIcons } from "../../../../../../types/templateEnum";

export const questionsColumns = (
  onEdit: (q?: QuestionType) => void,
  onDelete: (id?: string) => void,
  onDuplicate: (q: QuestionType) => void,
  disabled: boolean,
  questions: Array<QuestionType>,
): ColumnsType<QuestionType> => {
  const limitReached = questions?.length >= MAX_QUESTIONS_QUANTITY;

  return [
    {
      key: "sort",
      width: 1,
    },
    {
      key: "order",
      dataIndex: "order",
      title: "№",
      render: (text) => Number(text) - 1,
    },
    {
      key: "name",
      title: I18n.t("questionName"),
      dataIndex: "name",
      render: (text, record) => (
        <div className={"link-name"}>
          <span className={"link"} onClick={() => onEdit(record)}>
            {text}
          </span>
        </div>
      ),
    },
    {
      title: I18n.t("template"),
      key: "answerTemplate",
      dataIndex: "answerTemplate",
      render: (_, record) => {
        const type = record?.answerTemplate?.type;
        return (
          <div>
            {!type ? (
              renderNullableText()
            ) : (
              <div className={"answer-template-name"}>
                {templateIcons[type]}
                {getTemplateLabels()[type]}
              </div>
            )}
          </div>
        );
      },
    },
    {
      key: "tags",
      width: 400,
      dataIndex: "tags",
      title: I18n.t("tags"),
      render: (_, record) =>
        record?.tags?.length ? (
          record.tags.map((tag, i) => (
            <span key={tag}>
              {tag}
              <span style={{ color: TEXT_LIGHT_COLOR }}>{record?.tags?.length - 1 === i ? "" : " | "}</span>
            </span>
          ))
        ) : (
          <Translate style={{ color: TEXT_LIGHT_COLOR }} value={"noData"} />
        ),
    },
    {
      key: "actions",
      render: (_, record) => {
        const isWelcomeSms = isWelcome(record);
        const canDelete = !questions?.find(
          (it) => it.previousAnswerCondition?.answeredQuestionId === record?.questionId,
        );

        return (
          <Popover
            placement="left"
            overlayInnerStyle={{ padding: 0 }}
            content={
              <div style={{ display: "grid" }}>
                <Button onClick={() => onEdit(record)} type={"text"}>
                  {I18n.t(disabled ? "show" : "edit")}
                </Button>
                {!disabled && !isWelcomeSms && (
                  <>
                    {!limitReached && (
                      <Button onClick={() => onDuplicate(record)} type={"text"}>
                        <Translate value={"createCopy"} />
                      </Button>
                    )}
                    <ActionButton
                      danger
                      hideIcon
                      disabled={!canDelete}
                      label={I18n.t("delete")}
                      tooltipLabel={canDelete ? undefined : I18n.t("thisQuestionIsCondition")}
                      confirmInfo={{
                        onConfirm: () => onDelete(record?.questionId),
                        label: I18n.t("deleteQuestionConfirmation"),
                      }}
                    />
                  </>
                )}
              </div>
            }
            trigger="click"
          >
            <MoreOutlined className={"surveys-table-actions-button"} />
          </Popover>
        );
      },
    },
  ];
};
