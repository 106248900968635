import { FC, useMemo } from "react";
import SelectFormItemComponent from "../form/items/select";
import { I18n } from "react-redux-i18n";
import { FactsEnum, getOperatorsList } from "../../types/subscriberConditionType";
import InputFormItemComponent from "../form/items/input";
import InputNumberFormItemComponent from "../form/items/inputNumber";
import DatepickerFormItemComponent from "../form/items/datepicker";
import { Form } from "antd";
import useConditions from "../conditionsTag/ useConditions";
import { mapToSelectItemCondition } from "./helpers";

type Props = {
  readonly layout: "vertical" | "horizontal";
  readonly noStyle?: boolean;
  readonly paramAsText?: boolean;
} & ReturnType<typeof useConditions>;

const ConditionForm: FC<Props> = (props) => {
  const isHorizontal = useMemo(() => props.layout === "horizontal", [props.layout]);

  const InputField = useMemo(() => {
    const style: any = isHorizontal ? { minWidth: 183, maxWidth: 183, flex: 1, marginRight: 10 } : {};
    if (props.selectedFact === FactsEnum.SUBSCRIBER_AGE) {
      return (
        <InputNumberFormItemComponent
          style={style}
          name={"value"}
          noStyle={props.noStyle}
          label={I18n.t("value")}
          placeHolder={I18n.t("enter")}
        />
      );
    } else if (props.selectedFact === FactsEnum.MSISDN_ACTIVATION_DATE) {
      return (
        <DatepickerFormItemComponent
          style={style}
          name={"value"}
          disablePast={false}
          noStyle={props.noStyle}
          label={I18n.t("value")}
        />
      );
    }
    return <InputFormItemComponent style={style} name={"value"} label={I18n.t("value")} noStyle={props.noStyle} />;
  }, [props.selectedFact]);

  return (
    <Form form={props.form} layout={props.layout} style={{ display: isHorizontal ? "flex" : undefined, gap: 10 }}>
      {props.paramAsText ? (
        <InputFormItemComponent
          name={"fact"}
          required={true}
          label={I18n.t("param")}
          placeHolder={I18n.t("enter")}
          style={isHorizontal ? { width: 300, flex: 2 } : {}}
        />
      ) : (
        <SelectFormItemComponent
          name={"fact"}
          required={true}
          allowClear={false}
          hasFeedback={false}
          noStyle={props.noStyle}
          label={I18n.t("param")}
          onSelect={props.factSelectHandler}
          placeHolder={I18n.t("select")}
          values={props.conditions.map(mapToSelectItemCondition)}
          style={isHorizontal ? { width: 300, flex: 2 } : {}}
        />
      )}

      <SelectFormItemComponent
        style={isHorizontal ? { width: 120, flex: 1 } : {}}
        values={props.paramAsText ? getOperatorsList() : props.operators}
        placeHolder={I18n.t("select")}
        label={I18n.t("operator")}
        noStyle={props.noStyle}
        hasFeedback={false}
        allowClear={false}
        name={"operator"}
        required={true}
      />
      {InputField}
    </Form>
  );
};

export default ConditionForm;
