import "./index.scss";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Alert, Button, Input, Space } from "antd";
import ApiKeyTableComponent from "./table";
import { useActions } from "../../../hooks/useActions";
import { I18n, Translate } from "react-redux-i18n";

const ApiKeysComponent: FC = () => {
  const { addApiKey } = useActions();
  const [description, setDescription] = useState<string | undefined>();
  const [err, setErr] = useState(false);
  const [placeholder, setPlaceholder] = useState(I18n.t("description"));

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
    if (err) setErr(false);
  };

  const submitHandler = () => {
    if (!description?.trim()) {
      return setErr(true);
    }
    setErr(false);
    addApiKey(description);
    setDescription(undefined);
  };

  useEffect(() => {
    setTimeout(() => {
      setPlaceholder(I18n.t("description"));
    }, 100);
  }, []);

  return (
    <>
      <Alert
        type={"info"}
        style={{ marginBottom: 20 }}
        message={<Translate value={"apiKeyHelpTxt"} />}
      />
      <div>
        <Space direction="horizontal" className={"filters"}>
          <Input
            placeholder={placeholder}
            style={{ width: 280, border: !err ? "" : "1px solid red" }}
            value={description}
            onChange={onInput}
          />
          <Button type={"primary"} onClick={submitHandler}>
            <Translate value={"create"} />
          </Button>
        </Space>
        <ApiKeyTableComponent />
      </div>
    </>
  );
};

export default ApiKeysComponent;
