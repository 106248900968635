import "./index.scss";
import React, { FC, useCallback, useState } from "react";
import { Alert, Button, Form, message, Modal, Space } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import { PlusOutlined } from "@ant-design/icons";
import EmailProvidersTableComponent from "./table";
import { EmailProviderType, EmailProviderTypesEnum } from "../../../types/EmailProviderType";
import SmtpForm from "./helpers/smtpForm";
import PostmarkForm from "./helpers/postmarkForm";
import { useActions } from "../../../hooks/useActions";
import FooterActions from "../../../components/FooterActions";

const EmailProvidersComponent: FC = () => {
  const { createEmailProvider } = useActions();
  const [type, setType] = useState<EmailProviderTypesEnum | undefined>();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<EmailProviderType>();

  const changeVisible = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  const handleCreateClick = useCallback(
    (type: EmailProviderTypesEnum) => {
      form.setFieldValue("providerType", type);
      setType(type);
      changeVisible();
    },
    [setType, changeVisible],
  );

  const handleCancel = useCallback(() => {
    setType(undefined);
    setOpen(false);
    form.resetFields();
  }, [setType, setOpen, form]);

  const handleSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        createEmailProvider(values);
        handleCancel();
      })
      .catch(() => message.error(I18n.t("fillRequiredFields")));
  }, [form, handleCancel, type]);

  return (
    <>
      <Alert
        type={"info"}
        style={{ marginBottom: 20 }}
        message={<Translate value={"emailProvidersMainDescription"} />}
      />
      <div className={"email-providers-page-layout"}>
        <Space direction={"horizontal"} className={"btns"}>
          <Button
            type={"primary"}
            icon={<PlusOutlined />}
            onClick={() => handleCreateClick(EmailProviderTypesEnum.SMTP)}
          >
            SMTP
          </Button>
          <Button
            type={"primary"}
            icon={<PlusOutlined />}
            onClick={() => handleCreateClick(EmailProviderTypesEnum.POSTMARK)}
          >
            Postmark
          </Button>
        </Space>
        <EmailProvidersTableComponent />

        <Modal
          open={open}
          onCancel={handleCancel}
          title={I18n.t(`add${type}Provider`)}
          footer={<FooterActions onCancel={handleCancel} onSubmit={handleSubmit} submitLabelTranslationKey={"add"} />}
        >
          <Form form={form} layout={"vertical"} style={{ marginTop: 20 }}>
            {type === EmailProviderTypesEnum.SMTP ? <SmtpForm /> : <PostmarkForm />}
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default EmailProvidersComponent;
