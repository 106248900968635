import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OffDayType } from "../../../types/offDayType";

type StateType = {
  list: Array<OffDayType>;
};
const initialState: StateType = {
  list: [],
};

const offDaysSlice = createSlice({
  name: "offDays",
  initialState,
  reducers: {
    setOffDaysList(state: StateType, action: PayloadAction<Array<OffDayType>>) {
      state.list = action.payload;
    },
    removeOffDayFromList(state: StateType, action: PayloadAction<number>) {
      state.list = state.list.filter((offDay) => offDay.id !== action.payload);
    },
    addOffDayToList(state: StateType, action: PayloadAction<OffDayType>) {
      const list = [...state.list, action.payload];
      state.list = list.sort((a, b) => (a.day > b.day ? 1 : -1));
    },
  },
});

export const { setOffDaysList, addOffDayToList, removeOffDayFromList } =
  offDaysSlice.actions;
export default offDaysSlice.reducer;
