import "./index.scss";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Alert, Button, DatePicker, Input, Space } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import OffDaysTableComponent from "./table";
import { useActions } from "../../../hooks/useActions";
import dayjs, { Dayjs } from "dayjs";
import { I18n, Translate } from "react-redux-i18n";

const OffDaysComponent: FC = () => {
  const { addOffDay } = useActions();

  const [date, setDate] = useState<{
    value: Dayjs | null;
    dateString: string;
  } | null>();
  const [description, setDescription] = useState<string | undefined>();
  const [dateErr, setDateErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [placeholder, setPlaceholder] = useState(I18n.t("description"));

  const onDatePick = (value: Dayjs | null, dateString: string | string[]) => {
    if (dateErr) setDateErr(false);
    setDate({ value, dateString: String(dateString) });
  };

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (descriptionErr) setDescriptionErr(false);
    setDescription(e.target.value);
  };
  const submitHandler = () => {
    if (date && !!description?.trim()) {
      const day = { day: date.dateString, description };
      addOffDay(day);
      setDate(null);
      setDescription(undefined);
      setDescriptionErr(false);
      return setDateErr(false);
    }
    setDateErr(true);
    setDescriptionErr(true);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs(new Date()).add(-1, "days");
  };

  useEffect(() => {
    setTimeout(() => {
      setPlaceholder(I18n.t("description"));
    }, 100);
  }, []);

  return (
    <>
      <Alert type={"info"} style={{ marginBottom: 20 }} message={<Translate value={"exclusionDatesHelpTxt"} />} />
      <div className={"offDays-page-layout"}>
        <Space direction="horizontal" className={"filters"}>
          <DatePicker
            inputReadOnly
            value={date?.value}
            onChange={onDatePick}
            disabledDate={disabledDate}
            style={{ width: 280, border: !dateErr ? "" : "1px solid red" }}
          />
          <Input
            placeholder={placeholder}
            value={description}
            onChange={onInput}
            style={{
              width: 280,
              border: !descriptionErr ? "" : "1px solid red",
            }}
          />
          <Button type={"primary"} onClick={submitHandler}>
            <Translate value={"add"} />
          </Button>
        </Space>
        <OffDaysTableComponent />
      </div>
    </>
  );
};

export default OffDaysComponent;
