export enum TourItemsEnum {
  WELCOME = "welcome",
  CREATE_SURVEY = "createSurvey",
  CREATE_SURVEY_QUESTIONS = "createSurveyQuestions",
  CREATE_SURVEY_DESIGN = "createSurveyDesign",
  CREATE_SURVEY_SHARE = "createSurveyShare",
  CREATE_SURVEY_INTEGRATIONS = "createSurveyIntegrations",
  CREATE_SURVEY_RESULTS = "createSurveyResults",
  CONTACTS = "contacts",
  SETTINGS = "settings",
  MY_PROFILE = "myProfile",
}

export type TourType = {
  readonly completed: boolean;
  readonly tourId: number;
  readonly tourName: TourItemsEnum;
};

export enum WelcomeTourItemsEnum {
  MY_PROFILE = "MY_PROFILE",
  SURVEYS = "SURVEYS",
  SETTINGS = "SETTINGS",
  CONTACTS = "CONTACTS",
  CREATE_SURVEY = "CREATE_SURVEY",
}

export enum CreateSurveyTourItemsEnum {
  SURVEY_NAME = "SURVEY_NAME",
  // SURVEY_TYPE = "SURVEY_TYPE",
  TAGS = "TAGS",
  LANGUAGES = "LANGUAGES",
  NEXT_BUTTON = "NEXT_BUTTON",
}

export enum CreateSurveyQuestionsTourItemsEnum {
  QUESTIONS_TABLE = "QUESTIONS_TABLE",
  CREATE_QUESTION_BUTTON = "CREATE_QUESTION_BUTTON",

  QUESTION_NAME = "QUESTION_NAME",
  TEMPLATE = "TEMPLATE",
  LANGUAGE_SELECTION = "LANGUAGE_SELECTION",
  QUESTION_SETTINGS = "QUESTION_SETTINGS",
  QUESTION_PREVIEW = "QUESTION_PREVIEW",
  QUESTION_SHOW_LOGIC = "QUESTION_SHOW_LOGIC",
  TAGS = "TAGS",
  QUESTIONS_LIST = "QUESTIONS_LIST",
  BACK_BUTTON = "BACK_BUTTON",
  SURVEY_PREVIEW_BUTTON = "SURVEY_PREVIEW_BUTTON",
}
