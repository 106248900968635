import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConditionType } from "../../../types/subscriberConditionType";

type StateType = {
  list: Array<ConditionType>;
};
const initialState: StateType = {
  list: [],
};

const conditionsSlice = createSlice({
  name: "conditions",
  initialState,
  reducers: {
    setConditionsList(
      state: StateType,
      action: PayloadAction<Array<ConditionType>>,
    ) {
      state.list = action.payload;
    },
  },
});

export const { setConditionsList } = conditionsSlice.actions;
export default conditionsSlice.reducer;
