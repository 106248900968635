import "./index.scss";
import React, { FC, useEffect } from "react";
import { Button, Popconfirm, Table } from "antd";
import { useSelector } from "react-redux";
import { ColumnsType } from "antd/es/table";
import { renderNullableDate, renderNullableText } from "../../../utils/renderNullableText";
import store, { RootState } from "../../../store";
import { useActions } from "../../../hooks/useActions";
import { I18n } from "react-redux-i18n";
import { AttributeWithValueType, ContactType } from "../../../types/contactType";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { deleteContact, setCurrentContact } from "../../../store/slices/contacts/contactsService";
import { ContactsAttributeType } from "../../../types/ContactsAttributeType";

const columns = (attributes: Array<ContactsAttributeType>): ColumnsType<ContactType> => {
  return [
    {
      title: I18n.t("fullName"),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 200,
      render: renderNullableText,
    },
    {
      title: I18n.t("updateAt"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      fixed: "left",
      width: 200,
      render: renderNullableDate,
    },
    ...attributes.map((it) => ({
      dataIndex: it.propertyKey,
      title: it.description,
      key: it.propertyKey,
      width: 200,
      render: renderNullableText,
    })),
    {
      title: I18n.t("actions"),
      key: "actions",
      fixed: "right",
      width: 120,
      render: renderActions,
    },
  ];
};

const renderActions = (contact: ContactType) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Button type={"text"} onClick={() => store.dispatch(setCurrentContact(contact))}>
        <EditOutlined />
      </Button>
      <Popconfirm
        title={I18n.t("contactDeleteConfirmation")}
        onConfirm={() => {
          if (contact?.contactId) {
            store.dispatch(deleteContact(contact.contactId));
          }
        }}
      >
        <Button type={"text"}>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    </div>
  );
};

const ContactsTableComponent: FC = () => {
  const { list, attributesList } = useSelector((state: RootState) => ({
    list: state.contacts.list.map((it) => ({ ...it, ...toAttributesObj(it.properties) })),
    attributesList: state.contacts.attributesList,
  }));
  const { getContacts, getAttributes } = useActions();

  useEffect(() => {
    getContacts();
    getAttributes();
  }, []);

  return (
    <Table
      dataSource={list}
      scroll={{ x: 1000 }}
      pagination={{ size: "small" }}
      columns={columns(attributesList)}
      rowKey={(record) => record?.contactId + ""}
    />
  );
};

export default ContactsTableComponent;

const toAttributesObj = (attr: Array<AttributeWithValueType>): any => {
  return attr.reduce((obj, item) => ({ ...obj, [item.propertyKey]: item.value }), {});
};
