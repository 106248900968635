import React, { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const LoginLayout: FC<Props> = ({ children }) => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#FFFFFF",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            paddingRight: 60,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ width: 300, marginBottom: 50 }} src={"/assets/icons/fullLogo.svg"} alt="logo" />
          <div style={{ padding: "0 60px" }}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
