import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ResultType } from "../../../types/resultType";
import { Pageable } from "../../../types/pageable";

type StateType = {
  list: Array<ResultType>;
  current?: ResultType;
  pageable: Pageable;
};
const initialState: StateType = {
  list: [],
  current: undefined,
  pageable: { page: 0, size: 10 },
};

const resultsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<Array<ResultType>>) {
      state.list = action.payload;
    },
    setPageable(state: StateType, action: PayloadAction<Pageable>) {
      state.pageable = action.payload;
    },
    setCurrent(
      state: StateType,
      action: PayloadAction<ResultType | undefined>,
    ) {
      state.current = action.payload;
    },
  },
});

export const { setList, setPageable, setCurrent } = resultsSlice.actions;
export default resultsSlice.reducer;
