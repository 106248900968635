import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { ApiKeyType } from "../../../types/apiKeyType";
import { DELETE, GET, POST } from "../../../types/httpMethod";
import { message } from "antd";
import { addApiKeyToList, removeApiKeyFromList, setApiKeysList } from "./apiKeysSlice";
import { I18n } from "react-redux-i18n";

export const getApiKeys = createAsyncThunk<void, undefined>("apiKeys/getApiKeys", async (_, { dispatch }) => {
  try {
    const res: Array<ApiKeyType> = await requestApi(GET, "/apikeys", {});
    dispatch(setApiKeysList(res));
  } catch (e) {
    throw e;
  }
});

export const addApiKey = createAsyncThunk<void, string>("apiKeys/addApiKey", async (description, { dispatch }) => {
  try {
    const res: ApiKeyType = await requestApi(POST, "/apikeys", description, false, true, {
      "Content-Type": "text/plain",
    });
    dispatch(addApiKeyToList(res));
    message.success(I18n.t("apiKeyCreated"));
  } catch (e) {
    console.log(e);
  }
});

export const deleteApiKey = createAsyncThunk<void, number>("apiKeys/deleteApiKey", async (id, { dispatch }) => {
  try {
    await requestApi(DELETE, `/apikeys/${id}`, {});
    dispatch(removeApiKeyFromList(id));
    message.success(I18n.t("apiKeyDeleted"));
  } catch (e) {
    console.log(e);
  }
});
