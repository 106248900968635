export const KzTariffTranslations = {
  tariffs: {
    basic: {
      name: "Basic",
      description: "Жеке сауалнамалар, академиялық зерттеушілер үшін",
      amount: "₸ 10 000",
      amountInt: "10000",
      options: {
        0: "1 ӘКІМШІ ПАЙДАЛАНУШЫ",
        1: "1 000 ПІКІРГЕ ДЕЙІН",
        2: "3 САУАЛНАМА",
        3: "200 КОНТАКТІГЕ ДЕЙІН",
        4: "1 000 API ҚОҢЫРАУЫНА ДЕЙІН",
      },
      additional: {
        0: "100 шолу = айына ₸1 000",
      },
    },
    standard: {
      name: "Standard",
      description: "Орта бизнес үшін ең қолайлы жоспар",
      amount: "₸ 20 000",
      amountInt: "20000",
      options: {
        0: "1 ӘКІМШІ ПАЙДАЛАНУШЫ",
        1: "2 500 ПІКІРГЕ ДЕЙІН",
        2: "10 САУАЛНАМА",
        3: "1 000 КОНТАКТІГЕ ДЕЙІН",
        4: "2 500 API ҚОҢЫРАУЫНА ДЕЙІН",
      },
      additional: {
        0: "500 шолу = айына ₸5 000",
      },
    },
    business: {
      name: "Business",
      description: "Ірі компаниялар үшін функциялар мен теңшеудің толық жиынтығы",
      amount: "₸ 50 000 тг",
      amountInt: "50000",
      options: {
        0: "3 ӘКІМШІ ПАЙДАЛАНУШЫҒА ДЕЙІН",
        1: "10 000 ПІКІРГЕ ДЕЙІН",
        2: "100 САУАЛНАМА",
        3: "2 000 КОНТАКТІГЕ ДЕЙІН",
        4: "10 000 API ҚОҢЫРАУЫНА ДЕЙІН",
      },
      additional: {
        0: "1000 шолу = айына ₸10 000",
      },
    },
    continueTrialPeriod: "Сынақ мерзімін жалғастыру",
    trialPeriodDaysLeft: "Сынақ мерзімі аяқталғанға дейін {days} күн қалды",
    subscribe: "Жазылымды рәсімдеу",
    trialPeriodEndDescription: "Сынақ мерзімі аяқталды. Жұмысты жалғастыру үшін ақылы тарифтік жоспарды қосу керек",
    currentLimits: "Тариф бойынша қалдықтар",
    numberOfReviews: "Пікірлер саны",
    numberOfUsers: "Пайдаланушылар саны",
    numberOfEmailSendings: "E-mail жөнелтілімдерінің саны",
    numberOfContacts: "Байланыс саны",
    numberOfAPICalls: "API қоңырауларының саны",
    numberOfSurveys: "Сауалнамалар саны",
    limitWillBeUpdated: "Сіздің тарифтік жоспарыңыз бойынша лимит жаңартылады",
    userInformation: "Пайдаланушы туралы ақпарат",
    nextPayment: "Пайдаланушы туралы ақпарат Келесі төлем",
    changeTheTariff: "Тарифті өзгерту",
    cancelTheTariff: "Тарифтен бас тарту",
    chooseAnotherCard: "Басқа картаны таңдау",
    paymentHistory: "Төлем тарихы",
    paymentMethod: "Төлем тәсілі",
    trialPeriodValidFromTo: "Сынақ мерзімі {startDate} бастап {endDate} аралығында жарамды",
    cancelConfirmationText: "Ағымдағы жоспардан шынымен бас тартқыңыз келе ме?",
    tariffCanceledSuccessText: "Тарифтік жоспар жойылды. Есептік жазбаңызға {dateValue} дейін кіре аласыз.",
    dateOfPayment: "Төлем күні",
    payment: "Төлем",
    completed: "Аяқталды",
    invoiceNumber: "Шот фактура нөмірі",
    price: "Құны",
    operation: "Операциясы",
    state: "Мәртебесі",
  },
};
