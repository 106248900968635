import { FC, ReactNode } from "react";
import { Form, Select } from "antd";
import { FormItemType } from "../../../types/formItemType";
import { DEFAULT_VALIDATION_MESSAGE } from "../../../utils";
import { LabelValue } from "../../../types/labelValue";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { I18n } from "react-redux-i18n";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

const SelectFormItemComponent: FC<
  FormItemType & {
    readonly mode?: "multiple";
    readonly allowClear?: boolean;
    readonly values: Array<LabelValue & { disabled?: boolean }>;
    readonly onSelect?: (value: any) => void;
    readonly onChange?: (value: any) => void;
    readonly tagRender?: (values: CustomTagProps) => any;
    readonly optionsElement?: ReactNode;
    readonly maxTagCount?: number;
  }
> = ({
  name,
  label,
  required,
  placeHolder,
  disabled,
  initialValue,
  mode,
  allowClear,
  values,
  onSelect,
  onChange,
  noStyle,
  style,
  hasFeedback = true,
  tagRender,
  optionsElement,
  tooltip,
  wrapperStyles,
  maxTagCount,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      noStyle={noStyle}
      tooltip={tooltip}
      style={wrapperStyles}
      hasFeedback={hasFeedback}
      initialValue={initialValue}
      rules={[{ required: required, message: I18n.t(DEFAULT_VALIDATION_MESSAGE) }]}
    >
      <Select
        mode={mode}
        style={style}
        onSelect={onSelect}
        onChange={onChange}
        disabled={disabled}
        tagRender={tagRender}
        allowClear={allowClear}
        maxTagCount={maxTagCount}
        placeholder={capitalizeFirstLetter(placeHolder || `${I18n.t("select")} ${label?.toLowerCase()}`)}
      >
        {optionsElement ||
          values.map((lv) => (
            <Select.Option key={`${lv.value}`} value={`${lv.value}`} disabled={lv?.disabled}>
              {lv.label}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default SelectFormItemComponent;
