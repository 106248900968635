import { requestApi } from "../utils/requestApi";
import { POST } from "../types/httpMethod";
import { ShareOptionsEnum } from "../components/ResultsShare";

enum ShareTypesEnum {
  ANONYMOUS = "ANONYMOUS",
  PERSONALISED = "PERSONALISED",
}

enum AccessRestrictionsTypesEnum {
  NONE = "NONE",
  PASSWORD = "PASSWORD",
}

type ShareProps = {
  shareType: ShareTypesEnum;
  accessPassword?: string;
  accessRestriction: AccessRestrictionsTypesEnum;
  sharedBy: string;
  sharedToEmail: string;
  sharedToUser?: string;
  surveyId: string;
};

const toDto = (obj: ShareResultDataType, surveyId: string, currentUserId: string): ShareProps => {
  return {
    accessPassword: obj?.accessPassword,
    sharedToEmail: obj.sharedToEmail,
    sharedBy: currentUserId,
    surveyId: surveyId,
    shareType:
      obj.accessOption === ShareOptionsEnum.ONLY_FOR_REGISTERED_USERS
        ? ShareTypesEnum.PERSONALISED
        : ShareTypesEnum.ANONYMOUS,
    accessRestriction:
      obj.accessOption === ShareOptionsEnum.ALL_WHO_HAS_LINK_AND_PASSWORD
        ? AccessRestrictionsTypesEnum.PASSWORD
        : AccessRestrictionsTypesEnum.NONE,
  };
};

export type ShareResultDataType = { sharedToEmail: string; accessPassword?: string; accessOption: string };

export const useResultsShare = () => {
  const shareResult = async (data: ShareResultDataType, surveyId: string, currentUserId: string) => {
    const dto = toDto(data, surveyId, currentUserId);
    return requestApi(POST, "/results/share", dto);
  };

  return { shareResult };
};
