import { FC } from "react";
import { Form, Radio } from "antd";
import { FormItemType } from "../../../types/formItemType";
import { DEFAULT_VALIDATION_MESSAGE } from "../../../utils";
import { LabelValue } from "../../../types/labelValue";
import { I18n } from "react-redux-i18n";

const RadioFormItemComponent: FC<
  FormItemType & {
    readonly values: Array<LabelValue>;
    readonly buttonStyle?: "solid" | "outline";
    readonly onChange?: (value: string) => void;
    readonly buttonType?: "radio-button" | "radio";
    readonly direction?: "horizontal" | "vertical";
  }
> = (props) => {
  const {
    name,
    label,
    required = true,
    disabled,
    values,
    buttonStyle = "solid",
    onChange,
    initialValue,
    buttonType,
    direction = "horizontal",
    tooltip,
    style,
  } = props;
  const RadioButton = buttonType === "radio" ? Radio : Radio.Button;
  return (
    <Form.Item
      hasFeedback
      name={name}
      label={label}
      style={style}
      tooltip={tooltip}
      initialValue={initialValue}
      rules={[{ required: required, message: I18n.t(DEFAULT_VALIDATION_MESSAGE) }]}
    >
      <Radio.Group
        disabled={disabled}
        buttonStyle={buttonStyle}
        size={"large"}
        style={{ display: direction === "horizontal" ? "flex" : "grid" }}
        onChange={onChange ? (e) => onChange(e.target.value) : undefined}
      >
        {values.map((lv) => (
          <RadioButton
            key={lv.value}
            value={lv.value}
            style={{
              flex: 1,
              whiteSpace: "nowrap",
              textAlign: "center",
              lineHeight: 2.7,
              fontWeight: 300,
              fontSize: 14,
            }}
          >
            {lv.label}
          </RadioButton>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default RadioFormItemComponent;
