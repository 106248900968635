import { FC, ReactNode, useCallback } from "react";
import { Modal } from "antd";
import FooterActions from "../../../../../../components/FooterActions";
import { I18n } from "react-redux-i18n";
import { useActions } from "../../../../../../hooks/useActions";

type Props = {
  readonly open: boolean;
  readonly title?: string;
  readonly onCancel: () => void;
  readonly onSubmit: (() => void) | null;
  readonly children: ReactNode;
  readonly loading?: boolean;
  readonly submitBtnText?: string;
  readonly footer?: ReactNode;
};

const getDefaultProps = () => ({
  title: I18n.t("distributionChannelCreation"),
  submitBtnText: I18n.t("saveAndCopy"),
});

const BaseCreateModal: FC<Props> = (props) => {
  const { title, open, onCancel, children, loading, submitBtnText, footer, onSubmit } = {
    ...getDefaultProps(),
    ...props,
  };
  const { setCurrentDistributionChannel, setCurrentQrCode, setCurrentDistribution } = useActions();

  const handleCancel = useCallback(() => {
    onCancel();
    setCurrentDistributionChannel(undefined);
    setCurrentQrCode(undefined);
    setCurrentDistribution(undefined);
  }, [onCancel, setCurrentDistribution, setCurrentQrCode, setCurrentDistributionChannel]);

  return (
    <Modal
      open={open}
      title={title}
      onCancel={handleCancel}
      footer={
        onSubmit === null ? (
          <></>
        ) : (
          footer ?? (
            <FooterActions
              loading={loading}
              onCancel={onCancel}
              onSubmit={onSubmit}
              submitLabel={submitBtnText}
              cancelLabelTranslationKey={"cancel"}
            />
          )
        )
      }
    >
      {children}
    </Modal>
  );
};

export default BaseCreateModal;
