import { createAsyncThunk } from "@reduxjs/toolkit";
import { DELETE, POST, PUT } from "../../../types/httpMethod";
import { requestApi } from "../../../utils/requestApi";
import { message } from "antd";
import { QuestionType } from "../../../types/questionType";
import {
  addQuestionToList,
  deleteFromList,
  removeMediaProperties,
  removeMediaPropertiesInCurrentQuestion,
  resetConditions,
  setCurrent,
  setList,
  setQuestionCreateFormVisible as setQuestionCreateFormVisible$,
  updateCurrent,
  updateList,
  updateMediaProperties,
  updateMediaPropertiesInCurrentQuestion,
} from "./questionsSlice";
import { sortByOrder } from "../../../utils/sortByOrder";
import { I18n } from "react-redux-i18n";
import store from "../../index";
import { QUESTIONS_DATA } from "../../../utils/surveyTemplate";
import { QuestionMediaFile } from "../../../types/AddMediaToQuestionResponseType";

export const setQuestions = createAsyncThunk<void, Array<QuestionType>>(
  "questions/setQuestions",
  (questions, { dispatch }) => {
    dispatch(setList(questions.map((q) => q).sort(sortByOrder)));
  },
);

export const resetQuestions = createAsyncThunk<void, undefined>("questions/resetQuestions", (_, { dispatch }) => {
  dispatch(setQuestions([]));
  dispatch(setCurrentQuestion(undefined));
});

export const resetAnswerDisplayConditions = createAsyncThunk<void, undefined>(
  "questions/resetAnswerDisplayConditions",
  (_, { dispatch }) => {
    dispatch(resetConditions());
  },
);

export const addQuestion = createAsyncThunk<void, QuestionType>("questions/addQuestion", (question, { dispatch }) => {
  dispatch(addQuestionToList(question));
});

export const setCurrentQuestion = createAsyncThunk<void, QuestionType | undefined>(
  "questions/setCurrentQuestion",
  (question, { dispatch }) => {
    dispatch(setCurrent(question));
  },
);

export const deleteQuestion = createAsyncThunk<
  void,
  {
    readonly surveyId: string;
    readonly questionId: string;
  }
>("questions/deleteQuestion", async ({ surveyId, questionId }, { dispatch }) => {
  try {
    await requestApi(DELETE, `/surveys/questions/${questionId}`, {});
    dispatch(deleteFromList(questionId));
    dispatch(reorderQuestions({ surveyId }));
    message.success(I18n.t("questionDeleted"));
  } catch (e) {
    console.log(e);
  }
});

export const createQuestion = createAsyncThunk<void, { surveyId: string; question: QuestionType }>(
  "questions/createQuestion",
  async ({ surveyId, question }, { dispatch }) => {
    try {
      const res: QuestionType = await requestApi(POST, `/surveys/${surveyId}/questions`, question);
      dispatch(addQuestion(res));
      message.success(I18n.t("questionCreated"));
    } catch (e) {
      console.log(e);
    }
  },
);

export const createQuestionsFromTemplate = createAsyncThunk<void, string>(
  "questions/createQuestion",
  async (surveyId, { dispatch }) => {
    try {
      QUESTIONS_DATA.map(async (question) => {
        const q = await requestApi(POST, `/surveys/${surveyId}/questions`, question);
        dispatch(addQuestion(q));
      });
    } catch (e) {
      throw e;
    }
  },
);

export const updateQuestion = createAsyncThunk<
  void,
  {
    readonly surveyId: string;
    readonly question: QuestionType;
    readonly fromEditable?: boolean;
    readonly showLoad?: boolean;
  }
>("questions/updateQuestion", async ({ surveyId, question, fromEditable = false, showLoad = true }, { dispatch }) => {
  try {
    const res: QuestionType = await requestApi(
      PUT,
      `/surveys/${surveyId}/questions/${question?.questionId}`,
      question,
      false,
      showLoad,
    );
    if (showLoad) {
      dispatch(updateList(res));
      message.success(I18n.t("changesSaved"));
    }
  } catch (e) {
    console.log(e);
  } finally {
    if (fromEditable) {
      dispatch(setCurrentQuestion(undefined));
    }
  }
});

export const reorderQuestions = createAsyncThunk<
  void,
  {
    readonly surveyId: string;
    readonly questions?: Array<QuestionType>;
  }
>("questions/reorderQuestions", async ({ surveyId, questions }, { dispatch }) => {
  const data = questions ?? store.getState().questions.list;
  const questionOrders: any = {};

  data.map((it, i) => {
    if (it?.questionId) {
      questionOrders[it?.questionId] = ++i;
    }
    return it;
  });

  const res: Array<QuestionType> = await requestApi(
    PUT,
    `/surveys/${surveyId}/questions/reorder`,
    questionOrders,
    false,
    false,
  );
  const currentQuestion = store.getState().questions.current;
  const updatedCurrentQuestion = res.find((q) => q.questionId === currentQuestion?.questionId);

  if (updatedCurrentQuestion) {
    dispatch(setCurrentQuestion(updatedCurrentQuestion));
  }

  dispatch(setQuestions(res));
});

export const updateCurrentQuestion = createAsyncThunk<void, Partial<QuestionType>>(
  "questions/updateCurrentQuestion",
  (payload, { dispatch }) => {
    dispatch(updateCurrent(payload));
  },
);

export const updateQuestionsMediaProperties = createAsyncThunk<
  void,
  QuestionMediaFile & Pick<QuestionType, "questionId">
>("questions/updateQuestionsMediaProperties", (payload, { dispatch }) => {
  dispatch(updateMediaProperties(payload));
});

export const removeQuestionsMediaProperties = createAsyncThunk<void, string>(
  "questions/removeQuestionsMediaProperties",
  (questionId, { dispatch }) => {
    dispatch(removeMediaProperties(questionId));
  },
);

export const updateCurrentQuestionMediaProperties = createAsyncThunk<void, string>(
  "questions/updateCurrentQuestionMediaProperties",
  (newMediaUrl, { dispatch }) => {
    dispatch(updateMediaPropertiesInCurrentQuestion(newMediaUrl));
  },
);

export const removeCurrentQuestionMediaProperties = createAsyncThunk<void>(
  "questions/removeCurrentQuestionMediaProperties",
  (_, { dispatch }) => {
    dispatch(removeMediaPropertiesInCurrentQuestion());
  },
);

export const setQuestionCreateFormVisible = createAsyncThunk<void, boolean>(
  "questions/setQuestionCreateFormVisible",
  (value, { dispatch }) => {
    dispatch(setQuestionCreateFormVisible$(value));
  },
);
