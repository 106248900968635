import { RootState } from "../store";
import { SurveyStatus } from "../types/surveyType";
import { StepsEnum } from "../types/surveyEditTabsEnum";
import { useSelector } from "react-redux";

export const useSurveyDisabled = () => {
  const { survey, currentStep } = useSelector((state: RootState) => ({
    survey: state.surveys.current,
    currentStep: state.persisted.surveysEditPageActiveStep,
  }));

  return !!survey?.surveyId && survey?.status !== SurveyStatus.ACTIVE && currentStep !== StepsEnum.RESULTS;
};

export default useSurveyDisabled;
