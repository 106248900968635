import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { login, logout } from "./sessionSlice";
import { POST } from "../../../types/httpMethod";
import { resetSurveysData } from "../surveys/surveysService";
import { getCurrentOriginUrlWithAppPrefix, getTokens } from "../../../utils/getKeycloakAuthUrl";

export const onRefreshToken = createAsyncThunk<boolean, undefined>(
  "session/onRefreshToken",
  async (_, { dispatch }) => {
    try {
      const data = getTokens();
      const res = await requestApi(POST, "/auth/refresh", data);
      dispatch(login(res));
      return true;
    } catch (e) {
      return false;
    }
  },
);

export const onKeycloakLogin = createAsyncThunk<boolean, string>(
  "session/onKeycloakLogin",
  async (code, { dispatch }) => {
    try {
      const res = await requestApi(POST, "/auth/login", {
        code,
        redirectUri: getCurrentOriginUrlWithAppPrefix(),
      });
      dispatch(login(res));
      return true;
    } catch (e) {
      return false;
    }
  },
);

export const onLogout = createAsyncThunk<boolean, boolean>(
  "session/onLogout",
  async (withRequest = true, { dispatch }) => {
    try {
      if (withRequest) {
        const data = getTokens(true);
        await requestApi(POST, "/auth/logout", data);
      }
      dispatch(resetAuthCtx());
      return true;
    } catch (e) {
      return false;
    }
  },
);

export const resetAuthCtx = createAsyncThunk<void, undefined>("session/resetAuthCtx", (_, { dispatch }) => {
  dispatch(logout());
  dispatch(resetSurveysData());
});
