import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { DELETE, GET, POST } from "../../../types/httpMethod";
import { SurveyLanguage } from "../../../types/language";
import { addLanguage, removeLanguage, setLanguages, setSurveyLanguages } from "./languagesSlice";
import { message } from "antd";
import { I18n } from "react-redux-i18n";

export const getLanguages = createAsyncThunk<void, undefined>("languages/getLanguages", async (_, { dispatch }) => {
  try {
    const res = await requestApi(GET, "/languages", {});
    dispatch(setAllLanguages(res));
  } catch (e) {
    throw e;
  }
});

export const getLanguagesBySurveyId = createAsyncThunk<void, string>(
  "languages/getLanguagesBySurveyId",
  async (surveyId, { dispatch }) => {
    try {
      const res = await requestApi(GET, `/languages/${surveyId}`, {});
      dispatch(setCurrentSurveyLanguages(res));
    } catch (e) {
      throw e;
    }
  },
);

export const createLanguage = createAsyncThunk<void, SurveyLanguage>(
  "languages/createLanguage",
  async (language, { dispatch }) => {
    try {
      const res = await requestApi(POST, "/languages", language);
      dispatch(addLanguageToList(res));
      message.success(I18n.t("languageCreated"));
    } catch (e) {
      console.log(e);
    }
  },
);

export const deleteLanguage = createAsyncThunk<void, string>("languages/deleteLanguage", async (code, { dispatch }) => {
  try {
    await requestApi(DELETE, `/languages/${code}`, {});
    dispatch(removeLanguageFromList(code));
    message.success(I18n.t("languageDeleted"));
  } catch (e) {
    console.log(e);
  }
});

export const setAllLanguages = createAsyncThunk<void, Array<SurveyLanguage>>(
  "languages/setAllLanguages",
  (languages, { dispatch }) => {
    dispatch(setLanguages(languages));
  },
);

export const setCurrentSurveyLanguages = createAsyncThunk<void, Array<SurveyLanguage>>(
  "languages/setCurrentSurveyLanguages",
  (languages, { dispatch }) => {
    dispatch(setSurveyLanguages(languages));
  },
);

export const addLanguageToList = createAsyncThunk<void, SurveyLanguage>(
  "languages/addLanguageToList",
  (language, { dispatch }) => {
    dispatch(addLanguage(language));
  },
);

export const removeLanguageFromList = createAsyncThunk<void, string>(
  "languages/removeLanguageFromList",
  (code, { dispatch }) => {
    dispatch(removeLanguage(code));
  },
);
