import "./index.scss";
import { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Translate } from "react-redux-i18n";

const STYLE = (height: number) => ({
  width: "100%",
  height,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

type Props = {
  readonly height: number;
  readonly text?: string | null;
};

const Loading: FC<Props> = ({ height, text }) => {
  return (
    <div style={STYLE(height)}>
      <div style={{ display: "grid", justifyItems: "center" }}>
        <LoadingOutlined className={"data-loading-indicator"} />
        {text !== null && <h3 style={{ fontSize: 17, marginTop: 10 }}>{text ?? <Translate value={"loading"} />}</h3>}
      </div>
    </div>
  );
};

export default Loading;
