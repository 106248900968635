import { FC, ReactNode } from "react";
import { Form, InputNumber } from "antd";
import { FormItemType } from "../../../types/formItemType";
import { DEFAULT_VALIDATION_MESSAGE } from "../../../utils";
import { I18n } from "react-redux-i18n";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";

type Props = {
  readonly max?: number;
  readonly min?: number;
  readonly showArrows?: boolean;
  readonly addonAfter?: ReactNode;
} & FormItemType;

const InputNumberFormItemComponent: FC<Props> = ({
  name,
  label,
  required = true,
  placeHolder,
  disabled,
  prefix,
  noStyle,
  style,
  min,
  max,
  showArrows,
  tooltip,
  wrapperStyles,
  hasFeedback,
  addonAfter,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      tooltip={tooltip}
      noStyle={noStyle}
      style={wrapperStyles}
      hasFeedback={hasFeedback}
      rules={[{ required: required, message: I18n.t(DEFAULT_VALIDATION_MESSAGE) }]}
    >
      <InputNumber
        min={min}
        max={max}
        prefix={prefix}
        disabled={disabled}
        controls={showArrows}
        addonAfter={addonAfter}
        style={{ width: "100%", ...style }}
        placeholder={capitalizeFirstLetter(placeHolder || `${I18n.t("enter")} ${label?.toLowerCase()}`)}
      />
    </Form.Item>
  );
};

export default InputNumberFormItemComponent;
