export const RuTariffTranslations = {
  tariffs: {
    basic: {
      name: "Basic",
      description: "Для индивидуальных опросников, академических исследователей",
      amount: "₸ 10 000",
      amountInt: "10000",
      options: {
        0: "1 АДМИН ПОЛЬЗОВАТЕЛЬ",
        1: "1 000 ОТЗЫВОВ",
        2: "3 ОПРОСА",
        3: "200 КОНТАКТОВ",
        4: "1 000 API ЗАПРОСОВ",
      },
      additional: {
        0: "100 отзывов = ₸1 000 в месяц",
      },
    },
    standard: {
      name: "Standard",
      description: "Наиболее удобный план для бизнеса среднего размера",
      amount: "₸ 20 000",
      amountInt: "20000",
      options: {
        0: "1 АДМИН ПОЛЬЗОВАТЕЛЬ",
        1: "2 500 ОТЗЫВОВ",
        2: "10 ОПРОСОВ",
        3: "1 000 КОНТАКТОВ",
        4: "2 500 API ЗАПРОСОВ",
      },
      additional: {
        0: "500 отзывов = ₸5 000 в месяц",
      },
    },
    business: {
      name: "Business",
      description: "Для крупных компаний, полный набор функций и кастомизации",
      amount: "₸ 50 000",
      amountInt: "50000",
      options: {
        0: "3 АДМИН ПОЛЬЗОВАТЕЛЯ",
        1: "10 000 ОТЗЫВОВ",
        2: "100 ОПРОСОВ",
        3: "2 000 КОНТАКТОВ",
        4: "10 000 API ЗАПРОСОВ",
      },
      additional: {
        0: "1 000 отзывов = ₸10 000 в месяц",
      },
    },
    continueTrialPeriod: "Продолжить пробный период",
    trialPeriodDaysLeft: "До завершения пробного периода осталось {days} дней",
    subscribe: "Оформить подписку",
    trialPeriodEndDescription:
      "Пробный период завершен. Чтобы продолжить работу необходимо подключить платный тарифный план",
    currentLimits: "Остатки по тарифу",
    numberOfReviews: "Количество отзывов",
    numberOfUsers: "Количество пользователей",
    numberOfEmailSendings: "Количество e-mail отправок",
    numberOfContacts: "Количество контактов",
    numberOfAPICalls: "Количество вызовов API",
    numberOfSurveys: "Количество опросов",
    limitWillBeUpdated: "Лимиты по вашему тарифному плану обновятся",
    userInformation: "Информация о пользователе",
    nextPayment: "Следующая оплата",
    changeTheTariff: "Изменить тариф",
    cancelTheTariff: "Отменить тариф",
    chooseAnotherCard: "Выбрать другую карту",
    paymentHistory: "История платежей",
    paymentMethod: "Способ оплаты",
    trialPeriodValidFromTo: "Действует триальный период с {startDate} по {endDate}",
    cancelConfirmationText: "Вы уверены, что хотите отменить текущий тарифный план?",
    tariffCanceledSuccessText: "Тар ифный план отменён. У Вас останется доступ к кабинету до {dateValue}",
    dateOfPayment: "Дата оплаты",
    payment: "Платеж",
    completed: "Завершенный",
    invoiceNumber: "Номер счета фактуры",
    price: "Стоимость",
    operation: "Операция",
    state: "Состояние",
  },
};
