import { TourItemsEnum } from "../../../types/tour";

export const KzTourTranslations = {
  tours: {
    startTour: "Жаттығуды бастаңыз",
    rejectTour: "Жаттығудан бас тарту",
    important: "Маңызды!",
    link: "Сілтеме",
    [TourItemsEnum.WELCOME]: {
      title: "Сәлем!",
      description: "Survey Suite - ке қош келдіңіз! Біздің қызметпен танысуды бастайық.",
      myProfile: "Менің профилім ",
      myProfileDescription:
        "Мұнда сіз өзіңіздің жеке ақпаратыңызды таба аласыз, парольді өзгерте аласыз, тарифті реттей аласыз және ыңғайлы уақыт белдеуін таңдай аласыз.",
      surveys: "Сауалнамалар",
      surveysDescription: "Сіздің барлық сауалнамаларыңыз оңай басқару үшін осы бетте қол жетімді.",
      settings: "Параметрлер",
      settingsDescription:
        "Аккаунтты орнатыңыз: триггер сауалнамалары үшін жалпыға ортақ API кілтін қосыңыз, тарату үшін ерекше күндерді орнатыңыз, Email және SMS тарату провайдерлеріне қосылуды орнатыңыз, жаңа пайдаланушыларды қосыңыз.",
      contacts: "Байланыс",
      contactsDescription:
        "Мұнда сіз респонденттердің контактілерін жүктей аласыз (электрондық пошта мекенжайлары, телефон нөмірлері, олармен байланыс тілдері және басқалары).",
      createSurvey: "Сауалнама құрыңыз",
      createSurveyDescription: "Бірінші сауалнамаңызды құруды бастаңыз!",
    },
    [TourItemsEnum.CREATE_SURVEY]: {
      title: "Сауалнама құру",
      description: "Алғашқы сауалнамаңызды құруды бастайық!",
      submitBtn: "Жалғастыру",
      cancelBtn: "Жаттығудан бас тарту",
      surveyNameTitle: "Сауалнама атауы",
      surveyNameDescription:
        "Сауалнаманың атауын енгізіңіз, ол респонденттерге көрінбейді. Бұл атау сауалнамаларды бір-бірінен ажырату үшін Сізге ыңғайлы болу үшін жасалған.",
      surveyTypeTitle: "Сауалнама түрі",
      surveyTypeDescription:
        "Web сауалнама - сауалнама респондент қызметтің арнайы веб-бетінде өтуі мүмкін. Мұндай сауалнаманың сауалнамасын QR арқылы бөлісуге, әлеуметтік желілер арқылы web-сілтемені бөлісуге, email-ге жіберуге болады.",
      surveyTypeSubDescription:
        "SMS сауалнама-SMS арнасы арқылы сауалнама, мұнда әрбір сұрақ пен жауап жеке SMS хабарламалармен беріледі (SMS-провайдерге қосылу қажет).",
      tagsTitle: "Тегтер",
      tagsDescription: `Тегтер сауалнамаларды жалпы негізде топтастыруға көмектеседі, мысалы, "тиімділікті бағалау" немесе "бөлімдегі сауалнама".`,
      languagesTitle: "Тілдер",
      languagesDescription:
        "Қол жетімді тілдер тізімінен респонденттердің қай тілде өтуін қалайтыныңызды таңдаңыз. Сіз бірнеше тілді таңдай аласыз және сауалнамадан өткен кезде респондент өзіне ыңғайлы тілді таңдай алады.",
      languagesSubDescription: "Сауалнама жасалғаннан кейін тілдер тізімін өзгерту мүмкін емес.",
      nextButtonTitle: "Келесі",
      nextButtonDescription: 'Барлық деректерді толтырғаннан кейін сауалнама жасау үшін "Келесі" түймесін басыңыз.',
    },
    [TourItemsEnum.CREATE_SURVEY_QUESTIONS]: {
      title: "Сұрақтар тізімі",
      description: "Мұнда сауалнама сұрақтарының тізімі көрсетіледі. Тізім бос, сауалнаманы толтыруды бастайық.",
      submitBtn: "Жалғастыру",
      cancelBtn: "Жаттығудан бас тарту",
      questionsTableTitle: "Сұрақтар тізімі",
      questionsTableDescription:
        "Мұнда сауалнама сұрақтарының тізімі көрсетіледі. Тізім бос, сауалнаманы толтыруды бастайық.",
      createQuestionButtonTitle: "Сұрақ жасау",
      createQuestionButtonDescription: `Бастау үшін "сұрақ Жасау" түймесін басыңыз.`,
      questionNameTitle: "Сұрақтың атауы",
      questionNameDescription:
        "Сұрақтың атауын енгізіңіз-бұл сұрақтарды бір-бірінен ажыратуға көмектесетін және болашақта есептерде көрсетілетін ішкі атау. Мысалы, үй-жайларды тазалау сапасы туралы мәселе.",
      templateTitle: "Үлгі",
      templateDescription1: "Жауап үлгілері сұраққа жауаптарды сізге ыңғайлы форматта жинауға мүмкіндік береді.",
      templateDescription2:
        "Бізде көптеген әртүрлі жауап үлгілері бар: тізімнен бір элементті таңдау, мәтінді енгізу, email енгізу немесе мобильді нөмір.",
      templateDescription3: "Сізге қажет кез келген үлгіні таңдаңыз. Үлгілер туралы толығырақ сілтеме бойынша.",
      languageSelectingTitle: "Тілді таңдау",
      languageSelectingDescription1:
        "Осы қойындылар арқылы сіз тиісті тілдерде сұрақ-жауап мәтінін қоя алатын өрістер арасында ауыса аласыз.",
      languageSelectingDescription2: "Сауалнама жасау кезінде таңдалған тілдері бар қойындылар көрсетіледі.",
      languageSelectingDescription3:
        "Маңызды! Сауалнаманы дұрыс құру үшін барлық қойындылардағы барлық өрістер толтырылуы керек.",
      questionSettingsTitle: "Сұрақ параметрлері",
      questionSettingsDescription:
        "Таңдалған үлгі негізінде сұрақтың мәтінін немесе хабарландыруды енгізіңіз. Әр сұрақ үлгісінің бірегей параметрлерін басқарыңыз. Барлық тілдердегі аудармаларды қосуды ұмытпаңыз.",
      questionPreviewTitle: "Мәселені алдын ала қарау",
      questionPreviewDescription:
        "Қажетті үлгіні таңдауды ыңғайлы ету үшін бұл терезеде респонденттер оны қалай көретіні туралы сұрағыңыз көрсетіледі.",
      questionShowLogicTitle: "Сұрақты көрсету логикасы",
      questionShowLogicDescription1: `Сұрақтардың тармақталу логикасын реттеуге болады, мысалы, егер "қолданбаны бағалау" сұрағында пайдаланушы <2 опциясын таңдаса, "сізге не ұнамады?”.`,
      questionShowLogicDescription2:
        "Сондай-ақ, сіз осы сұрақты кімге көрсету керектігі туралы қосымша сүзгілерді қоса аласыз (байланыс кітабындағы мәліметтер негізінде).",
      tagsTitle: "Тегтер",
      tagsDescription: "Сұрақ белгілері болашақта есеп беру үшін сұрақтарды топтастыруға көмектеседі.",
      questionsListTitle: "Сұрақтар тізімі",
      questionsListDescription: `Мұнда сіз жасаған барлық сұрақтарды көресіз және өңдеулер жасау үшін олардың арасында ауыса аласыз. Жаңа сұрақ жасау үшін "Сұрақ қосу" түймесін басыңыз.`,
      backButtonTitle: "Сұрақтар кестесіне оралу",
      backButtonDescription:
        "Барлық сұрақтарды жасағаннан кейін (немесе үзілісте) сұрақтардың ретін өзгерту, сұрақтың көшірмесін жасау немесе оны толығымен жою үшін сұрақтар кестесі бар бетке оралуға болады.",
      surveyPreviewButtonTitle: "Сауалнаманы алдын ала қарау",
      surveyPreviewButtonDescription:
        'Егер бәрі дайын болса, сіз "сауалнаманы алдын-ала қарау" батырмасын басу арқылы тест режимінде сауалнама жүргізе аласыз. Сауалнама жіберілмес бұрын респонденттерге қалай көрінетінін қараңыз.',
    },
    nextButtonTitle: "Одан әрі",
    prevButtonTitle: "Артқа",
  },
};
