import React, { CSSProperties, useEffect, useState } from "react";
import { MenuInfo } from "rc-menu/lib/interface";
import { ACCOUNT_SETTINGS_PAGE, CONTACTS_PAGE, SETTINGS_PAGE, SURVEYS_PAGE } from "../../utils/paths";
import { I18n, Translate } from "react-redux-i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { doLogout } from "../../utils/doLogout";
import store from "../../store";
import { Language } from "../../types/language";
import { WelcomeTourItemsEnum } from "../../types/tour";

enum MenuKeys {
  PASSWORD_CHANGE,
  ACCOUNT_SETTINGS,
  LOGOUT,
}

export const useHeadersPageProps = () => {
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const navigate = useNavigate();

  const onMenu = (e: MenuInfo) => {
    switch (Number(e.key)) {
      case MenuKeys.LOGOUT: {
        doLogout();
        break;
      }
      case MenuKeys.ACCOUNT_SETTINGS: {
        navigate(ACCOUNT_SETTINGS_PAGE);
        break;
      }
      case MenuKeys.PASSWORD_CHANGE: {
        setShowPasswordChange(true);
      }
    }
  };

  const menu = {
    onClick: onMenu,
    className: "header-actions-menu-btn",
    items: [
      { key: MenuKeys.PASSWORD_CHANGE, label: I18n.t("changePassword") },
      { key: MenuKeys.ACCOUNT_SETTINGS, label: I18n.t("accountSettings") },
      { key: MenuKeys.LOGOUT, label: I18n.t("logout") },
    ],
  };

  return { menu, showPasswordChange, setShowPasswordChange };
};

export type LinkListType = {
  readonly tourKey: WelcomeTourItemsEnum;
  readonly label: string;
  readonly path: string;
};

export const headerLinksList: Array<LinkListType> = [
  { tourKey: WelcomeTourItemsEnum.SURVEYS, label: "surveys", path: SURVEYS_PAGE },
  { tourKey: WelcomeTourItemsEnum.SETTINGS, label: "settings", path: SETTINGS_PAGE },
  { tourKey: WelcomeTourItemsEnum.CONTACTS, label: "contacts", path: CONTACTS_PAGE },
];

const getSelectedKey = (path: string): string => {
  if (path.includes(SURVEYS_PAGE)) {
    return SURVEYS_PAGE;
  } else if (path.includes(SETTINGS_PAGE)) {
    return SETTINGS_PAGE;
  } else if (path.includes(CONTACTS_PAGE)) {
    return CONTACTS_PAGE;
  }

  return SURVEYS_PAGE;
};

type HeaderLinkItemProps = { item: LinkListType; disabled: boolean };

export const HeaderLinkItem = ({ item, disabled }: HeaderLinkItemProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(location?.pathname || SURVEYS_PAGE);
  const handleRoute = (path: string) => navigate(path);

  useEffect(() => {
    setSelectedKey(getSelectedKey(location?.pathname));
  }, [location.pathname]);

  return (
    <a
      href={"/"}
      key={item.path}
      className={`header-link ${selectedKey === item.path ? "active" : ""}`}
      onClick={(e) => {
        e.preventDefault();
        if (disabled) return;
        handleRoute(item.path);
      }}
    >
      <Translate value={item.label} />
    </a>
  );
};

export const getPasswordTooltip = () => {
  const lang = store.getState().persisted.appLanguage;
  const style: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    wordWrap: "normal",
  };

  if (lang === Language.EN) {
    return (
      <div style={style}>
        <h3>Password Requirements:</h3>
        <span>- At least one digit.</span>
        <span>- At least one lowercase letter.</span>
        <span>- At least one uppercase letter.</span>
        <span>- At least one special character from the set [_!@#$()%^&+=-].</span>
        <span>- Must not contain single or double quotes.</span>
        <span>- Length between 8 and 32 characters.</span>
      </div>
    );
  } else if (lang === Language.KK) {
    return (
      <div style={style}>
        <h3>Парольдің талаптары:</h3>
        <span>- Кем дегенде бір санды болуы керек.</span>
        <span>- Кем дегенде бір кіші әріпті болуы керек.</span>
        <span>- Кем дегенде бір үлкен әріпті болуы керек.</span>
        <span>- [_!@#$()%^&+=-] жиынтығынан кем дегенде бір арнайы символ болуы керек.</span>
        <span>- Орташа не дубль көйлектерді қамсыздамауы керек.</span>
        <span>- 8 мен 32 символ аралығында болуы керек.</span>
      </div>
    );
  }
  return (
    <div style={style}>
      <h3>Требования к паролю:</h3>
      <span>- Хотя бы одна цифра.</span>
      <span>- Хотя бы одна строчная буква.</span>
      <span>- Хотя бы одна прописная буква.</span>
      <span>- Хотя бы один спецсимвол из набора [_!@#$()%^&+=-].</span>
      <span>- Не должен содержать одинарные или двойные кавычки.</span>
      <span>- Длина от 8 до 32 символов.</span>
    </div>
  );
};
