import React, { FC } from "react";
import { Form, Radio } from "antd";
import { CsiScale, csiScaleTitles } from "../../../../../../types/answerTemplateType";
import { DEFAULT_VALIDATION_MESSAGE } from "../../../../../../utils";
import { GrowingList } from "../helpers";
import { I18n } from "react-redux-i18n";
import Divider from "../../../../../../components/divider";
import { SurveyLanguage } from "../../../../../../types/language";
import useSurveyDisabled from "../../../../../../hooks/useSurveyDisabled";
import CustomTemplate from "./custom";

type CsiTemplateProps = {
  readonly langIndex: number;
  readonly lang: SurveyLanguage;
};

const CsiTemplate: FC<CsiTemplateProps> = (props) => {
  const disabled = useSurveyDisabled();

  return (
    <div>
      <div>
        <Form.Item
          name={["answerTemplate", "csiScale"]}
          label={I18n.t("ratingScale")}
          rules={[{ required: true, message: I18n.t(DEFAULT_VALIDATION_MESSAGE) }]}
        >
          <Radio.Group buttonStyle={"solid"}>
            {Object.keys(CsiScale).map((key) => (
              <Radio.Button key={key} value={key}>
                {csiScaleTitles()[key as CsiScale]}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
      <div className={"no-error-label"}>
        <Divider translationCode={"params"} />
        <GrowingList {...props} disabled={disabled} dataKey={"csiParams"} addButtonLabel={I18n.t("addParam")} />
        <Divider translationCode={"criteria"} />
        <GrowingList {...props} disabled={disabled} dataKey={"criteria"} addButtonLabel={I18n.t("addCriteria")} />
        <CustomTemplate />
      </div>
    </div>
  );
};

export default CsiTemplate;
