import { FC } from "react";
import { Form, TimePicker } from "antd";
import { FormItemType } from "../../../types/formItemType";
import { DEFAULT_TIME_FORMAT } from "../../../utils";

const TimePickerComponent: FC<FormItemType> = (props) => {
  const { name, label, hasFeedback, noStyle, style, required, tooltip } = props;
  return (
    <Form.Item
      name={name}
      label={label}
      noStyle={noStyle}
      tooltip={tooltip}
      hasFeedback={hasFeedback}
      rules={[{ required, message: "" }]}
    >
      <TimePicker
        style={style}
        minuteStep={5}
        showNow={false}
        showSecond={false}
        allowClear={false}
        inputReadOnly={true}
        format={DEFAULT_TIME_FORMAT}
      />
    </Form.Item>
  );
};

export default TimePickerComponent;
