import "./index.scss";
import { FC, useEffect } from "react";
import { Table, TablePaginationConfig } from "antd";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { SurveyType } from "../../../types/surveyType";
import { SorterResult } from "antd/es/table/interface";
import { getSortStr } from "../../../utils/getSortStr";
import { columns } from "./columns";
import { useActions } from "../../../hooks/useActions";
import { scrollTo } from "../utils";
import { StatusEnum } from "../../../types/statusType";

const SurveysTableComponent: FC = () => {
  const data = useSelector((state: RootState) => state.surveys.list);
  const pageable = useSelector(
    (state: RootState) => state.persisted.surveysPagePageable,
  );
  const { getSurveys, setSurveysPageable, updateSurveyFilters } = useActions();

  const onChange = (
    page: TablePaginationConfig,
    sorter: SorterResult<SurveyType> | Array<SorterResult<SurveyType>>,
  ) => {
    if (!page.current || !page.pageSize) return;
    setSurveysPageable({
      ...pageable,
      page: page.current - 1,
      size: page.pageSize,
      sort: getSortStr(sorter),
    });
    getSurveys();
    scrollTo("top");
  };

  const onStatusFilter = (status?: StatusEnum) => {
    setSurveysPageable({ ...pageable, page: 0 });
    updateSurveyFilters({ status });
    getSurveys();
  };

  useEffect(() => {
    getSurveys();
  }, []);

  return (
    <Table
      pagination={{
        size: "small",
        showSizeChanger: true,
        total: pageable?.total,
        pageSize: pageable.size,
        current: pageable.page + 1,
        defaultPageSize: pageable.size,
      }}
      dataSource={data}
      showSorterTooltip={false}
      rowKey={(record: SurveyType) => record?.surveyId + ""}
      columns={columns(onStatusFilter, pageable?.sort)}
      onChange={(page, _, sorter) => onChange(page, sorter)}
    />
  );
};

export default SurveysTableComponent;
