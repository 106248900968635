import "./index.scss";
import React, { FC } from "react";
import ContactsTableComponent from "./table";
import Filters from "./filters";

const ContactsPage: FC = () => {
  return (
    <div className={"contacts-page-layout"}>
      <Filters />
      <ContactsTableComponent />
    </div>
  );
};

export default ContactsPage;
