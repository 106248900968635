import { FC } from "react";
import { useParams } from "react-router-dom";
import { I18n, Translate } from "react-redux-i18n";
import FlexSpace from "../../components/FlexSpace";
import { BreadcrumbWithPageTitle } from "../../components/breadcrumbButton";

const PaymentPage: FC = () => {
  const { tariffId, methodName } = useParams();
  return (
    <div style={{ minHeight: "70vh" }}>
      <BreadcrumbWithPageTitle type={"text"} pageTitle={I18n.t("paymentPage")} />
      <FlexSpace mt={50}>
        <Translate value={"tariffPlan"} />
        <span>{tariffId ?? I18n.t("notFound")}</span>
      </FlexSpace>
      <FlexSpace>
        <Translate value={"method"} />
        <span>{methodName ?? I18n.t("notFound")}</span>
      </FlexSpace>
    </div>
  );
};

export default PaymentPage;
