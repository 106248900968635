import { I18n } from "react-redux-i18n";

export type ContactsAttributeType = {
  readonly propertyId?: string;
  readonly description: string;
  readonly propertyKey: string;
  dataType: ContactsAttributeDatatypeEnum;
  readonly updatedAt: string;
  readonly updatedBy: string;
  readonly createdAt: string;
  readonly createdBy: string;
};

export type ContactsAttributeCreateType = {
  readonly value?: string;
} & Pick<ContactsAttributeType, "propertyKey" | "dataType">;

export enum ContactsAttributeDatatypeEnum {
  STRING = "STRING",
  INTEGER = "INTEGER",
  DECIMAL = "DECIMAL",
  STRING_LIST = "STRING_LIST",
  DATE = "DATE",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
}

export const DataTypeTranslation = (): Record<ContactsAttributeDatatypeEnum, string> => ({
  [ContactsAttributeDatatypeEnum.STRING]: I18n.t("dataTypeText"),
  [ContactsAttributeDatatypeEnum.INTEGER]: I18n.t("integerDataType"),
  [ContactsAttributeDatatypeEnum.DECIMAL]: I18n.t("numberWithFractionalPart"),
  [ContactsAttributeDatatypeEnum.STRING_LIST]: I18n.t("stringList"),
  [ContactsAttributeDatatypeEnum.DATE]: I18n.t("dataTypeDate"),
  [ContactsAttributeDatatypeEnum.EMAIL]: I18n.t("emailDataType"),
  [ContactsAttributeDatatypeEnum.PHONE]: I18n.t("mobileNumber"),
});

export const TranslateDataTypeKey: Record<ContactsAttributeDatatypeEnum, string> = {
  [ContactsAttributeDatatypeEnum.STRING]: "dataTypeText",
  [ContactsAttributeDatatypeEnum.INTEGER]: "integerDataType",
  [ContactsAttributeDatatypeEnum.DECIMAL]: "numberWithFractionalPart",
  [ContactsAttributeDatatypeEnum.STRING_LIST]: "stringList",
  [ContactsAttributeDatatypeEnum.DATE]: "dataTypeDate",
  [ContactsAttributeDatatypeEnum.EMAIL]: "emailDataType",
  [ContactsAttributeDatatypeEnum.PHONE]: "mobileNumber",
};
