import decode from "jwt-decode";
import { RootState } from "../store";
import { useSelector } from "react-redux";

export const getExtDate = (token?: string): number => {
  if (!token) return 0;
  return (decode(token) as { exp: number })?.exp;
};

export type TokenObjType = {
  readonly name: string;
  readonly preferred_username: string;
  readonly email: string;
  readonly email_verified: boolean;
  readonly sub: string;
  readonly realm_access: {
    roles: string[];
  };
};

export const useAccessTokenObj = (): TokenObjType | undefined => {
  const jwt = useSelector((state: RootState) => state.session.jwtToken);
  if (jwt) {
    return decode(jwt);
  }
  return undefined;
};
