import { FC, useCallback, useEffect, useState } from "react";
import IconButton from "../iconButton";
import { QrCode } from "../../../../../../../components/icons";
import QrModal from "../../../distributionAndNotification/qrCodes/modal";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { useActions } from "../../../../../../../hooks/useActions";

type Props = {
  readonly showButton?: boolean;
  readonly openModal?: boolean;
};

const QrCodeButton: FC<Props> = ({ showButton = true, openModal }) => {
  const { id: surveyId } = useParams();
  const [modalOpen, setModalOpen] = useState(openModal ?? false);
  const qrCode = useSelector((state: RootState) => state.qrCodes.current);
  const { setCurrentDistributionChannel, setCurrentQrCode, getQrCodeById } = useActions();

  useEffect(() => {
    if (qrCode?.qrCodeId) {
      getQrCodeById(qrCode?.qrCodeId);
    }
  }, [qrCode?.qrCodeId]);

  const handleCancel = useCallback(() => {
    setModalOpen(false);
    setCurrentDistributionChannel(undefined);
    setCurrentQrCode(undefined);
  }, [setCurrentDistributionChannel, setModalOpen, setCurrentQrCode]);

  const handleClick = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  return (
    <>
      {showButton && <IconButton icon={<QrCode />} label={"QR code"} onClick={handleClick} />}
      {surveyId && <QrModal open={modalOpen} onCancel={handleCancel} surveyId={surveyId} qrCode={qrCode} />}
    </>
  );
};

export default QrCodeButton;
