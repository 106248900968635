import { DefaultOptionType } from "antd/lib/select";
import { I18n } from "react-redux-i18n";
import { CsiScale } from "../types/answerTemplateType";

export const getCriteriaOptions = (scale: CsiScale): DefaultOptionType[] | undefined => {
  function isScale(_scale: CsiScale) {
    return scale === _scale;
  }

  if (isScale(CsiScale.BOOLEAN)) {
    return [
      {
        value: "0",
        label: I18n.t("no"),
      },
      {
        value: "1",
        label: I18n.t("yes"),
      },
    ];
  }

  return Array.from(Array(isScale(CsiScale.FIVE_POINTS) ? 5 : 10).keys()).map((key) => ({
    value: key,
    label: key + 1,
  }));
};
