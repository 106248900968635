import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserExtendedData, UserType } from "../../../types/userType";
import { SubscriptionInfo } from "../../../types/subscriptionInfo";

type UserParamsType = {
  readonly value: string;
  readonly label: string;
};

function mapToVariable(value: string) {
  return {
    label: value,
    value: value.substring(1),
  };
}

type StateType = {
  list: Array<UserType>;
  subUsersList: Array<UserType>;
  current?: UserType;
  params: Array<UserParamsType>;
  userExtendedData?: UserExtendedData;
  subscriptionInfo?: SubscriptionInfo;
};

const initialState: StateType = {
  list: [],
  subUsersList: [],
  current: undefined,
  params: [],
  userExtendedData: undefined,
  subscriptionInfo: undefined,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<Array<UserType>>) {
      state.list = action.payload;
    },
    setCurrent(state: StateType, action: PayloadAction<UserType>) {
      state.current = action.payload;
    },
    setContactParams(state: StateType, action: PayloadAction<Array<string>>) {
      state.params = action.payload?.map(mapToVariable);
    },
    setUserExtendedData(state: StateType, action: PayloadAction<UserExtendedData | undefined>) {
      state.userExtendedData = action.payload;
    },
    updateUserFullName(state: StateType, action: PayloadAction<Pick<UserExtendedData, "firstName" | "lastName">>) {
      state.userExtendedData = state.userExtendedData
        ? {
            ...state.userExtendedData,
            ...action.payload,
          }
        : ({ ...action.payload } as UserExtendedData);
    },
    setUserTimezone(state: StateType, action: PayloadAction<string>) {
      state.userExtendedData = {
        ...(state?.userExtendedData ?? {}),
        zoneId: action.payload,
      } as UserExtendedData;
    },
    removeUserFromList(state: StateType, action: PayloadAction<string>) {
      state.list = state.list.filter((it) => it.id !== action.payload);
    },
    addUserToList(state: StateType, action: PayloadAction<UserType>) {
      state.list = [...state.list, action.payload];
    },
    setSubUsersList(state: StateType, action: PayloadAction<Array<UserType>>) {
      state.subUsersList = action.payload;
    },
    removeSubUserFromList(state: StateType, action: PayloadAction<string>) {
      state.subUsersList = state.subUsersList.filter((it) => it.id !== action.payload);
    },
    addSubUserToList(state: StateType, action: PayloadAction<UserType>) {
      state.subUsersList = [...state.subUsersList, action.payload];
    },
    setSubscriptionInfo(state: StateType, action: PayloadAction<SubscriptionInfo | undefined>) {
      state.subscriptionInfo = action.payload;
    },
  },
});

export const {
  setList,
  setCurrent,
  setContactParams,
  setUserExtendedData,
  setUserTimezone,
  removeUserFromList,
  addUserToList,
  setSubUsersList,
  addSubUserToList,
  removeSubUserFromList,
  updateUserFullName,
  setSubscriptionInfo,
} = usersSlice.actions;
export default usersSlice.reducer;
