import React, { CSSProperties, FC, useCallback, useMemo } from "react";
import { Button, Input, Space } from "antd";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Translate } from "react-redux-i18n";
import { copyToClipboard } from "../utils/copyToClipboard";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Language } from "../types/language";
import { SocialMedium } from "../types/DistributionChannel";

type Props = {
  readonly urlToShare: string;
};

const SocialMediumShare: FC<Props> = (props) => {
  const { urlToShare } = props;
  const frameValue = `<iframe src="${urlToShare}&variant=frame" height="100%" width="100%" />`;
  const { surveyName, currentLang } = useSelector((state: RootState) => {
    return {
      surveyName: state.surveys.current?.surveyName,
      currentLang: state.persisted.appLanguage,
    };
  });

  const handleCopy = useCallback(() => {
    copyToClipboard(urlToShare);
  }, [urlToShare]);

  const handleCopyFrame = useCallback(() => {
    copyToClipboard(frameValue);
  }, [frameValue]);

  const messageRu = `Здравствуйте! \nВас приглашают пройти опрос - ${surveyName} \n${urlToShare}`;
  const messageEn = `Hello! \nYou are invited to take a survey - ${surveyName} \n${urlToShare}`;
  const messageKz = `Сәлеметсіз бе! \nСізді - ${surveyName} - сауалнамаға қатысуға шақырады \n${urlToShare}`;

  const messageByLang = useMemo(() => {
    if (currentLang === Language.EN) {
      return messageEn;
    } else if (currentLang === Language.KK) {
      return messageKz;
    }
    return messageRu;
  }, [currentLang, messageEn, messageRu, messageKz]);

  return (
    <div>
      <h4>
        <Translate value={"share"} />:
      </h4>
      <div style={{ display: "flex", gap: 10, marginTop: 24, justifyContent: "center" }}>
        <LinkedinShareButton url={messageByLang} children={<Icon name={"linkedin"} />} />
        <FacebookShareButton url={messageByLang} children={<Icon name={"facebook"} />} />
        <TelegramShareButton url={messageByLang} children={<Icon name={"telegram"} />} />
        <WhatsappShareButton url={messageByLang} children={<Icon name={"whatsapp"} />} />
        <EmailShareButton url={messageByLang} children={<Icon name={"mail"} />} />
        <TwitterShareButton url={messageByLang} children={<Icon name={"twitter"} />} />
      </div>
      <Space.Compact style={{ margin: "24px 0", width: "100%" }}>
        <Input value={urlToShare} />
        <Button type="primary" onClick={handleCopy}>
          <Translate value={"copy"} />
        </Button>
      </Space.Compact>
      <Space.Compact style={{ marginBottom: 24, width: "100%" }}>
        <Input value={frameValue} />
        <Button type="primary" onClick={handleCopyFrame}>
          <Translate value={"copy"} />
        </Button>
      </Space.Compact>
    </div>
  );
};

const Icon: FC<{ name: string }> = ({ name }) => {
  return <img style={{ width: 60 }} src={`/assets/icons/${name}.png`} alt={name} />;
};

export default SocialMediumShare;

const SaveAndShareBtn = () => {
  return (
    <Button style={{ flex: 1, gap: 5 }} type={"primary"}>
      <Translate value={"saveAndShare"} />
    </Button>
  );
};

type SocialMediaButtonWithShareActionProps = {
  readonly socialMedia?: SocialMedium;
  readonly message: string;
  readonly onClick: () => void;
  readonly style?: CSSProperties;
};

export const SocialMediaButtonWithShareAction: FC<SocialMediaButtonWithShareActionProps> = (props) => {
  const { socialMedia, message, style, onClick } = props;
  switch (socialMedia) {
    case SocialMedium.LINKEDIN:
      return <LinkedinShareButton style={style} url={message} onClick={onClick} children={<SaveAndShareBtn />} />;
    case SocialMedium.FACEBOOK:
      return <FacebookShareButton style={style} url={message} onClick={onClick} children={<SaveAndShareBtn />} />;
    case SocialMedium.TELEGRAM:
      return <TelegramShareButton style={style} url={message} onClick={onClick} children={<SaveAndShareBtn />} />;
    case SocialMedium.WHATSAPP:
      return <WhatsappShareButton style={style} url={message} onClick={onClick} children={<SaveAndShareBtn />} />;
    case SocialMedium.EMAIL:
      return <EmailShareButton style={style} url={message} onClick={onClick} children={<SaveAndShareBtn />} />;
    case SocialMedium.TWITTER:
      return <TwitterShareButton style={style} url={message} onClick={onClick} children={<SaveAndShareBtn />} />;
    default:
      return <div />;
  }
};
