import { LabelValue } from "../../types/labelValue";
import { SurveyType } from "../../types/surveyType";
import { ChannelEnum } from "../../types/channelEnum";
import { QuestionType } from "../../types/questionType";
import { I18n } from "react-redux-i18n";
import { SourceEnum } from "../../types/distributionType";

export const surveyTypes = (): Array<LabelValue> => [
  { value: SourceEnum.CONTACTS, label: I18n.t("contacts") },
  { value: SourceEnum.FILE, label: I18n.t("fileFromDisc") },
];

export const communicationChannelTypes: Array<LabelValue> = [
  { value: ChannelEnum.WEB, label: "WEB" },
  { value: ChannelEnum.SMS, label: "SMS" },
];

export const scrollTo = (pos: "top" | "bottom") => {
  const element = document.getElementById("__scroll");
  if (element) {
    setTimeout(() =>
      window.scrollTo({
        top: pos === "top" ? 0 : element.scrollHeight,
        behavior: "smooth",
      }),
    );
  }
};

export const getNextOrderValue = (questions: Array<Pick<QuestionType, "order">>) => {
  const orders = questions.filter((q) => !!q?.order).map((q) => q.order);
  if (!orders?.length) return 1;
  return Math.max(...orders) + 1;
};

export const getSurveyValues = (survey: SurveyType): SurveyType => ({
  ...survey,
  languages: survey?.languages?.map((it) => it?.langCode as any),
});
