import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useActions } from "../useActions";

type Props = {
  children: ReactNode;
};
export const TokenValidatorWrapper: FC<Props> = ({ children }) => {
  const jwtToken = useSelector((state: RootState) => state.session.jwtToken);
  const { resetAuthCtx } = useActions();

  const [tokenExpirationDate, setTokenExpirationDate] = useState<number | null>(null);

  useEffect(() => {
    if (jwtToken) {
      const decodedToken: { exp: number } = jwtDecode(jwtToken);

      if (decodedToken?.exp) {
        setTokenExpirationDate(decodedToken.exp);
      }
    }
  }, [jwtToken]);

  const validate = useCallback(() => {
    if (tokenExpirationDate) {
      const currentTime = dayjs();
      const expirationTime = dayjs.unix(tokenExpirationDate);

      if (currentTime.isAfter(expirationTime)) {
        resetAuthCtx();
      }
    }
  }, [tokenExpirationDate, resetAuthCtx]);

  useEffect(() => {
    validate();

    const tokenCheckInterval = setInterval(() => {
      validate();
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, [tokenExpirationDate]);

  return <>{children}</>;
};
