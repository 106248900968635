import { I18n } from "react-redux-i18n";

export enum ChannelEnum {
  WEB = "WEB",
  SMS = "SMS",
}

export const channelEnumLabelRu = (): Record<ChannelEnum, string> => {
  return {
    [ChannelEnum.WEB]: I18n.t("web"),
    [ChannelEnum.SMS]: I18n.t("sms"),
  };
};
