const tinycolor = require("tinycolor2");

const getInvertedColor = (color: string) => {
  function isLightColor(color: string) {
    return tinycolor(color).isLight();
  }

  return isLightColor(color) ? "black" : "white";
};

export default getInvertedColor;
