import React, { CSSProperties, FC, useCallback, useEffect, useState } from "react";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Upload } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import { RcFile } from "antd/es/upload";
import { useRequests } from "../../../../../hooks/useRequests";
import Loading from "../../../../../components/loading";
import { QuestionMediaFile, QuestionMediaFileTypesEnum } from "../../../../../types/AddMediaToQuestionResponseType";
import { useActions } from "../../../../../hooks/useActions";
import ImgCrop from "antd-img-crop";

const ImageFormat = ".png, .jpeg, .jpg";
const FileAcceptableFormats = ".png, .jpeg, .jpg, .gif, .mp4";
const MaxFileWeight = 30000000;
const ContainerSizes: CSSProperties = {
  width: "100%",
  height: "auto",
};

type MediaUploadProps = {
  readonly surveyId: string;
  readonly questionId?: string;
  readonly initial?: QuestionMediaFile;
};

const MediaUpload: FC<MediaUploadProps> = ({ surveyId, questionId, initial }) => {
  const [awaitingUploadFile, setAwaitingUploadFile] = useState<RcFile | undefined>();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { addMediaToQuestion, deleteMediaFromQuestion } = useRequests();
  const [mediaPreviewUrl, setMediaPreviewUrl] = useState<string>("");
  const { updateCurrentQuestionMediaProperties, removeCurrentQuestionMediaProperties } = useActions();

  const createImageUrl = useCallback(
    (file: Blob) => {
      URL.revokeObjectURL(mediaPreviewUrl);
      const newUrl = URL.createObjectURL(file.slice());
      updateCurrentQuestionMediaProperties(newUrl);
      setMediaPreviewUrl(newUrl);
    },
    [mediaPreviewUrl, setMediaPreviewUrl, updateCurrentQuestionMediaProperties],
  );

  const isFileFitsAcceptableFormats = useCallback((file: RcFile) => {
    return FileAcceptableFormats.split(",")
      .map((format) => format.trim())
      .includes(`.${file.type.split("/")[1]}`);
  }, []);

  const beforeUpload = useCallback(
    (file: RcFile) => {
      const fileAcceptable = isFileFitsAcceptableFormats(file);
      if (!fileAcceptable) {
        message.error(I18n.t("acceptTypesFormat") + FileAcceptableFormats);
        return Upload.LIST_IGNORE;
      } else if (file.size > MaxFileWeight) {
        message.error(I18n.t("maxFileWeightError"));
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    [isFileFitsAcceptableFormats],
  );

  const beforeCrop = useCallback((file: RcFile) => {
    const acceptableFormatsToCrop = ImageFormat;
    const fileExt = `.${file.type.split("/")[1]}`;
    if (!acceptableFormatsToCrop.includes(fileExt)) {
      handleUpload(file);
      return false;
    }
    return true;
  }, []);

  const handleUpload = useCallback(
    (file?: RcFile) => {
      if (!file || !isFileFitsAcceptableFormats(file) || file.size > MaxFileWeight) {
        return;
      }

      if (!questionId) {
        setAwaitingUploadFile(file);
        createImageUrl(file.slice());
        return;
      }
      setLoading(true);
      createImageUrl(file.slice());
      addMediaToQuestion(surveyId, questionId, file)
        .then(() => {
          setLoading(false);
          setAwaitingUploadFile(undefined);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [surveyId, questionId, isFileFitsAcceptableFormats, addMediaToQuestion],
  );

  const handleDelete = useCallback(() => {
    if (!questionId) {
      setMediaPreviewUrl("");
      removeCurrentQuestionMediaProperties();
      return;
    }
    setDeleting(true);
    deleteMediaFromQuestion(surveyId, questionId)
      .then(() => {
        setMediaPreviewUrl("");
        setDeleting(false);
      })
      .catch(() => setDeleting(false));
  }, [
    surveyId,
    questionId,
    setDeleting,
    setMediaPreviewUrl,
    deleteMediaFromQuestion,
    removeCurrentQuestionMediaProperties,
  ]);

  useEffect(() => {
    setMediaPreviewUrl(initial?.url ?? "");
  }, [questionId, initial?.url]);

  useEffect(() => {
    if (questionId && !!awaitingUploadFile) {
      handleUpload(awaitingUploadFile);
    }
  }, [questionId]);

  return (
    <div style={{ ...ContainerSizes, marginBottom: 54, position: "relative" }}>
      <div className={"ant-col ant-form-item-label"}>
        <label htmlFor="">
          <Translate value={"questionMediaBanner.label"} />
        </label>
      </div>
      {loading ? (
        <div
          style={{
            ...ContainerSizes,
            border: "1px solid gray",
            borderRadius: 8,
          }}
        >
          <Loading height={200} text={null} />
        </div>
      ) : mediaPreviewUrl ? (
        <div style={{ ...ContainerSizes, borderRadius: 8, overflow: "hidden", position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              marginLeft: "auto",
              zIndex: 999,
            }}
          >
            <Popconfirm
              title={I18n.t("questionMediaBanner.deleteConfirmationText")}
              okText={I18n.t("confirm")}
              onConfirm={handleDelete}
              disabled={deleting}
            >
              <Button danger size={"small"} loading={deleting} icon={<DeleteOutlined />}>
                <Translate value={"delete"} />
              </Button>
            </Popconfirm>
          </div>
          {initial?.contentType === QuestionMediaFileTypesEnum.VIDEO ? (
            <video
              style={{
                maxWidth: 488,
                maxHeight: 300,
                borderRadius: "inherit",
                objectFit: "contain",
                objectPosition: "center",
              }}
              autoPlay
              controls
              src={mediaPreviewUrl}
            >
              <source src={mediaPreviewUrl} type="video/mp4" />
              Ваш браузер не поддерживает видео.
            </video>
          ) : (
            <img
              style={{
                maxWidth: 488,
                maxHeight: 300,
                borderRadius: "inherit",
                objectFit: "contain",
                objectPosition: "center",
              }}
              src={mediaPreviewUrl}
              alt="preview"
            />
          )}
        </div>
      ) : (
        <ImgCrop
          showGrid
          zoomSlider
          quality={1}
          aspectSlider
          rotationSlider
          showReset={false}
          cropShape={"rect"}
          fillColor={"transparent"}
          modalOk={I18n.t("save")}
          beforeCrop={beforeCrop}
          onModalOk={(newImg) => {
            handleUpload(newImg as RcFile);
          }}
        >
          <Upload
            maxCount={1}
            disabled={loading}
            listType={"picture"}
            beforeUpload={beforeUpload}
            accept={FileAcceptableFormats}
          >
            <Button
              style={{
                ...ContainerSizes,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                background: "rgba(0, 0, 0, 0.02)",
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ fontSize: 48 }} />
              </p>
              <p className="ant-upload-text" style={{ marginBottom: 0, color: "rgba(0, 0, 0, 0.88)", fontSize: 16 }}>
                <Translate value={"questionMediaBanner.title"} />
              </p>
              <p className="ant-upload-hint" style={{ marginBottom: 0, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>
                <Translate value={"questionMediaBanner.description"} />
              </p>
            </Button>
          </Upload>
        </ImgCrop>
      )}
    </div>
  );
};

export default MediaUpload;
