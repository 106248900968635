import { Translate } from "react-redux-i18n";
import { Divider as AntdDivider, DividerProps } from "antd";
import React, { CSSProperties } from "react";

type Props = {
  readonly translationCode?: string;
  readonly text?: string;
  readonly styles?: CSSProperties;
};

const style: DividerProps = {
  orientation: "left",
  style: {
    minWidth: 620,
    maxWidth: 620,
    marginTop: 0,
    marginBottom: 24,
    fontSize: 15,
    fontWeight: "bold",
  },
};

const Divider = ({ translationCode, text, styles }: Props) => {
  return (
    <AntdDivider style={{ ...style, ...styles }}>
      {text ?? (!translationCode ? "" : <Translate value={translationCode} />)}
    </AntdDivider>
  );
};

export default Divider;
