import "./index.scss";
import React, { FC, useCallback, useEffect, useState } from "react";
import QRCodeStyling, { Options } from "qr-code-styling";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Button, ColorPicker, Form, message, Upload } from "antd";
import { CopyOutlined, DeleteOutlined, DownloadOutlined, FileImageOutlined } from "@ant-design/icons";
import { I18n, Translate } from "react-redux-i18n";
import {
  FileTypesEnum,
  QrFormType,
  QrStylesEnum,
} from "../pages/surveys/edit/steps/distributionAndNotification/qrCodes/helpers";
import { toTranslatedLabelValue } from "../utils/toTranslatedLabelValue";
import { APP_PRIMARY_COLOR, DEFAULT_ERROR_LABEL } from "../utils";
import InputNumberFormItemComponent from "./form/items/inputNumber";
import { ColorFactory } from "antd/es/color-picker/color";
import { useRequests } from "../hooks/useRequests";
import { LOGO_TYPES } from "../pages/surveys/edit/steps/personalization/helpers";
import { RcFile } from "antd/es/upload";
import RadioFormItemComponent from "./form/items/radio";
import InputFormItemComponent from "./form/items/input";
import { DistributionChannelTypeEnum } from "../types/DistributionChannel";
import { useDistributionChannelsApi } from "../hooks/useDistributionChannelsApi";

type InitialDataType = {
  readonly size: string;
  readonly color: string;
  readonly style: QrStylesEnum;
  readonly logoUrl?: string;
  readonly fileType?: FileTypesEnum;
};

type Props = {
  readonly surveyId: string;
  readonly qrCodeId: string;
  readonly url: string;
  readonly receiver: string;
  readonly initialData?: InitialDataType;
};

const QrCodeGenerator: FC<Props> = (props) => {
  const { surveyId, qrCodeId, url, receiver, initialData } = props;
  const currentDistributionChannel = useSelector((state: RootState) => state.distributionChannels.current);
  const survey = useSelector((state: RootState) => state.surveys.current);
  const [form] = Form.useForm<QrFormType>();
  const [saving, setSaving] = useState(false);
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [logoUrl, setLogoUrl] = useState(SSBrandLogoPath);
  const { updateQrCode, uploadLogo } = useRequests();
  const { createDistributionChannel, updateDistributionChannel } = useDistributionChannelsApi();

  const clearCanvas = useCallback(() => {
    const canvas = document.getElementById("qr_canvas");
    while (canvas?.firstChild) {
      canvas.removeChild(canvas.firstChild);
    }
  }, []);

  const getInitialColor = useCallback(
    (initial?: string) => {
      return initial ?? initialData?.color ?? survey?.themeColor ?? APP_PRIMARY_COLOR;
    },
    [initialData, survey],
  );

  const getQrProps = useCallback(
    (initial?: QrFormType) => {
      const color$ = getInitialColor(initial?.color);
      const qrOptions = QrCodesData[initial?.style ?? initialData?.style ?? QrStylesEnum.STANDARD];

      return {
        data: url,
        image: logoUrl ?? SSBrandLogoPath,
        ...qrOptions,
        dotsOptions: { ...qrOptions.dotsOptions, color: color$ },
      };
    },
    [form, url, survey, logoUrl, initialData],
  );

  const prepareQr = useCallback(
    (initial?: QrFormType) => {
      const qrOptions = getQrProps(initial);
      const qrCode = new QRCodeStyling(qrOptions);
      const canvas = document.getElementById("qr_canvas");

      if (canvas) {
        clearCanvas();
        qrCode.append(canvas);
      }
    },
    [clearCanvas, getQrProps],
  );

  const colorFactoryToHexStr = useCallback((color$: ColorFactory) => {
    return color$.toHexString();
  }, []);

  const getCurrentQrCodeObj = useCallback(() => {
    const formProps = form.getFieldsValue();
    const color$ = formProps.color as string | ColorFactory;

    const qrOptions = getQrProps({
      ...formProps,
      color: typeof color$ === "string" ? color$ : colorFactoryToHexStr(color$),
    });

    return new QRCodeStyling({
      ...qrOptions,
      image: logoUrl,
      width: Number(formProps.size ?? qrOptions.width),
      height: Number(formProps.size ?? qrOptions.height),
    });
  }, [form, logoUrl, getQrProps, colorFactoryToHexStr]);

  const handleCopy = useCallback(async () => {
    const qrCode = getCurrentQrCodeObj();
    const canvas = document.getElementById("qr_canvas_for_copy");

    if (canvas) {
      qrCode.append(canvas);
      const qrSvgElement = await qrCode._getElement("svg");

      if (qrSvgElement) {
        handleCopyToClipboard(qrSvgElement as SVGElement)
          .catch(console.log)
          .finally(() => {
            if (canvas?.firstChild) {
              canvas?.removeChild(canvas.firstChild);
            }
          });
      }
    }
  }, [getCurrentQrCodeObj]);

  const handleSave = useCallback(() => {
    if (!surveyId || !qrCodeId) return;
    form
      .validateFields()
      .then(() => {
        setSaving(true);
        const formProps = form.getFieldsValue();
        const color$ = formProps.color as string | ColorFactory;
        const qrOptions = {
          qrCodeId: qrCodeId,
          surveyId: surveyId,
          imageSize: 300,
          receiver: receiver,
          qrLogoUrl: logoUrl,
          qrProperties: {
            ...formProps,
            logoUrl,
            color: typeof color$ === "string" ? color$ : colorFactoryToHexStr(color$),
          },
        };
        updateQrCode(qrOptions, currentDistributionChannel?.channelId)
          .then(async () => {
            if (currentDistributionChannel?.channelId) {
              await updateDistributionChannel({
                channelId: currentDistributionChannel.channelId,
                description: formProps?.description ?? currentDistributionChannel?.description ?? "",
              });
            } else {
              await createDistributionChannel({
                channelType: DistributionChannelTypeEnum.QR,
                qrCodeId: qrCodeId,
                surveyId: surveyId,
                description: formProps?.description ?? "",
              });
            }
            setSaving(false);
          })
          .catch(() => setSaving(false));
      })
      .catch(() => setSaving(false));
  }, [
    form,
    surveyId,
    qrCodeId,
    logoUrl,
    receiver,
    currentDistributionChannel,
    updateQrCode,
    setSaving,
    updateDistributionChannel,
    createDistributionChannel,
  ]);

  const handleDownload = useCallback(() => {
    const qrCode = getCurrentQrCodeObj();
    const fileType = form.getFieldValue("fileType") ?? initialData?.fileType ?? FileTypesEnum.PNG;

    qrCode
      .download({
        name: `QR_${receiver}`,
        extension: fileType,
      })
      .catch(console.log);
  }, [form, initialData, receiver, getCurrentQrCodeObj]);

  const handlePropsChange = useCallback(
    (val: any, values: QrFormType) => {
      if (val.logoUrl) {
        return;
      }
      const color$ = values.color as string | ColorFactory;
      prepareQr({
        ...values,
        color: typeof color$ === "string" ? color$ : colorFactoryToHexStr(color$),
      });
    },
    [prepareQr],
  );

  const handleLogoUpload = useCallback(
    (file: RcFile) => {
      setUploadingLogo(true);
      uploadLogo(qrCodeId, file)
        .then((newLogoUrl) => setLogoUrl(newLogoUrl))
        .catch(console.log)
        .finally(() => setUploadingLogo(false));
    },
    [setUploadingLogo, qrCodeId, uploadLogo, setLogoUrl],
  );

  const handleLogoDelete = useCallback(() => {
    setLogoUrl(SSBrandLogoPath);
    prepareQr();
  }, [setLogoUrl, prepareQr]);

  useEffect(() => {
    prepareQr();
  }, [logoUrl]);

  useEffect(() => {
    setLogoUrl(initialData?.logoUrl ?? SSBrandLogoPath);
    return () => {
      clearCanvas();
    };
  }, []);

  return (
    <div className={"qr_props_form"}>
      <Form
        form={form}
        layout={"vertical"}
        style={{ marginTop: 24 }}
        onValuesChange={handlePropsChange}
        initialValues={{
          ...(initialData ?? FormInitialValues),
          color: getInitialColor(),
          description: currentDistributionChannel?.description ?? I18n.t("qrCode"),
        }}
      >
        <InputFormItemComponent name={"description"} label={I18n.t("description")} />
        <RadioFormItemComponent
          name={"style"}
          required={false}
          label={I18n.t("style")}
          values={Object.values(QrStylesEnum).map(toTranslatedLabelValue)}
        />
        <div style={{ display: "flex", gap: 5, width: "100%" }}>
          <InputNumberFormItemComponent
            min={100}
            max={3840}
            name={"size"}
            required={true}
            addonAfter={"px"}
            hasFeedback={false}
            label={I18n.t("size")}
            wrapperStyles={{ flex: 1 }}
          />
          <RadioFormItemComponent
            label={I18n.t("fileFormat")}
            name={"fileType"}
            required={false}
            style={{ flex: 1 }}
            values={[
              { label: "PNG", value: "png" },
              { label: "JPEG", value: "jpeg" },
            ]}
          />
        </div>
        <div style={{ display: "flex", gap: 5, width: "100%" }}>
          <Form.Item style={{ flex: 1 }} label={I18n.t("color")} name={"color"} hasFeedback={false}>
            <ColorPicker
              arrow
              format={"hex"}
              size={"large"}
              placement={"top"}
              trigger={"hover"}
              defaultValue={APP_PRIMARY_COLOR}
              style={{ width: "100%", justifyContent: "left" }}
              showText={(color) => color.toHexString()}
            />
          </Form.Item>

          <Form.Item name={"logoUrl"} hasFeedback={false} style={{ flex: 1 }} label={I18n.t("logo")}>
            <Upload
              maxCount={1}
              fileList={[]}
              accept={LOGO_TYPES}
              disabled={uploadingLogo}
              customRequest={({ file }) => handleLogoUpload(file as RcFile)}
            >
              <Button
                htmlType={"button"}
                loading={uploadingLogo}
                style={{ width: "100%" }}
                icon={<FileImageOutlined />}
              >
                <Translate value={"selectLogo"} />
              </Button>
            </Upload>
          </Form.Item>
        </div>
      </Form>

      <div style={{ display: "flex", gap: 5 }}>
        <div id="qr_canvas" style={{ flex: 1 }} />
        <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: 5 }}>
          {logoUrl !== SSBrandLogoPath && (
            <Button type={"text"} icon={<DeleteOutlined />} onClick={handleLogoDelete}>
              <Translate value={"deleteLogo"} />
            </Button>
          )}
          <Button type={"text"} icon={<CopyOutlined />} onClick={handleCopy}>
            <Translate value={"copyQr"} />
          </Button>
          <Button type={"text"} icon={<DownloadOutlined />} onClick={handleDownload}>
            <Translate value={"downloadQr"} />
          </Button>
          <Button
            loading={saving}
            type={"primary"}
            onClick={handleSave}
            style={{ marginTop: "auto", marginBottom: 10 }}
          >
            <Translate value={"saveChanges"} />
          </Button>
        </div>
      </div>
      <div id="qr_canvas_for_copy" style={{ position: "absolute", visibility: "hidden" }} />
    </div>
  );
};

export default QrCodeGenerator;

const FormInitialValues: QrFormType = {
  style: QrStylesEnum.STANDARD,
  fileType: FileTypesEnum.PNG,
  size: "300",
  color: APP_PRIMARY_COLOR,
};

const SSBrandLogoPath = "/assets/icons/fullLogo.svg";

const StaticData: Partial<Options> = {
  width: 210,
  height: 210,
  type: "svg",
  backgroundOptions: { color: "#ffffff" },
  imageOptions: { crossOrigin: "anonymous", hideBackgroundDots: true, margin: 0 },
};

export const QrCodesData: Record<QrStylesEnum, Partial<Options>> = {
  [QrStylesEnum.STANDARD]: {
    ...StaticData,
    dotsOptions: { type: "square" },
    cornersSquareOptions: { type: "square", color: "#000000" },
    cornersDotOptions: { type: "square" },
  },
  [QrStylesEnum.ROUNDED]: {
    ...StaticData,
    dotsOptions: { type: "rounded" },
    cornersSquareOptions: { type: "extra-rounded", color: "#000000" },
  },
  [QrStylesEnum.DOTS]: {
    ...StaticData,
    dotsOptions: { type: "dots" },
    cornersSquareOptions: { type: "dot", color: "#000000" },
    cornersDotOptions: { type: "dot" },
  },
};

const handleCopyToClipboard = async (svgRef: SVGElement) => {
  if (svgRef) {
    // Step 1: Convert SVG element to data URL
    const svgData = new XMLSerializer().serializeToString(svgRef);
    const svgBlob = new Blob([svgData], { type: "image/svg+xml" });
    const svgUrl = URL.createObjectURL(svgBlob);

    // Step 2: Create a temporary canvas and draw SVG image onto it
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = async () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0, img.width, img.height);

      // Step 3: Convert canvas to blob
      canvas.toBlob(async (blob) => {
        if (blob) {
          // Step 4: Copy blob to clipboard using Clipboard API
          try {
            const clipboardItems = [new ClipboardItem({ [blob.type]: blob })];

            await navigator.clipboard.write(clipboardItems);
            message.success(I18n.t("successCopiedToClipBoard"));
          } catch (error) {
            message.error(I18n.t(DEFAULT_ERROR_LABEL));
          }
        }

        // Clean up
        URL.revokeObjectURL(svgUrl);
      }, "image/png");
    };
    img.src = svgUrl;
  }
};
