import { FC, useCallback, useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { PaymentHistoryType } from "../../types/paymentHistoryType";
import { useRequests } from "../../hooks/useRequests";
import { Pageable } from "../../types/pageable";
import { PageableInitial } from "../../types/surveyInstance";
import { renderNullableDate } from "../../utils/renderNullableText";
import { I18n } from "react-redux-i18n";

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
};

const PaymentHistoryModal: FC<Props> = ({ open, onClose }) => {
  const { getPaymentsHistory } = useRequests();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PaymentHistoryType[]>([]);
  const [page, setPage] = useState<Pageable>({ ...PageableInitial });

  const handleClose = useCallback(() => {
    setData([]);
    setPage({ ...PageableInitial });
    onClose();
  }, [setData, setPage, onClose]);

  const onPageChange = useCallback(
    (page: number) => {
      setPage((prev) => ({ ...prev, page: page }));
    },
    [setPage],
  );

  const onLoadData = useCallback(() => {
    setLoading(true);
    getPaymentsHistory(page)
      .then((res) => {
        setData(res.content);
        setPage((prev) => ({
          ...prev,
          total: res.totalElements,
        }));
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [page, setData, setPage, setLoading, getPaymentsHistory]);

  useEffect(() => {
    if (open && !data.length && !loading) {
      onLoadData();
    }
  }, [open]);

  useEffect(() => {
    if (page.page > 0) {
      onLoadData();
    }
  }, [page.page]);

  return (
    <Modal
      closable
      open={open}
      onCancel={handleClose}
      title={I18n.t("tariffs.paymentHistory")}
      footer={null}
      width={"100%"}
    >
      <Table
        bordered={false}
        loading={loading}
        dataSource={data}
        style={{ marginTop: 24, minHeight: 400 }}
        rowKey={(record) => record?.paymentId ?? ""}
        pagination={{
          size: "small",
          total: page.total,
          pageSize: page.size,
          showSizeChanger: false,
          onChange: onPageChange,
          current: page.page + 1,
          defaultPageSize: page.size,
        }}
        columns={[
          { key: "transactionId", dataIndex: "transactionId", title: I18n.t("tariffs.invoiceNumber") },
          {
            key: "paymentDate",
            dataIndex: "paymentDate",
            title: I18n.t("tariffs.dateOfPayment"),
            render: renderNullableDate,
          },
          {
            key: "amount",
            dataIndex: "amount",
            title: I18n.t("tariffs.price"),
            render: (_, record) => (
              <span>
                {record.amount.toLocaleString("ru-RU")} {record.currency}
              </span>
            ),
          },
          { key: "operation", dataIndex: "operation", title: I18n.t("tariffs.operation") },
          { key: "status", dataIndex: "status", title: I18n.t("tariffs.state") },
        ]}
      />
    </Modal>
  );
};

export default PaymentHistoryModal;
