import "./index.scss";
import React, { FC, useCallback } from "react";
import { Tabs } from "antd";
import { SettingsPageTabsEnum } from "../../types/settingsPageTabsEnum";
import { Translate } from "react-redux-i18n";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const items = [
  { key: SettingsPageTabsEnum.API_KEYS, label: <Translate value={"apiKeys"} /> },
  { key: SettingsPageTabsEnum.OFF_DAYS, label: <Translate value={"exclusionDates"} /> },
  { key: SettingsPageTabsEnum.EMAIL_PROVIDERS, label: <Translate value={"emailProviders"} /> },
  { key: SettingsPageTabsEnum.SMS_PROVIDERS, label: <Translate value={"smsProviders"} /> },
  { key: SettingsPageTabsEnum.CONTACTS_SETTINGS, label: <Translate value={"contactsSettings"} /> },
  { key: SettingsPageTabsEnum.USERS, label: <Translate value={"users"} /> },
];
const SettingsPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = location.pathname.split("/settings/")[1] ?? SettingsPageTabsEnum.API_KEYS;
  const onTabChange = useCallback((tab: string) => navigate(tab), [navigate]);

  return (
    <div className={"settings-page"}>
      <Tabs type="card" items={items} activeKey={activeTab} onChange={onTabChange} />
      <Outlet />
    </div>
  );
};

export default SettingsPage;
