import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { DELETE, GET, POST, PUT } from "../../../types/httpMethod";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import { addToList, removeFromList, setCurrent, setList, updateInList } from "./emailProvidersSlice";
import { EmailProviderType } from "../../../types/EmailProviderType";

export const getEmailProviders = createAsyncThunk<void, undefined>(
  "emailProviders/getEmailProviders",
  async (_, { dispatch }) => {
    try {
      const res = await requestApi(GET, "/email-provider", {});
      dispatch(setEmailProvidersData(res));
    } catch (e) {
      throw e;
    }
  },
);

export const deleteEmailProvider = createAsyncThunk<void, number>(
  "emailProviders/deleteEmailProvider",
  async (emailProviderId, { dispatch }) => {
    try {
      await requestApi(DELETE, `/email-provider/${emailProviderId}`, {});
      dispatch(removeFromList(emailProviderId));
      message.success(I18n.t("emailProviderDeleted"));
    } catch (e) {
      throw e;
    }
  },
);

export const createEmailProvider = createAsyncThunk<void, EmailProviderType>(
  "emailProviders/createEmailProvider",
  async (emailProvider, { dispatch }) => {
    try {
      const res = await requestApi(POST, "/email-provider", emailProvider);
      dispatch(addEmailProviderToList(res));
      message.success(I18n.t("emailProviderCreated"));
    } catch (e) {
      throw e;
    }
  },
);

export const updateEmailProvider = createAsyncThunk<
  void,
  {
    readonly emailProvidersId: number;
    readonly values: EmailProviderType;
  }
>("emailProviders/updateEmailProvider", async ({ emailProvidersId, values }, { dispatch }) => {
  try {
    const res = await requestApi(PUT, `/email-provider/${emailProvidersId}`, values);
    dispatch(updateEmailProviderInList(res));
    message.success(I18n.t("emailProviderChanged"));
  } catch (e) {
    throw e;
  }
});

export const setEmailProvidersData = createAsyncThunk<void, Array<EmailProviderType>>(
  "emailProviders/setEmailProvidersData",
  (data, { dispatch }) => {
    dispatch(setList(data));
  },
);

export const updateEmailProviderInList = createAsyncThunk<void, EmailProviderType>(
  "emailProviders/updateEmailProviderInList",
  (data, { dispatch }) => {
    dispatch(updateInList(data));
  },
);

export const setCurrentEmailProvider = createAsyncThunk<void, EmailProviderType | undefined>(
  "emailProviders/setCurrentEmailProvider",
  (data, { dispatch }) => {
    dispatch(setCurrent(data));
  },
);

export const addEmailProviderToList = createAsyncThunk<void, EmailProviderType>(
  "emailProviders/addEmailProviderToList",
  (data, { dispatch }) => {
    dispatch(addToList(data));
  },
);
