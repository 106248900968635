import React, { FC, useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import { callOnTitles, WebhookType } from "../../../../../types/WebhookType";
import { Button, Popover, Table } from "antd";
import Confirm from "../../../../../components/confirm";
import { MoreOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { useActions } from "../../../../../hooks/useActions";
import { useParams } from "react-router-dom";
import { I18n, Translate } from "react-redux-i18n";

export const columns = (
  onEdit: (webhook?: WebhookType) => void,
  onDelete: (id?: string) => void,
): ColumnsType<WebhookType> => [
  {
    key: "callOn",
    dataIndex: "callOn",
    title: I18n.t("callOn"),
    render: (_, record) => (
      <div className={"survey-name"}>
        <span className={"link"} onClick={() => onEdit(record)}>
          {callOnTitles()[record.callOn]}
        </span>
      </div>
    ),
  },
  {
    key: "requestMethod",
    dataIndex: "requestMethod",
    title: I18n.t("method"),
  },
  {
    title: "URL",
    key: "requestUrl",
    dataIndex: "requestUrl",
  },
  {
    key: "description",
    dataIndex: "description",
    title: I18n.t("description"),
  },
  {
    key: "actions",
    render: (_, record) => (
      <Popover
        trigger="click"
        placement="left"
        overlayInnerStyle={{ padding: 0 }}
        content={
          <div style={{ display: "grid" }}>
            <Button onClick={() => onEdit(record)} type={"text"}>
              <Translate value={"edit"} />
            </Button>
            <Confirm
              danger
              action={I18n.t("delete")}
              onConfirm={() => onDelete(record?.webhookId)}
              title={I18n.t("deleteWebhookConfirmation")}
            />
          </div>
        }
      >
        <MoreOutlined className={"surveys-table-actions-button"} />
      </Popover>
    ),
  },
];

const IntegrationsTable: FC<{
  readonly changeVisible: () => void;
}> = ({ changeVisible }) => {
  const { getWebhooks, deleteWebhook, setCurrentWebhook } = useActions();
  const { id } = useParams();
  const surveyId = useSelector((state: RootState) => state.surveys.current?.surveyId);
  const webhooks = useSelector((state: RootState) => state.webhooks.list);

  useEffect(() => {
    if (surveyId) {
      getWebhooks(surveyId);
    } else if (id) {
      getWebhooks(id);
    }
  }, []);

  function onEdit(webhook?: WebhookType) {
    setCurrentWebhook(webhook);
    changeVisible();
  }

  function onDelete(webhookId?: string) {
    if (surveyId && webhookId) {
      deleteWebhook({ surveyId, webhookId });
    }
  }

  return (
    <Table
      dataSource={webhooks}
      columns={columns(onEdit, onDelete)}
      rowKey={(record: WebhookType) => record?.webhookId + ""}
    />
  );
};

export default IntegrationsTable;
