import { TariffType } from "../../types/tariff";
import { I18n } from "react-redux-i18n";
import { TariffsProps } from "../../utils/i18/tariffs/helpers";

const getArrFromInt = (value: number) => Array.from(Array(value).keys());

export const BasicPlanId = "pln_basic_monthly";
export const StandardPlanId = "pln_standard_monthly";
export const BusinessPlanId = "pln_business_monthly";

export const generateTariffs = (planId?: string): Array<TariffType> => {
  return [
    {
      tariffId: BasicPlanId,
      name: I18n.t("tariffs.basic.name"),
      description: I18n.t("tariffs.basic.description"),
      amount: I18n.t("tariffs.basic.amount"),
      amountInt: Number(I18n.t("tariffs.basic.amountInt")),
      options: getArrFromInt(TariffsProps.basic.optionsQuantity).map((idx) => {
        return I18n.t(`tariffs.basic.options.${idx}`);
      }),
      additional: getArrFromInt(TariffsProps.basic.additionalOptionsQuantity).map((idx) => {
        return I18n.t(`tariffs.basic.additional.${idx}`);
      }),
      isCurrent: planId === BasicPlanId,
    },
    {
      tariffId: StandardPlanId,
      name: I18n.t("tariffs.standard.name"),
      description: I18n.t("tariffs.standard.description"),
      amount: I18n.t("tariffs.standard.amount"),
      amountInt: Number(I18n.t("tariffs.standard.amountInt")),
      options: getArrFromInt(TariffsProps.standard.optionsQuantity).map((idx) => {
        return I18n.t(`tariffs.standard.options.${idx}`);
      }),
      additional: getArrFromInt(TariffsProps.standard.additionalOptionsQuantity).map((idx) => {
        return I18n.t(`tariffs.standard.additional.${idx}`);
      }),
      isCurrent: planId === StandardPlanId,
    },
    {
      tariffId: BusinessPlanId,
      name: I18n.t("tariffs.business.name"),
      description: I18n.t("tariffs.business.description"),
      amount: I18n.t("tariffs.business.amount"),
      amountInt: Number(I18n.t("tariffs.business.amountInt")),
      options: getArrFromInt(TariffsProps.business.optionsQuantity).map((idx) => {
        return I18n.t(`tariffs.business.options.${idx}`);
      }),
      additional: getArrFromInt(TariffsProps.business.additionalOptionsQuantity).map((idx) => {
        return I18n.t(`tariffs.business.additional.${idx}`);
      }),
      isCurrent: planId === BusinessPlanId,
    },
  ];
};
