import React, { useEffect } from "react";
import PrivateRoutes from "./privateRoutes";
import { useActions } from "../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { getKeycloakAuthUrl } from "../utils/getKeycloakAuthUrl";
import { Outlet } from "react-router-dom";
import { useAccessTokenObj } from "../utils/jwt";
import PublicRoutes from "./publicRoutes";

const AppRoutes: React.FC = () => {
  const { onKeycloakLogin, resetAuthCtx } = useActions();
  const isLoggedIn = useSelector((state: RootState) => !!state.session.jwtToken);
  const tokenObj = useAccessTokenObj();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      onKeycloakLogin(code);
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (!isLoggedIn && !urlParams.get("code")) {
      resetAuthCtx();
      window.location.replace(getKeycloakAuthUrl());
    }
  });

  if (!isLoggedIn) {
    return <Outlet />;
  }

  if (!tokenObj?.email_verified) {
    return <PublicRoutes />;
  }

  return <PrivateRoutes />;
};

export default AppRoutes;
