import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QuestionType } from "../../../types/questionType";
import { sortByOrder } from "../../../utils/sortByOrder";
import { AnswerTemplateType } from "../../../types/answerTemplateType";
import { QuestionMediaFile } from "../../../types/AddMediaToQuestionResponseType";

type StateType = {
  list: Array<QuestionType>;
  current?: QuestionType;
  questionCreateFormVisible: boolean;
};
const initialState: StateType = {
  list: [],
  current: undefined,
  questionCreateFormVisible: false,
};

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<Array<QuestionType>>) {
      state.list = action.payload;
    },
    setCurrent(state: StateType, action: PayloadAction<QuestionType | undefined>) {
      if (!action?.payload) {
        return (state.current = undefined);
      }

      const answerTemplate = {
        ...action.payload?.answerTemplate,
        answerChoices: action.payload?.answerTemplate?.answerChoices?.map((it) => it)?.sort(sortByOrder),
      } as AnswerTemplateType;

      const prevC = action.payload?.previousAnswerCondition;
      const splitValue = Array.isArray(prevC?.value) ? prevC?.value : prevC?.value?.split(",");

      const previousAnswerCondition = {
        ...prevC,
        value: Array.isArray(splitValue) ? splitValue : prevC?.value,
      };

      state.current = {
        ...action.payload,
        answerTemplate,
        previousAnswerCondition,
      };
    },
    addQuestionToList(state: StateType, action: PayloadAction<QuestionType>) {
      state.list.push(action.payload);
      state.list = state.list.sort(sortByOrder);
      state.current = action.payload;
    },
    deleteFromList(state: StateType, action: PayloadAction<string>) {
      state.list = state.list.filter((q) => q.questionId !== action.payload).sort(sortByOrder);
    },
    updateList(state: StateType, action: PayloadAction<QuestionType>) {
      state.list = state.list
        .map((q) =>
          q.questionId === action.payload.questionId
            ? {
                ...action.payload,
                mediaProperties: q.mediaProperties,
              }
            : q,
        )
        .sort(sortByOrder);
    },
    updateCurrent(state: StateType, action: PayloadAction<any>) {
      let values: any = {};
      Object.keys(action.payload).map((key) => (values[key] = action.payload[key]));
      state.current = { ...state.current, ...values } as QuestionType;
    },
    updateMediaProperties(
      state: StateType,
      action: PayloadAction<QuestionMediaFile & Pick<QuestionType, "questionId">>,
    ) {
      const { questionId, ...mediaProps } = action.payload;
      state.list = state.list.map((q) =>
        q.questionId !== questionId
          ? q
          : {
              ...q,
              mediaProperties: mediaProps,
            },
      );
      if (state.current) {
        state.current = {
          ...state.current,
          mediaProperties: mediaProps,
        };
      }
    },
    updateMediaPropertiesInCurrentQuestion(state: StateType, action: PayloadAction<string>) {
      if (state.current) {
        state.current = {
          ...state.current,
          mediaProperties: {
            url: action.payload,
          } as QuestionMediaFile,
        };
      }
    },
    removeMediaProperties(state: StateType, action: PayloadAction<string>) {
      const questionId = action.payload;
      state.list = state.list.map((q) =>
        q.questionId !== questionId
          ? q
          : {
              ...q,
              mediaProperties: undefined,
            },
      );
      if (state.current) {
        state.current = {
          ...state.current,
          mediaProperties: undefined,
        };
      }
    },
    removeMediaPropertiesInCurrentQuestion(state: StateType) {
      if (state.current) {
        state.current = {
          ...state.current,
          mediaProperties: undefined,
        };
      }
    },
    setQuestionCreateFormVisible(state: StateType, action: PayloadAction<boolean>) {
      state.questionCreateFormVisible = action.payload;
    },
    resetConditions(state: StateType) {
      if (!state.current) return state;
      const originalQuestion = state.list.find((it) => it.questionId === state.current?.questionId);
      const originalConditions = originalQuestion?.conditions;
      const originalPreviousAnswerCondition = originalQuestion?.previousAnswerCondition;

      state.current = {
        ...state.current,
        conditions: originalConditions ? originalConditions : [],
        previousAnswerCondition: originalPreviousAnswerCondition ? originalPreviousAnswerCondition : undefined,
      };
    },
  },
});

export const {
  addQuestionToList,
  deleteFromList,
  updateCurrent,
  updateList,
  setCurrent,
  setList,
  updateMediaProperties,
  removeMediaProperties,
  updateMediaPropertiesInCurrentQuestion,
  removeMediaPropertiesInCurrentQuestion,
  setQuestionCreateFormVisible,
  resetConditions,
} = questionsSlice.actions;
export default questionsSlice.reducer;
