import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { DELETE, GET, PATCH, POST } from "../../../types/httpMethod";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import {
  addToList,
  setCurrent,
  setList,
  setPageable,
  updateCurrent,
  updateDescriptionInList as updateDescriptionInList$,
  updateInList,
  updateQrCodeInDistributionsList as updateQrCodeInDistributionsList$,
} from "./distributionChannelsSlice";
import { Pageable } from "../../../types/pageable";
import store from "../../index";
import { PagedResponseType } from "../../../types/pagedResponseType";
import { DistributionChannel, DistributionChannelCreate } from "../../../types/DistributionChannel";

export const getDistributionChannelsBySurveyId = createAsyncThunk<void, string>(
  "distributions/getDistributionChannelsBySurveyId",
  async (surveyId, { dispatch }) => {
    try {
      const page: Pageable = store.getState().distributionChannels.pageable;
      const res: PagedResponseType<DistributionChannel> = await requestApi(
        GET,
        `/distribution_channels/${surveyId}/paginated`,
        {},
        false,
        true,
        {},
        { ...page, total: undefined, sort: "created_at,desc" },
      );
      dispatch(setDistributionChannelList(res.content));
      dispatch(setDistributionChannelPagination({ ...page, total: res.totalElements }));
    } catch (e) {
      throw e;
    }
  },
);

export const getDistributionChannelById = createAsyncThunk<void, string>(
  "distributions/getDistributionById",
  async (channelId, { dispatch }) => {
    try {
      const res = await requestApi(GET, `/distribution_channels/${channelId}`, {});
      dispatch(setCurrentDistributionChannel(res));
    } catch (e) {
      throw e;
    }
  },
);

export const createDistributionChannel = createAsyncThunk<void, DistributionChannelCreate>(
  "distributions/createDistributionChannel",
  async (distributionChannel, { dispatch }) => {
    try {
      const res = await requestApi(POST, "/distribution_channels", distributionChannel);
      dispatch(setCurrentDistributionChannel(res));
      dispatch(addDistributionChannelToList(res));
      message.success(I18n.t("distributionCreated"));
      return res;
    } catch (e) {
      throw e;
    }
  },
);

export const updateDistributionChannel = createAsyncThunk<
  void,
  {
    readonly channelId: number;
    readonly description: string;
  }
>("distributions/updateDistributionChannel", async ({ channelId, description }, { dispatch }) => {
  try {
    const res = await requestApi(PATCH, `/distribution_channels/${channelId}`, { description });
    dispatch(updateDistributionChannelInList(res));
    message.success(I18n.t("distributionUpdated"));
  } catch (e) {
    throw e;
  }
});

export const deleteDistributionChannelById = createAsyncThunk<
  void,
  {
    surveyId: string;
    channelId: number;
  }
>("distributions/deleteDistributionChannelById", async ({ surveyId, channelId }, { dispatch }) => {
  try {
    await requestApi(DELETE, `/distribution_channels/${channelId}`, {});
    dispatch(getDistributionChannelsBySurveyId(surveyId));
    message.success(I18n.t("distributionDeleted"));
  } catch (e) {
    throw e;
  }
});

export const setDistributionChannelList = createAsyncThunk<void, Array<DistributionChannel>>(
  "distributions/setDistributionChannelList",
  (distributionChannelList, { dispatch }) => {
    dispatch(setList(distributionChannelList));
  },
);

export const setCurrentDistributionChannel = createAsyncThunk<void, DistributionChannel | undefined>(
  "distributions/setCurrentDistributionChannel",
  (distributionChannel, { dispatch }) => {
    dispatch(setCurrent(distributionChannel));
  },
);

export const updateCurrentDistributionChannel = createAsyncThunk<void, Partial<DistributionChannel>>(
  "distributions/updateCurrentDistributionChannel",
  (distributionChannel, { dispatch }) => {
    dispatch(updateCurrent(distributionChannel));
  },
);

export const addDistributionChannelToList = createAsyncThunk<void, DistributionChannel>(
  "distributions/addDistributionChannelToList",
  (distributionChannel, { dispatch }) => {
    dispatch(addToList(distributionChannel));
  },
);

export const updateDistributionChannelInList = createAsyncThunk<void, DistributionChannel>(
  "distributions/updateDistributionChannelInList",
  (distributionChannel, { dispatch }) => {
    dispatch(updateInList(distributionChannel));
  },
);

export const updateDescriptionInList = createAsyncThunk<void, Pick<DistributionChannel, "channelId" | "description">>(
  "distributions/updateDescriptionInList",
  (data, { dispatch }) => {
    dispatch(updateDescriptionInList$(data));
  },
);

export const updateQrCodeInDistributionsList = createAsyncThunk<
  void,
  Pick<DistributionChannel, "channelId" | "qrCode">
>("distributions/updateQrCodeInDistributionsList", (data, { dispatch }) => {
  dispatch(updateQrCodeInDistributionsList$(data));
});

export const setDistributionChannelPagination = createAsyncThunk<void, Pageable>(
  "distributions/setDistributionChannelPagination",
  (pageable, { dispatch }) => {
    dispatch(setPageable(pageable));
  },
);
