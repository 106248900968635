import { createAsyncThunk } from "@reduxjs/toolkit";
import { WebhookType } from "../../../types/WebhookType";
import { requestApi } from "../../../utils/requestApi";
import { DELETE, GET, PUT } from "../../../types/httpMethod";
import { removeFromList, setCurrent, setList } from "./webhooksSlice";
import { message } from "antd";
import { I18n } from "react-redux-i18n";

type IdsType = {
  readonly surveyId: string;
  readonly webhookId: string;
};

export const getWebhooks = createAsyncThunk<void, string>("webhooks/getWebhooks", async (surveyId, { dispatch }) => {
  try {
    const res: Array<WebhookType> = await requestApi(GET, `/surveys/${surveyId}/webhooks`, {});
    dispatch(setList(res));
  } catch (e) {
    throw e;
  }
});

export const getWebhookById = createAsyncThunk<void, IdsType>(
  "webhook/getWebhookById",
  async ({ surveyId, webhookId }, { dispatch }) => {
    try {
      const res: WebhookType = await requestApi(GET, `/surveys/${surveyId}/webhooks/${webhookId}`, {});
      dispatch(setCurrent(res));
    } catch (e) {
      throw e;
    }
  },
);

export const updateWebhook = createAsyncThunk<void, IdsType & { readonly webhook: WebhookType }>(
  "webhook/updateWebhook",
  async ({ surveyId, webhookId, webhook }, { dispatch }) => {
    try {
      await requestApi(PUT, `/surveys/${surveyId}/webhooks/${webhookId}`, webhook);
      dispatch(setCurrent(webhook));
      message.success(I18n.t("changesSaved"));
    } catch (e) {
      console.log(e);
    }
  },
);

export const deleteWebhook = createAsyncThunk<void, IdsType>(
  "webhook/deleteWebhook",
  async ({ surveyId, webhookId }, { dispatch }) => {
    try {
      await requestApi(DELETE, `/surveys/${surveyId}/webhooks/${webhookId}`, {});
      dispatch(removeFromList(webhookId));
      message.success(I18n.t("webhookDeleted"));
    } catch (e) {
      console.log(e);
    }
  },
);

export const setCurrentWebhook = createAsyncThunk<void, WebhookType | undefined>(
  "webhook/setCurrentWebhook",
  async (webhook, { dispatch }) => {
    dispatch(setCurrent(webhook));
  },
);

export const setWebhookList = createAsyncThunk<void, Array<WebhookType>>(
  "webhook/setWebhookList",
  async (webhooks, { dispatch }) => {
    dispatch(setList(webhooks));
  },
);
