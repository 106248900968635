import "./index.scss";
import React, { FC } from "react";
import { MoreOutlined } from "@ant-design/icons";
import { SurveyType } from "../../types/surveyType";
import { Popover } from "antd";
import { useNavigate } from "react-router-dom";
import { content } from "./content";
import { useActions } from "../../hooks/useActions";
import { SURVEYS_PAGE } from "../../utils/paths";
import { useRequests } from "../../hooks/useRequests";

const SurveyTableActionsComponent: FC<{
  readonly survey: SurveyType;
}> = ({ survey }) => {
  const navigate = useNavigate();
  const { activateSurvey, deactivateSurvey, duplicateSurvey } = useActions();
  const { deleteSurvey } = useRequests();

  const _content = content({
    survey,
    onActivate: () => activateSurvey(survey?.surveyId),
    onDeactivate: () => deactivateSurvey(survey?.surveyId),
    onDelete: () => deleteSurvey(survey?.surveyId),
    onDuplicate: () => duplicateSurvey(survey?.surveyId),
    onEdit: () => navigate(`${SURVEYS_PAGE}/${survey?.surveyId}/edit`),
  });

  return (
    <div>
      <Popover trigger="click" placement="left" content={_content} overlayInnerStyle={{ padding: 0 }}>
        <MoreOutlined className={"surveys-table-actions-button"} />
      </Popover>
    </div>
  );
};

export default SurveyTableActionsComponent;
