import { FC, useCallback, useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import StatisticsLayout from "../../../../../../components/statisticsLayout";
import GeneralStatisticsComponent from "../../../../../../components/generalStatisticsComponent";
import { requestApi } from "../../../../../../utils/requestApi";
import { POST } from "../../../../../../types/httpMethod";
import { useParams } from "react-router-dom";
import { GeneralStatisticType } from "../../../../../../types/generalStatisticType";
import { convertListStatisticsToObj } from "../helpers";

type Props = {
  readonly showTitle?: boolean;
  readonly bordered?: boolean;
  readonly questionsQuantity?: number;
};

const GeneralStatistics: FC<Props> = ({ showTitle, bordered = true, questionsQuantity }) => {
  const { id: surveyId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<GeneralStatisticType | undefined>();

  const loadData = useCallback(() => {
    setLoading(true);
    requestApi(POST, "/chart/total-statistics", {}, false, false, {}, { surveyId })
      .then((res) => setData(convertListStatisticsToObj(res)))
      .finally(() => setLoading(false));
  }, [surveyId]);

  useEffect(() => {
    loadData();
  }, []);

  if (!bordered) {
    return <GeneralStatisticsComponent data={data} loading={isLoading} questionsQuantity={questionsQuantity} />;
  }

  return (
    <StatisticsLayout title={!showTitle ? undefined : I18n.t("generalStatistics")} padding={"40px 60px"}>
      <GeneralStatisticsComponent data={data} loading={isLoading} />
    </StatisticsLayout>
  );
};

export default GeneralStatistics;
