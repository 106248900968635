import React from "react";
import { Navigate, Route } from "react-router-dom";
import { SettingsPageTabsEnum } from "../../types/settingsPageTabsEnum";
import ApiKeysComponent from "./apiKeys";
import OffDaysComponent from "./offDays";
import EmailProvidersComponent from "./emailProviders";
import SmsProvidersComponent from "./smsProviders";
import ContactsSettings from "./contactsSettings";
import SubUsersPage from "./subUsers";
// import LanguagesComponent from "./languages";

export const SettingsRoutes = (
  <>
    <Route element={<Navigate to={SettingsPageTabsEnum.API_KEYS} />} index />
    <Route path={SettingsPageTabsEnum.API_KEYS} element={<ApiKeysComponent />} />
    <Route path={SettingsPageTabsEnum.OFF_DAYS} element={<OffDaysComponent />} />
    {/*<Route path={SettingsPageTabsEnum.LANGUAGES} element={<LanguagesComponent />} />*/}
    <Route path={SettingsPageTabsEnum.EMAIL_PROVIDERS} element={<EmailProvidersComponent />} />
    <Route path={SettingsPageTabsEnum.SMS_PROVIDERS} element={<SmsProvidersComponent />} />
    <Route path={SettingsPageTabsEnum.CONTACTS_SETTINGS} element={<ContactsSettings />} />
    <Route path={SettingsPageTabsEnum.USERS} element={<SubUsersPage />} />
  </>
);
