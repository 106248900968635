import { requestApi } from "../../../utils/requestApi";
import { POST } from "../../../types/httpMethod";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import { APP_PREFIX } from "../../../utils";

export const validateOtpAndVerify = (code: string, email: string): Promise<unknown> => {
  return new Promise<unknown>(async (resolve, reject) => {
    try {
      const res = await requestApi(POST, "/users/code/validate", {}, false, true, undefined, {
        code,
        email,
      });

      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

export const passwordReset = (token: string, pass: string) => {
  return new Promise<unknown>(async (resolve, reject) => {
    try {
      const res = await requestApi(POST, "/users/password/reset", {}, false, true, undefined, { token, pass });
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

export const sendResetPasswordEmail = (email: string) => {
  return new Promise<string>(async (resolve, reject) => {
    try {
      await requestApi(POST, "/users/password/forgot", {}, false, true, undefined, { email });
      message.success(I18n.t("checkYourMailMessage"));
      resolve(`${APP_PREFIX}/login`);
    } catch (e) {
      reject(e);
    }
  });
};
