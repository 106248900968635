import React from "react";
import { I18n } from "react-redux-i18n";
import InputFormItemComponent from "../../../../components/form/items/input";
import SelectFormItemComponent from "../../../../components/form/items/select";
import { EmailProviderTypesEnum, SecurityEnum } from "../../../../types/EmailProviderType";
import HiddenValueFormItemComponent from "../../../../components/form/items/hiddenValue";

const securities = Object.values(SecurityEnum).map((it) => ({ value: it, label: it }));

const SmtpForm = () => {
  return (
    <>
      <InputFormItemComponent name={"description"} label={I18n.t("providerDescription")} />
      <InputFormItemComponent name={"smtpServer"} label={"SMTP Server"} />
      <InputFormItemComponent type={"number"} name={"port"} label={"Port"} />
      <InputFormItemComponent name={"username"} label={I18n.t("userName")} required={false} />
      <InputFormItemComponent name={"password"} label={I18n.t("password")} required={false} />
      <InputFormItemComponent name={"fromEmail"} label={"From"} />
      <SelectFormItemComponent required label={"Security"} values={securities} name={"securityType"} />
      <HiddenValueFormItemComponent name={"providerType"} value={EmailProviderTypesEnum.SMTP} />
      <HiddenValueFormItemComponent name={"providerId"} />
    </>
  );
};

export default SmtpForm;
