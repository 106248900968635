import { I18n } from "react-redux-i18n";

export type WebhookType = {
  authType?: AuthTypeEnum;
  callOn: CallOnEnum;
  credentials?: string;
  description?: string;
  requestBody?: string;
  requestFormat?: RequestFormatEnum;
  requestHeaders?: Record<string, string>;
  requestMethod?: RequestMethodEnum;
  requestUrl?: string;
  surveyId?: string;
  webhookId?: string;
};

export enum RequestFormatEnum {
  FORM = "FORM",
  JSON = "JSON",
  TXT = "TXT",
  XML = "XML",
}

export enum AuthTypeEnum {
  BASIC = "BASIC",
  BEARER = "BEARER",
  NONE = "NONE",
}

export enum CallOnEnum {
  SURVEY_END = "SURVEY_END",
  SURVEY_START = "SURVEY_START",
}

export enum RequestMethodEnum {
  POST = "POST",
  PUT = "PUT",
  GET = "GET",
}

export const authEnumTitles: Record<AuthTypeEnum, string> = {
  [AuthTypeEnum.BASIC]: "Basic",
  [AuthTypeEnum.BEARER]: "Bearer",
  [AuthTypeEnum.NONE]: "None",
};

export const callOnTitles = (): Record<CallOnEnum, string> => {
  return {
    [CallOnEnum.SURVEY_END]: I18n.t("surveyEnding"),
    [CallOnEnum.SURVEY_START]: I18n.t("surveyStarting"),
  };
};
