import "./index.scss";
import React, { FC, useCallback, useState } from "react";
import { Button } from "antd";
import IntegrationsTable from "./table";
import WebHookEditPage from "./edit";
import { Translate } from "react-redux-i18n";
import useSurveyDisabled from "../../../../../hooks/useSurveyDisabled";
import SourceConditionsComponent from "../../../../../components/sourceConditions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { SourceConditionType } from "../../../../../types/sourceConditionType";
import { useActions } from "../../../../../hooks/useActions";

const IntegrationsComponent: FC = () => {
  const [createFormVisible, setCreateFormVisible] = useState(false);
  const isDisabled = useSurveyDisabled();

  const handleAdd = useCallback(() => {
    setCreateFormVisible((prev) => !prev);
  }, []);

  return (
    <div className={"integrations-container"}>
      {createFormVisible ? (
        <WebHookEditPage changeVisible={handleAdd} />
      ) : (
        <>
          <TriggerSettings temporaryHideTriggerSettings />
          <div className={"create-webhook-btn"}>
            <h3>Webhooks</h3>
            <Button type={"primary"} onClick={handleAdd} disabled={isDisabled}>
              <Translate value={"createWebhook"} />
            </Button>
          </div>
          <IntegrationsTable changeVisible={handleAdd} />
        </>
      )}
    </div>
  );
};

export default IntegrationsComponent;

type TriggerSettingsProps = {
  temporaryHideTriggerSettings: boolean;
};

const TriggerSettings: FC<TriggerSettingsProps> = ({ temporaryHideTriggerSettings }) => {
  const { updateSurvey } = useActions();
  const survey = useSelector((state: RootState) => state.surveys.current);
  const sourceConditions: Array<SourceConditionType> = useSelector(
    (state: RootState) => state.surveys?.current?.sourceConditions || [],
  );

  const onSave = useCallback(() => {
    const filtered = sourceConditions.filter((it) => !!it.sourceConditions.length);
    if (survey?.surveyId && !!filtered.length) {
      updateSurvey({ survey: { ...survey, sourceConditions: filtered } });
    }
  }, [sourceConditions, survey]);

  return (
    <div>
      <div>
        <h3>Rest-API</h3>
        <div style={{ padding: "10px 20px" }}>
          <a
            href="https://surveysuite.notion.site/API-1b795d5ffe0d40c5b1766cb6a3a3cdcd?pvs=4"
            rel={"noreferrer"}
            target={"_blank"}
          >
            <Translate
              value={"documentation"}
              style={{ textDecoration: "underline", position: "relative", bottom: 5 }}
            />
          </a>
          <p>
            <Translate value={"integrationsDocHelpText"} />
          </p>
        </div>
      </div>
      {!temporaryHideTriggerSettings && (
        <div style={{ margin: "0.5em 0" }}>
          <h3>
            <Translate value={"triggerSurveySettings"} />
          </h3>
          <div style={{ padding: "10px 20px" }}>
            <SourceConditionsComponent />
          </div>
          {!!sourceConditions.length && (
            <Button type={"primary"} onClick={onSave}>
              <Translate value={"saveChanges"} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
