export type SubscriptionInfo = {
  readonly subscriptionPlan: SubscriptionPlan;
  readonly subscription: Subscription;
  readonly usage: Record<LimitsEnum, number>;
  readonly userId: string;
};

export type SubscriptionPlan = {
  readonly accessLevel: AccessLevelEnum;
  readonly currency: string;
  readonly description: string;
  readonly features: Record<string, boolean>;
  readonly interval: IntervalEnum;
  readonly limits: Record<LimitsEnum, number>;
  readonly name: string;
  readonly planId: string;
  readonly price: 0;
  readonly properties: Record<string, string>;
  readonly status: SubscriptionPlanStatusEnum;
  readonly trialPeriodDays: number;
};

export type Subscription = {
  readonly createdAt: string;
  readonly endDate: string;
  readonly externalId: string;
  readonly id: string;
  readonly lastPaymentAt: string;
  readonly lastStatusChangeAt: string;
  readonly paymentGateway: PaymentGatewayEnum;
  readonly paymentProps: PaymentProps;
  readonly planId: string;
  readonly startDate: string;
  readonly status: SubscriptionInfoStatusEnum;
  readonly trialEndDate: string;
  readonly trialStartDate: string;
  readonly userId: string;
};

export type PaymentProps = {
  readonly cardExpDate: string;
  readonly cardFirstSix: string;
  readonly cardLastFour: string;
  readonly cardType: string;
  readonly token: string;
};

export enum LimitsEnum {
  API_CALLS = "api_calls",
  CONTACTS = "contacts",
  EXTRA_USERS = "extra_users",
  SURVEY_INSTANCES = "survey_instances",
  SURVEYS = "surveys",
}

export enum PaymentGatewayEnum {
  PAYBOX = "PAYBOX",
  CLOUD_PAYMENTS = "CLOUD_PAYMENTS",
}

export enum SubscriptionInfoStatusEnum {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  INACTIVE = "INACTIVE",
  TRIAL = "TRIAL",
}

export enum AccessLevelEnum {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum IntervalEnum {
  Day = "Day",
  Month = "Month",
  Week = "Week",
  Year = "Year",
}

export enum SubscriptionPlanStatusEnum {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
}
