import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { OffDayType } from "../../../types/offDayType";
import { DELETE, GET, POST } from "../../../types/httpMethod";
import { addOffDayToList, removeOffDayFromList, setOffDaysList } from "./offDaysSlice";
import { message } from "antd";
import { I18n } from "react-redux-i18n";

export const getOffDays = createAsyncThunk<void, undefined>("offDays/getOffDays", async (_, { dispatch }) => {
  try {
    const res: Array<OffDayType> = await requestApi(GET, "/off-days", {});
    dispatch(setOffDaysList(res));
  } catch (e) {
    throw e;
  }
});

export const addOffDay = createAsyncThunk<void, OffDayType>("offDays/addOffDay", async (offDay, { dispatch }) => {
  try {
    const res: OffDayType = await requestApi(POST, "/off-days", offDay);
    dispatch(addOffDayToList(res));
    message.success(I18n.t("dateCreatedSuccessfully"));
  } catch (e) {
    console.log(e);
  }
});

export const deleteOffDay = createAsyncThunk<void, number>("offDays/deleteOffDay", async (id, { dispatch }) => {
  try {
    await requestApi(DELETE, `/off-days/${id}`, {});
    dispatch(removeOffDayFromList(id));
    message.success(I18n.t("dateDeleted"));
  } catch (e) {
    console.log(e);
  }
});
