import { FC } from "react";
import { Tag } from "antd";
import { surveyStatusInfoRu } from "../../types/statusType";
import { SurveyStatus } from "../../types/surveyType";

const StatusTagComponent: FC<{
  readonly status?: SurveyStatus;
}> = ({ status }) => {
  if (!status) {
    return <div>N/A</div>;
  }
  const backgroundColor = surveyStatusInfoRu()[status].backgroundColor;
  const textColor = surveyStatusInfoRu()[status].textColor;
  const label = surveyStatusInfoRu()[status].label;
  return (
    <Tag color={backgroundColor} style={{ width: 120, textAlign: "center", color: textColor }}>
      {label}
    </Tag>
  );
};

export default StatusTagComponent;
