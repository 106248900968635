export type SmsProviderType = {
  readonly providerId?: number;
  readonly description: string;
  readonly password: string;
  readonly providerType: SmsProviderTypesEnum;
  readonly sender: string;
  readonly status: SmsProviderStatus;
  readonly url: string;
  readonly userId: string;
  readonly username: string;
};

export enum SmsProviderStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum SmsProviderTypesEnum {
  KAZ_INFO_TECH = "kazinfoteh",
  DUMMY = "dummy",
}
