import { SubscriberCondition } from "../../types/subscriberConditionType";
import { FC, useCallback } from "react";
import useConditions from "../conditionsTag/ useConditions";
import ConditionForm from "./index";
import { Button } from "antd";
import { Translate } from "react-redux-i18n";
import { PlusOutlined } from "@ant-design/icons";

type Props = {
  readonly onAdd: (condition: SubscriberCondition, groupId?: number) => void;
};
const ConditionInline: FC<Props> = (props) => {
  const { onAdd } = props;
  const state = useConditions(onAdd);
  const handleAdd = useCallback(state.onSave, [state]);

  return (
    <div style={{ display: "flex" }}>
      <ConditionForm {...state} layout={"horizontal"} noStyle />
      <Button icon={<PlusOutlined />} onClick={handleAdd} type={"primary"} htmlType={"button"}>
        <Translate value={"add"} />
      </Button>
    </div>
  );
};

export default ConditionInline;
