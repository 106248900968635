import "./index.scss";
import React, { FC, useCallback, useEffect, useState } from "react";
import { I18n, Translate } from "react-redux-i18n";
import { Button, message } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ManuallyAddFormDrawer, UploadModal } from "./helpers";
import { isContactsCreatingAvailable } from "../../utils/appLimits";
import { useNavigate } from "react-router-dom";
import { APP_PREFIX } from "../../utils";
import { SettingsPageTabsEnum } from "../../types/settingsPageTabsEnum";
import Confirm from "../../components/confirm";

const Filters: FC = () => {
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [manuallyAddFormVisible, setManuallyAddFormVisible] = useState(false);
  const currentContact = useSelector((state: RootState) => state.contacts.current);
  const isCreatingAvailable = isContactsCreatingAvailable();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!currentContact && !manuallyAddFormVisible) {
      setManuallyAddFormVisible(true);
    }
  }, [currentContact, manuallyAddFormVisible]);

  const onNewAttribute = useCallback(() => {
    navigate(`${APP_PREFIX}/settings/${SettingsPageTabsEnum.CONTACTS_SETTINGS}`);
  }, [navigate]);

  return (
    <>
      <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", gap: 10, marginBottom: 24 }}>
        <h3 style={{ marginRight: "auto", fontWeight: 600 }}>
          <Translate value={"contacts"} />
        </h3>
        <Confirm
          success
          type={"primary"}
          onConfirm={onNewAttribute}
          action={"newAttribute"}
          title={"areYouSureYouWantToLeaveEditPage"}
        />
        <Button
          type={"primary"}
          onClick={() => {
            if (!isCreatingAvailable) {
              return message.error(I18n.t("youHaveReachedMaxContacts"));
            }
            setManuallyAddFormVisible(true);
          }}
        >
          <Translate value={"addContact"} />
        </Button>
        <Button
          type={"primary"}
          onClick={() => {
            if (!isCreatingAvailable) {
              return message.error(I18n.t("youHaveReachedMaxContacts"));
            }
            setUploadModalVisible(true);
          }}
        >
          <Translate value={"addFromFile"} />
        </Button>
      </div>
      {uploadModalVisible && isCreatingAvailable && <UploadModal open onClose={() => setUploadModalVisible(false)} />}
      <ManuallyAddFormDrawer
        open={manuallyAddFormVisible && isCreatingAvailable}
        onClose={() => setManuallyAddFormVisible(false)}
      />
    </>
  );
};

export default Filters;
