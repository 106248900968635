import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setAppLanguage,
  setAuthStep,
  setSurveysEditPageActiveStep,
  setSurveysPagePageable,
  updateSurveysPageFilters,
} from "./persistedSlice";
import { StepsEnum } from "../../../types/surveyEditTabsEnum";
import { Pageable } from "../../../types/pageable";
import { Language } from "../../../types/language";
import { setLocale } from "react-redux-i18n";
import { AuthStep } from "../../../types/authStep";

export const setSurveyEditActiveStep = createAsyncThunk<void, StepsEnum | undefined>(
  "persisted/setSurveyEditActiveStep",
  (key, { dispatch }) => {
    dispatch(setSurveysEditPageActiveStep(key));
  },
);

export const setSurveysPageable = createAsyncThunk<void, Pageable>(
  "persisted/setSurveysPageable",
  (pageable, { dispatch }) => {
    dispatch(setSurveysPagePageable(pageable));
  },
);

export const updateSurveyFilters = createAsyncThunk<void, Record<string, any>>(
  "persisted/updateSurveyFilters",
  (filters, { dispatch }) => {
    dispatch(updateSurveysPageFilters(filters));
  },
);

export const setLanguage = createAsyncThunk<void, Language>("persisted/setLanguage", (language, { dispatch }) => {
  dispatch(setAppLanguage(language));
  dispatch(setLocale(language));
});

export const setUserAuthStep = createAsyncThunk<void, AuthStep>("persisted/setLanguage", (step, { dispatch }) => {
  dispatch(setAuthStep(step));
});
