import { createAsyncThunk } from "@reduxjs/toolkit";
import { requestApi } from "../../../utils/requestApi";
import { DELETE, GET, POST, PUT } from "../../../types/httpMethod";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import {
  addToAttributesList,
  addToList,
  removeFromAttributesList,
  removeFromList,
  setAttributesList,
  setCurrent,
  setList,
  updateInAttributesList,
  updateInList,
} from "./contactsSlice";
import { ContactCreateType, ContactType } from "../../../types/contactType";
import { RcFile } from "antd/es/upload";
import { FileFormat } from "../../../types/fileFormatEnum";
import axios from "axios";
import { ContactsAttributeCreateType, ContactsAttributeType } from "../../../types/ContactsAttributeType";
import store from "../../index";

export const getContacts = createAsyncThunk<void, undefined>("contacts/getContacts", async (_, { dispatch }) => {
  try {
    const res = await requestApi(GET, "/contact", {});
    dispatch(setContactsData(res));
  } catch (e) {
    throw e;
  }
});

export const deleteContact = createAsyncThunk<void, string>(
  "contacts/deleteContact",
  async (contactId, { dispatch }) => {
    try {
      await requestApi(DELETE, `/contact/${contactId}`, {});
      dispatch(removeContactFromList(contactId));
      message.success(I18n.t("contactDeleted"));
    } catch (e) {
      throw e;
    }
  },
);

export const createContact = createAsyncThunk<void, ContactCreateType>(
  "contacts/createContact",
  async (contact, { dispatch }) => {
    try {
      const res = await requestApi(POST, "/contact", contact);
      dispatch(addContactToList(res));
      message.success(I18n.t("contactCreated"));
    } catch (e) {
      throw e;
    }
  },
);

export const updateContact = createAsyncThunk<
  void,
  {
    readonly contactId: string;
    readonly values: ContactType;
  }
>("contacts/updateContact", async ({ contactId, values }, { dispatch }) => {
  try {
    const res = await requestApi(PUT, `/contact/${contactId}`, values);
    dispatch(updateContactInList(res));
    message.success(I18n.t("contactUpdated"));
  } catch (e) {
    throw e;
  }
});

export const uploadContactsFile = async (format: FileFormat, file: RcFile) => {
  const token = store.getState().session.jwtToken;
  if (!token) return;

  const formData = new FormData();
  formData.append("file", file);
  const url = `${window._env_.API_BASE_URL + window._env_.API_PATH}/contact/upload/${format}`;

  try {
    const response = await axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error: any) {
    console.error("Error uploading contacts file:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    }
    throw error;
  }
};

export const setContactsData = createAsyncThunk<void, Array<ContactType>>(
  "contacts/setContactsData",
  (data, { dispatch }) => {
    dispatch(setList(data));
  },
);

export const updateContactInList = createAsyncThunk<void, ContactType>(
  "contacts/updateContactInList",
  (data, { dispatch }) => {
    dispatch(updateInList(data));
  },
);

export const setCurrentContact = createAsyncThunk<void, ContactType | undefined>(
  "contacts/setCurrentContact",
  (data, { dispatch }) => {
    dispatch(setCurrent(data));
  },
);

export const addContactToList = createAsyncThunk<void, ContactType>(
  "contacts/addContactToList",
  (data, { dispatch }) => {
    dispatch(addToList(data));
  },
);

export const removeContactFromList = createAsyncThunk<void, string>(
  "contacts/removeContactFromList",
  (contactId, { dispatch }) => {
    dispatch(removeFromList(contactId));
  },
);

/** Contact Properties */

export const getAttributes = createAsyncThunk<void, undefined>("contacts/getAttributes", async (_, { dispatch }) => {
  try {
    const res = await requestApi(GET, "/contact-property", {});
    dispatch(setAttributesData(res));
  } catch (e) {
    throw e;
  }
});

export const deleteAttribute = createAsyncThunk<void, string>(
  "contacts/deleteAttribute",
  async (attributeId, { dispatch }) => {
    try {
      await requestApi(DELETE, `/contact-property/${attributeId}`, {});
      dispatch(removeAttribute(attributeId));
      message.success(I18n.t("attributeDeleted"));
    } catch (e) {
      throw e;
    }
  },
);

export const createAttribute = createAsyncThunk<void, ContactsAttributeCreateType>(
  "contacts/createAttribute",
  async (attribute, { dispatch }) => {
    try {
      const res = await requestApi(POST, "/contact-property", attribute);
      dispatch(addAttribute(res));
      message.success(I18n.t("attributeCreated"));
    } catch (e) {
      throw e;
    }
  },
);

export const updateAttribute = createAsyncThunk<
  void,
  {
    readonly attributeId: string;
    readonly values: ContactsAttributeType;
  }
>("contacts/updateAttribute", async ({ attributeId, values }, { dispatch }) => {
  try {
    const res = await requestApi(PUT, `/contact-property/${attributeId}`, values);
    dispatch(updateAttributeInList(res));
    message.success(I18n.t("attributeUpdated"));
  } catch (e) {
    throw e;
  }
});

export const setAttributesData = createAsyncThunk<void, Array<ContactsAttributeType>>(
  "contacts/setAttributesData",
  (data, { dispatch }) => {
    dispatch(setAttributesList(data));
  },
);

export const updateAttributeInList = createAsyncThunk<void, ContactsAttributeType>(
  "contacts/updateAttribute",
  (data, { dispatch }) => {
    dispatch(updateInAttributesList(data));
  },
);

export const addAttribute = createAsyncThunk<void, ContactsAttributeType>(
  "contacts/addAttribute",
  (data, { dispatch }) => {
    dispatch(addToAttributesList(data));
  },
);

export const removeAttribute = createAsyncThunk<void, string>("contacts/removeAttribute", (data, { dispatch }) => {
  dispatch(removeFromAttributesList(data));
});
