import "./index.scss";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Form, message, Modal, Popconfirm, Table } from "antd";
import { useSelector } from "react-redux";
import store, { RootState } from "../../../../store";
import { ColumnsType } from "antd/es/table";
import { useActions } from "../../../../hooks/useActions";
import { renderNullableText } from "../../../../utils/renderNullableText";
import { I18n, Translate } from "react-redux-i18n";
import { EmailProviderType, EmailProviderTypesEnum } from "../../../../types/EmailProviderType";
import { deleteEmailProvider } from "../../../../store/slices/emailProviders/emailProvidersService";
import SmtpForm from "../helpers/smtpForm";
import PostmarkForm from "../helpers/postmarkForm";
import FooterActions from "../../../../components/FooterActions";

const columns = (handleChange: (value: EmailProviderType) => void): ColumnsType<EmailProviderType> => [
  {
    title: I18n.t("providerDescription"),
    dataIndex: "description",
    key: "description",
    render: renderNullableText,
  },
  {
    title: I18n.t("provider"),
    dataIndex: "providerType",
    key: "providerType",
    render: renderNullableText,
  },
  {
    title: I18n.t("actions"),
    key: "action",
    render: (_, record) => {
      return (
        <div style={{ display: "flex" }}>
          <Button onClick={() => handleChange(record)} type={"link"}>
            <Translate value={"change"} />
          </Button>
          <Popconfirm
            title={I18n.t("deleteEmailProviderConfirmation")}
            onConfirm={() => emailProviderDeleteHandler(record?.providerId)}
          >
            <Button danger type={"link"}>
              <Translate value={"delete"} />
            </Button>
          </Popconfirm>
        </div>
      );
    },
  },
];

function emailProviderDeleteHandler(id?: number) {
  if (!id) return;
  store.dispatch(deleteEmailProvider(id));
}

const EmailProvidersTableComponent: FC = () => {
  const data = useSelector((state: RootState) => state.emailProviders.list);
  const { getEmailProviders, updateEmailProvider } = useActions();
  const [current, setCurrent] = useState<EmailProviderType | undefined>();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<EmailProviderType>();

  const changeVisible = useCallback(() => {
    setOpen((prev) => !prev);
  }, [setOpen]);

  useEffect(() => {
    getEmailProviders();
  }, []);

  const handleEditClick = useCallback(
    (value: EmailProviderType) => {
      setCurrent(value);
      changeVisible();
      form.setFieldsValue(value);
    },
    [setCurrent, changeVisible],
  );

  const handleCancel = useCallback(() => {
    setCurrent(undefined);
    setOpen(false);
    form.resetFields();
  }, [setCurrent, setOpen, form]);

  const handleSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        if (current?.providerId) {
          updateEmailProvider({ emailProvidersId: current.providerId, values });
        }
        handleCancel();
      })
      .catch(() => message.error(I18n.t("fillRequiredFields")));
  }, [form, handleCancel, current]);

  return (
    <>
      <Table
        pagination={{
          size: "small",
          showSizeChanger: true,
          defaultPageSize: 10,
        }}
        dataSource={data}
        columns={columns(handleEditClick)}
        rowKey={(record: EmailProviderType) => record?.providerId + ""}
      />
      <Modal
        open={open}
        onCancel={handleCancel}
        title={I18n.t(`change${current?.providerType}Provider`)}
        footer={<FooterActions onCancel={handleCancel} onSubmit={handleSubmit} submitLabelTranslationKey={"save"} />}
      >
        <Form form={form} layout={"vertical"} style={{ marginTop: 20 }}>
          {current?.providerType === EmailProviderTypesEnum.SMTP ? <SmtpForm /> : <PostmarkForm />}
        </Form>
      </Modal>
    </>
  );
};

export default EmailProvidersTableComponent;
