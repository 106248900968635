import { ColumnsType } from "antd/es/table";
import { SurveyType } from "../../../types/surveyType";
import { getSortOrder } from "../../../utils/getSortOrder";
import SurveyNameComponent from "../../../components/surveyName";
import SurveyTableActionsComponent from "../../../components/surveyTableActions";
import React from "react";
import { StatusEnum } from "../../../types/statusType";
import { I18n, Translate } from "react-redux-i18n";
import StatusTagComponent from "../../../components/statusTag";
import { TEXT_LIGHT_COLOR } from "../../../utils";
import { convertDateToCurrentTimezone } from "../../../utils/timezones";

export const columns = (onStatus: (value: StatusEnum | undefined) => void, sort?: string): ColumnsType<SurveyType> => {
  return [
    {
      title: `${I18n.t("surveyName")}, ${I18n.t("createDate")}`,
      key: "created_at",
      sorter: true,
      defaultSortOrder: getSortOrder("created_at", sort),
      render: (_, record) => (
        <SurveyNameComponent
          id={record?.surveyId}
          status={record?.status}
          surveyName={record?.surveyName}
          createdAt={convertDateToCurrentTimezone(String(record?.createdAt))}
        />
      ),
    },
    // {
    //   title: I18n.t("type"),
    //   dataIndex: "communicationChannel",
    //   key: "communication_channel",
    //   sorter: true,
    //   defaultSortOrder: getSortOrder("communication_channel", sort),
    //   render: (_, record) => channelEnumLabelRu()[record.communicationChannel],
    // },
    {
      title: I18n.t("questionsQuantity"),
      key: "questionsQuantity",
      width: 10,
      render: (record: SurveyType) => (record.questions.length ?? 1) - 1,
    },
    {
      key: "tags",
      width: 300,
      title: I18n.t("tags"),
      render: (record: SurveyType) => (
        <div>
          {!!record?.tags?.length ? (
            record.tags.map((tag, i) => (
              <span key={tag}>
                {tag}
                <span style={{ color: TEXT_LIGHT_COLOR }}>{record?.tags?.length - 1 === i ? "" : " | "}</span>
              </span>
            ))
          ) : (
            <Translate style={{ color: TEXT_LIGHT_COLOR }} value={"noData"} />
          )}
        </div>
      ),
    },
    {
      key: "languages",
      width: 300,
      title: I18n.t("languages"),
      render: (record: SurveyType) => (
        <div>
          {!!record?.languages?.length ? (
            record.languages.map((lang, i) => (
              <span key={lang.langCode}>
                {lang.langName}
                <span style={{ color: TEXT_LIGHT_COLOR }}>{record?.languages?.length - 1 === i ? "" : " | "}</span>
              </span>
            ))
          ) : (
            <Translate style={{ color: TEXT_LIGHT_COLOR }} value={"noData"} />
          )}
        </div>
      ),
    },
    {
      title: I18n.t("status"),
      dataIndex: "status",
      key: "status",
      render: (_, record) => <StatusTagComponent status={record?.status} />,
    },
    {
      key: "actions",
      render: (_, record) => <SurveyTableActionsComponent survey={record} />,
    },
  ];
};
