export enum RoutePaths {
  LOGIN = "login",
  FORGOT_PASSWORD = "forgot-password",
  RESET_PASSWORD = "reset-password",
  REGISTRATION = "registration",
  VALIDATION = "validation",
  SETTINGS = "settings",
  SURVEYS = "surveys",
  CONTACTS = "contacts",
  CREATE_SURVEY = "create",
  AI_CREATE_SURVEY = "ai/create",
  EDIT_SURVEY = ":id/edit",
  ACCOUNT_SETTINGS = "account-settings",
  ACCOUNT_SETTINGS_TARIFF = "account-settings/plans",
  ACCOUNT_SETTINGS_TARIFF_SELECTED = "account-settings/plans/:tariffId/:methodName",
}
