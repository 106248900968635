import InputFormItemComponent from "../../../components/form/items/input";
import { I18n } from "react-redux-i18n";
import HiddenValueFormItemComponent from "../../../components/form/items/hiddenValue";
import React from "react";
import SelectFormItemComponent from "../../../components/form/items/select";
import { roleToLabel, UserRolesEnum } from "../../../types/userRoles";
import { Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import EmailFormItemComponent from "../../../components/form/items/email";

export const CreateSubUserFormItems = () => {
  return (
    <>
      <InputFormItemComponent required name={"fullName"} label={I18n.t("fullName")} addonBefore={<UserOutlined />} />
      <SelectFormItemComponent
        values={Object.values(UserRolesEnum).map((role) => ({ value: role, label: roleToLabel(role) }))}
        label={I18n.t("role")}
        name={"roles"}
        mode={"multiple"}
        required
      />
      <EmailFormItemComponent />
      <Form.Item
        name={"password"}
        label={I18n.t("password")}
        rules={[
          {
            required: true,
            message: I18n.t("enterPassword"),
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder={I18n.t("enterPassword")} addonBefore={<LockOutlined />} />
      </Form.Item>
      <HiddenValueFormItemComponent name={"id"} />
    </>
  );
};

export const PasswordResetFormItems = () => {
  return (
    <>
      <Form.Item
        name={"newPassword"}
        label={I18n.t("newPassword")}
        rules={[
          {
            required: true,
            message: I18n.t("enterNewPassword"),
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder={I18n.t("enterNewPassword")} />
      </Form.Item>

      <Form.Item
        label={I18n.t("confirmPassword")}
        dependencies={["newPassword"]}
        name={"confirm"}
        hasFeedback
        rules={[
          {
            required: true,
            message: I18n.t("confirmPassword"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("newPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(I18n.t("passwordsNotEqual")));
            },
          }),
        ]}
      >
        <Input.Password placeholder={I18n.t("enterNewPassword")} />
      </Form.Item>
      <HiddenValueFormItemComponent name={"subUserId"} />
    </>
  );
};
