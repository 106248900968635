import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RoutePaths } from "./paths";
import CodeValidationPage from "../pages/auth/codeValidation";
import { APP_PREFIX } from "../utils";

const PublicRoutes: FC = () => {
  return (
    <Routes>
      <Route path={APP_PREFIX}>
        <Route path="" element={<Navigate to={RoutePaths.VALIDATION} />} />
        <Route path={RoutePaths.VALIDATION} element={<CodeValidationPage />} />
      </Route>
      <Route path={"*"} element={<Navigate to={`${APP_PREFIX}/${RoutePaths.VALIDATION}`} />} />
    </Routes>
  );
};

export default PublicRoutes;
