import React, { FC } from "react";
import { AppProviders } from "./hooks/providers";
import AppRoutes from "./routes";

const Application: FC = () => {
  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
};

export default Application;
