import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SmsProviderType } from "../../../types/smsProvider";

type StateType = {
  list: Array<SmsProviderType>;
  current?: SmsProviderType;
};
const initialState: StateType = {
  list: [],
  current: undefined,
};

const smsProvidersSlice = createSlice({
  name: "smsProviders",
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<Array<SmsProviderType>>) {
      state.list = action.payload;
    },
    removeFromList(state: StateType, action: PayloadAction<number>) {
      state.list = state.list.filter((it) => it?.providerId !== action.payload);
    },
    addToList(state: StateType, action: PayloadAction<SmsProviderType>) {
      state.list = [action.payload, ...state.list];
    },
    setCurrent(state: StateType, action: PayloadAction<SmsProviderType | undefined>) {
      state.current = action.payload;
    },
    updateInList(state: StateType, action: PayloadAction<SmsProviderType | undefined>) {
      state.list = state.list.map(
        (it) => (it.providerId === action.payload?.providerId ? { ...it, ...action.payload } : it) as SmsProviderType,
      );
    },
  },
});

export const { setList, removeFromList, setCurrent, addToList, updateInList } = smsProvidersSlice.actions;
export default smsProvidersSlice.reducer;
