import "./index.scss";
import { FC, useCallback } from "react";
import { CaretDownOutlined, UserOutlined } from "@ant-design/icons";
import { SURVEYS_PAGE } from "../../utils/paths";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown } from "antd";
import PasswordChange from "./passwordChange";
import { HeaderLinkItem, headerLinksList, useHeadersPageProps } from "./helpers";
import { useAccessTokenObj } from "../../utils/jwt";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useTourCtx } from "../../hooks/providers/tours/TourProvider";
import { TourItemsEnum, WelcomeTourItemsEnum } from "../../types/tour";
import TrialAlert from "./TrialAlert";

const HeaderComponent: FC = () => {
  const {
    data: {
      [TourItemsEnum.WELCOME]: { refs },
    },
    tourInProgress,
  } = useTourCtx();
  const navigate = useNavigate();
  const location = useLocation();
  const { menu, showPasswordChange, setShowPasswordChange } = useHeadersPageProps();
  const tokenObj = useAccessTokenObj();

  const fullName = useSelector((state: RootState) => {
    const user$ = state.users.userExtendedData;

    if (user$?.firstName && user$?.lastName) {
      return user$?.firstName.concat(" ", user$?.lastName);
    }
    return "N/A";
  });

  const loggedIn = Boolean(tokenObj);

  const navigateToMainPage = useCallback(() => {
    if (loggedIn && !location.pathname.endsWith("/surveys")) {
      navigate(SURVEYS_PAGE);
    }
  }, [loggedIn, location]);

  return (
    <div className={`app-header ${!loggedIn ? "unauthorized" : ""}`}>
      <div className="wrapper">
        <div className="left-side" />
        <div className="main-container">
          <div className="header">
            <div
              className="logo unselectable"
              onClick={navigateToMainPage}
              style={{
                flexDirection: "column",
                alignItems: "flex-end",
                lineHeight: 0,
              }}
            >
              <img src={"/assets/icons/fullLogo.svg"} alt="logo" width={200} />
              {/*<small style={{ color: DEFAULT_COLOR, wordSpacing: -1 }}>powered by AI</small>*/}
            </div>
            <div className={"box"}>
              {loggedIn && (
                <>
                  {headerLinksList.map((it, i) => (
                    <div key={i} ref={refs[it.tourKey]}>
                      <HeaderLinkItem item={it} disabled={tourInProgress} />
                    </div>
                  ))}
                  <div className="user-info">
                    <Dropdown menu={menu} trigger={["click"]}>
                      <Button className="button" ref={refs[WelcomeTourItemsEnum.MY_PROFILE]}>
                        <UserOutlined />
                        <div className="user-name">{fullName}</div>
                        <CaretDownOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                </>
              )}
              <PasswordChange show={showPasswordChange} setShow={setShowPasswordChange} />
            </div>
          </div>
        </div>
      </div>
      <TrialAlert />
    </div>
  );
};

export default HeaderComponent;
