import { FC } from "react";
import Title from "./components/title";
import ShareButtons from "./shareButtons";
import ShareChannelsTable from "./shareChannelsTable";
import { I18n } from "react-redux-i18n";

const SharePage: FC = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Title value={I18n.t("share")} />
      <ShareButtons />
      <Title value={I18n.t("distributionChannelsList")} />
      <ShareChannelsTable />
    </div>
  );
};

export default SharePage;
