import React, { FC, ReactNode } from "react";
import { Button, Popconfirm, Tooltip } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { I18n } from "react-redux-i18n";

const ActionButton: FC<{
  readonly label: string;
  readonly danger?: boolean;
  readonly action?: () => void;
  readonly showPlus?: boolean;
  readonly disabled?: boolean;
  readonly plusIcon?: ReactNode;
  readonly minusIcon?: ReactNode;
  readonly tooltipLabel?: ReactNode | string;
  readonly style?: Record<string, string | number>;
  readonly hideIcon?: boolean;
  readonly confirmInfo?: {
    readonly onConfirm: () => void;
    readonly label: string;
    readonly okLabel?: string;
  };
}> = ({
  label,
  danger,
  action,
  showPlus,
  disabled = false,
  plusIcon = <PlusOutlined />,
  minusIcon = <MinusOutlined />,
  tooltipLabel,
  style,
  confirmInfo,
  hideIcon = false,
}) => {
  const btn = (
    <Button
      type={"text"}
      style={style}
      danger={danger}
      onClick={action}
      disabled={disabled}
    >
      {!hideIcon ? (
        <span>
          {showPlus ? plusIcon : minusIcon}
          {label}
        </span>
      ) : (
        <span>{label}</span>
      )}
    </Button>
  );

  const tooltip = (
    <Tooltip
      overlayInnerStyle={{
        borderRadius: 5,
        backgroundColor: "rgb(109,107,107)",
      }}
      title={tooltipLabel}
    >
      {btn}
    </Tooltip>
  );

  const confirm = (
    <Popconfirm
      title={confirmInfo?.label || I18n.t("areYouSure")}
      onConfirm={confirmInfo?.onConfirm}
      okText={confirmInfo?.okLabel}
      disabled={disabled}
    >
      {tooltipLabel ? tooltip : btn}
    </Popconfirm>
  );

  return confirmInfo ? confirm : tooltipLabel ? tooltip : btn;
};

export default ActionButton;
