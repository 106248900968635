export type QrFormType = {
  readonly size: string;
  readonly style: QrStylesEnum;
  readonly color: string;
  readonly logoUrl?: string;
  readonly fileType: FileTypesEnum;
  readonly description?: string;
};

export enum QrStylesEnum {
  STANDARD = "standard",
  ROUNDED = "rounded",
  DOTS = "dots",
}

export enum FileTypesEnum {
  PNG = "png",
  JPEG = "jpeg",
}

export const getSurveyInstanceUrl = (surveyId: string, receiver: string) => {
  return window._env_.WEB_SURVEY_BASE_URL.concat("/", surveyId, "?receiver=", receiver);
};
