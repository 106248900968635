import { FileFormat } from "../types/fileFormatEnum";
import { message } from "antd";
import { I18n } from "react-redux-i18n";

export const downloadFile = (file: any, fileName: string, fileFormat: FileFormat) => {
  const href = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${fileName}.${fileFormat}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
  message.success(I18n.t("fileDownloaded"));
};

export const downloadFileUsingBlob = (blobUrl: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = blobUrl;
  link.setAttribute("download", fileName.concat(".png"));
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
