export type QuestionMediaFile = {
  readonly contentSize: number;
  readonly contentType: QuestionMediaFileTypesEnum;
  readonly url: string;
};

export type AddMediaToQuestionResponseType = QuestionMediaFile;

export enum QuestionMediaFileTypesEnum {
  GIF = "GIF",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}
