import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DistributionStatus, DistributionType } from "../../../types/distributionType";
import { sortDistributions } from "../../../utils/sortDistribution";

type StateType = {
  list: Array<DistributionType>;
  current?: DistributionType;
};

const initialState: StateType = {
  list: [],
  current: undefined,
};

const distributionsSlice = createSlice({
  name: "distributions",
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<Array<DistributionType>>) {
      state.list = sortDistributions(action.payload);
    },
    removeFromList(state: StateType, action: PayloadAction<string>) {
      state.list = state.list.filter((it) => it?.distributionId !== action.payload);
    },
    addToList(state: StateType, action: PayloadAction<DistributionType>) {
      state.list = sortDistributions([action.payload, ...state.list]);
    },
    setCurrent(state: StateType, action: PayloadAction<DistributionType | undefined>) {
      state.current = action.payload;
    },
    updateCurrent(state: StateType, action: PayloadAction<Partial<DistributionType>>) {
      if (action?.payload) {
        state.current = {
          ...state.current,
          ...action.payload,
        } as DistributionType;
      }
    },
    updateInList(state: StateType, action: PayloadAction<DistributionType>) {
      state.list = state.list.map((it) =>
        it?.distributionId === action.payload?.distributionId ? { ...it, ...action.payload } : it,
      );
    },
    updateStatusInList(
      state: StateType,
      action: PayloadAction<{ distributionId: string; status: DistributionStatus }>,
    ) {
      state.list = state.list.map((it) =>
        it?.distributionId === action.payload.distributionId ? { ...it, status: action.payload.status } : it,
      );
    },
  },
});

export const { setList, removeFromList, addToList, setCurrent, updateCurrent, updateInList, updateStatusInList } =
  distributionsSlice.actions;
export default distributionsSlice.reducer;
