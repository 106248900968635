import React, { FC, useCallback, useState } from "react";
import { Alert, Button, Form, message, Modal } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import FlexSpace from "../../../components/FlexSpace";
import SubUsersTableComponent from "./table";
import { CreateSubUserFormItems } from "./helpers";
import { UserType } from "../../../types/userType";
import { useActions } from "../../../hooks/useActions";
import FooterActions from "../../../components/FooterActions";

const SubUsersPage: FC = () => {
  const { createSubUser } = useActions();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<UserType>();

  const handleAddUser = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleCancel = useCallback(() => {
    setOpen(false);
    form.resetFields();
  }, [setOpen, form]);

  const handleSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        createSubUser({ ...values, username: values.email });
        handleCancel();
      })
      .catch(() => message.error(I18n.t("fillRequiredFields")));
  }, [form, handleCancel]);

  return (
    <div>
      <Alert type={"info"} style={{ marginBottom: 20 }} message={<Translate value={"subUserPageHelpTxt"} />} />
      <div>
        <FlexSpace position={"left"}>
          <Button type={"primary"} onClick={handleAddUser}>
            <Translate value={"addUser"} />
          </Button>
        </FlexSpace>
        <SubUsersTableComponent />
      </div>
      <Modal
        open={open}
        onCancel={handleCancel}
        title={I18n.t("addUser")}
        footer={<FooterActions onSubmit={handleSubmit} onCancel={handleCancel} submitLabelTranslationKey={"add"} />}
      >
        <Form form={form} layout={"vertical"} style={{ marginTop: 30 }}>
          <CreateSubUserFormItems />
        </Form>
      </Modal>
    </div>
  );
};

export default SubUsersPage;
