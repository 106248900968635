import { FC } from "react";
import CopyLinkButton from "./shareButtons/copyLink";
import QrCodeButton from "./shareButtons/qrCode";
import IFrameButton from "./shareButtons/iFrame";
import SocialMediaButton from "./shareButtons/socialMedia";
import EmailButton from "./shareButtons/email";
import SMSButton from "./shareButtons/sms";
import { DistributionChannelTypeEnum } from "../../../../../../types/DistributionChannel";

type Props = {
  readonly type: DistributionChannelTypeEnum;
};

const ChannelEditModal: FC<Props> = ({ type }) => {
  switch (type) {
    case DistributionChannelTypeEnum.EMAIL:
      return <EmailButton showButton={false} openModal />;
    case DistributionChannelTypeEnum.MSISDN:
      return <SMSButton showButton={false} openModal />;
    case DistributionChannelTypeEnum.IFRAME:
      return <IFrameButton showButton={false} openModal />;
    case DistributionChannelTypeEnum.URL_QR:
    case DistributionChannelTypeEnum.QR:
      return <QrCodeButton showButton={false} openModal />;
    case DistributionChannelTypeEnum.URL:
      return <CopyLinkButton showButton={false} openModal />;
    default:
      return <SocialMediaButton showButton={false} openModal />;
  }
};

export default ChannelEditModal;
