import { FC } from "react";
import { Form, Input } from "antd";
import { FormItemType } from "../../../types/formItemType";

const HiddenValueFormItemComponent: FC<Pick<FormItemType, "name"> & { value?: any }> = ({ name, value }) => {
  return (
    <Form.Item name={name} required={false} initialValue={value} style={{ position: "absolute", visibility: "hidden" }}>
      <Input type={"hidden"} />
    </Form.Item>
  );
};

export default HiddenValueFormItemComponent;
