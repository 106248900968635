import { DistributionType } from "./distributionType";
import { QrCodeType } from "./qrCode";
import React, { ReactNode } from "react";
import { CopyLink, Email, IFrame, QrCode, SMS } from "../components/icons";
import { SurveyType } from "./surveyType";

export type DistributionChannel = {
  readonly channelId: number;
  readonly channelType: DistributionChannelTypeEnum;
  readonly createdAt: string;
  readonly createdBy: string;
  readonly description: string;
  readonly distribution?: DistributionType;
  readonly qrCode?: QrCodeType;
  readonly survey?: SurveyType;
  readonly surveyId: string;
  readonly receiver?: string;
};

export type DistributionChannelCreate = Pick<DistributionChannel, "channelType" | "description" | "receiver"> & {
  qrCodeId?: string;
  distributionId?: string;
  surveyId?: string;
};

export enum DistributionChannelTypeEnum {
  EMAIL = "EMAIL",
  FACEBOOK = "FACEBOOK",
  IFRAME = "IFRAME",
  LINKEDIN = "LINKEDIN",
  MSISDN = "MSISDN",
  QR = "QR",
  TELEGRAM = "TELEGRAM",
  TWITTER = "TWITTER",
  URL = "URL",
  URL_QR = "URL_QR",
  WHATSAPP = "WHATSAPP",
}

export enum SocialMedium {
  WHATSAPP = "WHATSAPP",
  TELEGRAM = "TELEGRAM",
  TWITTER = "TWITTER",
  LINKEDIN = "LINKEDIN",
  FACEBOOK = "FACEBOOK",
  EMAIL = "EMAIL",
}

const SocialMediaIcon = ({ variant }: { variant: string }) => {
  return <img style={{ width: 30 }} src={`/assets/icons/${variant}.png`} alt={variant} />;
};

export const DistributionChannelInfos: Record<
  DistributionChannelTypeEnum,
  {
    readonly icon: ReactNode;
    readonly label: string;
  }
> = {
  [DistributionChannelTypeEnum.EMAIL]: { icon: <Email />, label: "E-mail рассылка" },
  [DistributionChannelTypeEnum.MSISDN]: { icon: <SMS />, label: "SMS рассылка" },
  [DistributionChannelTypeEnum.URL]: { icon: <CopyLink />, label: "Ссылка на опрос" },
  [DistributionChannelTypeEnum.IFRAME]: { icon: <IFrame />, label: "iFrame" },
  [DistributionChannelTypeEnum.URL_QR]: { icon: <QrCode />, label: "QR code" },
  [DistributionChannelTypeEnum.QR]: { icon: <QrCode />, label: "QR code" },
  [DistributionChannelTypeEnum.FACEBOOK]: { icon: <SocialMediaIcon variant={"facebook"} />, label: "Facebook" },
  [DistributionChannelTypeEnum.LINKEDIN]: { icon: <SocialMediaIcon variant={"linkedin"} />, label: "LinkedIn" },
  [DistributionChannelTypeEnum.TELEGRAM]: { icon: <SocialMediaIcon variant={"telegram"} />, label: "Telegram" },
  [DistributionChannelTypeEnum.TWITTER]: { icon: <SocialMediaIcon variant={"twitter"} />, label: "Twitter" },
  [DistributionChannelTypeEnum.WHATSAPP]: { icon: <SocialMediaIcon variant={"whatsapp"} />, label: "WhatsApp" },
};
