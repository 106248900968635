import { SortOrder } from "antd/es/table/interface";

export const getSortOrder = (field: string, sort?: string): SortOrder | undefined => {
  const fields = sort?.split(",");
  if (!fields?.length) return undefined;
  if (fields[0] === field) {
    return (fields[1] + "end") as SortOrder;
  }
  return undefined;
};
