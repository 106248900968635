import React, { FC, ReactNode } from "react";
import StylesProvider from "../../utils/providers/stylesProvider";
import LayoutComponent from "../../components/layout";
import { ModalProvider } from "../useConfirmModal";
import TimezoneWrapper from "./TimezoneProvider";
import { TokenValidatorWrapper } from "./tokenValidator";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAccessTokenObj } from "../../utils/jwt";
import { MainProvider } from "./mainProvider";
import { TourProvider } from "./tours/TourProvider";

type Props = {
  children: ReactNode;
};

export const AppProviders: FC<Props> = ({ children }) => {
  return (
    <MainProvider>
      <StylesProvider>
        <TokenValidatorWrapper>
          <Wrapper>{children}</Wrapper>
        </TokenValidatorWrapper>
      </StylesProvider>
    </MainProvider>
  );
};

const Wrapper: FC<Props> = ({ children }) => {
  const isLoggedIn = useSelector((state: RootState) => !!state.session.jwtToken);
  const tokenObj = useAccessTokenObj();
  const showLoginLayout = !isLoggedIn || !tokenObj?.email_verified;

  if (showLoginLayout) {
    return <>{children}</>;
  }

  return (
    <TimezoneWrapper>
      <TourProvider>
        <LayoutComponent>
          <ModalProvider>{children}</ModalProvider>
        </LayoutComponent>
      </TourProvider>
    </TimezoneWrapper>
  );
};
