import { Locale } from "antd/es/locale";
import kz_KZ from "antd/lib/locale/kk_KZ";
import ru_RU from "antd/lib/locale/ru_RU";
import en_EN from "antd/lib/locale/en_US";

export enum Language {
  RU = "RU",
  KK = "KK",
  EN = "EN",
}

export const langTitle: Record<Language, string> = {
  [Language.RU]: "Русский",
  [Language.KK]: "Қазақ",
  [Language.EN]: "English",
};

export type SurveyLanguage = {
  readonly langCode: string;
  readonly langName: string;
};

export const getSurveyLanguages = (languages: Array<Language>): Array<SurveyLanguage> => {
  return languages.map((lang) => ({ langCode: lang, langName: langTitle[lang] }));
};

export const langLocale: Record<Language, Locale> = {
  [Language.KK]: kz_KZ,
  [Language.EN]: en_EN,
  [Language.RU]: ru_RU,
};

export const toKcLanguage: Record<Language, string> = {
  [Language.KK]: "kz",
  [Language.EN]: "en",
  [Language.RU]: "ru",
};
