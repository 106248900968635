import { SubscriberCondition } from "../../types/subscriberConditionType";
import { FC } from "react";
import { Modal } from "antd";
import { I18n } from "react-redux-i18n";
import useConditions from "../conditionsTag/ useConditions";
import ConditionForm from "./index";
import FooterActions from "../FooterActions";

type Props = {
  readonly show: boolean;
  readonly groupId?: number;
  readonly onHide: () => void;
  readonly paramAsText?: boolean;
  readonly onFinish: (condition: SubscriberCondition, groupId?: number) => void;
};
const ConditionModal: FC<Props> = (props) => {
  const { show, groupId, onHide, onFinish, paramAsText } = props;
  const state = useConditions(onFinish, groupId, onHide);

  return (
    <Modal
      open={show}
      onCancel={state.onClear}
      title={I18n.t("addFilter")}
      footer={<FooterActions onCancel={state.onClear} onSubmit={state.onSave} submitLabelTranslationKey={"add"} />}
    >
      <ConditionForm {...state} layout={"vertical"} paramAsText={paramAsText} />
    </Modal>
  );
};

export default ConditionModal;
