import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from "../../../types/httpMethod";
import { requestApi } from "../../../utils/requestApi";
import { setList, setPageable } from "./resultsSlice";
import { ResultType } from "../../../types/resultType";
import { PagedResponseType } from "../../../types/pagedResponseType";
import store from "../../index";
import axios from "axios";
import { FileFormat } from "../../../types/fileFormatEnum";
import { message } from "antd";
import { I18n } from "react-redux-i18n";
import { DEFAULT_ERROR_LABEL } from "../../../utils";

export const getResults = createAsyncThunk<void, string>("results/getResults", async (surveyId, { dispatch }) => {
  try {
    const pageable = store.getState().results.pageable;
    const res: PagedResponseType<ResultType> = await requestApi(GET, `/surveys/${surveyId}/results`, pageable);
    dispatch(setList(res.content));
    dispatch(
      setPageable({
        ...pageable,
        total: res.totalElements,
      }),
    );
  } catch (e) {
    throw e;
  }
});

export const getResultsFile = createAsyncThunk<
  void,
  {
    readonly surveyId: string;
    readonly surveyName: string;
    readonly fileFormat: FileFormat;
  }
>("results/getResultsFile", async ({ surveyId, surveyName, fileFormat }) => {
  try {
    await downloadFile(`/surveys/${surveyId}/results/${fileFormat}`, fileFormat, surveyName);
  } catch (e) {
    throw e;
  }
});

async function downloadFile(url: string, fileFormat: FileFormat, surveyName: string) {
  const token = store.getState().session.jwtToken;

  axios({
    url: window._env_.API_PATH + url,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `${surveyName}.${fileFormat}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      message.success(I18n.t("fileDownloaded"));
    })
    .catch(() => message.error(I18n.t(DEFAULT_ERROR_LABEL)));
}
