import "./index.scss";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Form, Tabs, Tag } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SURVEYS_PAGE } from "../../../utils/paths";
import { SurveyStatus, SurveyType } from "../../../types/surveyType";
import { ChannelEnum } from "../../../types/channelEnum";
import { useActions } from "../../../hooks/useActions";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { getSurveyValues } from "../utils";
import { StepsEnum, surveyEditPageTabContent, surveyEditPageTabItems } from "../../../types/surveyEditTabsEnum";
import { message } from "antd/lib";
import { surveyStatusInfoRu } from "../../../types/statusType";
import { I18n, Translate } from "react-redux-i18n";
import useSurveyDisabled from "../../../hooks/useSurveyDisabled";
import { APP_PRIMARY_COLOR } from "../../../utils";
import { useRequests } from "../../../hooks/useRequests";
import { useDistributionChannelsApi } from "../../../hooks/useDistributionChannelsApi";
import { DistributionChannelTypeEnum } from "../../../types/DistributionChannel";
import { useTourCtx } from "../../../hooks/providers/tours/TourProvider";
import { CreateSurveyTourItemsEnum, TourItemsEnum } from "../../../types/tour";
import { isSurveyCreatingAvailable } from "../../../utils/appLimits";

export const surveyInitial = {
  communicationChannel: ChannelEnum.WEB,
  sourceConditions: [],
  tags: [],
  questions: [],
} as unknown as SurveyType;

type Props = {
  readonly createByAI?: boolean;
};

const SurveyCreatePage: FC<Props> = ({ createByAI }) => {
  const {
    tourInProgress,
    data: {
      [TourItemsEnum.CREATE_SURVEY]: { refs },
    },
  } = useTourCtx();
  const { id: surveyId } = useParams();
  const {
    setQuestions,
    updateSurvey,
    getLanguages,
    getSurveyById,
    setQrCodesList,
    resetQuestions,
    getSmsProviders,
    setCurrentQrCode,
    setCurrentSurvey,
    getEmailProviders,
    updateCurrentSurvey,
    setDistributionsList,
    setCurrentDistribution,
    setSurveyEditActiveStep: setCurrentStep,
  } = useActions();

  const { createDistributionChannel } = useDistributionChannelsApi();
  const { createSurvey, createWelcomeSms } = useRequests();
  const navigate = useNavigate();
  const location = useLocation();
  const isDisabled = useSurveyDisabled();

  const [form] = Form.useForm<SurveyType>();
  const [loading, setLoading] = useState(false);

  const { survey, languages, currentStep } = useSelector((state: RootState) => {
    const survey = state.surveys.current;
    const questions = state.questions.list;
    const languages = state.languages.list;
    const currentStep = state.persisted.surveysEditPageActiveStep;
    return { languages, questions, survey, currentStep };
  });

  const isCurrent = useCallback(
    (step: StepsEnum) => {
      return currentStep === step;
    },
    [currentStep],
  );

  const onCancel = () => navigate(SURVEYS_PAGE);

  useEffect(() => {
    getLanguages();
    getSmsProviders();
    getEmailProviders();
    if (!surveyId) {
      form.setFieldsValue(getSurveyValues(surveyInitial));
      updateCurrentSurvey(surveyInitial);
    } else getSurveyById(surveyId);

    return () => {
      resetQuestions();
      setQuestions([]);
      setCurrentSurvey(undefined);
      setCurrentDistribution(undefined);
      setCurrentQrCode(undefined);
      setDistributionsList([]);
      setQrCodesList([]);
      setCurrentStep(undefined);
    };
  }, []);

  useEffect(() => {
    if (!survey) return;

    if (!currentStep) {
      setCurrentStep(StepsEnum.CONFIGURATIONS);
    }

    form.setFieldsValue(getSurveyValues(survey));
  }, [survey?.surveyId]);

  const onSubmit = useCallback(async () => {
    if (!survey) return;

    if (survey?.surveyId) {
      return updateSurvey({ survey });
    }
    try {
      setLoading(true);
      const newSurvey = await createSurvey({
        ...survey,
        surveyDecor: { ...survey?.surveyDecor, showProgress: true },
      });
      if (newSurvey.communicationChannel === ChannelEnum.WEB) {
        await createWelcomeSms(newSurvey);
        await createDistributionChannel({
          surveyId: newSurvey?.surveyId ?? "",
          channelType: DistributionChannelTypeEnum.URL,
          description: I18n.t("linkToSurvey"),
        });
      }
      navigate(`${SURVEYS_PAGE}/${newSurvey.surveyId}/edit`);
      setCurrentStep(StepsEnum.QUESTIONS);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [survey, navigate, setLoading]);

  const onSave = useCallback(() => {
    form
      .validateFields()
      .then(form.submit)
      .catch(() => message.error(I18n.t("fillRequiredFields")));
  }, [form]);

  const onValuesChange = (value: any) => {
    if (value?.languages) {
      value = {
        languages: value.languages.map((lang: string) => languages.find((it) => it.langCode === lang)),
      };
    }
    updateCurrentSurvey(value);
  };

  useEffect(() => {
    if (!surveyId) {
      setCurrentStep(StepsEnum.CONFIGURATIONS);
    }
  }, []);

  const extraContent = (
    <div className="box">
      <div className="unselectable">
        {survey?.status && (
          <div className={"status-tag"}>
            <span>
              <Translate value={"status"} />:
            </span>
            <Tag
              color={surveyStatusInfoRu()[survey.status].backgroundColor}
              style={{ color: surveyStatusInfoRu()[survey.status].textColor }}
            >
              {surveyStatusInfoRu()[survey.status].label}
            </Tag>
          </div>
        )}
      </div>
    </div>
  );

  const items = surveyEditPageTabItems()
    .filter((it) =>
      it.key === StepsEnum.PERSONALIZATION || it.key === StepsEnum.INTEGRATIONS
        ? survey?.communicationChannel === ChannelEnum.WEB
        : true,
    )
    .filter((it) => (it.key === StepsEnum.RESULTS ? !!surveyId : true))
    .map((it, index) => ({
      ...it,
      label: (
        <span style={{ color: it.key !== currentStep ? "rgba(0, 0, 0, 0.25)" : APP_PRIMARY_COLOR }}>
          {index + 1}. {it.label}
        </span>
      ),
      disabled: !survey?.surveyId ? !isCurrent(it.key) : false,
    }));

  const onTabChange = useCallback(
    (key: string) => {
      if (survey?.surveyId) {
        setCurrentStep(key as StepsEnum);
      }
    },
    [survey?.surveyId, setCurrentStep],
  );

  const surveyCreatingUnavailable = !isSurveyCreatingAvailable() && !location.pathname.endsWith("/edit");

  return (
    <div className={"survey-create-page"}>
      <Tabs
        items={items}
        onChange={onTabChange}
        activeKey={currentStep}
        tabBarExtraContent={extraContent}
        className={!survey?.surveyId ? "" : "disabled-color"}
      />
      {isCurrent(StepsEnum.QUESTIONS) ? (
        !survey?.surveyId ? (
          <></>
        ) : (
          surveyEditPageTabContent()[StepsEnum.QUESTIONS]
        )
      ) : isCurrent(StepsEnum.INTEGRATIONS) ? (
        surveyEditPageTabContent()[StepsEnum.INTEGRATIONS]
      ) : isCurrent(StepsEnum.PERSONALIZATION) ? (
        surveyEditPageTabContent()[StepsEnum.PERSONALIZATION]
      ) : (
        currentStep && (
          <>
            <div className="steps-content">
              <Form
                form={form}
                scrollToFirstError
                onFinish={onSubmit}
                disabled={isDisabled}
                layout={"horizontal"}
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 5 }}
                onValuesChange={onValuesChange}
              >
                {surveyEditPageTabContent(createByAI)[currentStep]}
              </Form>
            </div>
            {!createByAI && isCurrent(StepsEnum.CONFIGURATIONS) && survey?.status !== SurveyStatus.DEACTIVATED && (
              <div className="steps-action">
                <Button onClick={onCancel}>
                  <Translate value={"cancel"} />
                </Button>
                <Button
                  type="primary"
                  loading={loading}
                  disabled={surveyCreatingUnavailable}
                  ref={refs[CreateSurveyTourItemsEnum.NEXT_BUTTON]}
                  onClick={tourInProgress || surveyCreatingUnavailable ? undefined : onSave}
                >
                  <Translate value={!survey?.surveyId ? "next" : "save"} />
                </Button>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};

export default SurveyCreatePage;
