import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import offDaysSlice from "./slices/offDays/offDaysSlice";
import surveysSlice from "./slices/surveys/surveysSlice";
import thunk from "redux-thunk";
import { KzTranslations } from "../utils/i18/kz";
import { RuTranslations } from "../utils/i18/ru";
import { EnTranslations } from "../utils/i18/en";
import { EnTariffTranslations } from "../utils/i18/tariffs/en";
import { KzTariffTranslations } from "../utils/i18/tariffs/kz";
import { RuTariffTranslations } from "../utils/i18/tariffs/ru";
import { EnTourTranslations } from "../utils/i18/tours/en";
import { KzTourTranslations } from "../utils/i18/tours/kz";
import { RuTourTranslations } from "../utils/i18/tours/ru";
import { i18nReducer, loadTranslations, syncTranslationWithStore } from "react-redux-i18n";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist/es/constants";
import sessionSlice from "./slices/session/sessionSlice";
import persistedSlice from "./slices/persisted/persistedSlice";
import conditionsSlice from "./slices/conditions/conditionsSlice";
import questionsSlice from "./slices/questions/questionsSlice";
import appSlice from "./slices/app/appSlice";
import apiKeysSlice from "./slices/apiKeys/apiKeysSlice";
import resultsSlice from "./slices/results/resultsSlice";
import webhooksSlice from "./slices/webhooks/webhooksSlice";
import usersSlice from "./slices/users/usersSlice";
import distributionsSlice from "./slices/distributions/distributionsSlice";
import languagesSlice from "./slices/languages/languagesSlice";
import qrCodesSlice from "./slices/qrCodes/qrCodesSlice";
import emailProvidersSlice from "./slices/emailProviders/emailProvidersSlice";
import smsProvidersSlice from "./slices/smsProviders/smsProvidersSlice";
import contactsSlice from "./slices/contacts/contactsSlice";
import distributionChannelsSlice from "./slices/distributionChannels/distributionChannelsSlice";

const rootReducer = combineReducers({
  session: sessionSlice,
  persisted: persistedSlice,
  offDays: offDaysSlice,
  surveys: surveysSlice,
  conditions: conditionsSlice,
  questions: questionsSlice,
  apiKeys: apiKeysSlice,
  results: resultsSlice,
  webhooks: webhooksSlice,
  users: usersSlice,
  languages: languagesSlice,
  emailProviders: emailProvidersSlice,
  smsProviders: smsProvidersSlice,
  distributions: distributionsSlice,
  distributionChannels: distributionChannelsSlice,
  qrCodes: qrCodesSlice,
  contacts: contactsSlice,
  app: appSlice,
  i18n: i18nReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["session", "persisted", "conditions"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk),
});

export const persistor = persistStore(store);
export default store;

store.dispatch(
  loadTranslations({
    KK: { ...KzTranslations, ...KzTariffTranslations, ...KzTourTranslations },
    RU: { ...RuTranslations, ...RuTariffTranslations, ...RuTourTranslations },
    EN: { ...EnTranslations, ...EnTariffTranslations, ...EnTourTranslations },
  }),
);
syncTranslationWithStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
