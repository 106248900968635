import React, { FC, ReactNode, useEffect } from "react";
import { useActions } from "../useActions";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { langLocale, Language } from "../../types/language";
import { setLocale } from "react-redux-i18n";
import dayjs from "dayjs";
import { ConfigProvider } from "antd";

type Props = {
  children: ReactNode;
};

export const MainProvider: FC<Props> = ({ children }) => {
  const { getAppFeatures } = useActions();
  const dispatch = useAppDispatch();
  const { setLanguage } = useActions();
  const lang = useSelector((state: RootState) => state.persisted.appLanguage);
  const locale = langLocale[lang];

  useEffect(() => {
    dispatch(setLocale(lang));
    dayjs.locale(lang.toLowerCase());
    console.log("version", window._env_.COMMIT_SHORT_SHA);
  }, [lang]);

  useEffect(() => {
    if (!lang) setLanguage(Language.RU);
    getAppFeatures();
  }, []);

  return <ConfigProvider locale={locale}>{children}</ConfigProvider>;
};
