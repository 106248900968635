import React, { FC, useCallback, useState } from "react";
import { CloseOutlined, DesktopOutlined, EyeOutlined, MobileOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useTourCtx } from "../../../../../hooks/providers/tours/TourProvider";
import { CreateSurveyQuestionsTourItemsEnum, TourItemsEnum } from "../../../../../types/tour";
import { Translate } from "react-redux-i18n";

type Props = {
  readonly surveyId: string;
  readonly disabled?: boolean;
};

const SurveyEmulator: FC<Props> = (props) => {
  const {
    tourInProgress,
    data: {
      [TourItemsEnum.CREATE_SURVEY_QUESTIONS]: { refs },
    },
  } = useTourCtx();

  const { surveyId, disabled } = props;
  const [open, setOpen] = useState(false);
  const [desktopView, setDesktopView] = useState(true);
  const [reloadMark, setReloadMark] = useState(false);
  const [receiverIdx, setReceiverIdx] = useState(1000);

  const frameUrl = `${window._env_.WEB_SURVEY_BASE_URL}/${surveyId}?receiver=${receiverIdx}&variant=emulator`;

  const onReload = useCallback(() => {
    setReceiverIdx((prev) => prev + 10);
    setReloadMark(true);
  }, [setReceiverIdx, setReloadMark]);

  const onOpenEmulator = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onCloseEmulator = useCallback(() => {
    setOpen(false);
    setDesktopView(true);
    onReload();
  }, [setOpen, onReload, setDesktopView]);

  if (reloadMark) {
    setReloadMark(false);
    return null;
  }

  return (
    <div>
      <Button
        type={"default"}
        disabled={disabled}
        icon={<EyeOutlined />}
        style={{ marginBottom: 20 }}
        onClick={tourInProgress ? undefined : onOpenEmulator}
        ref={refs[CreateSurveyQuestionsTourItemsEnum.SURVEY_PREVIEW_BUTTON]}
      >
        <Translate value={"previewOfTheSurvey"} />
      </Button>
      <Modal
        title={null}
        footer={null}
        width={"100vw"}
        closable={false}
        open={open && !disabled}
        onCancel={onCloseEmulator}
        style={{ position: "absolute", top: 5, left: 15, margin: "unset" }}
      >
        <div style={{ height: "93.5vh" }}>
          <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
            <span style={{ fontSize: 17, fontWeight: "bold" }}>
              <Translate value={"previewOfTheSurvey"} />
            </span>
            <Button
              icon={<DesktopOutlined />}
              style={{ marginLeft: "auto" }}
              onClick={() => setDesktopView(true)}
              type={desktopView ? "primary" : undefined}
            />
            <Button
              icon={<MobileOutlined />}
              style={{ marginRight: 20 }}
              onClick={() => setDesktopView(false)}
              type={!desktopView ? "primary" : undefined}
            />
            <Button icon={<ReloadOutlined />} onClick={onReload} />
            <Button danger type={"primary"} icon={<CloseOutlined />} onClick={onCloseEmulator} />
          </div>
          <div
            style={{
              height: "inherit",
              width: "inherit",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <iframe
              title={"com"}
              src={frameUrl}
              style={{
                width: desktopView ? "100%" : 450,
                border: "2px dashed lightgray",
                height: "90%",
                marginTop: 20,
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SurveyEmulator;
