import { I18n } from "react-redux-i18n";

export enum CustomAnswerTypesEnum {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  IIN = "IIN",
  NONE = "NONE",
}

export enum CustomAnswerLinesEnum {
  ONE_LINE = "ONE_LINE",
  MULTILINE = "MULTILINE",
}

export const textTemplateTypesLabels = (): Record<CustomAnswerTypesEnum, string> => {
  return {
    [CustomAnswerTypesEnum.PHONE]: I18n.t("phone"),
    [CustomAnswerTypesEnum.IIN]: I18n.t("iin"),
    [CustomAnswerTypesEnum.EMAIL]: "E-Mail",
    [CustomAnswerTypesEnum.NONE]: I18n.t("text"),
  };
};

export const textTemplateLinesLabels = (): Record<CustomAnswerLinesEnum, string> => {
  return {
    [CustomAnswerLinesEnum.ONE_LINE]: I18n.t("oneLine"),
    [CustomAnswerLinesEnum.MULTILINE]: I18n.t("multiLine"),
  };
};

export const lineOptionsList = () => [
  { value: CustomAnswerLinesEnum.ONE_LINE, label: textTemplateLinesLabels()[CustomAnswerLinesEnum.ONE_LINE] },
  { value: CustomAnswerLinesEnum.MULTILINE, label: textTemplateLinesLabels()[CustomAnswerLinesEnum.MULTILINE] },
];

export const customAnswerTypesPlaceholders = (): Record<CustomAnswerTypesEnum, string> => {
  return {
    [CustomAnswerTypesEnum.PHONE]: " | +7 (999) 999 99 99",
    [CustomAnswerTypesEnum.IIN]: " | 000000000000",
    [CustomAnswerTypesEnum.EMAIL]: " | example@site.com",
    [CustomAnswerTypesEnum.NONE]: "",
  };
};
