import { FC, useCallback } from "react";
import { Modal, Tabs } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import PreviousAnswerConditionComponent from "./previousAnswerCondition";
import SubscriberProfileFiltersComponent from "../../../../../components/conditionsTag";
import FooterActions from "../../../../../components/FooterActions";
import { useActions } from "../../../../../hooks/useActions";

type QuestionViewSettingsModalProps = {
  open: boolean;
  onClose: (forceReset?: boolean) => void;
};

const { TabPane } = Tabs;

const QuestionViewSettingsModal: FC<QuestionViewSettingsModalProps> = (props) => {
  const { open, onClose } = props;
  const { resetAnswerDisplayConditions } = useActions();

  const handleSave = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCancel = useCallback(() => {
    resetAnswerDisplayConditions();
    onClose(true);
  }, [resetAnswerDisplayConditions, onClose]);

  return (
    <Modal
      footer={<FooterActions onCancel={handleCancel} onSubmit={handleSave} submitLabelTranslationKey={"save"} />}
      title={I18n.t("questionDisplayLogic")}
      style={{ top: "30%", overflow: "hidden" }}
      className={"question-settings-modal"}
      onCancel={handleCancel}
      width={800}
      open={open}
    >
      <Tabs type={"card"} defaultActiveKey="1">
        <TabPane tab={I18n.t("basedOnPreviousResponses")} key="1" style={{ minHeight: 100 }}>
          <Translate value={"displayIfAnswerOn"} />
          <PreviousAnswerConditionComponent />
        </TabPane>

        <TabPane tab={I18n.t("basedOnContactData")} key="2" style={{ minHeight: 100 }}>
          <Translate value={"displayOnlyContactsWhich"} />
          <SubscriberProfileFiltersComponent type={"questions"} variant={"inline"} title={""} noStyle />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default QuestionViewSettingsModal;
