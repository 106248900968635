import React, { useCallback, useEffect, useState } from "react";
import { useAccessTokenObj } from "../../utils/jwt";
import { useActions } from "../../hooks/useActions";
import Link from "antd/es/typography/Link";
import { I18n, Translate } from "react-redux-i18n";

const ResendCodeButton = () => {
  const [canResend, setCanResend] = useState(false);
  const [timer, setTimer] = useState(120);
  const tokenObj = useAccessTokenObj();
  const { resendVerificationCode } = useActions();

  const resendCode = useCallback(
    (force = false) => {
      if (tokenObj?.email && (canResend || force)) {
        resendVerificationCode(tokenObj.email);
        setCanResend(false);
        setTimer(120);
        localStorage.setItem(tokenObj?.email, String(Date.now()));
      }
    },
    [canResend, tokenObj?.email],
  );

  useEffect(() => {
    const timerStartTime = localStorage.getItem(tokenObj?.email + "");

    if (!timerStartTime) {
      return resendCode(true);
    }

    const elapsedTime = Math.floor((Date.now() - Number(timerStartTime)) / 1000);
    if (elapsedTime < 120) {
      setTimer(120 - elapsedTime);
      setCanResend(false);
    }
  }, []);

  useEffect(() => {
    let interval: any;
    if (timer > 0) {
      interval = setInterval(() => setTimer((prevTimer) => prevTimer - 1), 1000);
    } else {
      setCanResend(true);
      localStorage.removeItem(tokenObj?.email + "");
    }

    return () => clearInterval(interval);
  }, [timer]);

  const formatTime = useCallback(() => {
    const mins = Math.floor(timer / 60);
    const secs = timer % 60;

    const formattedMins = mins.toString().padStart(2, "0");
    const formattedSecs = secs.toString().padStart(2, "0");

    if (!formattedMins) {
      return formattedSecs;
    }

    return `${formattedMins}:${formattedSecs}`;
  }, [timer]);

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <Link onClick={() => resendCode()} disabled={!canResend}>
        {canResend ? <Translate value={"resendCodeAgain"} /> : `${I18n.t("resendCode")} (${formatTime()})`}
      </Link>
    </div>
  );
};

export default ResendCodeButton;
