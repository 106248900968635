import { useAppDispatch } from "../store";
import { useCallback } from "react";
import { requestApi } from "../utils/requestApi";
import { DELETE, PATCH, POST } from "../types/httpMethod";
import { DistributionChannel, DistributionChannelCreate } from "../types/DistributionChannel";
import {
  addDistributionChannelToList,
  getDistributionChannelsBySurveyId,
  setCurrentDistributionChannel,
  updateDescriptionInList,
} from "../store/slices/distributionChannels/distributionChannelsService";
import { I18n } from "react-redux-i18n";
import { message } from "antd";

type UpdatePayload = {
  readonly channelId: number;
  readonly description: string;
};

interface IUseDistributionChannelsApi {
  createDistributionChannel: (payload: DistributionChannelCreate) => Promise<DistributionChannel>;
  updateDistributionChannel: (payload: UpdatePayload) => Promise<DistributionChannel>;
  deleteDistributionChannel: (surveyId: string, channelId: number) => Promise<void>;
}

export const useDistributionChannelsApi = (): IUseDistributionChannelsApi => {
  const dispatch = useAppDispatch();

  const createDistributionChannel = useCallback(
    async (payload: DistributionChannelCreate): Promise<DistributionChannel> => {
      try {
        const res = await requestApi(POST, "/distribution_channels", payload);
        dispatch(setCurrentDistributionChannel(res));
        dispatch(addDistributionChannelToList(res));
        message.success(I18n.t("distributionCreated"));
        return res;
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const updateDistributionChannel = useCallback(
    async (payload: UpdatePayload): Promise<DistributionChannel> => {
      try {
        const res = await requestApi(
          PATCH,
          `/distribution_channels/${payload.channelId}`,
          {},
          false,
          false,
          undefined,
          { description: payload.description },
        );
        dispatch(updateDescriptionInList(res));
        message.success(I18n.t("distributionUpdated"));
        return res;
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  const deleteDistributionChannel = useCallback(
    async (surveyId: string, channelId: number): Promise<void> => {
      try {
        await requestApi(DELETE, `/distribution_channels/${channelId}`, {});
        dispatch(getDistributionChannelsBySurveyId(surveyId));
        message.success(I18n.t("distributionDeleted"));
        return Promise.resolve();
      } catch (e) {
        throw e;
      }
    },
    [dispatch],
  );

  return {
    createDistributionChannel: createDistributionChannel,
    updateDistributionChannel: updateDistributionChannel,
    deleteDistributionChannel: deleteDistributionChannel,
  };
};
