import React from "react";
import { I18n } from "react-redux-i18n";
import InputFormItemComponent from "../../../../components/form/items/input";
import HiddenValueFormItemComponent from "../../../../components/form/items/hiddenValue";
import { EmailProviderTypesEnum } from "../../../../types/EmailProviderType";

const PostmarkForm = () => {
  return (
    <>
      <InputFormItemComponent name={"description"} label={I18n.t("providerDescription")} />
      <InputFormItemComponent name={"xPostmarkServerToken"} label={"X-Postmark-Server-Token"} />
      <InputFormItemComponent name={"fromEmail"} label={"From"} />
      <HiddenValueFormItemComponent name={"providerType"} value={EmailProviderTypesEnum.POSTMARK} />
      <HiddenValueFormItemComponent name={"providerId"} />
    </>
  );
};

export default PostmarkForm;
