import { createAsyncThunk } from "@reduxjs/toolkit";
import { setFeatures, setShowLoader } from "./appSlice";
import { requestApi } from "../../../utils/requestApi";
import { GET } from "../../../types/httpMethod";
import { AppFeaturesType } from "../../../types/appFeatures";

export const getAppFeatures = createAsyncThunk<void, undefined>("app/getAppFeatures", async (_, { dispatch }) => {
  try {
    const res = await requestApi(
      GET,
      "/app/features",
      {},
      false,
      true,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
    );
    dispatch(setAppFeatures(res));
  } catch (e) {
    console.log(e);
  }
});

export const setLoader = createAsyncThunk<void, boolean>("app/setLoader", (loader, { dispatch }) => {
  dispatch(setShowLoader(loader));
});

export const setAppFeatures = createAsyncThunk<void, AppFeaturesType>(
  "app/setAppFeatures",
  (features, { dispatch }) => {
    dispatch(setFeatures(features));
  },
);
