export const EnTariffTranslations = {
  tariffs: {
    basic: {
      name: "Basic",
      description: "For individual questionnaires, academic researchers",
      amount: "₸ 10 000",
      amountInt: "10000",
      options: {
        0: "1 ADMIN USER",
        1: "UP TO 1,000 REVIEWS",
        2: "UP TO 3 SURVEYS",
        3: "UP TO 200 CONTACTS",
        4: "UP TO 1,000 API CALLS",
      },
      additional: {
        0: "100 reviews = ₸1 000 per month",
      },
    },
    standard: {
      name: "Standard",
      description: "The most convenient plan for medium-sized businesses",
      amount: "₸ 20 000",
      amountInt: "20000",
      options: {
        0: "UP TO 1 ADMIN USERS",
        1: "UP TO 2,500 REVIEWS",
        2: "UP TO 10 SURVEYS",
        3: "UP TO 1,000 CONTACTS",
        4: "UP TO 2,500 API CALLS PER DAY",
      },
      additional: {
        0: "500 reviews = ₸5 000 per month",
      },
    },
    business: {
      name: "Business",
      description: "For large companies, a full set of functions and customization",
      amount: "₸ 50 000",
      amountInt: "50000",
      options: {
        0: "UP TO 3 ADMIN USERS",
        1: "UP TO 10,000 REVIEWS",
        2: "UP TO 100 SURVEYS",
        3: "UP TO 2,000 CONTACTS",
        4: "UP TO 10,000 API CALLS",
      },
      additional: {
        0: "1,000 reviews = ₸10 000 per month",
      },
    },
    continueTrialPeriod: "Continue trial period",
    trialPeriodDaysLeft: "There are {days} days left until the end of the trial period",
    subscribe: "Subscribe",
    trialPeriodEndDescription: "The trial period is over. To continue working, you need to activate a paid tariff plan",
    currentLimits: "Remaining tariff",
    numberOfReviews: "Number of reviews",
    numberOfUsers: "Number of users",
    numberOfEmailSendings: "Number of e-mail sendings",
    numberOfContacts: "Number of contacts",
    numberOfAPICalls: "Number of API calls",
    numberOfSurveys: "Number of surveys",
    limitWillBeUpdated: "The limit on your tariff plan will be updated",
    userInformation: "User Information",
    nextPayment: "The next payment is on",
    changeTheTariff: "Change the tariff",
    cancelTheTariff: "Cancel the tariff",
    chooseAnotherCard: "Choose another card",
    paymentHistory: "Payment history",
    paymentMethod: "Payment method",
    trialPeriodValidFromTo: "The trial period is valid from {startDate} to {endDate}",
    cancelConfirmationText: "Are you sure you want to cancel your current plan?",
    tariffCanceledSuccessText:
      "The tariff plan has been cancelled. You will have access to your account until {dateValue}",
    dateOfPayment: "Date of payment",
    payment: "Payment",
    completed: "Completed",
    invoiceNumber: "Invoice number",
    price: "Cost",
    operation: "Operation",
    state: "Status",
  },
};
