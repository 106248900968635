import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SurveyLanguage } from "../../../types/language";

type StateType = {
  list: Array<SurveyLanguage>;
  surveyLanguages: Array<SurveyLanguage>;
};
const initialState: StateType = {
  list: [],
  surveyLanguages: [],
};

const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    setLanguages(
      state: StateType,
      action: PayloadAction<Array<SurveyLanguage>>,
    ) {
      state.list = action.payload;
    },
    setSurveyLanguages(
      state: StateType,
      action: PayloadAction<Array<SurveyLanguage>>,
    ) {
      state.surveyLanguages = action.payload;
    },
    addLanguage(state: StateType, action: PayloadAction<SurveyLanguage>) {
      state.list.push(action.payload);
    },
    removeLanguage(state: StateType, action: PayloadAction<string>) {
      state.list = state.list.filter((it) => it.langCode !== action.payload);
    },
  },
});

export const { setLanguages, setSurveyLanguages, addLanguage, removeLanguage } =
  languagesSlice.actions;
export default languagesSlice.reducer;
