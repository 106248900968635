import { useMemo, useRef } from "react";
import { TourProps } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import { CreateSurveyTourItemsEnum, TourItemsEnum } from "../../../types/tour";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IGetTourDataReturnProps } from "./types";
import { StepsEnum } from "../../../types/surveyEditTabsEnum";

export const useCreateSurveyTourData = (): IGetTourDataReturnProps => {
  const { tourObj, tourCompleted, isCurrentStepConfigurations } = useSelector((state: RootState) => {
    const tours = state.users.userExtendedData?.tours ?? [];
    const obj = tours.find((t) => t.tourName === TourItemsEnum.CREATE_SURVEY);
    const curStep = state.persisted.surveysEditPageActiveStep;

    return {
      isCurrentStepConfigurations: !curStep || curStep === StepsEnum.CONFIGURATIONS,
      tourObj: obj,
      tourCompleted: Boolean(obj?.completed),
    };
  });
  const location = useLocation();

  const available = useMemo(() => {
    return isCurrentStepConfigurations && location.pathname.endsWith("surveys/create") && !tourCompleted;
  }, [location.pathname, tourCompleted, isCurrentStepConfigurations]);

  const surveyName = useRef(null);
  // const surveyType = useRef(null);
  const tags = useRef(null);
  const languages = useRef(null);
  const nextButton = useRef(null);

  const modalProps = {
    title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.title`),
    description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.description`),
    submitLabel: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.submitBtn`),
    cancelLabel: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.cancelBtn`),
  };

  const steps: TourProps["steps"] = [
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.surveyNameTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.surveyNameDescription`),
      target: () => surveyName.current,
    },
    // {
    //   title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.surveyTypeTitle`),
    //   description: (
    //     <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
    //       <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY}.surveyTypeDescription`} />
    //       <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY}.surveyTypeSubDescription`} />
    //     </div>
    //   ),
    //   target: () => surveyType.current,
    // },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.tagsTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.tagsDescription`),
      target: () => tags.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.languagesTitle`),
      description: (
        <div>
          <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY}.languagesDescription`} />
          <div style={{ marginTop: 10 }}>
            <b style={{ marginRight: 5 }}>
              <Translate value={`tours.important`} />
            </b>
            <Translate value={`tours.${TourItemsEnum.CREATE_SURVEY}.languagesSubDescription`} />
          </div>
        </div>
      ),
      target: () => languages.current,
    },
    {
      title: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.nextButtonTitle`),
      description: I18n.t(`tours.${TourItemsEnum.CREATE_SURVEY}.nextButtonDescription`),
      target: () => nextButton.current,
    },
  ];

  return {
    available: available,
    completed: tourCompleted,
    steps: steps,
    refs: {
      [CreateSurveyTourItemsEnum.SURVEY_NAME]: surveyName,
      // [CreateSurveyTourItemsEnum.SURVEY_TYPE]: surveyType,
      [CreateSurveyTourItemsEnum.TAGS]: tags,
      [CreateSurveyTourItemsEnum.LANGUAGES]: languages,
      [CreateSurveyTourItemsEnum.NEXT_BUTTON]: nextButton,
    },
    modal: modalProps,
    tourObj: tourObj,
  };
};
