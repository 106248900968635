import { FC, useCallback, useState, useEffect } from "react";
import { Form, message, Modal } from "antd";
import InputFormItemComponent from "../../../components/form/items/input";
import { I18n } from "react-redux-i18n";
import {
  ContactsAttributeCreateType,
  ContactsAttributeDatatypeEnum,
  DataTypeTranslation,
} from "../../../types/ContactsAttributeType";
import { useActions } from "../../../hooks/useActions";
import SelectFormItemComponent from "../../../components/form/items/select";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import FooterActions from "../../../components/FooterActions";
import { transliterate as tr } from "transliteration";

type CreateAttributeProps = {
  readonly open: boolean;
  readonly onClose: () => void;
};

const CreateAttribute: FC<CreateAttributeProps> = (props) => {
  const { open, onClose } = props;
  const [form] = Form.useForm<ContactsAttributeCreateType>();
  const { createAttribute } = useActions();
  const attributes = useSelector((state: RootState) => state.contacts.attributesList);
  const [uniqueLabelError, setUniqueLabelError] = useState(false);

  const onAttributeNameChange = useCallback(
    (value: string) => {
      const valueWithoutLeadingDigits = value.replace(/^\d+/, "");
      const transliteratedValue = tr(valueWithoutLeadingDigits).toUpperCase().trim().replace(/\s+/g, "");

      form.setFieldsValue({ propertyKey: transliteratedValue });

      const label = transliteratedValue.replace(/[^A-Z_]/g, "").replace(/^_/, "");
      const exist = attributes.find((attr) => attr.propertyKey === label);
      if (exist && !uniqueLabelError) {
        setUniqueLabelError(true);
      } else if (!exist && uniqueLabelError) {
        setUniqueLabelError(false);
      }
    },
    [form, attributes, uniqueLabelError],
  );

  const onPropertyKeyChange = useCallback(
    (value: string) => {
      const upperCaseValue = value.replace(/^\d+/, "").toUpperCase();
      form.setFieldsValue({ propertyKey: upperCaseValue });
    },
    [form],
  );

  const onSubmit = useCallback(() => {
    if (uniqueLabelError) {
      return message.error(I18n.t("labelMustBeUnique"));
    }
    form
      .validateFields()
      .then((fields) => {
        createAttribute(fields);
        onCancel();
      })
      .catch(() => message.error(I18n.t("fillRequiredFields")));
  }, [form, uniqueLabelError, createAttribute]);

  const onCancel = useCallback(() => {
    form.resetFields();
    setUniqueLabelError(false);
    onClose();
  }, [form, onClose]);

  useEffect(() => {
    form.setFieldsValue({ propertyKey: "" });
  }, [form]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={I18n.t("newAttribute")}
      footer={<FooterActions onCancel={onCancel} onSubmit={onSubmit} submitLabelTranslationKey={"save"} />}
    >
      <Form form={form} layout={"vertical"} style={{ marginTop: 24 }} validateTrigger="onBlur">
        <InputFormItemComponent
          hasFeedback
          name={"description"}
          label={I18n.t("attributeName")}
          onChange={onAttributeNameChange}
        />
        <InputFormItemComponent
          hasFeedback
          name={"propertyKey"}
          label={I18n.t("propertyKey")}
          showError={uniqueLabelError}
          errorText={uniqueLabelError ? I18n.t("labelMustBeUnique") : undefined}
          tooltip={I18n.t("theVariableIsRequiredForTexts")}
          pattern={/^[A-Z_][A-Z0-9_]*$/}
          onChange={onPropertyKeyChange}
        />
        <SelectFormItemComponent
          required
          hasFeedback
          name={"dataType"}
          label={I18n.t("datatype")}
          values={Object.values(ContactsAttributeDatatypeEnum).map((it) => ({
            value: it,
            label: DataTypeTranslation()[it],
          }))}
        />
      </Form>
    </Modal>
  );
};

export default CreateAttribute;
