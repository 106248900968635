import { sortByOrder } from "./sortByOrder";
import { getCurrentTextTranslation } from "./getCurrentTextTranslation";
import { CriteriaType } from "../types/criteriaType";
import { CsiScale } from "../types/answerTemplateType";
import { CsiType } from "../types/csiType";

export const getCriteriaAnswers = (
  csiParams: Array<CsiType>,
  criteria: Array<CriteriaType>,
  csiScale: CsiScale,
): Array<any> => {
  return (
    csiParams
      ?.map((csi) => csi)
      .sort(sortByOrder)
      .map((csi) => ({
        paramName: getCurrentTextTranslation(csi.textTranslations),
        scale: csiScale,
        criterias: criteria
          ?.map((cr) => cr)
          .sort(sortByOrder)
          .map((criteria) => ({
            name: getCurrentTextTranslation(criteria?.textTranslations),
            score: 0,
          })),
      })) || []
  );
};
