import { FC, useCallback } from "react";
import { Button, Dropdown } from "antd";
import { langTitle, Language } from "../types/language";
import { CaretDownOutlined } from "@ant-design/icons";
import { MenuInfo } from "rc-menu/lib/interface";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useActions } from "../hooks/useActions";
import { Translate } from "react-redux-i18n";

const PrivacyPolicyUrl = process.env.REACT_APP_PRIVACY_POLICY_URL as string; // Политика конфиденциальности
const TermsOfUseUrl = process.env.REACT_APP_TERMS_OF_USE_URL as string; // Условия использования
const ReferenceUrl = process.env.REACT_APP_REFERENCE_URL as string; // Справка

const FooterComponent: FC = () => {
  const { setLanguage } = useActions();
  const language = useSelector((state: RootState) => state.persisted.appLanguage);
  const onLanguageChange = useCallback((e: MenuInfo) => setLanguage(e.key as Language), [setLanguage]);

  const openInNewTab = useCallback((url: string) => {
    window.open(url, "_blank");
  }, []);

  const langMenu = {
    onClick: onLanguageChange,
    items: Object.values(Language)
      .map((key) => ({ key, label: langTitle[key] }))
      .filter((it) => it.key !== language),
  };

  return (
    <div className="app-footer">
      <div className="lang-change-select">
        <Dropdown menu={langMenu} trigger={["click"]}>
          <Button className="button" size={"small"} style={{ padding: "15px 10px" }}>
            <div className="lang-label">{langTitle[language]}</div>
            <CaretDownOutlined />
          </Button>
        </Dropdown>
      </div>
      <div className={"links"}>
        <span onClick={() => openInNewTab(PrivacyPolicyUrl)}>
          <Translate value={"footer.privacyPolicy"} />
        </span>
        |
        <span onClick={() => openInNewTab(TermsOfUseUrl)}>
          <Translate value={"footer.termsOfUse"} />
        </span>
        |
        <span onClick={() => openInNewTab(ReferenceUrl)}>
          <Translate value={"footer.reference"} />
        </span>
      </div>
    </div>
  );
};

export default FooterComponent;
