export const TariffsProps = {
  basic: {
    optionsQuantity: 5,
    additionalOptionsQuantity: 1,
  },
  standard: {
    optionsQuantity: 5,
    additionalOptionsQuantity: 1,
  },
  business: {
    optionsQuantity: 5,
    additionalOptionsQuantity: 1,
  },
};
