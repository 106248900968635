import "../../index.scss";
import { FC, ReactNode } from "react";

type Props = {
  onClick: () => void;
  label: string;
  icon: ReactNode;
};

const IconButton: FC<Props> = ({ icon, onClick, label }) => {
  return (
    <div
      style={{
        width: 110,
        minHeight: 110,
        borderRadius: 5,
        cursor: "pointer",
        border: "1px solid #E8E8E8",
        boxShadow: "2px 2px 4px 0px #0000001A",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        gap: 10,
      }}
      onClick={onClick}
      className={"share-page-icon-button"}
    >
      {icon}
      <span
        style={{
          fontSize: 16,
          fontWeight: 400,
        }}
      >
        {label}
      </span>
    </div>
  );
};

export default IconButton;
