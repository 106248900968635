import { FC, useCallback, useEffect, useState } from "react";
import IconButton from "../iconButton";
import BaseCreateModal from "../baseCreateModal";
import { Form } from "antd";
import { useActions } from "../../../../../../../hooks/useActions";
import { useParams } from "react-router-dom";
import { DistributionChannelTypeEnum } from "../../../../../../../types/DistributionChannel";
import InputFormItemComponent from "../../../../../../../components/form/items/input";
import { I18n } from "react-redux-i18n";
import { getSurveyInstanceUrl } from "../../../distributionAndNotification/qrCodes/helpers";
import { copyToClipboard } from "../../../../../../../utils/copyToClipboard";
import { IFrame } from "../../../../../../../components/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { useDistributionChannelsApi } from "../../../../../../../hooks/useDistributionChannelsApi";

const getLink = (surveyId: string, receiver: string) => {
  return `<iframe src="${getSurveyInstanceUrl(surveyId, receiver)}&variant=frame" height="100%" width="100%" />`;
};

type Props = {
  readonly showButton?: boolean;
  readonly openModal?: boolean;
};

const getDefaultProps = (surveyId: string) => ({
  initialValues: {
    description: I18n.t("linkToSurvey"),
    link: getLink(surveyId, ""),
  },
  showButton: true,
  openModal: false,
});

const IFrameButton: FC<Props> = (props) => {
  const { id: surveyId } = useParams();
  const { initialValues, showButton, openModal } = {
    ...getDefaultProps(surveyId ?? ""),
    ...props,
  };
  const [modalOpen, setModalOpen] = useState(openModal ?? false);
  const [form] = Form.useForm<{ description: string; receiver: string }>();
  const { setCurrentDistributionChannel } = useActions();
  const { createDistributionChannel, updateDistributionChannel } = useDistributionChannelsApi();
  const [loading, setLoading] = useState(false);
  const currentChannel = useSelector((state: RootState) => state.distributionChannels.current);

  const handleClick = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const handleCancel = useCallback(() => {
    setModalOpen(false);
    setCurrentDistributionChannel(undefined);
    form.resetFields();
  }, [setCurrentDistributionChannel, setModalOpen, form]);

  const handleSubmit = useCallback(() => {
    if (!surveyId) return;
    setLoading(true);
    form
      .validateFields()
      .then(async (value) => {
        if (currentChannel?.channelId) {
          await updateDistributionChannel({
            channelId: currentChannel.channelId,
            description: value.description,
          });
        } else {
          await createDistributionChannel({
            surveyId: surveyId,
            channelType: DistributionChannelTypeEnum.IFRAME,
            description: value.description,
            receiver: value.receiver,
          });
          handleCancel();
        }
        copyToClipboard(getLink(surveyId, value.receiver));
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [
    setLoading,
    handleCancel,
    setModalOpen,
    createDistributionChannel,
    updateDistributionChannel,
    currentChannel,
    form,
    surveyId,
  ]);

  useEffect(() => {
    form.setFieldValue("description", currentChannel?.description ?? initialValues.description);
    form.setFieldValue("receiver", currentChannel?.receiver);
    form.setFieldValue("link", getLink(surveyId ?? "", currentChannel?.receiver ?? ""));
  }, []);

  return (
    <>
      {showButton && <IconButton icon={<IFrame />} label={"iFrame"} onClick={handleClick} />}
      <BaseCreateModal
        children={
          <Form style={{ marginTop: 24 }} layout={"vertical"} form={form}>
            <Form.Item shouldUpdate noStyle>
              {({ setFieldValue }) => {
                const onChange = (value: string) => {
                  const formattedValue = value.replace(/ /g, "_");
                  setFieldValue("link", getLink(surveyId ?? "", formattedValue));
                  setFieldValue("receiver", formattedValue);
                };
                return (
                  <InputFormItemComponent
                    required
                    name={"receiver"}
                    onChange={onChange}
                    pattern={/^[a-zA-Z0-9_-]+$/}
                    label={I18n.t("identifier")}
                    errorText={I18n.t("identifierErrorMsg")}
                    disabled={Boolean(currentChannel?.channelId)}
                  />
                );
              }}
            </Form.Item>
            <InputFormItemComponent required name={"description"} label={I18n.t("description")} />
            <InputFormItemComponent name={"link"} disabled />
          </Form>
        }
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        loading={loading}
        open={modalOpen}
      />
    </>
  );
};

export default IFrameButton;
