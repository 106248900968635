import "./index.scss";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Button, Tag } from "antd";
import { useActions } from "../../hooks/useActions";
import { conditionsAbbr, factsAbbr, SubscriberCondition } from "../../types/subscriberConditionType";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { SourceConditionType } from "../../types/sourceConditionType";
import ConditionModal from "../condition/conditionModal";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { I18n, Translate } from "react-redux-i18n";
import useSurveyDisabled from "../../hooks/useSurveyDisabled";
import { TEXT_LIGHT_COLOR } from "../../utils";
import ActionButton from "../actionButton";

const SourceConditionsComponent: FC = () => {
  const { getConditions, updateCurrentSurvey } = useActions();
  const conditions = useSelector((state: RootState) => state.conditions.list);
  const sourceConditions: Array<SourceConditionType> = useSelector(
    (state: RootState) => state.surveys?.current?.sourceConditions || [],
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const disabled = useSurveyDisabled();

  const onConditionSave = useCallback(
    (condition: SubscriberCondition, groupId?: number) => {
      onSetCondition(
        sourceConditions.map((sc, i) =>
          i === groupId
            ? {
                ...sc,
                sourceConditions: [...(sc?.sourceConditions || []), condition],
              }
            : sc,
        ),
      );
    },
    [sourceConditions],
  );

  const deleteCondition = useCallback(
    (groupId: number, conditionIndex: number) => {
      onSetCondition(
        sourceConditions.map((sc, i) =>
          i === groupId
            ? {
                ...sc,
                sourceConditions: sc.sourceConditions.filter((_, j) => j !== conditionIndex),
              }
            : sc,
        ),
      );
    },
    [sourceConditions],
  );

  const deleteConditionsGroup = useCallback(
    (groupId: number) => {
      onSetCondition(sourceConditions.filter((_, i) => i !== groupId));
    },
    [sourceConditions],
  );

  const onSetCondition = useCallback(
    (_conditions: Array<SourceConditionType>) => {
      updateCurrentSurvey({ sourceConditions: _conditions });
    },
    [updateCurrentSurvey],
  );

  const onAddGroup = useCallback(() => {
    onSetCondition([...sourceConditions, { sourceConditions: [] } as SourceConditionType]);
  }, [sourceConditions, onSetCondition]);

  const onShowAddForm = useCallback(() => {
    setShowAddModal((prev) => !prev);
  }, [setShowAddModal]);

  useEffect(() => {
    if (!conditions.length) {
      getConditions();
    }
  }, []);

  return (
    <div className={"source-conditions-tag"}>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <div style={{ width: 300 }}>
          <span>
            <Translate value={"triggerCondition"} />:
          </span>
        </div>
        <div style={{ width: 400 }}>
          {!sourceConditions?.length ? (
            <span style={{ color: TEXT_LIGHT_COLOR }}>N/A</span>
          ) : (
            sourceConditions.map((condition, i) => (
              <div key={i} style={{ display: "flex", alignItems: "center", width: "inherit" }}>
                <div className={"conditions-group"}>
                  {condition?.sourceConditions?.map((sc, j) => (
                    <Tag key={sc.value + j} closable={!disabled} onClose={() => deleteCondition(i, j)}>
                      {factsAbbr()[sc.fact] || sc.fact}
                      {": "}
                      <b>
                        {conditionsAbbr()[sc.operator]} {sc.value}
                      </b>
                    </Tag>
                  ))}
                  {disabled ? (
                    <Button type={"dashed"} size={"small"} disabled>
                      <PlusOutlined /> <Translate value={"addFilter"} />
                    </Button>
                  ) : (
                    <Tag
                      onClick={() => {
                        setGroupId(i);
                        onShowAddForm();
                      }}
                      className={"tag-add-btn"}
                    >
                      <PlusOutlined /> <Translate value={"addFilter"} />
                    </Tag>
                  )}
                </div>
                {!disabled && (
                  <div className={"pin"}>
                    <ActionButton
                      label={""}
                      showPlus={true}
                      plusIcon={<DeleteOutlined />}
                      confirmInfo={{
                        label: I18n.t("areYouSureYouWantToDeleteFiltersGroup"),
                        onConfirm: () => deleteConditionsGroup(i),
                      }}
                    />
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
      {!disabled && (
        <Tag
          onClick={onAddGroup}
          className={"tag-add-btn"}
          style={{ marginLeft: 300, width: "max-content", marginTop: 14 }}
        >
          <PlusOutlined /> <Translate value={"addFiltersGroup"} />
        </Tag>
      )}
      <ConditionModal
        paramAsText
        groupId={groupId}
        show={showAddModal}
        onHide={onShowAddForm}
        onFinish={onConditionSave}
      />
    </div>
  );
};

export default SourceConditionsComponent;
