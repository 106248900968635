import { useAccessTokenObj } from "../utils/jwt";
import { APP_ALLOWED_ROLES } from "../utils";

interface IReturnType {
  accessLimited: boolean;
  roles: string[];
}

export const useUserAccessValidation = (): IReturnType => {
  const accessTokenObj = useAccessTokenObj();
  const userRoles = accessTokenObj?.realm_access?.roles ?? [];
  const accessLimited = Boolean(!userRoles.find((ur) => APP_ALLOWED_ROLES.includes(ur)));

  return {
    accessLimited: accessLimited,
    roles: userRoles,
  };
};
