const toRecord = (array: Array<{ key: string; value: string }>) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item.key]: item.value,
    };
  }, initialValue);
};
export default toRecord;
