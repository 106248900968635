import "./index.scss";
import React, { FC, useCallback, useState } from "react";
import { Alert, Button, Form, message, Modal, Space } from "antd";
import { I18n, Translate } from "react-redux-i18n";
import { PlusOutlined } from "@ant-design/icons";
import EmailProvidersTableComponent from "./table";
import { useActions } from "../../../hooks/useActions";
import { FormItems } from "./helpers/formItems";
import { SmsProviderType } from "../../../types/smsProvider";
import FooterActions from "../../../components/FooterActions";

const SmsProvidersComponent: FC = () => {
  const { createSmsProvider } = useActions();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<SmsProviderType>();

  const handleCreateClick = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleCancel = useCallback(() => {
    setOpen(false);
    form.resetFields();
  }, [setOpen, form]);

  const handleSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        createSmsProvider(values);
        handleCancel();
      })
      .catch(() => message.error(I18n.t("fillRequiredFields")));
  }, [form, handleCancel]);

  return (
    <>
      <Alert type={"info"} style={{ marginBottom: 20 }} message={<Translate value={"smsProvidersMainDescription"} />} />
      <div className={"sms-providers-page-layout"}>
        <Space direction={"horizontal"} className={"btns"}>
          <Button type={"primary"} icon={<PlusOutlined />} onClick={handleCreateClick}>
            <Translate value={"add"} />
          </Button>
        </Space>
        <EmailProvidersTableComponent />

        <Modal
          open={open}
          onCancel={handleCancel}
          title={I18n.t("add")}
          footer={<FooterActions onCancel={handleCancel} onSubmit={handleSubmit} submitLabelTranslationKey={"add"} />}
        >
          <Form form={form} layout={"vertical"} style={{ marginTop: 20 }}>
            <FormItems />
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default SmsProvidersComponent;
