import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactType } from "../../../types/contactType";
import { ContactsAttributeType } from "../../../types/ContactsAttributeType";

type StateType = {
  list: Array<ContactType>;
  current?: ContactType;
  attributesList: Array<ContactsAttributeType>;
};
const initialState: StateType = {
  list: [],
  current: undefined,
  attributesList: [],
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<Array<ContactType>>) {
      state.list = action.payload;
    },
    removeFromList(state: StateType, action: PayloadAction<string>) {
      state.list = state.list.filter((it) => it?.contactId !== action.payload);
    },
    addToList(state: StateType, action: PayloadAction<ContactType>) {
      state.list = [action.payload, ...state.list];
    },
    setCurrent(state: StateType, action: PayloadAction<ContactType | undefined>) {
      state.current = action.payload;
    },
    updateInList(state: StateType, action: PayloadAction<ContactType | undefined>) {
      state.list = state.list.map(
        (it) => (it.contactId === action.payload?.contactId ? action.payload : it) as ContactType,
      );
    },
    setAttributesList(state: StateType, action: PayloadAction<Array<ContactsAttributeType>>) {
      state.attributesList = action.payload;
    },
    removeFromAttributesList(state: StateType, action: PayloadAction<string>) {
      state.attributesList = state.attributesList.filter((it) => it?.propertyId !== action.payload);
    },
    addToAttributesList(state: StateType, action: PayloadAction<ContactsAttributeType>) {
      state.attributesList = [action.payload, ...state.attributesList];
    },
    updateInAttributesList(state: StateType, action: PayloadAction<ContactsAttributeType | undefined>) {
      state.attributesList = state.attributesList.map(
        (it) => (it.propertyId === action.payload?.propertyId ? action.payload : it) as ContactsAttributeType,
      );
    },
  },
});

export const {
  setList,
  removeFromList,
  setCurrent,
  addToList,
  updateInList,
  setAttributesList,
  removeFromAttributesList,
  addToAttributesList,
  updateInAttributesList,
} = contactsSlice.actions;
export default contactsSlice.reducer;
