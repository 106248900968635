import "./index.scss";
import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { SURVEYS_PAGE } from "../../utils/paths";
import dayjs, { Dayjs } from "dayjs";
import { Translate } from "react-redux-i18n";
import { DEFAULT_DATE_FORMAT, TEXT_LIGHT_COLOR } from "../../utils";
import { useActions } from "../../hooks/useActions";
import { StepsEnum } from "../../types/surveyEditTabsEnum";
import { SurveyStatus } from "../../types/surveyType";

const SurveyNameComponent: FC<{
  readonly id?: string;
  readonly status?: SurveyStatus;
  readonly surveyName: string;
  readonly createdAt?: string | Dayjs;
}> = ({ id, surveyName, status, createdAt }) => {
  const navigate = useNavigate();
  const { setSurveyEditActiveStep } = useActions();

  const onEdit = useCallback(() => {
    if (status === SurveyStatus.ACTIVE) {
      setSurveyEditActiveStep(StepsEnum.RESULTS);
    }
    navigate(`${SURVEYS_PAGE}/${id}/edit`);
  }, [status, id]);

  return (
    <div className={"survey-name"} onClick={onEdit}>
      <span>{surveyName}</span>
      <span style={{ color: TEXT_LIGHT_COLOR }}>
        <Translate value={"createdAt"} />
        {dayjs(createdAt).format(DEFAULT_DATE_FORMAT)?.toString()}
      </span>
    </div>
  );
};

export default SurveyNameComponent;
