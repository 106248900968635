import { FC, useEffect, useState, useCallback } from "react";
import { Form } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { getOperatorsList } from "../../../../../types/subscriberConditionType";
import isWelcome from "../../../../../utils/welcomeSmsChecker";
import { TemplateEnum } from "../../../../../types/templateEnum";
import SelectFormItemComponent from "../../../../../components/form/items/select";
import InputFormItemComponent from "../../../../../components/form/items/input";

const PreviousAnswerConditionComponent: FC = () => {
  const operators = getOperatorsList();
  const { current: question, list: questions } = useSelector((state: RootState) => state.questions);
  const [isMultiple, setMultiple] = useState(false);
  const [prevAnswerChoices, setPrevAnswerChoices] = useState<Array<{ text: string; id: string }>>([]);

  useEffect(() => {
    const multiple = checkIsMultiple(question?.previousAnswerCondition?.operator);
    setMultiple(multiple);

    if (multiple) loadChoices(question?.previousAnswerCondition?.answeredQuestionId);
  }, [question?.previousAnswerCondition?.operator, question?.previousAnswerCondition?.answeredQuestionId]);

  const checkIsMultiple = useCallback((type?: string) => {
    return type === "ONE_OF" || type === "NOT_ONE_OF";
  }, []);

  const loadChoices = useCallback(
    (answeredQuestionId?: string) => {
      const template = questions.find(
        (q) => q.questionId === (answeredQuestionId || question?.previousAnswerCondition?.answeredQuestionId),
      )?.answerTemplate;

      if (template?.type === TemplateEnum.SINGLE_CHOICE || template?.type === TemplateEnum.MULTIPLE_CHOICE) {
        const newChoices: Array<{ text: string; id: string }> = [];

        template?.answerChoices.forEach((choice) => {
          const firstTranslation = choice?.textTranslations?.[0];
          if (firstTranslation?.text && choice?.answerChoiceId) {
            newChoices.push({ text: firstTranslation.text, id: choice.answerChoiceId.toString() });
          }
        });

        const uniqueChoices = Array.from(new Map(newChoices.map((choice) => [choice.id, choice])).values());

        setPrevAnswerChoices(uniqueChoices);
      } else {
        setPrevAnswerChoices([]);
      }
    },
    [question, questions, setPrevAnswerChoices],
  );

  return (
    <div style={{ display: "flex", width: "100%", gap: 10 }}>
      <div style={{ flex: 1 }}>
        <Form.Item noStyle shouldUpdate>
          {({ setFieldValue }) => {
            function resetValue(answeredQuestionId: string) {
              if (isMultiple) {
                loadChoices(answeredQuestionId);
              } else {
                setPrevAnswerChoices([]);
              }
              setFieldValue(["previousAnswerCondition", "value"], undefined);
            }

            return (
              <SelectFormItemComponent
                noStyle
                label={""}
                required={true}
                hasFeedback={false}
                onChange={resetValue}
                style={{ width: 370 }}
                name={["previousAnswerCondition", "answeredQuestionId"]}
                values={questions
                  .filter((q) => q.questionId !== question?.questionId && !isWelcome(q))
                  .map((q) => ({
                    value: q.questionId || "",
                    label: q.name,
                  }))}
              />
            );
          }}
        </Form.Item>
      </div>
      <div style={{ display: "flex", gap: 10, flex: 1 }}>
        <Form.Item noStyle shouldUpdate>
          {({ setFieldValue, getFieldValue }) => {
            function resetValue(e: string) {
              const _isOneOf = checkIsMultiple(e);
              setMultiple(_isOneOf);
              if (_isOneOf) {
                const answeredQuestionId = getFieldValue(["previousAnswerCondition", "answeredQuestionId"]);
                loadChoices(answeredQuestionId);
              }
              setFieldValue(["previousAnswerCondition", "value"], undefined);
            }

            return (
              <SelectFormItemComponent
                noStyle
                label={""}
                required={true}
                values={operators}
                hasFeedback={false}
                style={{ flex: 1 }}
                onChange={resetValue}
                name={["previousAnswerCondition", "operator"]}
              />
            );
          }}
        </Form.Item>
        {isMultiple ? (
          <SelectFormItemComponent
            noStyle
            required
            label={""}
            maxTagCount={1}
            mode={"multiple"}
            style={{ flex: 1 }}
            hasFeedback={false}
            name={["previousAnswerCondition", "value"]}
            values={prevAnswerChoices.map((it) => ({ label: it.text, value: it.id }))}
          />
        ) : (
          <InputFormItemComponent name={["previousAnswerCondition", "value"]} label={""} style={{ flex: 1 }} />
        )}
      </div>
    </div>
  );
};

export default PreviousAnswerConditionComponent;
