import { FC, ReactNode } from "react";

import { ConfigProvider } from "antd";
import { APP_PRIMARY_COLOR } from "../index";

const StylesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ConfigProvider
      button={{
        style: {
          boxShadow: "none",
        },
      }}
      theme={{
        token: {
          colorPrimary: APP_PRIMARY_COLOR,
          colorPrimaryHover: "#00934F",
          colorPrimaryActive: "#00934F",
          borderRadius: 4,
        },
        components: {
          Button: {
            controlHeight: 40,
            colorText: APP_PRIMARY_COLOR,
            colorBorder: APP_PRIMARY_COLOR,
          },
          Input: {
            controlHeight: 40,
          },
          InputNumber: {
            controlHeight: 40,
          },
          DatePicker: {
            controlHeight: 40,
          },
          Select: {
            controlHeight: 40,
            borderRadius: 4,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default StylesProvider;
