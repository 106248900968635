import { FC, useCallback, useEffect, useState } from "react";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { I18n } from "react-redux-i18n";
import { LabelValue } from "../../../../../../types/labelValue";
import StatisticsLayout from "../../../../../../components/statisticsLayout";
import { ColumnChart } from "../../../../../../components/charts";
import { requestApi } from "../../../../../../utils/requestApi";
import { POST } from "../../../../../../types/httpMethod";
import { DATE_FORMAT } from "../../../../../../utils";
import { useParams } from "react-router-dom";
import { mapMonthList } from "../helpers";

const AnswersByDays: FC = () => {
  const { id: surveyId } = useParams();
  const [selectedFilter, setSelectedFilter] = useState<Dayjs>(dayjs(new Date()));
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<Array<LabelValue>>([]);

  const onFilter = useCallback((value: Dayjs | null) => {
    if (value) {
      setSelectedFilter(value);
      loadData(value.format(DATE_FORMAT));
    }
  }, []);

  const loadData = useCallback(
    (date?: string) => {
      setLoading(true);
      requestApi(
        POST,
        "/chart/answers-by-day",
        { month: date || selectedFilter.format(DATE_FORMAT) },
        false,
        false,
        {},
        { surveyId },
      )
        .then((res) => setData(mapMonthList(res)))
        .finally(() => setLoading(false));
    },
    [selectedFilter, surveyId],
  );

  useEffect(() => {
    loadData();
  }, []);

  return (
    <StatisticsLayout title={I18n.t("answersByDays")}>
      <DatePicker
        inputReadOnly
        picker={"month"}
        allowClear={false}
        onChange={onFilter}
        format={"MMMM YYYY"}
        value={selectedFilter}
        style={{ width: 160, marginBottom: 24 }}
      />
      <ColumnChart data={data} loading={isLoading} height={400} />
    </StatisticsLayout>
  );
};

export default AnswersByDays;
