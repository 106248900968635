import { TourItemsEnum } from "../../../types/tour";

export const EnTourTranslations = {
  tours: {
    startTour: "Start training",
    rejectTour: "Refuse training",
    important: "Important!",
    link: "Link",
    [TourItemsEnum.WELCOME]: {
      title: "Hello!",
      description: "Welcome to Survey Suite! Let's start getting acquainted with our service.",
      myProfile: "My profile",
      myProfileDescription:
        "Here you will find your personal information, be able to change your password, adjust your subscription, and select a convenient time zone.",
      surveys: "Surveys",
      surveysDescription: "All your created surveys are available on this page for easy management.",
      settings: "Settings",
      settingsDescription:
        "Customize your account: add a public API key for trigger surveys, set exclusion dates for mailings, configure connections to Email and SMS service providers, and add new users.",
      contacts: "Contacts",
      contactsDescription:
        "Here you can upload contacts of your respondents (email addresses, phone numbers, preferred communication languages, and any other details).",
      createSurvey: "Create Survey",
      createSurveyDescription: "Start creating your first survey!",
    },
    [TourItemsEnum.CREATE_SURVEY]: {
      title: "Creating a survey",
      description: "Let's start creating your first survey!",
      submitBtn: "Continue",
      cancelBtn: "Refuse training",
      surveyNameTitle: "Survey name",
      surveyNameDescription:
        "Enter the name of the survey, it will not be visible to respondents. This name was created for your convenience to distinguish surveys from each other.",
      surveyTypeTitle: "Type of survey",
      surveyTypeDescription:
        "WEB survey is a survey that the respondent can take on a special web page of the service. You can share the questionnaire of such a survey by QR, share a web link via social networks, or send it by email.",
      surveyTypeSubDescription:
        "SMS survey is a survey via an SMS channel, where each question and answer are transmitted by separate SMS messages (connection to an SMS provider is required).",
      tagsTitle: "Tags",
      tagsDescription: `Tags will help you group surveys by a common feature, for example, “Performance Assessment” or “Department Survey".`,
      languagesTitle: "Languages",
      languagesDescription:
        "Select from the list of available languages in which language you want respondents to take it. You can select several languages, and when completing the survey, the respondent will be able to choose the one that is convenient for him.",
      languagesSubDescription: "After creating a survey, the list of languages cannot be changed.",
      nextButtonTitle: "Further",
      nextButtonDescription: "After filling in all the data, click “Next” to create a survey.",
    },
    [TourItemsEnum.CREATE_SURVEY_QUESTIONS]: {
      title: "List of questions",
      description:
        "A list of questionnaire questions will be displayed here. The list is empty, let's start filling out the questionnaire.",
      submitBtn: "Continue",
      cancelBtn: "Refuse training",
      questionsTableTitle: "List of questions",
      questionsTableDescription:
        "A list of questionnaire questions will be displayed here. The list is empty, let's start filling out the questionnaire.",
      createQuestionButtonTitle: "Create a question",
      createQuestionButtonDescription: `To start, click on the “Create a question" button.`,
      questionNameTitle: "Name of the issue",
      questionNameDescription:
        "Enter the name of the question – this is an internal name that will help you distinguish the questions from each other and will be displayed in the reports in the future. For example, the question of the quality of cleaning in the premises.",
      templateTitle: "Pattern",
      templateDescription1:
        "Answer templates allow you to collect answers to a question in a format that is convenient for you.",
      templateDescription2:
        "We have many different response templates: selecting one item from a list, text input, email or mobile number input.",
      templateDescription3:
        "Choose any template you like that you need. For more information about the templates, follow the link.",
      languageSelectingTitle: "Language selection",
      languageSelectingDescription1:
        "Through these tabs, you can switch between fields in which you can ask the text of questions and answers in the appropriate languages.",
      languageSelectingDescription2:
        "Tabs with the languages that were selected when creating the survey are displayed.",
      languageSelectingDescription3:
        "Important! To create a survey correctly, all fields in all tabs must be filled in.",
      questionSettingsTitle: "Question Settings",
      questionSettingsDescription:
        "Enter the text of the question or announcement based on the selected template. Manage the unique settings of each question template. Don't forget to add translations in all languages.",
      questionPreviewTitle: "Previewing the question",
      questionPreviewDescription:
        "To make it easier for you to select the desired template, your question will be displayed in this window, as the respondents will see it.",
      questionShowLogicTitle: "The logic of showing the question",
      questionShowLogicDescription1:
        "You can customize the logic of branching questions, for example, if the user chose option <2 for the question “Rate the application”, show the question “What did you not like?”.",
      questionShowLogicDescription2:
        "You can also add additional filters for who exactly to show this question to (based on the data in the contact book).",
      tagsTitle: "Tags",
      tagsDescription: "Question labels will help you further group questions for reporting.",
      questionsListTitle: "List of questions",
      questionsListDescription: `Here you will see all the questions that you have created and you can switch between them to make edits. And to create a new question, click “Add a question".`,
      backButtonTitle: "Back to the question table",
      backButtonDescription:
        "After creating all the questions (or during a break), you can return to the page with the table of questions in order to change the order of the questions, create a copy of the question or delete it altogether.",
      surveyPreviewButtonTitle: "Preview of the survey",
      surveyPreviewButtonDescription: `If everything is ready, you can take the survey in test mode by clicking the “Preview survey" button. See what the survey will look like for your respondents before sending it out.`,
    },
    nextButtonTitle: "Next",
    prevButtonTitle: "Back",
  },
};
