import { FC } from "react";
import CopyLinkButton from "./components/shareButtons/copyLink";
import QrCodeButton from "./components/shareButtons/qrCode";
import IFrameButton from "./components/shareButtons/iFrame";
import SocialMediaButton from "./components/shareButtons/socialMedia";
import EmailButton from "./components/shareButtons/email";
import SMSButton from "./components/shareButtons/sms";

const ShareButtons: FC = () => {
  return (
    <div style={{ display: "flex", gap: 35, marginBottom: 48, flexWrap: "wrap" }}>
      <CopyLinkButton />
      <QrCodeButton />
      <IFrameButton />
      <SocialMediaButton />
      <EmailButton />
      <SMSButton />
    </div>
  );
};

export default ShareButtons;
