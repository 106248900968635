import { FC, useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

import { LabelValue } from "../../types/labelValue";
import { getRandomKey } from "../../pages/surveys/edit/steps/results/helpers";

type ColumnChartProps = {
  data: Array<LabelValue>;
  height: number;
  key?: string;
};

const ColumnChartComponent: FC<ColumnChartProps> = ({ data, height, key = getRandomKey() }) => {
  const seriesRef = useRef<am5xy.ColumnSeries | null>(null);
  const xAxisRef = useRef<am5xy.CategoryAxis<any> | null>(null);

  useLayoutEffect(() => {
    const root = am5.Root.new(key);

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      }),
    );

    let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({});

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    );

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: xRenderer,
        categoryField: "label",
        maxDeviation: 0.3,
        tooltip: am5.Tooltip.new(root, {}),
      }),
    );

    let series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "label",
        sequencedInterpolation: true,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{value}",
        }),
      }),
    );

    series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
    series.columns.template.adapters.add("fill", function (fill, target) {
      return chart?.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart?.get("colors")?.getIndex(series.columns.indexOf(target));
    });

    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    xAxisRef.current = xAxis;
    seriesRef.current = series;

    return () => {
      root.dispose();
    };
  }, []);

  useLayoutEffect(() => {
    if (xAxisRef.current) xAxisRef.current.data.setAll(data);
    if (seriesRef.current) seriesRef.current.data.setAll(data);
  }, [data]);

  return <div id={key} style={{ width: "100%", height }} />;
};

export default ColumnChartComponent;
