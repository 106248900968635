import React, { CSSProperties, FC, ReactNode } from "react";
import { BaseButtonProps } from "antd/es/button/button";
import FlexSpace from "../../components/FlexSpace";
import { Button, Popconfirm } from "antd";
import { Translate } from "react-redux-i18n";
import { ButtonProps } from "antd/lib";

type BoxProps = {
  children: ReactNode;
  additionalContent?: ReactNode;
  title: string;
  onChangeClick?: () => void;
  buttonLabel?: string;
  buttonType?: BaseButtonProps["type"];
  buttonNode?: ReactNode;
  style?: CSSProperties;
  alignItems?: CSSProperties["alignItems"];
};

export const Box = (props: BoxProps) => {
  return (
    <AccountSettingsItemLayout title={props.title} style={props.style}>
      <FlexSpace position={"space-around"} alignItems={props?.alignItems}>
        <div style={{ width: "50%" }}>{props.children}</div>
        {props.buttonNode ? (
          props.buttonNode
        ) : (
          <Button type={props.buttonType || "primary"} onClick={props?.onChangeClick}>
            <span style={{ maxWidth: 100, overflow: "hidden" }}>
              {props.buttonLabel || <Translate value={"change"} />}
            </span>
          </Button>
        )}
      </FlexSpace>
      {props?.additionalContent && <>{props?.additionalContent}</>}
    </AccountSettingsItemLayout>
  );
};

export const AccountSettingsItemLayout = (props: Pick<BoxProps, "children" | "title" | "style">) => {
  return (
    <div
      style={{
        width: "600px",
        borderRadius: 20,
        padding: "30px 0 30px 0",
        backgroundColor: "#F9F9F9",
        border: "1px solid #d3d3d345",
        ...props?.style,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          marginBottom: 24,
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h3 style={{ margin: 0, fontWeight: 600 }}>{props.title}</h3>
      </div>
      {props.children}
    </div>
  );
};

export const BoxContent: FC<{
  readonly title: string;
  readonly description: string;
  readonly style?: CSSProperties;
}> = ({ title, description, style }) => {
  return (
    <div style={style}>
      <span style={{ fontSize: 19, fontWeight: 500 }}>{title}</span>
      <span style={{ color: "gray" }}>{description}</span>
    </div>
  );
};

type BtnProps = ButtonProps & {
  readonly label: string;
  readonly confirm?: {
    readonly text: string;
    readonly action: () => void;
  };
};

export const BoxButtons: FC<{
  readonly button1?: BtnProps;
  readonly button2?: BtnProps;
  readonly style?: CSSProperties;
}> = ({ button1, button2, style = { display: "flex", flexDirection: "column", gap: 10 } }) => {
  return (
    <div style={style}>
      {!button1 ? null : button1?.confirm ? (
        <Popconfirm
          disabled={button1.disabled}
          title={button1.confirm.text}
          onConfirm={button1.confirm.action}
          overlayInnerStyle={{ whiteSpace: "nowrap" }}
        >
          <Button style={{ width: "100%" }} type={button1?.type ?? "primary"} {...button1} onClick={undefined}>
            {button1.label}
          </Button>
        </Popconfirm>
      ) : (
        <Button style={{ width: "100%" }} type={button1?.type ?? "primary"} {...button1}>
          {button1.label}
        </Button>
      )}
      {!button2 ? null : button2?.confirm ? (
        <Popconfirm
          disabled={button2.disabled}
          title={button2.confirm.text}
          onConfirm={button2.confirm.action}
          overlayInnerStyle={{ whiteSpace: "nowrap" }}
        >
          <Button style={{ width: "100%" }} type={button2?.type ?? "default"} {...button2} onClick={undefined}>
            {button2.label}
          </Button>
        </Popconfirm>
      ) : (
        <Button style={{ width: "100%" }} type={button2?.type ?? "default"} {...button2}>
          {button2.label}
        </Button>
      )}
    </div>
  );
};
