import { FC } from "react";
import Loading from "../loading";
import ColumnChartComponent from "./columnChart";
import { LabelValue } from "../../types/labelValue";
import DonutChartComponent from "./donutChart";
import { Empty } from "antd";

type Props = {
  readonly loading: boolean;
  readonly data: Array<LabelValue>;
  readonly height: number;
  readonly alignLabels?: boolean;
  readonly legend?: boolean;
};

const ColumnChart: FC<Omit<Props, "alignLabels" | "legend">> = ({ loading, data, height }) => {
  if (loading) {
    return <Loading height={height} />;
  }

  if (!data.length) {
    return (
      <Empty
        style={{ height, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}
      />
    );
  }

  return <ColumnChartComponent data={data} height={height} />;
};

const DonutChart: FC<Props> = ({ loading, data, height, alignLabels = false, legend }) => {
  if (loading) {
    return <Loading height={height} />;
  }

  if (!data.length) {
    return (
      <Empty
        style={{ height, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}
      />
    );
  }

  return <DonutChartComponent data={data} height={height} alignLabels={alignLabels} legend={legend} />;
};

export { ColumnChart, DonutChart };
