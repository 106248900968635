import { useCallback, useMemo, useState } from "react";
import { conditionsAbbr, FactsEnum, SubscriberCondition } from "../../types/subscriberConditionType";
import { LabelValue } from "../../types/labelValue";
import store from "../../store";
import { Form } from "antd";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../utils";

export const useConditions = (
  handleFinish: (condition: SubscriberCondition, groupId?: number) => void,
  groupId?: number,
  handleHide?: () => void,
) => {
  const [selectedFact, setSelectedFact] = useState<FactsEnum | undefined>();
  const [operators, setOperators] = useState<Array<LabelValue>>([]);
  const conditions = store.getState().conditions.list;
  const [form] = Form.useForm<SubscriberCondition>();

  const factSelectHandler = useCallback(
    (fact: any) => {
      setSelectedFact(fact);
      form.resetFields(["value", "operator"]);
      setOperators(
        conditions
          .find((c) => c.fact === fact)
          ?.operators.map((str) => ({
            value: str,
            label: conditionsAbbr()[str],
          })) || [],
      );
    },
    [setSelectedFact, form, setOperators, conditions],
  );
  const onClear = useCallback(() => {
    setSelectedFact(undefined);
    form.resetFields();
    if (handleHide) {
      handleHide();
    }
  }, [form, setSelectedFact]);

  const onSave = useCallback(() => {
    form
      .validateFields()
      .then((condition) => {
        handleFinish(
          selectedFact === FactsEnum.MSISDN_ACTIVATION_DATE
            ? {
                ...condition,
                value: dayjs(condition.value).format(DATE_FORMAT),
              }
            : condition,
          groupId,
        );
        onClear();
      })
      .catch(console.log);
  }, [form, selectedFact, handleFinish, onClear]);

  const value = useMemo(
    () => ({
      selectedFact,
      setSelectedFact,
      operators,
      setOperators,
      conditions,
      form,
      factSelectHandler,
      onClear,
      onSave,
    }),
    [selectedFact, setSelectedFact, operators, setOperators, conditions, form, factSelectHandler, onClear, onSave],
  );

  return { ...value };
};

export default useConditions;
