import { TourItemsEnum } from "../../../types/tour";

export const RuTourTranslations = {
  tours: {
    startTour: "Начать обучение",
    rejectTour: "Отказаться от обучения",
    important: "Важно!",
    link: "Ссылка",
    [TourItemsEnum.WELCOME]: {
      title: "Привет!",
      description: "Добро пожаловать в Survey Suite! Давайте начнем знакомство с нашим сервисом.",
      myProfile: "Мой профиль",
      myProfileDescription:
        "Здесь вы найдете свою личную информацию, сможете изменить пароль, настроить тариф и выбрать удобный часовой пояс.",
      surveys: "Опросы",
      surveysDescription: "Все ваши созданные опросы доступны на этой странице для удобного управления.",
      settings: "Настройки",
      settingsDescription:
        "Настройте свой аккаунт: добавьте публичный API-ключ для триггерных опросов, установите даты исключений для рассылок, настройте подключение к провайдерам сервисов рассылки Email и SMS,  добавьте новых пользователей.",
      contacts: "Контакты",
      contactsDescription:
        "Здесь вы можете загрузить контакты ваших респондентов (адреса электронной почты, номера телефонов, языки коммуникации с ними и любые другие).",
      createSurvey: "Создать опрос",
      createSurveyDescription: "Начните создание вашего первого опроса!",
    },
    [TourItemsEnum.CREATE_SURVEY]: {
      title: "Создание опроса",
      description: "Начнем создание вашего первого опроса!",
      submitBtn: "Продолжить",
      cancelBtn: "Отказаться от обучения",
      surveyNameTitle: "Наименование опроса",
      surveyNameDescription:
        "Введите наименование опроса, оно не будет видно респондентам. Данное наименование создано для вашего удобства, чтобы отличать опросы друг от друга.",
      surveyTypeTitle: "Тип опроса",
      surveyTypeDescription:
        "WEB опрос - опрос который респондент может пройти на специальной web-странице сервиса. Анкетой такого опроса можно поделится по QR, поделится web-ссылкой посредством социальных сетей, отправить на email.",
      surveyTypeSubDescription:
        "SMS опрос - опрос посредством канала SMS, где каждый вопрос и ответ передаются отдельными SMS сообщениями (требуется подключение к SMS-провайдеру).",
      tagsTitle: "Метки",
      tagsDescription:
        "Метки помогут группировать опросы по общему признаку, например, “Оценка эффективности” или “Опрос в отделении”.",
      languagesTitle: "Языки",
      languagesDescription:
        "Выберите из списка доступных языков, на каком языке Вы хотите чтобы респонденты его проходили. Можно выбрать несколько языков, и при прохождении опроса респондент сможет сам выбрать удобный для него.",
      languagesSubDescription: "После создания опроса, список языков не может быть изменен. ",
      nextButtonTitle: "Далее",
      nextButtonDescription: "После заполнения всех данных кликните “Далее”, чтобы создать опрос.",
    },
    [TourItemsEnum.CREATE_SURVEY_QUESTIONS]: {
      title: "Список вопросов",
      description: "Здесь будет отображаться список вопросов анкеты. Список пуст, давайте начнем заполнять анкету.",
      submitBtn: "Продолжить",
      cancelBtn: "Отказаться от обучения",
      questionsTableTitle: "Список вопросов",
      questionsTableDescription:
        "Здесь будет отображаться список вопросов анкеты. Список пуст, давайте начнем заполнять анкету.",
      createQuestionButtonTitle: "Создать вопрос",
      createQuestionButtonDescription: "Для начала кликните на кнопку “Создать вопрос”.",
      questionNameTitle: "Наименование вопроса",
      questionNameDescription:
        "Введите наименование вопроса – это внутреннее название, которое поможет вам отличать вопросы друг от друга и в дальнейшем будет отображено в отчётах. К примеру, вопрос о качестве уборки в помещениях.",
      templateTitle: "Шаблон",
      templateDescription1:
        "Шаблоны ответов позволяют собирать ответы на вопрос в том формате, который удобен для вас.",
      templateDescription2:
        "У нас есть много разных шаблонов ответов: выбор одного пункта из списка, текстовый ввод, ввод email или мобильного номера.",
      templateDescription3:
        "Выберите любой понравившийся шаблон, который необходим Вам. Подробнее о шаблонах по ссылке.",
      languageSelectingTitle: "Выбор языка",
      languageSelectingDescription1:
        "Посредством этих вкладок вы сможете переключаться между полями в которых вы сможете задавать текст вопросов и ответов на соответствующих языках.",
      languageSelectingDescription2: "Отображаются вкладки с языками, которые были выбраны при создании опроса. ",
      languageSelectingDescription3:
        "Важно! Для корректного создания опроса, все поля во всех вкладках должны быть заполнены.",
      questionSettingsTitle: "Настройки вопроса",
      questionSettingsDescription:
        "Введите текст вопроса или объявление на основании выбранного шаблона. Управляйте уникальными настройками каждого шаблона вопроса. Не забудьте добавить переводы на всех языках.",
      questionPreviewTitle: "Предпросмотр вопроса",
      questionPreviewDescription:
        "Чтобы вам было удобнее выбрать необходимый шаблон, в этом окошке отобразится ваш вопрос, как его будут видеть респонденты.",
      questionShowLogicTitle: "Логика показа вопроса",
      questionShowLogicDescription1:
        "Вы можете настроить логику ветвления вопросов, например, если на вопрос “Оцените приложение” пользователь выбрал вариант <2, покажи вопрос “Что вам не понравилось?”.",
      questionShowLogicDescription2:
        "Так же, можно добавить дополнительные фильтры, кому именно показывать этот вопрос (на основании данных в контактной книге).",
      tagsTitle: "Метки",
      tagsDescription: "Метки вопроса помогут в дальнейшем группировать вопросы для отчётности.",
      questionsListTitle: "Список вопросов",
      questionsListDescription:
        "Тут вы увидите все вопросы, которые создали и можете переключаться между ними, чтобы внести правки. А для создания нового вопроса кликните “Добавить вопрос”.",
      backButtonTitle: "Назад к таблице вопросов",
      backButtonDescription:
        "После создания всех вопросов (или в перерыве) вы можете вернуться на страницу с таблицей вопросов для того, чтобы изменить порядок вопросов, создать копию вопроса или вовсе его удалить.",
      surveyPreviewButtonTitle: "Предпросмотр опроса",
      surveyPreviewButtonDescription:
        "Если всё готово, вы можете пройти опрос в тестовом режиме, кликнув кнопку “Предпросмотр опроса”. Посмотрите, как будет выглядеть опрос для ваших респондентов перед его рассылкой.",
    },
    nextButtonTitle: "Далее",
    prevButtonTitle: "Назад",
  },
};
