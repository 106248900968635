import { I18n } from "react-redux-i18n";
import { TextTranslationType } from "./textTranslationType";
import { NpsIconEnum } from "./npsIconEnum";

export type NpsInfoType = {
  icon?: NpsIconEnum;
  maxGrade: number;
  npsParams?: Array<NpsTextValuesType>;
  type: NpsTypeEnum;
};

export type NpsTextValuesType = {
  readonly grade: NpsGradeEnum;
  readonly textKk: string;
  readonly textRu: string;
  readonly textEn: string;
  readonly textTranslations?: Array<TextTranslationType>;
};

export enum NpsGradeEnum {
  MIN_GRADE = "MIN_GRADE",
  MAX_GRADE = "MAX_GRADE",
}

export enum NpsTypeEnum {
  DROPDOWN = "DROPDOWN",
  RADIO_BUTTONS = "RADIO_BUTTONS",
  RATING = "RATING",
  SLIDER = "SLIDER",
}

export const npsGradeTitles: Record<NpsGradeEnum, string> = {
  [NpsGradeEnum.MIN_GRADE]: "Мин.",
  [NpsGradeEnum.MAX_GRADE]: "Макс.",
};

export const npsTypeTitles = (): Record<NpsTypeEnum, string> => ({
  [NpsTypeEnum.RATING]: I18n.t("visualRow"),
  [NpsTypeEnum.SLIDER]: I18n.t("slider"),
  [NpsTypeEnum.DROPDOWN]: I18n.t("dropdown"),
  [NpsTypeEnum.RADIO_BUTTONS]: I18n.t("radioButtons"),
});
