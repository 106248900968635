import "./index.scss";
import React, { FC, useEffect } from "react";
import { Button, message, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { renderNullableDate, renderNullableText } from "../../../../utils/renderNullableText";
import { ApiKeyType } from "../../../../types/apiKeyType";
import { CopyOutlined } from "@ant-design/icons";
import store, { RootState } from "../../../../store";
import { deleteApiKey } from "../../../../store/slices/apiKeys/apiKeysService";
import { useSelector } from "react-redux";
import { useActions } from "../../../../hooks/useActions";
import { I18n, Translate } from "react-redux-i18n";

const columns = (): ColumnsType<ApiKeyType> => {
  return [
    {
      key: "createDate",
      dataIndex: "createDate",
      title: I18n.t("date"),
      render: (_, record) => renderNullableDate(record?.createDate),
    },
    {
      key: "apiKey",
      dataIndex: "apiKey",
      title: I18n.t("apiKey"),
      render: (text: string) => (
        <div className={"table-copy-btn"}>
          {renderNullableText(text)}
          {text && <CopyOutlined onClick={() => copyToClipboard(text)} />}
        </div>
      ),
    },
    {
      key: "description",
      dataIndex: "description",
      title: I18n.t("description"),
      render: renderNullableText,
    },
    {
      key: "action",
      title: I18n.t("action"),
      render: (text, record) => (
        <Popconfirm title={I18n.t("apiKeyDeleteConfirmation")} onConfirm={() => apiKeyDeleteHandler(record?.apiKeyId)}>
          <Button danger type={"text"}>
            <Translate value={"delete"} />
          </Button>
        </Popconfirm>
      ),
    },
  ];
};

function copyToClipboard(apiKey: string) {
  navigator.clipboard.writeText(apiKey).then(() => message.success(I18n.t("apiKeyCopiedToCopyBoard")));
}

function apiKeyDeleteHandler(id?: number) {
  if (!id) return;
  store.dispatch(deleteApiKey(id));
}

const ApiKeyTableComponent: FC = () => {
  const data = useSelector((state: RootState) => state.apiKeys.list);

  const { getApiKeys } = useActions();

  useEffect(() => {
    getApiKeys();
  }, []);

  return (
    <Table
      dataSource={data}
      columns={columns()}
      rowKey={(record: ApiKeyType) => record?.apiKeyId + ""}
      pagination={{ size: "small", showSizeChanger: true, defaultPageSize: 10 }}
    />
  );
};

export default ApiKeyTableComponent;
