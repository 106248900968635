import React, { createContext, FC, ReactNode, useContext, useMemo, useState } from "react";
import { Modal } from "antd";

export interface ModalContextProps {
  isOpen: boolean;
  getModalProps: () => ModalProps | null;
  openModal: (props: ModalProps) => void;
  closeModal: () => void;
}

const useModalState = () => {
  const [isOpen, setOpen] = useState(false);
  return { isOpen, setOpen };
};

const ModalContext = createContext<ModalContextProps>({
  isOpen: false,
  getModalProps: () => null,
  closeModal: () => {},
  openModal: () => {},
});

type ModalProviderProps = {
  readonly children: ReactNode;
};

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const { isOpen, setOpen } = useModalState();
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);

  const openModal = (props: ModalProps) => {
    setModalProps(props);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setModalProps(null);
  };

  const getModalProps = () => modalProps;

  const contextValue = useMemo(
    () => ({
      isOpen,
      getModalProps,
      openModal,
      closeModal,
    }),
    [isOpen, modalProps, openModal, closeModal],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      <CustomModal />
      {children}
    </ModalContext.Provider>
  );
};

type ModalProps = {
  okText?: string;
  cancelText?: string;
  title: string;
  body: string | ReactNode;
  footer?: ReactNode;
  onCancel?: () => void;
  onOk?: () => void;
};

const CustomModal = () => {
  const { isOpen, closeModal, getModalProps } = useContext(ModalContext);
  const props = getModalProps();

  const handleConfirm = () => {
    if (props?.onOk) {
      props.onOk();
    }
    closeModal();
  };

  const handleCancel = () => {
    if (props?.onCancel) props.onCancel();
    closeModal();
  };

  return (
    <Modal
      open={isOpen}
      title={props?.title}
      onOk={handleConfirm}
      okText={props?.okText}
      onCancel={handleCancel}
      cancelText={props?.cancelText}
      footer={props?.footer}
    >
      {props?.body}
    </Modal>
  );
};

export const useModalCtx = () => {
  const ctx = useContext<ModalContextProps>(ModalContext);

  if (!ctx) {
    throw new Error("Wrap app with ModalProvider");
  }

  return ctx;
};
