import axios from "axios";
import { Language } from "../types/language";

type RequestType = {
  choices: {
    message: { role: string; content: string };
  }[];
};

type ResponseContentType = {
  questions: Array<{
    question_title: string;
    question_text: Record<Language, string>;
    type: "SINGLE_CHOICE" | "MULTIPLE_CHOICE";
    answer_choices: Record<Language, Array<string>>;
  }>;
};

export const SurveyGoals = ["Увеличить продажи", "Удовлетворенность клиентов"];

export const BusinessCategories = [
  "AI (искусственный интеллект)",
  "AR/VR (Виртуальная и дополненная реальность)",
  "Big Data (большие данные)",
  "GovTech",
  "HR и рекрутинг",
  "Insurtech",
  "IT-решения для здравоохранения",
  "Retail trade(розничная торговля)",
  "SaaS (Программное обеспечение как услуга)",
  "Software (программное обеспечение)",
  "Анализ данных",
  "Биометрия",
  "Биотехнология",
  "Блокчейн",
  "Еда, напитки и рестораны",
  "Интернет вещей (IoT)",
  "Информационные и новостные порталы и их агрегаторы",
  "Информационные сети",
  "Кибер-безопасность",
  "Корпоративное и платформенное программное обеспечение",
  "Краудсорсинг/Краудфандинг",
  "Маркетинговая технология",
  "Микроэлектроника",
  "Нефтегазовая отрасль",
  "НИОКР (RND)",
  "Облачные вычисления",
  "Оборудование и Электроника",
  "Образование",
  "Работа с аудио, видео и изображения",
  "Радиосвязь",
  "Развлечения и игровая индустрия",
  "Робототехника",
  "Сельское Хозяйство / Agtech",
  "Системы беспилотного управления и спутниковая навигация",
  "Социально ориентированные продукты",
  "Соцсети и мессенджеры, дейтинг, боты",
  "Спорт, фитнес, здоровый образ жизни",
  "Телекоммуникационные и навигационные технологии",
  "Технологии строительства и недвижимости",
  "Транспортная логистика",
  "Туристические услуги",
  "Умный город",
  "Финтех",
  "Центр обработки данных (ЦОДы)",
  "Электронная коммерция, рекомендательные системы, системы лояльности, маркетплейс",
  "Энергетика",
];

export const useAiAssistant = () => {
  const headers = { Authorization: `Bearer ${window._env_.OPENAI_KEY}` };
  const url = "https://api.openai.com/v1/chat/completions";
  const method = "POST";

  const systemMessage =
    "Create survey questions. 3 questions. JSON format. Response json fields  questions: [{ question_title: string; question_text: { ['RU' | 'EN']: string }; type: 'SINGLE_CHOICE' | 'MULTIPLE_CHOICE', answer_choices: { ['RU' | 'EN']: string[] }].";
  const request = async (goal: string, category: string): Promise<ResponseContentType> => {
    const requestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        { role: "system", content: systemMessage },
        {
          role: "user",
          content: `создай опрос по следующим критериям:  цель - ${goal}, категория бизнеса - ${category}.  На русском (RU) и английском (EN), 3 вопроса максимум, длительность опроса 30 секунд.`,
        },
      ],
      temperature: 1,
      max_tokens: 700,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };

    const config = { method, url, data: requestBody, headers };
    const { data } = await axios.request<RequestType>(config);
    return Promise.resolve(mapToQuestionsList(data.choices[0].message.content));
  };

  return { request };
};

const mapToQuestionsList = (json: string): ResponseContentType => {
  return JSON.parse(json ?? "");
};
