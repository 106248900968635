import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QrCodeType } from "../../../types/qrCode";

type StateType = {
  list: Array<QrCodeType>;
  current?: QrCodeType;
};
const initialState: StateType = {
  list: [],
  current: undefined,
};

const qrCodesSlice = createSlice({
  name: "qrCodes",
  initialState,
  reducers: {
    setList(state: StateType, action: PayloadAction<Array<QrCodeType>>) {
      state.list = action.payload;
    },
    removeFromList(state: StateType, action: PayloadAction<string>) {
      state.list = state.list.filter((it) => it?.qrCodeId !== action.payload);
    },
    addToList(state: StateType, action: PayloadAction<QrCodeType>) {
      if (!state.list.length) {
        return { ...state, list: [action.payload] };
      }
      state.list = [action.payload, ...state.list];
    },
    setCurrent(state: StateType, action: PayloadAction<QrCodeType | undefined>) {
      state.current = action.payload;
    },
    updateInList(state: StateType, action: PayloadAction<QrCodeType>) {
      if (!state.list.length) {
        return { ...state, list: [action.payload] };
      }
      state.list = state.list.map((it) => {
        if (it?.qrCodeId === action.payload?.qrCodeId) {
          return { ...it, ...action.payload };
        }
        return it;
      });
    },
  },
});

export const { setList, removeFromList, addToList, setCurrent, updateInList } = qrCodesSlice.actions;
export default qrCodesSlice.reducer;
