import "./index.scss";
import React, { FC } from "react";
import DefaultLayout from "./defaultLayout";

const LayoutComponent: FC<{
  readonly children: React.ReactNode;
}> = ({ children }) => {
  return <DefaultLayout>{children}</DefaultLayout>;
};

export default LayoutComponent;
