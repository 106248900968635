import React from "react";
import { DEFAULT_DATE_FORMAT, TEXT_LIGHT_COLOR } from "./index";
import dayjs from "dayjs";
import { Translate } from "react-redux-i18n";
import { convertDateToCurrentTimezone } from "./timezones";

export function isURL(str: string) {
  const pattern = /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/i;
  return pattern.test(str);
}

export const renderNullableText = (text?: string) => {
  if (!text) {
    return (
      <span style={{ color: TEXT_LIGHT_COLOR }}>
        <Translate value={"noData"} />
      </span>
    );
  } else if (isURL(text)) {
    return (
      <a href={text} target={"_blank"} rel={"noreferrer"}>
        Link
      </a>
    );
  }

  const cleanedText = text.replace(/^\[|\]$/g, "");
  const links = cleanedText.split(", ");
  const foundLink = links.find(isURL);

  if (foundLink) {
    return (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
        {links.map((link, idx) => {
          return (
            <a key={idx} href={link} target={"_blank"} rel={"noreferrer"}>
              Link #{idx + 1}
            </a>
          );
        })}
      </div>
    );
  }

  return text;
};

export const renderNullableDate = (date?: string) => {
  try {
    if (date) {
      const convertedDate = convertDateToCurrentTimezone(date);
      return dayjs(convertedDate).format(DEFAULT_DATE_FORMAT);
    }

    return renderNullableText();
  } catch (e) {
    return date;
  }
};
