import { ArrowLeftOutlined } from "@ant-design/icons";
import { Translate } from "react-redux-i18n";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { BaseButtonProps } from "antd/es/button/button";
import FlexSpace from "./FlexSpace";

type Props = {
  type?: BaseButtonProps["type"];
  pageTitle: string;
};

export const BreadcrumbButton = (props: Pick<Props, "type">) => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <Button type={props.type} icon={<ArrowLeftOutlined />} onClick={goBack}>
      <Translate value={"back"} />
    </Button>
  );
};

export const BreadcrumbWithPageTitle = (props: Props) => {
  return (
    <FlexSpace>
      <BreadcrumbButton type={props.type} />
      <h3 style={{ margin: 0 }}>{props.pageTitle}</h3>
    </FlexSpace>
  );
};
