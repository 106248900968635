import "./index.scss";
import React, { FC, useCallback, useEffect } from "react";
import { Button, message } from "antd";
import Search from "antd/es/input/Search";
import { useLocation, useNavigate } from "react-router-dom";
import SurveysTableComponent from "./table";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/useActions";
import { I18n, Translate } from "react-redux-i18n";
import { SurveyStatus } from "../../types/surveyType";
import { SURVEYS_PAGE } from "../../utils/paths";
import { useTourCtx } from "../../hooks/providers/tours/TourProvider";
import { TourItemsEnum, WelcomeTourItemsEnum } from "../../types/tour";
import { isSurveyCreatingAvailable } from "../../utils/appLimits";

const SurveysPage: FC = () => {
  const {
    data: {
      [TourItemsEnum.WELCOME]: { refs },
    },
    tourInProgress,
  } = useTourCtx();
  const navigate = useNavigate();
  const location = useLocation();
  const { setSurveysPageable, setCurrentQuestion, setCurrentWebhook, setCurrentSurvey, setWebhookList, getSurveys } =
    useActions();

  const pageable = useSelector((state: RootState) => state.persisted.surveysPagePageable);
  const survey = useSelector((state: RootState) => state.surveys.current);

  const onSearch = useCallback(
    (query: string) => {
      setSurveysPageable({ ...pageable, page: 0, query });
      getSurveys();
    },
    [pageable],
  );

  const onLoad = useCallback(() => {
    setWebhookList([]);
    setCurrentWebhook(undefined);

    if (!survey?.surveyId || survey?.status !== SurveyStatus.ACTIVE) {
      return onClear();
    }
  }, [location.search, survey]);

  const onClear = useCallback(() => {
    setCurrentSurvey(undefined);
    setCurrentQuestion(undefined);
  }, []);

  const onResetData = useCallback(() => {}, []);

  useEffect(() => {
    onLoad();
    return () => {
      onResetData();
    };
  }, []);

  return (
    <div className={"page-layout"}>
      <div className={"filters"}>
        <Search
          placeholder={I18n.t("searchBySurveys")}
          defaultValue={pageable?.query}
          style={{ width: 400 }}
          onSearch={onSearch}
          enterButton
          allowClear
        />
        {/*<Button*/}
        {/*  style={{ marginLeft: "auto", backgroundColor: "#8A5BC8", color: "#ffffff", borderColor: "#9F6DE0" }}*/}
        {/*  onClick={() => navigate(`${SURVEYS_PAGE}/ai/create`)}*/}
        {/*  className={"ai-assistant-button"}*/}
        {/*>*/}
        {/*  ✨ <Translate value={"aiAssistant"} />*/}
        {/*</Button>*/}

        <Button
          type={"primary"}
          style={{ marginLeft: "auto" }}
          ref={refs[WelcomeTourItemsEnum.CREATE_SURVEY]}
          onClick={() => {
            if (tourInProgress) return;
            if (!isSurveyCreatingAvailable()) {
              return message.error(I18n.t("youHaveReachedMaxSurveys"));
            }
            navigate(`${SURVEYS_PAGE}/create`);
          }}
          icon={
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 14H12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4 18.5H12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4 9.5H20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M4 5H20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M15 16.5H20" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M17.5 14V19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          }
        >
          <Translate value={"createSurvey"} />
        </Button>
      </div>
      <SurveysTableComponent />
    </div>
  );
};

export default SurveysPage;
