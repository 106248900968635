import { I18n } from "react-redux-i18n";
import { LabelValue } from "./labelValue";

export type ConditionType = {
  readonly conditionDescId: number;
  readonly fact: FactsEnum;
  readonly value: string;
  readonly operators: Array<string>;
  readonly dataType?: "INTEGER" | "STRING" | "DATE";
  readonly original?: any;
  readonly sourceId?: any;
};

export type SubscriberCondition = {
  conditionId?: number;
  fact: FactsEnum;
  operator: string;
  value: string;
};

export enum FactsEnum {
  SUBSCRIBER_AGE = "SUBSCRIBER_AGE",
  BRAND = "BRAND",
  SUBSCRIBER_NAME = "SUBSCRIBER_NAME",
  GENDER = "GENDER",
  STATUS = "STATUS",
  MSISDN_ACTIVATION_DATE = "MSISDN_ACTIVATION_DATE",
}

export const factsAbbr = (): Record<FactsEnum, string> => {
  return {
    SUBSCRIBER_AGE: I18n.t("age"),
    BRAND: I18n.t("brand"),
    SUBSCRIBER_NAME: I18n.t("name"),
    GENDER: I18n.t("gender"),
    STATUS: I18n.t("status"),
    MSISDN_ACTIVATION_DATE: I18n.t("activationDate"),
  };
};

export const conditionsAbbr = (): Record<string, string> => {
  return {
    GREATER_THAN: ">",
    LESS_THAN: "<",
    IS: "=",
    IS_NOT: "≠",
    AFTER: I18n.t("after"),
    BEFORE: I18n.t("before"),
    NOT_ONE_OF: I18n.t("notOneOf"),
    ONE_OF: I18n.t("oneOf"),
  };
};

export const getOperatorsList = (): Array<LabelValue> => {
  return [
    { value: "GREATER_THAN", label: ">" },
    { value: "LESS_THAN", label: "<" },
    { value: "IS", label: "=" },
    { value: "IS_NOT", label: "≠" },
    { value: "NOT_ONE_OF", label: I18n.t("notOneOf") },
    { value: "ONE_OF", label: I18n.t("oneOf") },
  ];
};
