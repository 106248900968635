import dayjs, { Dayjs } from "dayjs";

function setMaxTimeToDate(date: Dayjs, format: string) {
  date = dayjs(date).hour(23);
  date = dayjs(date).minute(59);
  date = dayjs(date).second(59);
  return dayjs(date).format(format);
}

export default setMaxTimeToDate;
