import React, { FC, useCallback, useEffect, useState } from "react";
import { Button } from "antd";
import QuestionsTable from "./table/table";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { Translate } from "react-redux-i18n";
import { MAX_QUESTIONS_QUANTITY } from "../../../../../utils";
import { useActions } from "../../../../../hooks/useActions";
import QuestionEditPageLayout from "./editPageLayout";
import useSurveyDisabled from "../../../../../hooks/useSurveyDisabled";
import { getNextOrderValue } from "../../../utils";
import { NEW_QUESTION } from "./helpers";
import { useParams } from "react-router-dom";
import SurveyEmulator from "./emulator";
import { useTourCtx } from "../../../../../hooks/providers/tours/TourProvider";
import { CreateSurveyQuestionsTourItemsEnum, TourItemsEnum } from "../../../../../types/tour";

const QuestionsComponent: FC = () => {
  const {
    tourInProgress,
    data: {
      [TourItemsEnum.CREATE_SURVEY_QUESTIONS]: { refs },
    },
  } = useTourCtx();

  const { id: surveyId } = useParams();
  const {
    setQuestionCreateFormVisible,
    getLanguagesBySurveyId,
    getUserContactParams,
    setCurrentQuestion,
    getConditions,
  } = useActions();
  const { survey, limitReached, questions, createFormVisibleInitial } = useSelector((state: RootState) => ({
    survey: state.surveys.current,
    languages: state.languages.surveyLanguages,
    limitReached: state.questions.list?.length >= MAX_QUESTIONS_QUANTITY,
    questions: state.questions.list,
    createFormVisibleInitial: state.questions.questionCreateFormVisible,
  }));
  const [createFormVisible, setCreateFormVisible] = useState(createFormVisibleInitial);
  const disabled = useSurveyDisabled();

  const changeEditPageVisible = useCallback(
    (val?: boolean) => {
      setQuestionCreateFormVisible(val ?? !createFormVisible);
      setCreateFormVisible((prev) => val ?? !prev);
    },
    [createFormVisible, setQuestionCreateFormVisible, setCreateFormVisible],
  );

  useEffect(() => {
    setCreateFormVisible(createFormVisibleInitial);
  }, [createFormVisibleInitial]);

  const handleAddNewQuestion = useCallback(() => {
    setCurrentQuestion(NEW_QUESTION(getNextOrderValue(questions)));

    if (!limitReached) {
      changeEditPageVisible();
    }
  }, [setCurrentQuestion, questions, limitReached, changeEditPageVisible]);

  useEffect(() => {
    const id = surveyId ?? survey?.surveyId;
    if (id) getLanguagesBySurveyId(id);
  }, [survey?.surveyId]);

  useEffect(() => {
    getUserContactParams();
    getConditions();

    return () => {
      setCurrentQuestion(undefined);
      changeEditPageVisible(false);
    };
  }, []);

  return (
    <div className={"questions-container"}>
      {createFormVisible ? (
        <QuestionEditPageLayout changeEditPageVisible={changeEditPageVisible} />
      ) : (
        <>
          <div className={"create-question-btn"}>
            {surveyId && <SurveyEmulator surveyId={surveyId} disabled={disabled || questions.length <= 1} />}
            <Button
              type={"primary"}
              style={{ marginLeft: 10 }}
              disabled={limitReached || disabled}
              onClick={tourInProgress ? undefined : handleAddNewQuestion}
              ref={refs[CreateSurveyQuestionsTourItemsEnum.CREATE_QUESTION_BUTTON]}
            >
              <Translate value={"createQuestion"} />
            </Button>
          </div>
          <QuestionsTable changeEditPageVisible={changeEditPageVisible} actionsDisabled={disabled} />
        </>
      )}
    </div>
  );
};

export default QuestionsComponent;
