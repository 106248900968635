import { FC, ReactNode } from "react";
import { GeneralStatisticType } from "../types/generalStatisticType";
import Loading from "./loading";
import { Translate } from "react-redux-i18n";
import { CalendarIcon, ClockIcon, PercentageIcon, PlayIcon, QuestionIcon } from "./icons";
import { Empty } from "antd";
import { formatTime } from "../utils/mapSeconds";
import { EyeOutlined } from "@ant-design/icons";

const HEIGHT = 150;

type Props = {
  readonly loading: boolean;
  readonly data?: GeneralStatisticType;
  readonly questionsQuantity?: number;
};

const GeneralStatisticsComponent: FC<Props> = ({ loading, data, questionsQuantity }) => {
  if (loading) {
    return <Loading height={HEIGHT} />;
  }

  if (!data) {
    return (
      <Empty
        style={{
          height: HEIGHT,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      />
    );
  }

  return (
    <Layout className={!!questionsQuantity ? "general-statistics-column-layout" : "general-statistics-row-layout"}>
      <Element label={"viewed"} value={data.visited} icon={<EyeOutlined style={{ fontSize: 25 }} />} />
      <Element label={"surveysStarted"} value={data.started} icon={<PlayIcon />} />
      {questionsQuantity && <Element label={"questionsInSurvey"} value={questionsQuantity} icon={<QuestionIcon />} />}
      <Element label={"avgFinishTime"} value={formatTime(data.avg_finished_time)} icon={<ClockIcon />} />
      <Element label={"finishedCount"} value={data.finished} icon={<CalendarIcon />} />
      <Element label={"finishedProportion"} value={data.percent_finished.toFixed(1)} icon={<PercentageIcon />} />
    </Layout>
  );
};

type ElementProps = {
  label: string;
  value: number | string | undefined;
  icon: ReactNode;
  grid?: "column" | "row";
};

const Element: FC<ElementProps> = ({ label, value, icon }) => {
  return (
    <div className={"general-statistics-column-element"}>
      <div style={{ display: "grid", justifyContent: "center", textAlign: "center" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 10 }}>
          <span style={{ width: 25, height: 25, marginRight: 15 }}>{icon}</span>
          <span style={{ fontWeight: 600, fontSize: 22, color: "#000000" }}>{value}</span>
        </div>
        <span style={{ color: "#8D8D8D", fontSize: 18, fontWeight: 500 }}>
          <Translate value={label} />
        </span>
      </div>
    </div>
  );
};

type LayoutProps = { children: ReactNode; className: string };

const Layout = ({ children, className }: LayoutProps) => {
  return (
    <div
      style={{ gap: 8, height: HEIGHT, display: "flex", alignItems: "center", flexWrap: "wrap" }}
      className={className}
    >
      {children}
    </div>
  );
};

export default GeneralStatisticsComponent;
