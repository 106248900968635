import { QuestionType } from "./questionType";
import { ChannelEnum } from "./channelEnum";
import { SourceConditionType } from "./sourceConditionType";
import { StatusEnum } from "./statusType";
import { Dayjs } from "dayjs";
import { SurveyLanguage } from "./language";
import { TextTranslationType } from "./textTranslationType";

export type SurveyType = {
  readonly surveyId: string;
  readonly surveyName: string;
  readonly status?: SurveyStatus;
  readonly communicationChannel: ChannelEnum;
  readonly tags: Array<string>;
  readonly createdBy?: string;
  readonly createdAt?: Dayjs | string;
  readonly updatedBy?: string;
  readonly updatedAt?: Dayjs | string;
  readonly startDate?: Dayjs | string;
  readonly questions: Array<QuestionType>;
  readonly sourceConditions: Array<SourceConditionType>;
  readonly faviconPath?: string;
  readonly logoPath?: string;
  readonly themeColor?: string;
  readonly surveyMetadata?: SurveyMetaData;
  readonly languages: Array<SurveyLanguage>;
  readonly surveyDecor: SurveyDecor;
};

export enum SurveyStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
  DELETED = "DELETED",
}

export type SurveyDecor = {
  readonly title: string;

  readonly textColor: string;
  readonly textFont: string;
  readonly textSize: number;

  readonly titleColor: string;
  readonly titleFont: string;
  readonly titleSize: number;

  readonly showProgress: boolean;
};

export type SurveyMetaData = {
  readonly metaTitle?: string;
  readonly metaDescription?: string;
  readonly textTranslations?: Array<TextTranslationType>;
};

export type SurveyEditType = Omit<SurveyType, "createdBy" | "createdAt" | "updatedAt" | "updatedBy" | "status">;

export type SurveyFilter = {
  readonly createdBy?: string;
  readonly status?: Array<StatusEnum>;
  readonly tags?: Array<string>;
};
