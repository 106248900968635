import { FC, ReactNode } from "react";
import {
  FrownOutlined,
  HeartFilled,
  MehOutlined,
  SmileOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Rate } from "antd";
import { NpsIconEnum } from "../types/npsIconEnum";

export const smileIcons: Record<number, ReactNode> = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

const Rating: FC<{
  readonly iconType?: NpsIconEnum;
  readonly defaultValue: number;
  readonly maxValue: number;
}> = ({ iconType, defaultValue, maxValue }) => {
  const getNumeric = (({ index }: { index: number }) => index + 1) as any;
  const getSmile = ({ index }: { index: number }) => smileIcons[index + 1];
  const showHalf = () => maxValue === 5 || isIcon(NpsIconEnum.SMILE);

  function isIcon(type: NpsIconEnum) {
    return iconType === type;
  }

  return (
    <Rate
      style={{
        fontSize:
          maxValue === 5
            ? 30
            : isIcon(NpsIconEnum.STAR) || isIcon(NpsIconEnum.HEART)
            ? 14
            : 23,
      }}
      allowHalf={showHalf()}
      defaultValue={defaultValue}
      count={isIcon(NpsIconEnum.SMILE) ? 5 : maxValue}
      character={
        isIcon(NpsIconEnum.NUMERIC) ? (
          getNumeric
        ) : isIcon(NpsIconEnum.SMILE) ? (
          getSmile
        ) : isIcon(NpsIconEnum.HEART) ? (
          <HeartFilled />
        ) : (
          <StarFilled />
        )
      }
    />
  );
};

export default Rating;
