import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppFeaturesType } from "../../../types/appFeatures";

type StateType = {
  showLoader: boolean;
  features?: AppFeaturesType;
};

const initialState: StateType = {
  showLoader: false,
  features: undefined,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setShowLoader(state: StateType, action: PayloadAction<boolean>) {
      state.showLoader = action.payload;
    },
    setFeatures(state: StateType, action: PayloadAction<AppFeaturesType>) {
      state.features = action.payload;
    },
  },
});

export const { setShowLoader, setFeatures } = appSlice.actions;
export default appSlice.reducer;
