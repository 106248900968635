import { Alert, Button } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useCallback, useEffect, useMemo } from "react";
import { SubscriptionInfoStatusEnum } from "../../types/subscriptionInfo";
import dayjs from "dayjs";
import { I18n, Translate } from "react-redux-i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { ACCOUNT_SETTINGS_TARIFF_PAGE } from "../../utils/paths";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const IS_USER_VISITED_APP = "IS_USER_VISITED_APP";

const TrialAlert = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { subscriptionInfo, appLang } = useSelector((state: RootState) => ({
    subscriptionInfo: state.users.subscriptionInfo,
    appLang: state.persisted.appLanguage,
  }));

  const isUserVisitedApp = useMemo(() => {
    const localStorageItem = localStorage.getItem(IS_USER_VISITED_APP);
    return Boolean(localStorageItem && localStorageItem === "true");
  }, [location.pathname]);

  const isTrialActive = Boolean(
    subscriptionInfo?.subscription.status === SubscriptionInfoStatusEnum.TRIAL &&
      dayjs().isBetween(
        subscriptionInfo.subscription.trialStartDate,
        subscriptionInfo.subscription.trialEndDate,
        "day",
        "[]",
      ),
  );

  const isSubscriptionActive = Boolean(
    subscriptionInfo?.subscription.status === SubscriptionInfoStatusEnum.ACTIVE &&
      dayjs().isBetween(dayjs().startOf("day"), subscriptionInfo.subscription.endDate, "day", "[]"),
  );

  const type = isTrialActive ? "warning" : "error";

  const message = useMemo((): string => {
    if (isTrialActive) {
      const trialEndDate = dayjs(subscriptionInfo?.subscription.trialEndDate);
      const daysLeft = Math.ceil(trialEndDate.diff(dayjs(), "day", true));

      const msg = I18n.t("tariffs.trialPeriodDaysLeft");
      return msg.replace("{days}", String(daysLeft));
    } else if (isSubscriptionActive) {
      return "";
    }

    return I18n.t("tariffs.trialPeriodEndDescription");
  }, [appLang, isSubscriptionActive, subscriptionInfo, isTrialActive]);

  const handleSubscribe = useCallback(() => {
    navigate(ACCOUNT_SETTINGS_TARIFF_PAGE);
  }, [navigate]);

  useEffect(() => {
    if (!isSubscriptionActive && !isTrialActive && location.pathname !== ACCOUNT_SETTINGS_TARIFF_PAGE) {
      handleSubscribe();
    }
  }, [isTrialActive, isSubscriptionActive, location.pathname]);

  useEffect(() => {
    if (!isUserVisitedApp) {
      localStorage.setItem(IS_USER_VISITED_APP, "true");
      setTimeout(handleSubscribe, 100);
    }
  }, []);

  if (!isTrialActive && isSubscriptionActive) {
    return <div style={{ display: "none" }} />;
  }

  return (
    <div style={{ width: "97%", margin: "0 auto", height: 50 }}>
      <Alert
        message={message}
        type={type}
        action={
          <Button size={"small"} type={"primary"} onClick={handleSubscribe}>
            <Translate value={"tariffs.subscribe"} />
          </Button>
        }
      />
      ;
    </div>
  );
};

export default TrialAlert;
