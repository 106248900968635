import { CsiType } from "../types/csiType";
import { QuestionType } from "../types/questionType";
import { TextTranslationType } from "../types/textTranslationType";
import { NpsInfoType } from "../types/npsInfoType";

function getNonIndexedQuestion(order: number, q: QuestionType, isWebChannel: boolean): QuestionType {
  return {
    ...q,
    order,
    questionId: undefined,
    previousAnswerCondition: !q?.previousAnswerCondition
      ? undefined
      : {
          ...q.previousAnswerCondition,
          conditionId: undefined,
        },
    name: q.name + " copy",
    conditions: q?.conditions?.map((c) => ({ ...c, conditionId: undefined })) || [],
    textTranslations: getNonIndexedTextTranslations(q?.textTranslations),
    answerTemplate:
      !isWebChannel || !q?.answerTemplate
        ? undefined
        : {
            ...q.answerTemplate,
            answerChoices:
              q.answerTemplate?.answerChoices.map((ac) => ({
                ...ac,
                answerChoiceId: undefined,
                textTranslations: getNonIndexedTextTranslations(ac?.textTranslations),
              })) || [],
            csiParams:
              q.answerTemplate?.csiParams?.map(
                (cp) =>
                  ({
                    ...cp,
                    csiParamId: undefined,
                    textTranslations: getNonIndexedTextTranslations(cp?.textTranslations),
                  } as CsiType),
              ) || [],
            criteria:
              q.answerTemplate?.criteria?.map((cr) => ({
                ...cr,
                csiCriteriaId: undefined,
                textTranslations: getNonIndexedTextTranslations(cr?.textTranslations),
              })) || [],
            npsInfoParams: {
              ...q?.answerTemplate?.npsInfoParams,
              npsParams: q.answerTemplate?.npsInfoParams?.npsParams?.map((np) => ({
                ...np,
                textTranslations: getNonIndexedTextTranslations(np?.textTranslations),
              })),
            } as NpsInfoType,
          },
  };
}

export default getNonIndexedQuestion;

function getNonIndexedTextTranslations(translations?: Array<TextTranslationType>) {
  return translations?.map((it) => ({
    ...it,
    textTranslationId: undefined,
  }));
}
