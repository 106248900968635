import "./index.scss";
import React, { FC } from "react";
import { Alert, Layout, Spin } from "antd";
import HeaderComponent from "../header";
import FooterComponent from "../footer";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useUserAccessValidation } from "../../hooks/useUserAccessValidation";
import { copyToClipboard } from "../../utils/copyToClipboard";

const DefaultLayout: FC<{
  readonly children: React.ReactNode;
}> = ({ children }) => {
  const { Content } = Layout;
  const showLoader = useSelector((state: RootState) => state.app.showLoader);
  const { accessLimited } = useUserAccessValidation();
  const supportEmail = window._env_.SUPPORT_EMAIL ?? "";

  return (
    <Layout style={{ minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <HeaderComponent />
      {accessLimited && (
        <Alert
          type={"error"}
          style={{ paddingLeft: 30 }}
          message={
            <h4 style={{ margin: 0 }}>
              Чтобы пользоваться функционалом, активируйте аккаунт, написав на{" "}
              <strong style={{ cursor: "pointer" }} onClick={() => copyToClipboard(supportEmail)}>
                {supportEmail}
              </strong>
            </h4>
          }
        />
      )}
      <div style={{ flex: 3, width: "100%", maxWidth: 1920, margin: "0 auto" }}>
        <Spin spinning={showLoader}>
          <Content
            id={"__scroll"}
            style={{
              margin: 30,
              padding: 30,
              background: "#fff",
            }}
          >
            {children}
          </Content>
        </Spin>
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default DefaultLayout;
