import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RoutePaths } from "./paths";
import SurveysPage from "../pages/surveys";
import SurveyCreatePage from "../pages/surveys/edit";
import SettingsPage from "../pages/settings";
import ContactsPage from "../pages/contacts";
import { APP_PREFIX } from "../utils";
import AccountSettingsPage from "../pages/user/accountSettings";
import TariffPage from "../pages/user/tariff";
import PaymentPage from "../pages/user/paymentPage";
import { SettingsRoutes } from "../pages/settings/routes";

const PrivateRoutes: FC = () => {
  return (
    <Routes>
      <Route path={APP_PREFIX}>
        <Route path={RoutePaths.SURVEYS}>
          <Route index element={<SurveysPage />} />
          <Route path={RoutePaths.CREATE_SURVEY} element={<SurveyCreatePage />} />
          <Route
            path={RoutePaths.AI_CREATE_SURVEY}
            element={<SurveyCreatePage createByAI={false /** return to true when feature back */} />}
          />
          <Route path={RoutePaths.EDIT_SURVEY} element={<SurveyCreatePage />} />
        </Route>
        <Route path={RoutePaths.SETTINGS} element={<SettingsPage />}>
          {SettingsRoutes}
        </Route>
        <Route path={RoutePaths.CONTACTS} element={<ContactsPage />} />
        <Route path={RoutePaths.ACCOUNT_SETTINGS} element={<AccountSettingsPage />} />
        <Route path={RoutePaths.ACCOUNT_SETTINGS_TARIFF} element={<TariffPage />} />
        <Route path={RoutePaths.ACCOUNT_SETTINGS_TARIFF_SELECTED} element={<PaymentPage />} />
        <Route index element={<Navigate to={RoutePaths.SURVEYS} replace />} />
        <Route path={"*"} element={<Navigate to={RoutePaths.SURVEYS} replace />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
