import { FC } from "react";
import AnswersByDays from "./answersByDays";
import GeneralStatistics from "./generalStatistics";
import InteractionsByCategories from "./interactionsByCategories";
import AverageTime from "./averageTime";

const ReviewTab: FC = () => {
  return (
    <div>
      <GeneralStatistics showTitle />
      <AnswersByDays />
      <InteractionsByCategories />
      <AverageTime />
    </div>
  );
};

export default ReviewTab;
