import "./index.scss";
import React, { FC, useCallback, useState } from "react";
import { I18n, Translate } from "react-redux-i18n";
import { Alert, Button, Input, message, Select } from "antd";
import { validateOtpAndVerify } from "../../store/slices/users";
import { useAccessTokenObj } from "../../utils/jwt";
import { useActions } from "../../hooks/useActions";
import LoginLayout from "../../components/loginLayout";
import { Link } from "react-router-dom";
import { doLogout } from "../../utils/doLogout";
import ResendCodeButton from "./resedCodeButton";
import { langTitle, Language } from "../../types/language";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const CodeValidationPage: FC = () => {
  const appLanguage = useSelector((state: RootState) => state.persisted.appLanguage);
  const { onRefreshToken, setLanguage } = useActions();
  const tokenObj = useAccessTokenObj();
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);

  const onConfirm = useCallback(() => {
    if (!code || code.trim().length !== 6) {
      return message.error(I18n.t("fillRequiredFields"));
    }
    const username = tokenObj?.email;

    if (!!username?.trim() && !!code?.trim()) {
      validateOtpAndVerify(code, username)
        .then(async () => {
          message.success(I18n.t("successful"));
          onRefreshToken();
          setError(false);
        })
        .catch(() => setError(true));
    }
  }, [code, tokenObj?.email]);

  const handleChange = (inputValue: string) => {
    if (error) setError(false);
    const digitsOnly = inputValue.replace(/\D/g, "");
    const sixDigits = digitsOnly.slice(0, 6);
    setCode(sixDigits);
  };

  const handleBackToLogin = () => doLogout();

  return (
    <LoginLayout>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        {error && (
          <Alert
            message={I18n.t("error")}
            description={I18n.t("codeValidationError")}
            type="error"
            style={{ marginBottom: 24 }}
          />
        )}
        <Input
          style={{
            width: 358,
            marginBottom: 24,
            lineHeight: "16px",
            padding: "20px 16px",
            border: error ? "1px solid red" : "",
          }}
          onChange={({ currentTarget }) => handleChange(currentTarget.value)}
          placeholder={I18n.t("enterValidationCode")}
          value={code}
        />
        <div style={{ marginBottom: 24, display: "flex", justifyContent: "start" }}>
          <Link
            to={"/"}
            style={{ fontSize: 15, textDecoration: "none" }}
            onClick={(e) => {
              e.preventDefault();
              handleBackToLogin();
            }}
          >
            <Translate value={"backToLogin"} />
          </Link>
        </div>
        <Button onClick={onConfirm} type={"primary"} style={{ width: 358, lineHeight: "16px", padding: "28px 16px" }}>
          <Translate value={"confirm"} />
        </Button>
        <ResendCodeButton />
        <Select
          value={appLanguage}
          variant={"borderless"}
          style={{ color: "#4B5563" }}
          className={"login-layout-language-select"}
          onChange={(val) => setLanguage(val)}
          options={Object.values(Language).map((lang) => ({ value: lang, label: langTitle[lang] }))}
        />
      </div>
    </LoginLayout>
  );
};

export default CodeValidationPage;
