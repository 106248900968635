import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useRequests } from "../useRequests";

type Props = { children: ReactNode };

const TimezoneWrapper: FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { getUserInformation } = useRequests();

  const loadData = useCallback(() => {
    getUserInformation()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [setLoading, getUserInformation]);

  useEffect(() => loadData(), []);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          overflow: "hidden",
          color: "lightgrey",
          gap: 10,
        }}
      >
        <small>Loading</small>
        <span>Fetching user data</span>
      </div>
    );
  }

  return <>{children}</>;
};

export default TimezoneWrapper;
