import { TEXT_LIGHT_COLOR } from "../utils";

const EmptyNA = () => {
  return (
    <div style={{ margin: 0, marginBottom: 14 }}>
      <span style={{ color: TEXT_LIGHT_COLOR }}>N/A</span>
    </div>
  );
};

export default EmptyNA;
