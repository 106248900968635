import React, { FC, ReactNode } from "react";
import { Button, Popconfirm } from "antd";
import { ButtonType } from "antd/lib/button";
import { I18n, Translate } from "react-redux-i18n";

type ConfirmProps = {
  readonly action: string;
  readonly title: string;
  readonly description?: string;
  readonly danger?: boolean;
  readonly success?: boolean;
  readonly type?: ButtonType;
  readonly icon?: ReactNode;
  readonly onConfirm: () => void;
  readonly okText?: string;
};

const Confirm: FC<ConfirmProps> = ({
  action,
  title,
  description,
  danger = false,
  success,
  type = "text",
  icon,
  onConfirm,
  okText,
}) => {
  return (
    <Popconfirm
      onConfirm={onConfirm}
      title={
        <div style={{ display: "grid" }}>
          <span>
            <Translate value={title} />
          </span>{" "}
          {description && (
            <span>
              <Translate value={description} />
            </span>
          )}{" "}
        </div>
      }
      okText={okText ? I18n.t(okText) : undefined}
    >
      <Button
        type={type}
        danger={danger}
        icon={icon}
        style={
          !success
            ? {}
            : type === "primary"
            ? {
                backgroundColor: "#60A917",
                color: "#FFFFFF",
              }
            : { color: "#60A917" }
        }
      >
        <Translate value={action} />
      </Button>
    </Popconfirm>
  );
};

export default Confirm;
