import "./index.scss";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Alert, Button, Checkbox, Form, Input, message, Select, Tooltip, Upload, UploadFile } from "antd";
import { QuestionCircleOutlined, UndoOutlined } from "@ant-design/icons";
import { RcFile } from "antd/es/upload";
import PhoneComponent from "../../../../../components/phone";
import { useActions } from "../../../../../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { SURVEYS_PAGE } from "../../../../../utils/paths";
import { useNavigate } from "react-router-dom";
import { SurveyDecor, SurveyStatus, SurveyType } from "../../../../../types/surveyType";
import { I18n, Translate } from "react-redux-i18n";
import HiddenValueFormItemComponent from "../../../../../components/form/items/hiddenValue";
import { FontsList, fontsValues } from "../../../../../utils/fontsList";
import SelectFormItemComponent from "../../../../../components/form/items/select";
import { TextTranslationType } from "../../../../../types/textTranslationType";
import {
  DecorFormType,
  DEFAULT_COLOR,
  FAVICON_TYPES,
  LOGO_TYPES,
  MAX_FILE_WEIGHT,
  SURVEY_DECOR_FONT_MAX_SIZE,
  SURVEY_DECOR_FONT_MIN_SIZE,
} from "./helpers";
import InputNumberFormItemComponent from "../../../../../components/form/items/inputNumber";
import { IconImage } from "../../../../../components/IconImage";
import ImgCrop from "antd-img-crop";

const PersonalizationComponent: FC = () => {
  const { updateSurvey, getSurveyById, changeSurveyIcon } = useActions();
  const navigate = useNavigate();
  const survey = useSelector((state: RootState) => state.surveys.current);
  const [form] = Form.useForm<DecorFormType>();

  const [corporateColor, setCorporateColor] = useState<string | undefined>();
  const [logosList, setLogosList] = useState<Array<UploadFile>>([]);
  const [faviconsList, setFaviconsList] = useState<Array<UploadFile>>([]);
  const [logoBlob, setLogoBlob] = useState<any>();
  const [aspect, setAspect] = useState(1);

  const isFileFits = useCallback((acceptTypes: string, file: any) => {
    return acceptTypes.split(",").find((ac) => ac.trim() === `.${file?.type?.split("/")[1]}`);
  }, []);

  const beforeUpload = useCallback(
    (file: RcFile, acceptTypes: string) => {
      if (!isFileFits(acceptTypes, file)) {
        message.error(I18n.t("acceptTypesFormat") + acceptTypes).then();
      } else if (file?.size > MAX_FILE_WEIGHT) {
        message.error(I18n.t("maxFileWeightError")).then();
      }
      return false;
    },
    [isFileFits],
  );

  const onClear = useCallback(() => {
    form.resetFields();
    setLogosList([]);
    setFaviconsList([]);
    URL.revokeObjectURL(logoBlob);
    setLogoBlob(undefined);
  }, [logoBlob, form, setLogoBlob, setLogosList, setFaviconsList]);

  const createImageUrl = useCallback(
    (file: Blob) => {
      URL.revokeObjectURL(logoBlob);
      setLogoBlob(URL.createObjectURL(file.slice()));
    },
    [logoBlob, setLogoBlob],
  );

  const handleChange = useCallback(
    (type: "logo" | "favicon", fileList: Array<UploadFile>) => {
      const isFavicon$ = type === "favicon";

      const file = fileList.at(-1)?.originFileObj;
      if (!file || !isFileFits(isFavicon$ ? FAVICON_TYPES : LOGO_TYPES, file) || file?.size > MAX_FILE_WEIGHT) {
        return;
      }

      if (isFavicon$) {
        return setFaviconsList(fileList);
      }

      setLogosList(fileList);
      createImageUrl(file.slice());
    },
    [isFileFits, setFaviconsList, createImageUrl],
  );

  const submitHandler = useCallback(() => {
    if (!survey?.surveyId) return;
    form
      .validateFields()
      .then(async (fields) => {
        setCorporateColor(fields.themeColor);
        let showMessage = true;
        if (form.isFieldsTouched()) {
          await updateSurvey({ survey: { ...survey, ...fields } });
          showMessage = false;
        }

        const logo = logosList.at(0)?.originFileObj;
        const favicon = faviconsList.at(0)?.originFileObj;

        if (logo) {
          await changeSurveyIcon({
            surveyId: survey?.surveyId,
            file: logo,
            iconType: "icon",
          });
        }
        if (favicon) {
          await changeSurveyIcon({
            surveyId: survey?.surveyId,
            file: favicon,
            iconType: "favicon",
          });
        }
        if ((logo || favicon) && showMessage) {
          message.success(I18n.t("changesSaved"));
        }
        setFaviconsList([]);
        setLogosList([]);
        setLogoBlob(undefined);
      })
      .catch(() => message.error(I18n.t("fillRequiredFields")));
  }, [
    form,
    survey,
    logosList,
    faviconsList,
    changeSurveyIcon,
    updateSurvey,
    setCorporateColor,
    setFaviconsList,
    setLogosList,
    setLogoBlob,
  ]);

  const onCancel = useCallback(() => {
    onClear();
    navigate(SURVEYS_PAGE);
  }, [onClear]);

  useEffect(() => {
    setCorporateColor(survey?.themeColor);
    form.setFieldsValue(toFormValues(survey));
  }, [survey]);

  useEffect(() => {
    if (!survey?.surveyId) return;
    getSurveyById(survey?.surveyId);
    return onClear;
  }, []);

  const calculateAspectRatio = useCallback(
    (file: RcFile) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const aspectRatio = (img.width / img.height).toFixed(2);
          setAspect(Number(aspectRatio));
        };
      };
      return !file.type.includes("svg");
    },
    [setAspect],
  );

  return (
    <>
      <div className={"personalization-container"}>
        <div>
          <Alert
            type={"info"}
            style={{ marginBottom: 20, maxWidth: "fit-content" }}
            message={I18n.t("personalizationHelpText")}
          />
          <div className={"personalization-tab"}>
            <div className={"row"}>
              <LogoLabel />
              <ImgCrop
                showGrid
                zoomSlider
                quality={1}
                aspectSlider
                rotationSlider
                aspect={aspect}
                showReset={false}
                cropShape={"rect"}
                fillColor={"transparent"}
                modalOk={I18n.t("save")}
                beforeCrop={calculateAspectRatio}
              >
                <Upload
                  maxCount={1}
                  accept={LOGO_TYPES}
                  listType={"picture"}
                  onRemove={() => {
                    setLogosList([]);
                    setLogoBlob(undefined);
                  }}
                  fileList={logosList.map((f) => ({ ...f, status: "done" }))}
                  onChange={(e) => handleChange("logo", e.fileList)}
                >
                  <Button
                    icon={<IconImage path={survey?.logoPath} />}
                    style={survey?.logoPath?.endsWith(".svg") ? { paddingRight: 36 } : {}}
                  >
                    <Translate value={"selectLogo"} />
                  </Button>
                </Upload>
              </ImgCrop>
            </div>
            <div className={"row"}>
              <FaviconLabel />
              <Upload
                maxCount={1}
                listType={"picture"}
                accept={FAVICON_TYPES}
                fileList={faviconsList}
                onRemove={() => setFaviconsList([])}
                onChange={(e) => handleChange("favicon", e.fileList)}
                beforeUpload={(file) => beforeUpload(file, FAVICON_TYPES)}
              >
                <Button icon={<IconImage path={survey?.faviconPath} />}>
                  <Translate value={"selectFavicon"} />
                </Button>
              </Upload>
            </div>

            <Form form={form} scrollToFirstError layout={"horizontal"} labelCol={{ span: 3 }} wrapperCol={{ span: 5 }}>
              <Form.Item shouldUpdate noStyle required={false}>
                {({ setFieldValue, getFieldValue }) => {
                  function onChangeColor(val: string) {
                    setFieldValue(["themeColor"], val);
                  }
                  const initial = getFieldValue(["themeColor"]);
                  return (
                    <div className={"row"}>
                      <h4>
                        <Translate value={"themeColor"} />
                      </h4>
                      <ColorField onChange={onChangeColor} initialColor={initial || DEFAULT_COLOR} />
                    </div>
                  );
                }}
              </Form.Item>
              <HiddenValueFormItemComponent name={["themeColor"]} />
              <HiddenValueFormItemComponent name={["surveyDecor", "textColor"]} />
              <HiddenValueFormItemComponent name={["surveyDecor", "titleColor"]} />
              <HiddenValueFormItemComponent name={["surveyMetadata", "metaTitle"]} />{" "}
              <HiddenValueFormItemComponent name={["surveyMetadata", "metaDescription"]} />
              <HiddenValueFormItemComponent name={["surveyDecor", "showProgress"]} />
              {/*<InputFormItemComponent*/}
              {/*  required={false}*/}
              {/*  label={I18n.t("surveyName")}*/}
              {/*  name={["surveyDecor", "title"]}*/}
              {/*  tooltip={I18n.t("surveyNameTooltip")}*/}
              {/*/>*/}
              {/*<Form.Item label={I18n.t("titleFont")}>*/}
              {/*  <div style={{ display: "flex", alignItems: "center", gap: 4 }}>*/}
              {/*    <SelectFormItemComponent*/}
              {/*      placeHolder={I18n.t("selectFont")}*/}
              {/*      style={{ minWidth: 160, maxWidth: 160 }}*/}
              {/*      name={["surveyDecor", "titleFont"]}*/}
              {/*      values={fontsValues}*/}
              {/*      required={false}*/}
              {/*      noStyle*/}
              {/*      optionsElement={fontsValues.map(({ label, value }) => {*/}
              {/*        return (*/}
              {/*          <Select.Option key={value} value={value}>*/}
              {/*            <span style={{ fontFamily: value }} className={"dont-use-global-font"}>*/}
              {/*              {label}*/}
              {/*            </span>*/}
              {/*          </Select.Option>*/}
              {/*        );*/}
              {/*      })}*/}
              {/*    />*/}
              {/*    <div>*/}
              {/*      <InputNumberFormItemComponent*/}
              {/*        noStyle*/}
              {/*        showArrows*/}
              {/*        required={false}*/}
              {/*        placeHolder={"00"}*/}
              {/*        min={SURVEY_DECOR_FONT_MIN_SIZE}*/}
              {/*        max={SURVEY_DECOR_FONT_MAX_SIZE}*/}
              {/*        name={["surveyDecor", "titleSize"]}*/}
              {/*        style={{ width: 60, maxWidth: 60 }}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <Form.Item shouldUpdate noStyle required={false}>*/}
              {/*      {({ setFieldValue, getFieldValue }) => {*/}
              {/*        function onChangeColor(val: string) {*/}
              {/*          setFieldValue(["surveyDecor", "titleColor"], val);*/}
              {/*        }*/}
              {/*        const initial = getFieldValue(["surveyDecor", "titleColor"]);*/}
              {/*        return (*/}
              {/*          <div className={"row nomargin"}>*/}
              {/*            <ColorField*/}
              {/*              initialColor={initial || "#000000"}*/}
              {/*              onChange={onChangeColor}*/}
              {/*              defaultColor={"#000000"}*/}
              {/*            />*/}
              {/*          </div>*/}
              {/*        );*/}
              {/*      }}*/}
              {/*    </Form.Item>*/}
              {/*  </div>*/}
              {/*</Form.Item>*/}
              <Form.Item label={I18n.t("textFont")}>
                <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <SelectFormItemComponent
                    style={{ minWidth: 160, maxWidth: 160 }}
                    placeHolder={I18n.t("selectFont")}
                    name={["surveyDecor", "textFont"]}
                    values={fontsValues}
                    required={false}
                    noStyle
                    optionsElement={fontsValues.map(({ label, value }) => {
                      return (
                        <Select.Option key={value} value={value}>
                          <span style={{ fontFamily: value }} className={"dont-use-global-font"}>
                            {label}
                          </span>
                        </Select.Option>
                      );
                    })}
                  />
                  <div>
                    <InputNumberFormItemComponent
                      noStyle
                      showArrows
                      required={false}
                      placeHolder={"00"}
                      min={SURVEY_DECOR_FONT_MIN_SIZE}
                      max={SURVEY_DECOR_FONT_MAX_SIZE}
                      name={["surveyDecor", "textSize"]}
                      style={{ width: 60, maxWidth: 60 }}
                    />
                  </div>
                  <Form.Item shouldUpdate noStyle required={false}>
                    {({ setFieldValue, getFieldValue }) => {
                      function onChangeColor(val: string) {
                        setFieldValue(["surveyDecor", "textColor"], val);
                      }
                      const initial = getFieldValue(["surveyDecor", "textColor"]);
                      return (
                        <div className={"row nomargin"}>
                          <ColorField
                            defaultColor={"#000000"}
                            onChange={onChangeColor}
                            initialColor={initial || "#000000"}
                          />
                        </div>
                      );
                    }}
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item
                valuePropName={"checked"}
                name={["surveyDecor", "showProgress"]}
                label={I18n.t("showProgressBar")}
              >
                <Checkbox style={{ margin: 0 }} />
              </Form.Item>
              {/*<InputFormItemComponent*/}
              {/*  pattern={/^.{1,70}$/}*/}
              {/*  label={"HTML Meta Title"}*/}
              {/*  name={["surveyMetadata", "metaTitle"]}*/}
              {/*  helpLabel={`${REQUIRED_MESSAGE()}. ${I18n.t("maxCharacters")} 70`}*/}
              {/*/>*/}
              {/*<InputFormItemComponent*/}
              {/*  pattern={/^.{1,300}$/}*/}
              {/*  label={"HTML Meta Description"}*/}
              {/*  name={["surveyMetadata", "metaDescription"]}*/}
              {/*  helpLabel={`${REQUIRED_MESSAGE()}. ${I18n.t("maxCharacters")} 300`}*/}
              {/*/>*/}
              {/*<Divider />*/}
              {/*<Form.List name={["surveyMetadata", "textTranslations"]}>*/}
              {/*  {(fields) => {*/}
              {/*    return (*/}
              {/*      <>*/}
              {/*        {fields.map(({ name: index }) => {*/}
              {/*          const langName = form.getFieldValue([*/}
              {/*            "surveyMetadata",*/}
              {/*            "textTranslations",*/}
              {/*            index,*/}
              {/*            "language",*/}
              {/*            "langName",*/}
              {/*          ]);*/}
              {/*          return (*/}
              {/*            <div key={index}>*/}
              {/*              <HiddenValueFormItemComponent name={[index, "language", "langCode"]} />*/}
              {/*              <HiddenValueFormItemComponent name={[index, "language", "langName"]} />*/}
              {/*              <InputFormItemComponent*/}
              {/*                tooltip={langName}*/}
              {/*                pattern={/^.{1,70}$/}*/}
              {/*                name={[index, "text"]}*/}
              {/*                label={I18n.t("surveyMetaName")}*/}
              {/*                helpLabel={`${REQUIRED_MESSAGE()}. ${I18n.t("maxCharacters")} 70`}*/}
              {/*              />*/}
              {/*            </div>*/}
              {/*          );*/}
              {/*        })}*/}
              {/*      </>*/}
              {/*    );*/}
              {/*  }}*/}
              {/*</Form.List>*/}
            </Form>
          </div>
        </div>
        <div className={"device-presentation"}>
          <PhoneComponent
            style={{ position: "absolute", left: 700 }}
            color={corporateColor || DEFAULT_COLOR}
            decorForm={form}
            logo={logoBlob}
            size={"large"}
          />
        </div>
      </div>
      {survey?.status === SurveyStatus.ACTIVE && (
        <div className={"steps-action"}>
          <Button onClick={onCancel}>
            <Translate value={"cancel"} />
          </Button>
          <Button type={"primary"} onClick={submitHandler}>
            <Translate value={"save"} />
          </Button>
        </div>
      )}
    </>
  );
};

export default PersonalizationComponent;

const LogoLabel: FC = () => {
  return (
    <h4>
      <Translate value={"logo"} />
      <Tooltip
        title={
          <div>
            <span>
              <Translate value={"logoMinSizeHelpText"} />
              192x192 px
            </span>
            <br />
            <span>
              <Translate value={"logoMaxSizeHelpText"} />
              512x512 px
            </span>
            <br />
            <span>
              <Translate value={"logoMaxWeightHelpText"} />
              100 kB
            </span>
          </div>
        }
      >
        <QuestionCircleOutlined style={{ margin: "0 5px" }} />
      </Tooltip>
      :
    </h4>
  );
};

const FaviconLabel: FC = () => {
  return (
    <h4>
      Favicon
      <Tooltip
        title={
          <div>
            <span>
              <Translate value={"faviconMaxSizeHelpText"} />
              64x64 px
            </span>
            <br />
            <span>
              <Translate value={"logoMaxWeightHelpText"} />
              100 kB
            </span>
          </div>
        }
      >
        <QuestionCircleOutlined style={{ margin: "0 5px" }} />
      </Tooltip>
      :
    </h4>
  );
};

type ColorFieldProps = {
  onChange: (color: string) => void;
  initialColor?: string;
  defaultColor?: string;
};

const ColorField: FC<ColorFieldProps> = ({ onChange, initialColor, defaultColor }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Tooltip trigger={"hover"} title={I18n.t("useDefaultColor")}>
        <UndoOutlined className={"undo-icon"} onClick={() => onChange(defaultColor || DEFAULT_COLOR)} />
      </Tooltip>
      <input type={"color"} value={initialColor || DEFAULT_COLOR} onChange={(e) => onChange(e.currentTarget.value)} />
      <Input
        className={"hex-text"}
        value={initialColor || DEFAULT_COLOR}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
    </div>
  );
};

const toFormValues = (survey?: SurveyType): DecorFormType => {
  return {
    surveyMetadata: {
      metaDescription: survey?.surveyMetadata?.metaDescription || I18n.t("metaDescriptionDefaultText"),
      metaTitle: survey?.surveyMetadata?.metaTitle || survey?.surveyName,
      textTranslations: survey?.surveyMetadata?.textTranslations?.length
        ? survey?.surveyMetadata?.textTranslations
        : survey?.languages.map((lang) => ({ language: lang } as TextTranslationType)),
    },
    themeColor: survey?.themeColor || DEFAULT_COLOR,
    surveyDecor: {
      ...survey?.surveyDecor,
      title: survey?.surveyDecor?.title ?? "",
      textFont: survey?.surveyDecor?.textFont ?? FontsList.at(0),
      titleFont: survey?.surveyDecor?.titleFont ?? FontsList.at(0),
    } as SurveyDecor,
  };
};
